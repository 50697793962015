import { Injectable } from "@angular/core";
import * as _ from 'lodash';

@Injectable()
export class StandardMeasurementUtility {
    topConfig = {
        residentials: {
            ipms1: {
                ids: [
                    23 // IPMS 1 - House
                ],
                keys: {
                    a1_component: { name: 'Vertical Circulation Penetrations', description: 'Example - stairs, lift/elevator shafts and ducts, staircase openings.',}, 
                    a2_component: { name: 'Vertical Technical Penetrations', description: 'Example - pipes, shafts and ducts whose floor opening and surrounding walls, if any, is more than 0.1 m2/1ft2. Otherwise, the opening is not separately identified and remains in the Component Area where it is found. ' }, 
                    b1_component: { name: 'External Walls', description: 'Example - External structural walls, including windows and walls'}, 
                    b2_component: { name: 'Internal Structural Elements', description: 'Example - Internal structural walls, pillars and columns'}, 
                    b3_component: { name: 'Internal Non Structural Elements', description: 'Example - Internal non structural walls'}, 
                    c_component: { name: 'Technical Services', description: 'Example - plant rooms, lift/elevator motor rooms and maintenance rooms'}, 
                    d_component: { name: 'Hygiene Areas', description: 'Example - toilet facilities, cleaner’s cupboards, shower rooms and changing rooms'},
                    e_component: { name: 'Circulation Areas', description: 'Example - all horizontal circulation areas'},
                    f_component: {name: 'Amenities', description: 'Example - cafeterias, day care facilities, fitness areas and prayer rooms'},
                    g_component: {name: 'Living space', description: 'Example - residential living space'},
                    h_component: {name: 'Other areas', description: 'Example - covered galleries, balconies, verandas, internal permanent mezzanines, internal catwalks, sheltered areas, generally accessible rooftop terraces, internal car parking, storage rooms'},
                    total_component: null,
                    additional_component: null
                },
                smName: 'IPMS 1',
                additionalItemsKeys: [
                    'temporary_mezzanines', 
                    'open_light_wells',
                    'open_external_stairways_structure',
                    'external_areas'
                ]
            },
            ipms2: {
                ids: [
                    22 // IPMS 2 - House
                ],
                keys: {
                    a1_component: { name: 'Vertical Circulation Penetrations', description: 'Example - stairs, lift/elevator shafts and ducts, staircase openings.',}, 
                    a2_component: { name: 'Vertical Technical Penetrations', description: 'Example - pipes, shafts and ducts whose floor opening and surrounding walls, if any, is more than 0.1 m2/1ft2. Otherwise, the opening is not separately identified and remains in the Component Area where it is found. ' }, 
                    b2_component: { name: 'Internal Structural Elements', description: 'Example - Internal structural walls, pillars and columns'}, 
                    b3_component: { name: 'Internal Non Structural Elements', description: 'Example - Internal non structural walls'}, 
                    c_component: { name: 'Technical Services', description: 'Example - plant rooms, lift/elevator motor rooms and maintenance rooms'}, 
                    d_component: { name: 'Hygiene Areas', description: 'Example - toilet facilities, cleaner’s cupboards, shower rooms and changing rooms'},
                    e_component: { name: 'Circulation Areas', description: 'Example - all horizontal circulation areas'},
                    f_component: {name: 'Amenities', description: 'Example - cafeterias, day care facilities, fitness areas and prayer rooms'},
                    g_component: {name: 'Living space', description: 'Example - residential living space'},
                    h_component: {name: 'Other areas', description: 'Example - covered galleries, balconies, verandas, internal permanent mezzanines, internal catwalks, sheltered areas, generally accessible rooftop terraces, internal car parking, storage rooms'},
                    total_component: null,
                    additional_component: null
                },
                smName: 'IPMS 2',
                additionalItemsKeys: [
                    'temporary_mezzanines', 
                    'open_light_wells',
                    'open_external_stairways_structure',
                    'external_areas'
                ]
            }, 
            ipms3a: {
                ids: [
                    4, // IPMS 3A - Apartment
                    21 // IPMS 3A - House
                ],
                keys: {
                    a1_component: { name: 'Vertical Circulation Penetrations', description: 'Example - stairs at lowest level, lift/elevator shafts and ducts in exclusive use',}, 
                    a2_component: { name: 'Vertical Technical Penetrations < 0.25sqm', description: 'Example - pipes, shafts and ducts' }, 
                    b1_component: { name: 'External Walls', description: 'Example - External structural walls'}, 
                    b2_component: { name: 'Internal Structural Elements', description: 'Example - Internal structural walls and columns'}, 
                    b3_component: { name: 'Internal Non Structural Elements', description: 'Example - Internal non structural walls'}, 
                    c_component: { name: 'Technical Services', description: 'Example - plant rooms, lift/elevator motor rooms and maintenance rooms'}, 
                    d_component: { name: 'Hygiene Areas', description: 'Example - toilet facilities, cleaner’s cupboards, shower rooms and changing rooms'},
                    e_component: { name: 'Circulation Areas', description: 'Example - all horizontal circulation areas'},
                    f_component: {name: 'Amenities', description: 'Example - cafeterias, day care facilities, fitness areas and prayer rooms'},
                    g_component: {name: 'Living space', description: 'Example - Living room, dining room, bedroom'},
                    h_component: {name: 'Other areas', description: 'Example - Balconies, covered galleries, garages, loggia, attics'},
                    total_component: null,
                    additional_component: null
                },
                smName: 'IPMS 3A',
                additionalItemsKeys: [
                    'patios', 
                    'unenclosed_parking_areas',
                    'staircases_opening_above',
                    'void_including_enclosing_wall',
                    'vertical_penetrations'
                ]
            },
            ipms3b: {
                ids: [
                    5, // IPMS 3B - Apartment
                    20 // IPMS 3A - Apartment
                ],
                keys: {
                    a1_component: { name: 'Vertical Circulation Penetrations', description: 'Example - stairs at lowest level, lift/elevator shafts and ducts in exclusive use',}, 
                    a2_component: { name: 'Vertical Technical Penetrations < 0.25sqm', description: 'Example - pipes, shafts and ducts' }, 
                    b2_component: { name: 'Internal Structural Elements', description: 'Example - Internal structural walls and columns'}, 
                    b3_component: { name: 'Internal Non Structural Elements', description: 'Example - Internal non structural walls'}, 
                    c_component: { name: 'Technical Services', description: 'Example - plant rooms, lift/elevator motor rooms and maintenance rooms'}, 
                    d_component: { name: 'Hygiene Areas', description: 'Example - toilet facilities, bathroom'},
                    e_component: { name: 'Circulation Areas', description: 'Example - all horizontal circulation areas'},
                    f_component: {name: 'Amenities', description: 'Example - cafeterias, day care facilities, fitness areas and prayer rooms'},
                    g_component: {name: 'Living space', description: 'Example - Living room, dining room, bedroom'},
                    h_component: {name: 'Other areas', description: 'Example - Balconies, covered galleries, garages, loggia, attics'},
                    total_component: null,
                    additional_component: null
                },
                smName: 'IPMS 3B',
                additionalItemsKeys: [
                    'patios', 
                    'unenclosed_parking_areas',
                    'staircases_opening_above',
                    'void_including_enclosing_wall',
                    'vertical_penetrations'
                ]
            },
            ipms3c: {
                ids: [
                    6, // IPMS 3C - Apartment
                    16, // IPMS 3C - House
                ],
                keys: {
                    a1_component: { name: 'Vertical Circulation Penetrations', description: 'Example - stairs at lowest level, lift/elevator shafts and ducts in exclusive use',}, 
                    a2_component: { name: 'Vertical Technical Penetrations < 0.25sqm', description: 'Example - pipes, shafts and ducts' }, 
                    c_component: { name: 'Technical Services', description: 'Example - plant rooms, lift/elevator motor rooms and maintenance rooms'}, 
                    d_component: { name: 'Hygiene Areas', description: 'Example - toilet facilities, bathroom'},
                    e_component: { name: 'Circulation Areas', description: 'Example - all horizontal circulation areas'},
                    f_component: {name: 'Amenities', description: 'Example - Kitchen'},
                    g_component: {name: 'Living space', description: 'Example - Living room, dining room, bedroom'},
                    h_component: {name: 'Other areas', description: 'Example - Balconies, covered galleries, garages, loggia, attics'},
                    total_component: null,
                    additional_component: null
                },
                smName: 'IPMS 3C',
                additionalItemsKeys: [
                    'patios', 
                    'unenclosed_parking_areas',
                    'staircases_opening_above',
                    'void_including_enclosing_wall',
                    'vertical_penetrations'
                ]
            }, 
            mongolianStandard: {
                ids: [
                    7, // Mongolian Measurement Standards - Apartment
                    14, // Mongolian Measurement Standards - House
                ],
                keys: ['living_area', 'standard_facilities', 'external_areas', 'total']
            }
        },
        commercials: {
            ipms1_office: {
                ids: [
                    25, // IPMS 1 - Office
                ],
                keys: {
                    a1_component: { name: 'Vertical Circulation Penetrations', description: 'Example - stairs, lift/elevator shafts and ducts, staircase openings.',}, 
                    a2_component: { name: 'Vertical Technical Penetrations', description: 'Example - pipes, shafts and ducts whose floor opening and surrounding walls, if any, is more than 0.1 m2/1ft2. Otherwise, the opening is not separately identified and remains in the Component Area where it is found. ' }, 
                    b1_component: { name: 'External Walls', description: 'Example - External structural walls, including windows and walls'}, 
                    b2_component: { name: 'Internal Structural Elements', description: 'Example - Internal structural walls, pillars and columns'}, 
                    b3_component: { name: 'Internal Non Structural Elements', description: 'Example - Internal non structural walls'}, 
                    c_component: { name: 'Technical Services', description: 'Example - plant rooms, lift/elevator motor rooms and maintenance rooms'}, 
                    d_component: { name: 'Hygiene Areas', description: 'Example - toilet facilities, cleaner’s cupboards, shower rooms and changing rooms'},
                    e_component: { name: 'Circulation Areas', description: 'Example - all horizontal circulation areas'},
                    f_component: {name: 'Amenities', description: 'Example - cafeterias, day care facilities, fitness areas and prayer rooms'},
                    g_component: {name: 'Workspace', description: 'Example - private office rooms, conference rooms etc.'},
                    h_component: {name: 'Other areas', description: 'Example - covered galleries, balconies, verandas, internal permanent mezzanines, internal catwalks, sheltered areas, generally accessible rooftop terraces, internal car parking, storage rooms'},
                    total_component: null,
                    additional_component: null
                },
                smName: 'IPMS 1',
                additionalItemsKeys: [
                    'temporary_mezzanines', 
                    'open_light_wells',
                    'open_external_stairways_structure',
                    'external_areas'
                ]
            },
            ipms1_retail: {
                ids: [
                    26, // IPMS 1 - Retail Building
                ],
                keys: {
                    a1_component: { name: 'Vertical Circulation Penetrations', description: 'Example - stairs, lift/elevator shafts and ducts, staircase openings.',}, 
                    a2_component: { name: 'Vertical Technical Penetrations', description: 'Example - pipes, shafts and ducts whose floor opening and surrounding walls, if any, is more than 0.1 m2/1ft2. Otherwise, the opening is not separately identified and remains in the Component Area where it is found. ' }, 
                    b1_component: { name: 'External Walls', description: 'Example - External structural walls, including windows and walls'}, 
                    b2_component: { name: 'Internal Structural Elements', description: 'Example - Internal structural walls, pillars and columns'}, 
                    b3_component: { name: 'Internal Non Structural Elements', description: 'Example - Internal non structural walls'}, 
                    c_component: { name: 'Technical Services', description: 'Example - plant rooms, lift/elevator motor rooms and maintenance rooms'}, 
                    d_component: { name: 'Hygiene Areas', description: 'Example - toilet facilities, cleaner’s cupboards, shower rooms and changing rooms'},
                    e1_component: { name: 'Circulation Areas - mall', description: 'Example - all public (shopper) circulation space within the mall area fronting retail outlets. '},
                    e2_component: { name: 'Circulation Areas - others', description: 'Example - all other circulations areas other than those forming part of the mall (Component Area E1), measured horizontally'},
                    f_component: {name: 'Amenities', description: 'Example - food courts, child-minding facilities and prayer rooms for the benefit of shoppers.'},
                    g_component: {name: 'Retailing Areas', description: 'Example - retail outlets, areas designated for temporary retail stores and mall promotion areas.'},
                    h_component: {name: 'Other areas', description: 'Example - External Floor Area(s), covered galleries, internal car parking and storage rooms, whether or not in exclusive use. '},
                    total_component: null,
                    additional_component: null
                },
                smName: 'IPMS 1',
                additionalItemsKeys: [
                    'temporary_structures', 
                    'open_light_wells',
                    'open_external_stairways',
                    'any_structure_beyond',
                ]
            },
            ipms2_office: {
                ids: [
                    24, // IPMS 2 - Office
                ],
                keys: {
                    a1_component: { name: 'Vertical Circulation Penetrations', description: 'Example - stairs, lift/elevator shafts and ducts, staircase openings.',}, 
                    a2_component: { name: 'Vertical Technical Penetrations', description: 'Example - pipes, shafts and ducts whose floor opening and surrounding walls, if any, is more than 0.1 m2/1ft2. Otherwise, the opening is not separately identified and remains in the Component Area where it is found. ' }, 
                    b2_component: { name: 'Internal Structural Elements', description: 'Example - Internal structural walls, pillars and columns'}, 
                    b3_component: { name: 'Internal Non Structural Elements', description: 'Example - Internal non structural walls'}, 
                    c_component: { name: 'Technical Services', description: 'Example - plant rooms, lift/elevator motor rooms and maintenance rooms'}, 
                    d_component: { name: 'Hygiene Areas', description: 'Example - toilet facilities, cleaner’s cupboards, shower rooms and changing rooms'},
                    e_component: { name: 'Circulation Areas', description: 'Example - all horizontal circulation areas'},
                    f_component: {name: 'Amenities', description: 'Example - cafeterias, day care facilities, fitness areas and prayer rooms'},
                    g_component: {name: 'Workspace', description: 'Example - private office rooms, conference rooms etc.'},
                    h_component: {name: 'Other areas', description: 'Example - covered galleries, balconies, verandas, internal permanent mezzanines, internal catwalks, sheltered areas, generally accessible rooftop terraces, internal car parking, storage rooms'},
                    total_component: null,
                    additional_component: null
                },
                smName: 'IPMS 2',
                additionalItemsKeys: [
                    'temporary_mezzanines', 
                    'open_light_wells',
                    'open_external_stairways_structure',
                    'external_areas'
                ]
            },
            ipms2_retail: {
                ids: [
                    27, // IPMS 2 - Retail Building
                ],
                keys: {
                    a1_component: { name: 'Vertical Circulation Penetrations', description: 'Example - stairs, lift/elevator shafts and ducts, staircase openings.',}, 
                    a2_component: { name: 'Vertical Technical Penetrations', description: 'Example - pipes, shafts and ducts whose floor opening and surrounding walls, if any, is more than 0.1 m2/1ft2. Otherwise, the opening is not separately identified and remains in the Component Area where it is found. ' }, 
                    b2_component: { name: 'Internal Structural Elements', description: 'Example - Internal structural walls, pillars and columns'}, 
                    b3_component: { name: 'Internal Non Structural Elements', description: 'Example - Internal non structural walls'}, 
                    c_component: { name: 'Technical Services', description: 'Example - plant rooms, lift/elevator motor rooms and maintenance rooms'}, 
                    d_component: { name: 'Hygiene Areas', description: 'Example - toilet facilities, cleaner’s cupboards, shower rooms and changing rooms'},
                    e1_component: { name: 'Circulation Areas - mall', description: 'Example - all public (shopper) circulation space within the mall area fronting retail outlets. '},
                    e2_component: { name: 'Circulation Areas - others', description: 'Example - all other circulations areas other than those forming part of the mall (Component Area E1), measured horizontally'},
                    f_component: {name: 'Amenities', description: 'Example - food courts, child-minding facilities and prayer rooms for the benefit of shoppers.'},
                    g_component: {name: 'Retailing Areas', description: 'Example - retail outlets, areas designated for temporary retail stores and mall promotion areas.'},
                    h_component: {name: 'Other areas', description: 'Example - External Floor Area(s), covered galleries, internal car parking and storage rooms, whether or not in exclusive use. '},
                    total_component: null,
                    additional_component: null
                },
                smName: 'IPMS 2',
                additionalItemsKeys: [
                    'sheltered_areas', 
                    'external_bays',
                    'any_area_outside',
                ]
            },
            ipms3: {
                ids: [
                    8, // IPMS 3 - Office
                ],
                keys: {
                    b_component: {name: 'Structural Elements', description: 'Example - Internal walls, columns'},
                    e_component: {name: 'Circulation Areas', description: 'Example - all horizontal circulation areas'},
                    f_component: {name: 'Amenities', description: 'Example - cafeterias, day care facilities, fitness areas and prayer rooms'},
                    g_component: {name: 'Workspace', description: 'Example - private office rooms, conference rooms etc.'},
                    h_component: {name: 'Other areas', description: 'Example - covered galleries, balconies, verandas, internal permanent mezzanines, internal catwalks, sheltered areas, generally accessible rooftop terraces, internal car parking, storage rooms'},
                    total_component: null,
                    additional_component: null
                },
                smName: 'IPMS 3 (Office)',
                additionalItemsKeys: [
                    'patios', 
                    'unenclosed_parking_areas',
                    'staircases_opening_above',
                    'void_including_enclosing_wall',
                    'vertical_penetrations'
                ]
            },
            ipms3a: {
                ids: [
                    28, // IPMS 3A - Retail
                ],
                keys: {
                    a1_component: { name: 'Vertical Circulation Penetrations', description: 'Example - stairs, lift/elevator shafts and ducts, staircase openings.',}, 
                    a2_component: { name: 'Vertical Technical Penetrations', description: 'Example - pipes, shafts and ducts whose floor opening and surrounding walls, if any, is more than 0.1 m2/1ft2. Otherwise, the opening is not separately identified and remains in the Component Area where it is found. ' }, 
                    b1_component: { name: 'External Walls', description: 'Example - External structural walls, including windows and walls'}, 
                    b2_component: { name: 'Internal Structural Elements', description: 'Example - Internal structural walls, pillars and columns'}, 
                    b3_component: { name: 'Internal Non Structural Elements', description: 'Example - Internal non structural walls'}, 
                    c_component: { name: 'Technical Services', description: 'Example - plant rooms, lift/elevator motor rooms and maintenance rooms'}, 
                    d_component: { name: 'Hygiene Areas', description: 'Example - toilet facilities, cleaner’s cupboards, shower rooms and changing rooms'},
                    e1_component: { name: 'Circulation Areas - mall', description: 'Example - all public (shopper) circulation space within the mall area fronting retail outlets. '},
                    e2_component: { name: 'Circulation Areas - others', description: 'Example - all other circulations areas other than those forming part of the mall (Component Area E1), measured horizontally'},
                    f_component: {name: 'Amenities', description: 'Example - food courts, child-minding facilities and prayer rooms for the benefit of shoppers.'},
                    g_component: {name: 'Retailing Areas', description: 'Example - retail outlets, areas designated for temporary retail stores and mall promotion areas.'},
                    h_component: {name: 'Other areas', description: 'Example - External Floor Area(s), covered galleries, internal car parking and storage rooms, whether or not in exclusive use. '},
                    total_component: null,
                    additional_component: null
                },
                smName: 'IPMS 3A',
                additionalItemsKeys: [
                    'temporary_structures', 
                    'open_light_wells'
                ]
            },
            ipms3b: {
                ids: [
                    29, // IPMS 3B - Retail
                ],
                keys: {
                    a1_component: { name: 'Vertical Circulation Penetrations', description: 'Example - stairs, lift/elevator shafts and ducts, staircase openings.',}, 
                    a2_component: { name: 'Vertical Technical Penetrations', description: 'Example - pipes, shafts and ducts whose floor opening and surrounding walls, if any, is more than 0.1 m2/1ft2. Otherwise, the opening is not separately identified and remains in the Component Area where it is found. ' }, 
                    b2_component: { name: 'Internal Structural Elements', description: 'Example - Internal structural walls, pillars and columns'}, 
                    b3_component: { name: 'Internal Non Structural Elements', description: 'Example - Internal non structural walls'}, 
                    c_component: { name: 'Technical Services', description: 'Example - plant rooms, lift/elevator motor rooms and maintenance rooms'}, 
                    d_component: { name: 'Hygiene Areas', description: 'Example - toilet facilities, cleaner’s cupboards, shower rooms and changing rooms'},
                    e1_component: { name: 'Circulation Areas - mall', description: 'Example - all public (shopper) circulation space within the mall area fronting retail outlets. '},
                    e2_component: { name: 'Circulation Areas - others', description: 'Example - all other circulations areas other than those forming part of the mall (Component Area E1), measured horizontally'},
                    f_component: {name: 'Amenities', description: 'Example - food courts, child-minding facilities and prayer rooms for the benefit of shoppers.'},
                    g_component: {name: 'Workspace', description: 'Example - retail outlets, areas designated for temporary retail stores and mall promotion areas.'},
                    h_component: {name: 'Other areas', description: 'Example - External Floor Area(s), covered galleries, internal car parking and storage rooms, whether or not in exclusive use. '},
                    total_component: null,
                    additional_component: null
                },
                smName: 'IPMS 3B',
                additionalItemsKeys: [
                    'sheltered_areas',
                    'temporary_structures', 
                    'any_area_outside'
                ]
            },
            ipms3c: {
                ids: [
                    30, // IPMS 3C - Retail
                ],
                keys: {
                    a1_component: { name: 'Vertical Circulation Penetrations', description: 'Example - stairs, lift/elevator shafts and ducts, staircase openings.',}, 
                    a2_component: { name: 'Vertical Technical Penetrations', description: 'Example - pipes, shafts and ducts whose floor opening and surrounding walls, if any, is more than 0.1 m2/1ft2. Otherwise, the opening is not separately identified and remains in the Component Area where it is found. ' }, 
                    c_component: { name: 'Technical Services', description: 'Example - plant rooms, lift/elevator motor rooms and maintenance rooms'}, 
                    d_component: { name: 'Hygiene Areas', description: 'Example - toilet facilities, cleaner’s cupboards, shower rooms and changing rooms'},
                    e1_component: { name: 'Circulation Areas - mall', description: 'Example - all public (shopper) circulation space within the mall area fronting retail outlets. '},
                    e2_component: { name: 'Circulation Areas - others', description: 'Example - all other circulations areas other than those forming part of the mall (Component Area E1), measured horizontally'},
                    f_component: {name: 'Amenities', description: 'Example - food courts, child-minding facilities and prayer rooms for the benefit of shoppers.'},
                    g_component: {name: 'Retailing Areas', description: 'Example - retail outlets, areas designated for temporary retail stores and mall promotion areas.'},
                    h_component: {name: 'Other areas', description: 'Example - External Floor Area(s), covered galleries, internal car parking and storage rooms, whether or not in exclusive use. '},
                    total_component: null,
                    additional_component: null
                },
                smName: 'IPMS 3C',
                additionalItemsKeys: [
                    'temporary_mezzanines',
                ]
            },
            // mongolianStandard: {
            //     ids: [
            //         9, // Mongolia Measurement Standards - Office
            //         17, // Mongolian Measurement Standards - Retail
            //         18, // Mongolia Measurement Standards - Retail Building
            //     ]
            // }
        },
        industrial: {
            ipms1: {
                ids: [
                    31, // IPMS 1 - Warehouse
                ],
                keys: {
                    a1_component: { name: 'Vertical Circulation Penetrations', description: 'Example - stairs, lift/elevator shafts and ducts, staircase openings.',}, 
                    a2_component: { name: 'Vertical Technical Penetrations', description: 'Example - pipes, shafts and ducts whose floor opening and surrounding walls, if any, is more than 0.1 m2/1ft2. Otherwise, the opening is not separately identified and remains in the Component Area where it is found. ' }, 
                    b1_component: { name: 'External Walls', description: 'Example - External structural walls, including windows and walls'}, 
                    b2_component: { name: 'Internal Structural Elements', description: 'Example - Internal structural walls, pillars and columns'}, 
                    b3_component: { name: 'Internal Non Structural Elements', description: 'Example - Internal non structural walls'}, 
                    c_component: { name: 'Technical Services', description: 'Example - plant rooms, lift/elevator motor rooms and maintenance rooms'}, 
                    d_component: { name: 'Hygiene Areas', description: 'Example - toilet facilities, cleaner’s cupboards, shower rooms and changing rooms'},
                    e_component: { name: 'Circulation Areas', description: 'Example - all horizontal circulation areas'},
                    f_component: {name: 'Amenities', description: 'Example - cafeterias, day care facilities, fitness areas and prayer rooms'},
                    g_component: {name: 'Workspace', description: 'Example - Warehouse, production, office, showroom, mezzanine and enclosed loading docks'},
                    h_component: {name: 'Other areas', description: 'Example - balconies, ancillary areas, mezzanines, catwalks, unenclosed loading docks, sheltered areas'},
                    total_component: null,
                    additional_component: null
                },
                smName: 'IPMS 1',
                additionalItemsKeys: [
                    'aggregate_non_limited', 
                    'sheltered_areas', 
                    'any_other_areas',
                    'external_bays',
                    'open_light_wells'
                ]
            },
            ipms2: {
                ids: [
                    32, // IPMS 2 - Warehouse
                ],
                keys: {
                    a1_component: { name: 'Vertical Circulation Penetrations', description: 'Example - stairs, lift/elevator shafts and ducts',}, 
                    a2_component: { name: 'Vertical Technical Penetrations', description: 'Example - pipes, shafts and ducts' }, 
                    b2_component: { name: 'Internal Structural Elements', description: 'Example - Internal structural walls and columns'}, 
                    b3_component: { name: 'Internal Non Structural Elements', description: 'Example - Internal non structural walls'}, 
                    c_component: { name: 'Technical Services', description: 'Example - plant rooms, lift/elevator motor rooms and maintenance rooms'}, 
                    d_component: { name: 'Hygiene Areas', description: 'Example - toilet facilities, cleaner’s cupboards, shower rooms and changing rooms'},
                    e_component: { name: 'Circulation Areas', description: 'Example - all horizontal circulation areas'},
                    f_component: {name: 'Amenities', description: 'Example - cafeterias, day care facilities, fitness areas and prayer rooms'},
                    g_component: {name: 'Workspace', description: 'Example - Warehouse, production, office, showroom, mezzanine and enclosed loading docks'},
                    h_component: {name: 'Other areas', description: 'Example - balconies, ancillary areas, mezzanines, catwalks, unenclosed loading docks, sheltered areas'},
                    total_component: null,
                    additional_component: null
                },
                smName: 'IPMS 2',
                additionalItemsKeys: [
                    'aggregate_non_limited', 
                    'sheltered_areas', 
                    'any_other_areas',
                    'external_bays',
                    'open_light_wells'
                ]
            },
            ipms3a: {
                ids: [
                    33, // IPMS 3A - Warehouse
                ],
                keys: {
                    a1_component: { name: 'Vertical Circulation Penetrations', description: 'Example - stairs, lift/elevator shafts and ducts',}, 
                    a2_component: { name: 'Vertical Technical Penetrations', description: 'Example - pipes, shafts and ducts' }, 
                    b1_component: { name: 'External Walls', description: 'Example - External structural walls'}, 
                    b2_component: { name: 'Internal Structural Elements', description: 'Example - Internal structural walls and columns'}, 
                    b3_component: { name: 'Internal Non Structural Elements', description: 'Example - Internal non structural walls'}, 
                    c_component: { name: 'Technical Services', description: 'Example - plant rooms, lift/elevator motor rooms and maintenance rooms'}, 
                    d_component: { name: 'Hygiene Areas', description: 'Example - toilet facilities, cleaner’s cupboards, shower rooms and changing rooms'},
                    e_component: { name: 'Circulation Areas', description: 'Example - all horizontal circulation areas'},
                    f_component: {name: 'Amenities', description: 'Example - cafeterias, day care facilities, fitness areas and prayer rooms'},
                    g_component: {name: 'Workspace', description: 'Example - Warehouse, production, office, showroom, mezzanine and enclosed loading docks'},
                    h_component: {name: 'Other areas', description: 'Example - balconies, ancillary areas, mezzanines, catwalks, unenclosed loading docks, sheltered areas'},
                    total_component: null,
                    additional_component: null
                },
                smName: 'IPMS 3A',
                additionalItemsKeys: [
                    'external_car_parking', 
                    'temporary_structures', 
                    'open_external_stairways',
                    'any_structures_beyond',
                    'open_light_wells'
                ]
            },
            ipms3b: {
                ids: [
                    34, // IPMS 3B - Warehouse
                ],
                keys: {
                    a1_component: { name: 'Vertical Circulation Penetrations', description: 'Example - stairs at lowest level only, lift/elevator shafts and ducts',}, 
                    a2_component: { name: 'Vertical Technical Penetrations', description: 'Example - pipes, shafts and ducts' }, 
                    b2_component: { name: 'Internal Structural Elements', description: 'Example - Internal structural walls and columns'}, 
                    b3_component: { name: 'Internal Non Structural Elements', description: 'Example - Internal non structural walls'}, 
                    c_component: { name: 'Technical Services', description: 'Example - plant rooms, lift/elevator motor rooms and maintenance rooms'}, 
                    d_component: { name: 'Hygiene Areas', description: 'Example - toilet facilities, cleaner’s cupboards, shower rooms and changing rooms'},
                    e_component: { name: 'Circulation Areas', description: 'Example - all horizontal circulation areas'},
                    f_component: {name: 'Amenities', description: 'Example - cafeterias, day care facilities, fitness areas and prayer rooms'},
                    g_component: {name: 'Workspace', description: 'Example - Warehouse, production, office, showroom, mezzanine and enclosed loading docks'},
                    h_component: {name: 'Other areas', description: 'Example - balconies, ancillary areas, mezzanines, catwalks, unenclosed loading docks, sheltered areas'},
                    total_component: null,
                    additional_component: null
                },
                smName: 'IPMS 3B',
                additionalItemsKeys: [
                    'external_car_parking', 
                    'temporary_structures', 
                    'any_area_outside',
                    'other'
                ]
            },
            // mongolianStandard: {
            //     ids: [
            //         15, // Mongolian standards - Warehouse
            //     ]
            // } 
        }
    }

    private _getIds(type: 'ipms' | 'mongolianStandard'): number[] {
        const comp = 
            type == 'ipms' 
                ? (key: string) => key != 'mongolianStandard'
                : (key: string) => key == 'mongolianStandard'

        const idss = Object.values(this.topConfig).map(v => {
            const res = Object.entries(v)
                .filter(([key, value]) => {
                    return comp(key);
                })
                .map(([key ,value]) => {
                    return value.ids;
                })
                .reduce((item, arr) => _.concat(arr, item), []);
            return res
        });
        return idss.reduce((v, acc) => _.concat(acc, v), []);
    }

    isIPMS(id: number): boolean {
        const ids = this._getIds('ipms');
        return ids.includes(id);
    }

    isMongolianStandard(id: number): boolean {
        const ids = this._getIds('mongolianStandard');
        return ids.includes(id)
    }

    checkHasOwnTable(standard_measurement_id: number): boolean {
        const entry = Object.entries(this.topConfig).find(([key, value]) => {
            const ids = Object.values(value).map(v => v.ids).reduce((ids, arr) => _.concat(arr, ids), []);
            return ids.includes(standard_measurement_id);
        });
        return entry ? true : false;
    }

    private _getIPMSConfig(tConfig: any): any[] {
        const keys = _.keys(tConfig.keys);
        const configs = this.ipmsConfig
            .filter(c => keys.includes(c.key))
            .map(nc => {
                const c = _.cloneDeep(nc);
                if (c.key == 'additional_component') {
                    c.items = c.items.filter(item => tConfig.additionalItemsKeys.includes(item.key));
                    c.title = `${c.title} ${tConfig.smName}`
                }
                if (c.key == 'total_component') {
                    c.title = `${c.title} ${tConfig.smName}`
                }
                const tc = tConfig.keys[c.key];
                if (!tc) {
                    return c;
                }
                c['name'] = tc.name;
                c['description'] = tc.description;
                return c;
            })
        return configs;
    }

    private _getMongolianStandardConfig(tConfig: any): any[] {
        const keys = tConfig.keys;
        let configs = this.msConfig
            .filter(c => keys.includes(c.key))
        configs = this._changeIden(configs);
        return configs
    }

    private _changeIden(configs: any[]): any[] {
        let cs = _.cloneDeep(configs);
        let count = 0;
        cs = cs.map(item => {
            item.num = count;
            count = item.items.length;
        });
        return cs
    }

    getConfig(standard_measurement_id: number, type: 'ipms' | 'mongolianStandard'): any[] {
        const t = Object
            .values(this.topConfig)
            .map(v => Object.values(v).reduce((v, arr) => _.concat(arr, v), []))
            .reduce((v, arr) => _.concat(arr, v), [])
        const tConfig = t.find(c => c.ids.includes(standard_measurement_id));
        if (!tConfig || !tConfig.keys) {
            return [];
        }
        return type == 'ipms' ? this._getIPMSConfig(tConfig) : this._getMongolianStandardConfig(tConfig)
    }

    getComponents(sm_id: number): any[] {
        // Office Mongolian
        if (sm_id == 9) {
            // IPMS 3C
            return this.getConfig(8, 'ipms');
        }
        if ([17, 18].includes(sm_id)) {
            return this.getConfig(30, 'ipms');
        }
        if (sm_id == 15) {
            return this.getConfig(34, 'ipms');
        }
        return [];
    }

    private msConfig = [
        {
            title: 'Living Area',
            key: 'living_area',
            items: [
                {
                    title: 'Living room',
                    key: 'living_room'
                },
                {
                    title: 'Working space/office room',
                    key: 'working_space'
                }
            ]
        },
        {
            title: 'Standard Facilities',
            key: 'standard_facilities',
            items: [
                {
                    title: 'Kitchen',
                    key: 'kitchen'
                },
                {
                    title: 'Bar/food area',
                    key: 'food_area'
                }
            ]
        },
        // {
        //     title: 'External Areas',
        //     key: 'external_areas'
        // },
        {
            title: 'Total Area (NIA)',
            key: 'total',
            items: [
                {
                    title: 'Sa total',
                    key: 'sa_total'
                }
            ]
        }
    ];

    private ipmsConfig = [
        {
            title: 'A1',
            primaryColor: '#7277B8',
            secondaryColor: '#9498CA',
            lightColor: '#E4E5F2',
            key: 'a1_component',
            items: [
                {
                    title: 'IPMS Total',
                    type: 'input',
                    key: 'ipms_total'
                }
            ]
        },
        {
            title: 'A2',
            primaryColor: '#9C81B9',
            secondaryColor: '#B6A0CB',
            lightColor: '#EDE7F2',
            key: 'a2_component',
            items: [
                {
                    title: 'IPMS Total',
                    type: 'input',
                    key: 'ipms_total'
                }
            ]
        },
        {
            title: 'B',
            primaryColor: '#F9493D',
            secondaryColor: '#FD7A6F',
            lightColor: '#FED2CE',
            key: 'b_component',
            items: [
                {
                    title: 'Total area',
                    type: 'input',
                    key: 'total'
                },
                {
                    title: 'Limited use areas',
                    type: 'input',
                    key: 'limited'
                },
                {
                    title: 'IPMS Total',
                    type: 'static',
                    key: 'ipms_total'
                }
            ]
        },
        {
            title: 'B1',
            primaryColor: '#C6377F',
            secondaryColor: '#DB79A8',
            lightColor: '#EFBDD6',
            key: 'b1_component',
            items: [
                {
                    title: 'Total area',
                    type: 'input',
                    key: 'total'
                },
                {
                    title: 'Limited use areas',
                    type: 'input',
                    key: 'limited'
                },
                {
                    title: 'IPMS Total',
                    type: 'static',
                    key: 'ipms_total'
                }
            ]
        },
        {
            title: 'B2',
            primaryColor: '#F9493D',
            secondaryColor: '#FD7A6F',
            lightColor: '#FED2CE',
            key: 'b2_component',
            items: [
                {
                    title: 'Total area',
                    type: 'input',
                    key: 'total'
                },
                {
                    title: 'Limited use areas',
                    type: 'input',
                    key: 'limited'
                },
                {
                    title: 'IPMS Total',
                    type: 'static',
                    key: 'ipms_total'
                }
            ]
        },
        {
            title: 'B3',
            primaryColor: '#F790B2',
            secondaryColor: '#FAACC5',
            lightColor: '#FDE4EC',
            key: 'b3_component',
            items: [
                {
                    title: 'Total area',
                    type: 'input',
                    key: 'total'
                },
                {
                    title: 'Limited use areas',
                    type: 'input',
                    key: 'limited'
                },
                {
                    title: 'IPMS Total',
                    type: 'static',
                    key: 'ipms_total'
                }
            ]
        },
        {
            title: 'C',
            primaryColor: '#88FA59',
            secondaryColor: '#A8FB85',
            lightColor: '#E2FED7',
            key: 'c_component',
            items: [
                {
                    title: 'Total area',
                    type: 'input',
                    key: 'total'
                },
                {
                    title: 'Limited use areas',
                    type: 'input',
                    key: 'limited'
                },
                {
                    title: 'IPMS Total',
                    type: 'static',
                    key: 'ipms_total'
                }
            ]
        },
        {
            title: 'D',
            primaryColor: '#FE9222',
            secondaryColor: '#FEAD5F',
            lightColor: '#FFE3C9',
            key: 'd_component',
            items: [
                {
                    title: 'Total area',
                    type: 'input',
                    key: 'total'
                },
                {
                    title: 'Limited use areas',
                    type: 'input',
                    key: 'limited'
                },
                {
                    title: 'IPMS Total',
                    type: 'static',
                    key: 'ipms_total'
                }
            ]
        },
        {
            title: 'E',
            primaryColor: '#FCFC6F',
            secondaryColor: '#FEFD96',
            lightColor: '#FEFEdc',
            key: 'e_component',
            items: [
                {
                    title: 'Total area',
                    type: 'input',
                    key: 'total'
                },
                {
                    title: 'Limited use areas',
                    type: 'input',
                    key: 'limited'
                },
                {
                    title: 'IPMS Total',
                    type: 'static',
                    key: 'ipms_total'
                }
            ]
        },
        {
            title: 'E1',
            primaryColor: '#FCFC6F',
            secondaryColor: '#FEFD96',
            lightColor: '#FEFEdc',
            key: 'e1_component',
            items: [
                {
                    title: 'Total area',
                    type: 'input',
                    key: 'total'
                },
                {
                    title: 'Limited use areas',
                    type: 'input',
                    key: 'limited'
                },
                {
                    title: 'IPMS Total',
                    type: 'static',
                    key: 'ipms_total'
                }
            ]
        },
        {
            title: 'E2',
            primaryColor: '#FEF062',
            secondaryColor: '#FEF172',
            lightColor: '#FEFEdc',
            key: 'e2_component',
            items: [
                {
                    title: 'Total area',
                    type: 'input',
                    key: 'total'
                },
                {
                    title: 'Limited use areas',
                    type: 'input',
                    key: 'limited'
                },
                {
                    title: 'IPMS Total',
                    type: 'static',
                    key: 'ipms_total'
                }
            ]
        },
        {
            title: 'F',
            primaryColor: '#21B11D',
            secondaryColor: '#67C55C',
            lightColor: '#CEECC9',
            key: 'f_component',
            items: [
                {
                    title: 'Total area',
                    type: 'input',
                    key: 'total'
                },
                {
                    title: 'Limited use areas',
                    type: 'input',
                    key: 'limited'
                },
                {
                    title: 'IPMS Total',
                    type: 'static',
                    key: 'ipms_total'
                }
            ]
        },
        {
            title: 'G',
            primaryColor: '#76FCE9',
            secondaryColor: '#9DFDF0',
            lightColor: '#DFFDF9',
            key: 'g_component',
            items: [
                {
                    title: 'Total area',
                    type: 'input',
                    key: 'total'
                },
                {
                    title: 'Limited use areas',
                    type: 'input',
                    key: 'limited'
                },
                {
                    title: 'IPMS Total',
                    type: 'static',
                    key: 'ipms_total'
                }
            ]
        },
        {
            title: 'H',
            primaryColor: '#2891D2',
            secondaryColor: '#66ADDD',
            lightColor: '#CEE4F4',
            key: 'h_component',
            items: [
                {
                    title: 'Total area',
                    type: 'input',
                    key: 'total'
                },
                {
                    title: 'Limited use areas',
                    type: 'input',
                    key: 'limited'
                },
                {
                    title: 'IPMS Total',
                    type: 'static',
                    key: 'ipms_total'
                }
            ]
        },
        {
            title: 'TOTAL',
            primaryColor: '#d98B81',
            secondaryColor: '#E3ACA6',
            lightColor: '#F6E4E0',
            key: 'total_component',
            items: [
                {
                    title: 'Total area',
                    type: 'static_total',
                    key: 'total'
                },
                {
                    title: 'Limited use areas',
                    type: 'static_total',
                    key: 'limited'
                },
                {
                    title: 'IPMS Total',
                    type: 'static',
                    key: 'ipms_total'
                }
            ]
        },
        {
            title: 'ADDITIONAL AREAS OUTSIDE',
            primaryColor: '#AEAEAE',
            secondaryColor: '#C2C2C2',
            lightColor: '#EBEBEB',
            key: 'additional_component',
            items: [
                {
                    title: 'Aggregate non-limited use Component Areas',
                    type: 'input',
                    key: 'aggregate_non_limited'
                },
                {
                    title: 'Sheltered areas',
                    type: 'input',
                    key: 'sheltered_areas'
                },
                {
                    title: 'Any other areas (Example - equipment yards, cooling)',
                    type: 'input',
                    key: 'any_other_areas'
                },
                {
                    title: 'External Loading bays',
                    type: 'input',
                    key: 'external_bays'
                },
                {
                    title: 'External car parking',
                    type: 'input',
                    key: 'external_car_parking'
                },
                {
                    title: 'Temporary structures',
                    type: 'input',
                    key: 'temporary_structures'
                },
                {
                    title: 'Temporary mezzanines',
                    type: 'input',
                    key: 'temporary_mezzanines'
                },
                {
                    title: 'Open light wells / upper voids of an atrium',
                    type: 'input',
                    key: 'open_light_wells'
                },
                {
                    title: 'Open external stairways that are not integral part of the structure',
                    type: 'input',
                    key: 'open_external_stairways_structure'
                },
                {
                    title: 'External areas such as external vehicle parking, external catwalks, vehicle circulation and other areas or structures',
                    type: 'input',
                    key: 'external_areas'
                },
                {
                    title: 'Open external stairways that are not integral part of the building',
                    type: 'input',
                    key: 'open_external_stairways'
                },
                {
                    title: 'Any structures beyond the covered area',
                    type: 'input',
                    key: 'any_structures_beyond'
                },
                {
                    title: 'Any area outside the External Wall',
                    type: 'input',
                    key: 'any_area_outside'
                },
                {
                    title: 'Patios',
                    type: 'input',
                    key: 'patios'
                },
                {
                    title: 'Unenclosed Parking areas',
                    type: 'input',
                    key: 'unenclosed_parking_areas'
                },
                {
                    title: 'Staircases opening above level 0',
                    type: 'input',
                    key: 'staircases_opening_above'
                },
                {
                    title: 'Voids including the enclosing wall > 0.25 sqm',
                    type: 'input',
                    key: 'void_including_enclosing_wall'
                },
                {
                    title: 'Vertical penetrations part of common facilities',
                    type: 'input',
                    key: 'vertical_penetrations'
                },
                {
                    title: 'Other (...)',
                    type: 'input',
                    key: 'other'
                },
            ]
        }
    ]
}