<div class="kt-login-body">
  <div class="kt-login-form">
    <div class="mad-login-form">
      <img src="assets/media/interval/logo.png" class="interval-blue-logo" alt="InterVal logo">
      <div style="background-color: #333; width:450px; border-radius: 4px;">
        <h1 style="color: #fff; font-size: 32px;
        font-weight: bold;
        line-height: 40px;
        margin-top: 0;
        text-align: center; padding-top: 4rem;">Thank You</h1>


        <div class="wrapper">
          <p>
            You have successfully registered your new account with InterVal. We are generating your valuation environment. A confirmation email will be sent to you within 15 minutes.
          </p>
          <p>
            Please check you registered email and follow the instructions to start using InterVal.
          </p>
          <p>
            If you any questions,
            <br />
            contact us at <strong>info&#64;interval-soft.com</strong>
          </p>
        </div>

      </div>
    </div>
  </div>
</div>
