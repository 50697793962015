<div class="col-md-12 mb-10 section-margin-top-60">
    <h5 class="my-3 text-mad text-uppercase">straight line method</h5>
    <hr class="active">
</div>
<div class="col-md-12 mat-table-wrapper section-margin-bottom-60" style="overflow: hidden;">
    <table class="table lmat-elevation-z8">
        <tbody>
            <ng-container *ngIf="data$|async as data">
                <tr>
                    <th>Headline Rent (per year) ({{tpCurrency}})</th>
                    <td>{{data.headlineRent | mask:'separator.2':{thousandSeparator: ','} }}</td>
                    <td>
                        <ng-template #popHeadlineRent>
                        <strong>Calculation:</strong> Headline Rent (per year)
                        <ng-container *ngIf="data.rent_input_amount_type === 'total'">
                            <ng-container *ngIf="data.rent_basis_id === 1">
                                <br> 
                                = <span class="text-blue">(Amount total ({{data.rent_basis_name}}))</span> <span class="text-red"> x 12</span>
                                <br>
                                = <span class="text-blue">{{data.total_consideration | mask:'separator.2':{thousandSeparator: ','} }}</span> <span class="text-red"> x 12</span>
                            </ng-container>
                            <ng-container *ngIf="data.rent_basis_id === 2">
                                <br> 
                                = <span class="text-blue">(Amount total ({{data.rent_basis_name}}))</span> <span class="text-red"> x 4</span>
                                <br>
                                = <span class="text-blue">{{data.total_consideration | mask:'separator.2':{thousandSeparator: ','} }}</span> <span class="text-red"> x 4</span>
                            </ng-container>
                            <ng-container *ngIf="data.rent_basis_id === 3">
                                <br> 
                                = <span class="text-blue">(Amount total ({{data.rent_basis_name}}))</span>
                                <br>
                                = <span class="text-blue">{{data.total_consideration | mask:'separator.2':{thousandSeparator: ','} }}</span>
                            </ng-container>
                        </ng-container>
                        <ng-container *ngIf="data.rent_input_amount_type === 'per_units'">
                            <ng-container *ngIf="data.rent_basis_id === 1">
                                <br> 
                                = <span class="text-blue">(Amount per unit ({{data.rent_basis_name}}))</span> <span class="text-red"> x </span> <span class="text-blue">(Size unit)</span> <span class="text-red"> x 12</span>
                                <br>
                                = <span class="text-blue">{{data.total_consideration | mask:'separator.2':{thousandSeparator: ','} }}</span> <span class="text-red"> x </span> <span class="text-blue">{{data.size ? data.size : 0}}</span> <span class="text-red"> x 12</span>
                            </ng-container>
                            <ng-container *ngIf="data.rent_basis_id === 2">
                                <br> 
                                = <span class="text-blue">(Amount per unit ({{data.rent_basis_name}}))</span> <span class="text-red"> x </span> <span class="text-blue">(Size unit)</span> <span class="text-red"> x 4</span>
                                <br>
                                = <span class="text-blue">{{data.total_consideration | mask:'separator.2':{thousandSeparator: ','} }}</span> <span class="text-red"> x </span> <span class="text-blue">{{data.size ? data.size : 0}}</span> <span class="text-red"> x 4</span>
                            </ng-container>
                            <ng-container *ngIf="data.rent_basis_id === 3">
                                <br> 
                                = <span class="text-blue">(Amount per unit ({{data.rent_basis_name}}))</span> <span class="text-red"> x </span> <span class="text-blue">(Size unit)</span>
                                <br>
                                = <span class="text-blue">{{data.total_consideration | mask:'separator.2':{thousandSeparator: ','} }}</span> <span class="text-red"> x </span> <span class="text-blue">{{data.size ? data.size : 0}}</span>
                            </ng-container>
                        </ng-container>
                        <br>
                        = <span class="text-green">{{data.headlineRent | mask:'separator.2':{thousandSeparator: ','} }}</span>
                        </ng-template>
                        <span popoverClass="my-popover" placement="top" container="body" [ngbPopover]="popHeadlineRent"
                            style="cursor: pointer;">
                            <mat-icon color="primary">information</mat-icon>
                        </span>
                    </td>
                </tr>
                <tr class="gray">
                    <th>Lease Duration (Years)</th>
                    <td colspan="2">{{consideration.lease_duration}}</td>
                </tr>
                <tr>
                    <th>Rent Free Period (Years)</th>
                    <td colspan="2">{{rent_free_period | mask:'separator.2'}}</td>
                </tr>
                <tr class="gray">
                    <th>Fitting out Period (Years)</th>
                    <td colspan="2">{{fitting_out_period | mask:'separator.2'}}</td>
                </tr>
                <tr>
                    <th>Received for (Years)</th>
                    <td>{{data.receivedFor}}</td>
                    <td>
                        <ng-template #popReceivedFor>
                            <strong>Calculation:</strong> Received for (years)
                            <br>
                            = <span class="text-blue">(Lease Duration)</span> <span class="text-red"> - </span> <span
                                class="text-blue">(Rent Free Period)</span>
                            <br>
                            = <span class="text-blue">{{consideration.lease_duration}}</span> <span class="text-red"> -
                            </span> <span class="text-blue">{{rent_free_period|mask:'separator.2'}}</span>
                            <br>
                            = <span class="text-green">{{data.receivedFor}}</span>
                        </ng-template>
                        <span popoverClass="my-popover" placement="top" container="body" [ngbPopover]="popReceivedFor"
                            style="cursor: pointer;">
                            <mat-icon color="primary">information</mat-icon>
                        </span>
                    </td>
                </tr>
                <tr class="gray">
                    <th>Capital Value of Headline Rent ({{tpCurrency}})</th>
                    <td>{{data.capitalValueOfHeadlineRent | mask:'separator.2':{thousandSeparator: ','} }}</td>
                    <td>
                        <ng-template #popCapValHeadline>
                            <strong>Calculation:</strong> Capital Value of Headline Rent
                            <br>
                            = <span class="text-blue">(Headline Rent per Year)</span> <span class="text-red"> x </span>
                            <span class="text-blue">(Received for)</span>
                            <br>
                            = <span class="text-blue">{{data.headlineRent | mask:'separator.2':{thousandSeparator: ','}
                                }}</span> <span class="text-red"> x </span> <span
                                class="text-blue">{{data.receivedFor}}</span>
                            <br>
                            = <span class="text-green">{{data.capitalValueOfHeadlineRent |
                                mask:'separator.2':{thousandSeparator: ','} }}</span>
                        </ng-template>
                        <span popoverClass="my-popover" placement="top" container="body"
                            [ngbPopover]="popCapValHeadline" style="cursor: pointer;">
                            <mat-icon color="primary">information</mat-icon>
                        </span>
                    </td>
                </tr>
                <tr>
                    <th>Capital Payment {{tpCurrency}}</th>
                    <td colspan="2">{{data.capital_payment | mask:'separator.2':{thousandSeparator: ','} }}</td>
                </tr>
                <tr class="gray">
                    <th>Capital Value of Inducements ({{tpCurrency}})</th>
                    <td>{{data.capitalValueOfInducements | mask:'separator.2':{thousandSeparator: ','} }}</td>
                    <td>
                        <ng-template #popCapValInducement>
                            <strong>Calculation:</strong> Capital Value of Inducements
                            <br>
                            = <span class="text-blue">(Capital Value)</span> <span class="text-red"> - </span> <span
                                class="text-blue">(Capital Payment)</span>
                            <br>
                            = <span class="text-blue">{{data.capitalValueOfHeadlineRent |
                                mask:'separator.2':{thousandSeparator: ','} }}</span> <span class="text-red"> - </span>
                            <span class="text-blue">{{data.capital_payment ? (data.capital_payment | mask:'separator,') :
                                '0'}}</span>
                            <br>
                            = <span class="text-green">{{data.capitalValueOfInducements |
                                mask:'separator.2':{thousandSeparator: ','} }}</span>
                        </ng-template>
                        <span popoverClass="my-popover" placement="top" container="body"
                            [ngbPopover]="popCapValInducement" style="cursor: pointer;">
                            <mat-icon color="primary">information</mat-icon>
                        </span>
                    </td>
                </tr>
                <tr>
                    <th>Spread Over</th>
                    <td>{{data.spreadOver}}</td>
                    <td>
                        <ng-template #popSpreadOver>
                            <strong>Calculation:</strong> Spread Over
                            <br>
                            = <span class="text-blue">(Lease Duration)</span> <span class="text-red"> - </span> <span
                                class="text-blue">(Fitting out Period)</span>
                            <br>
                            = <span class="text-blue">{{consideration.lease_duration}}</span> <span class="text-red"> -
                            </span> <span class="text-blue">{{fitting_out_period|mask:'separator.2'}}</span>
                            <br>
                            = <span class="text-green">{{data.spreadOver}}</span>
                        </ng-template>
                        <span popoverClass="my-popover" placement="top" container="body" [ngbPopover]="popSpreadOver"
                            style="cursor: pointer;">
                            <mat-icon color="primary">information</mat-icon>
                        </span>
                    </td>
                </tr>
                <tr class="computed">
                    <th>Effective Rent (per year) ({{tpCurrency}})</th>
                    <td>{{data.effectiveRent.toFixed(2) | mask:'separator.2':{thousandSeparator: ','} }}</td>
                    <td>
                        <ng-template #popEffectiveRent>
                            <strong>Calculation:</strong> Effective Rent (per year)
                            <br>
                            = <span class="text-blue">(Capital Value of Inducements)</span> <span class="text-red"> /
                            </span> <span class="text-blue">(Spread Over)</span>
                            <br>
                            = <span class="text-blue">{{data.capitalValueOfInducements |
                                mask:'separator.2':{thousandSeparator: ','} }}</span> <span class="text-red"> / </span>
                            <span class="text-blue">{{data.spreadOver}}</span>
                            <br>
                            = <span class="text-green">{{data.effectiveRent.toFixed(2) |
                                mask:'separator.2':{thousandSeparator: ','} }}</span>
                        </ng-template>
                        <span popoverClass="my-popover" placement="top" container="body" [ngbPopover]="popEffectiveRent"
                            style="cursor: pointer;">
                            <mat-icon color="primary">information</mat-icon>
                        </span>
                    </td>
                </tr>
            </ng-container>
        </tbody>
    </table>
</div>