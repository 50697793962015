import { calculationResult } from "../../_selectors";
import { CostRevenue } from "../../types";
import { EffectiveRentUtil } from "../headline-rent";
import { groupCostRevenuesByPropertySubType } from "./utils";

export function financialInformation(
  items: CostRevenue[],
  sizes: {
    totalLeasedArea: number,
    tpSize: number
  },
  valuationDate: Date
) {
  const grouped = groupCostRevenuesByPropertySubType(items)

  return Object.entries(grouped).map(([key, values]) => {
    const mapped = values.map(item => {
      const effectiveRent = EffectiveRentUtil.getValue(item, sizes)
      const result = calculationResult(
        item,
        effectiveRent,
        sizes,
        valuationDate,
      )

      return {
        leaseNetInitialYield: leaseNetInitialYield(item, result.passingRent, result.value),
        leaseGrossInitialYield: leaseGrossInitialYield(item, effectiveRent, result.value),
        leaseReversionaryYield: undefined
      }
    })



    if (values.length == 1) {
      if (
        mapped.some(item => item.leaseNetInitialYield === undefined) ||
        mapped.some(item => item.leaseGrossInitialYield === undefined)
      ) {
        return {
          propertySubType: key,
          avgNetInitialYield: undefined,
          avgGrossInitialYield: undefined,
          avgRevisionaryYield: undefined
        }
      }
    }

    return {
      propertySubType: key,
      avgNetInitialYield: avgNetInitialYield(mapped),
      avgGrossInitialYield: avgGrossInitialYield(mapped),
      avgRevisionaryYield: undefined
    }
  })
}

function leaseNetInitialYield(
  item: CostRevenue,
  passingRent: number,
  result: number
) {
  if (!item.calculationMeta) return undefined
  if ([23, 24, 25].includes(item.calculationMeta.methodId)) return undefined
  return passingRent / result
}

function avgNetInitialYield(items: {leaseNetInitialYield: number}[]) {
  const validItems = items.filter(item => item.leaseNetInitialYield !== undefined)
  const total = validItems.reduce((acc, item) => acc + item.leaseNetInitialYield, 0)
  return (total / validItems.length) * 100
}

function leaseGrossInitialYield(
  item: CostRevenue,
  effectiveRent: number,
  result: number
) {
  if (!item.calculationMeta) return undefined
  if ([23, 24, 25].includes(item.calculationMeta.methodId)) return undefined
  return effectiveRent / result
}

function avgGrossInitialYield(items: {leaseGrossInitialYield: number}[]) {
  const validItems = items.filter(item => item.leaseGrossInitialYield !== undefined)
  const total = validItems.reduce((acc, item) => acc + item.leaseGrossInitialYield, 0)
  return (total / validItems.length) * 100
}