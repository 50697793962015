import { Action } from "@ngrx/store";
import { ConsiderationConversionData } from "../../valuation/_models/criterion.model";
import { VComparable } from "../_models";
import { ConsiderationCriterionModel } from "../_models/valuation-process-criterion.model";
import { ValuationAdjustmentTabDataModel } from "../_models/valuation-adjustment-tab-data.model";
import { CurrencyExchange } from "../_models/currency-exchange";

export enum ValuationProcessConsiderationCriterionActionTypes {
    CreateCriterion = '[Valuation Process Effect] Create Consideration Criterion',

    AddComparable = '[Valuation Process Effect] Add comparable to consideration criterion',
    RemoveComparable = '[Valuation Process Effect] Remove comparable from consideration criterion',
    AddMultipleComparables = '[Valuation Comparable Effect] Add multiple comparables to consideration criterion',

    UpdateCriterionValue = '[Criterions Table] Update consideration criterion value',
    UpdateCriterionValueBasedOnSize = '[Size Criterion Effects] Update consideration criterion value based on size',

    LoadCriterion = '[Valuation Process Effect] Load consideration criterion',
    ChangeConsiderationExchangeRate = '[Valuation Comparable Effect] Change Consideration Exchange Rate'
}

export class ValuationProcessCreateConsiderationCriterion implements Action {
    readonly type = ValuationProcessConsiderationCriterionActionTypes.CreateCriterion
    constructor(public payload: {
        tenure: string,
        considerationType: string,
        countryCurrency: string
    }) {}
}

export class ValuationProcessConsiderationCriterionAddComparable implements Action {
    readonly type = ValuationProcessConsiderationCriterionActionTypes.AddComparable
    constructor(public payload: {
        comparable: VComparable,
        selectedConsiderationId: number
    }) {}
}

export class ValuationProcessConsiderationCriterionAddMultipleComparables implements Action {
    readonly type = ValuationProcessConsiderationCriterionActionTypes.AddMultipleComparables
    constructor(public payload: {
        comparables: VComparable[],
        selectedConsiderations: {
            refNum: string,
            id: number
        }[],
        adjustmentTabData: ValuationAdjustmentTabDataModel
    }) {}
}

export class ValuationProcessConsiderationCriterionRemoveComparable implements Action {
    readonly type = ValuationProcessConsiderationCriterionActionTypes.RemoveComparable
    constructor(public payload: {
        refNum: string
    }) {}
}

export class ValuationProcessConsiderationCriterionUpdateValue implements Action {
    readonly type = ValuationProcessConsiderationCriterionActionTypes.UpdateCriterionValue
    constructor(public payload: {
        refNum: string,
        value: number,
        convertionData: ConsiderationConversionData
    }) {}
}

export class ValuationProcessConsiderationCriterionUpdateValueBasedOnSize implements Action {
    readonly type = ValuationProcessConsiderationCriterionActionTypes.UpdateCriterionValueBasedOnSize
    constructor(public payload: {
        refNum: string,
        size: number,
        exchangeRate: number
    }) {}
}

export class ValuationProcessLoadConsiderationCriterion implements Action {
    readonly type = ValuationProcessConsiderationCriterionActionTypes.LoadCriterion
    constructor(public payload: {crit: ConsiderationCriterionModel}){ }
}

export class ValuationProcessConsiderationChangeCurrencyExchange implements Action {
    readonly type = ValuationProcessConsiderationCriterionActionTypes.ChangeConsiderationExchangeRate
    constructor(public payload: {
        refNum: string,
        currencyExchange: CurrencyExchange,
        size: number
    }) {}
}

export type ValuationProcessConsiderationCriterionActions 
    = ValuationProcessCreateConsiderationCriterion
    | ValuationProcessConsiderationCriterionAddComparable
    | ValuationProcessConsiderationCriterionAddMultipleComparables
    | ValuationProcessConsiderationCriterionRemoveComparable
    | ValuationProcessConsiderationCriterionUpdateValue
    | ValuationProcessLoadConsiderationCriterion
    | ValuationProcessConsiderationCriterionUpdateValueBasedOnSize
    | ValuationProcessConsiderationChangeCurrencyExchange
