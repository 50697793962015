// Core
import {CommonModule} from '@angular/common';
import {HttpClientModule, HTTP_INTERCEPTORS} from '@angular/common/http';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {RouterModule, Routes} from '@angular/router';
// Translate
import {TranslateModule} from '@ngx-translate/core';
// Store
import {EffectsModule} from '@ngrx/effects';
import {StoreModule} from '@ngrx/store';
// Material
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatButtonModule} from '@angular/material/button';
import {MatCardModule} from '@angular/material/card';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatNativeDateModule} from '@angular/material/core';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatDialogModule, MAT_DIALOG_DEFAULT_OPTIONS} from '@angular/material/dialog';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatMenuModule} from '@angular/material/menu';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatRadioModule} from '@angular/material/radio';
import {MatSelectModule} from '@angular/material/select';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatSortModule} from '@angular/material/sort';
import {MatTableModule} from '@angular/material/table';
import {MatTabsModule} from '@angular/material/tabs';
import {MatTooltipModule} from '@angular/material/tooltip';
// Other
import {NgxMaskDirective, provideNgxMask, NgxMaskPipe, IConfig} from 'ngx-mask';
import {NgbModule, NgbPopoverModule} from '@ng-bootstrap/ng-bootstrap';
import {HttpUtilsService, 
    // InterceptService, 
    LayoutUtilsService, TypesUtilsService} from 'src/app/core/_base/crud';
import {PartialsModule} from '../../partials/partials.module';
import {SharedModule} from '../shared_components/shared.module';
import {CustomPipesModule} from '../../theme/pipes/custom-pipes.module';
import {AgmCoreModule} from '@agm/core';
import {TagInputModule} from 'ngx-chips';
import {environment} from '../../../../environments/environment';


import {
    AdminService,
    agenciesReducer,
    AgencyEffects,
    AgencyService, appendicesReducer, appendixCategoriesReducer, AppendixCategoriesService, AppendixCategoryEffects, AppendixEffects,
    citiesReducer,
    CityEffects,
    CityService,
    ClientEffects,
    clientsReducer, ContactEffects, contactsReducer,
    countriesReducer,
    CountryEffects,
    CountryService,
    currenciesReducer,
    CurrencyEffects,
    CurrencyService,
    KeyPlaceEffects,
    KeyPlaceService,
    keyPlacesReducer, 
} from 'src/app/core/admin';
import { ColorSketchModule } from 'ngx-color/sketch'
import { ColorChromeModule } from 'ngx-color/chrome'

// Components
import {CanDeactivateGuard} from '../../../core/auth';
import {AdminManagementComponent} from './admin-management.component';
import {CountriesListComponent} from './countries/countries-list/countries-list.component';
import {CountryEditComponent} from './countries/country-edit/country-edit.component';
import {CityEditComponent} from './countries/cities/city-edit/city-edit.component';
import {CitiesListComponent} from './countries/cities/cities-list/cities-list.component';
import {CurrenciesListComponent} from './countries/currencies/currencies-list/currencies-list.component';
import {CurrencyEditComponent} from './countries/currencies/currency-edit/currency-edit.component';
import {ClientsListComponent} from './clients/clients-list/clients-list.component';
import {ClientEditComponent} from './clients/clients-edit/client-edit.component';
import {AgencyEditComponent} from './agency/edit/agency-edit.component';
import {AgencyListComponent} from './agency/list/agency-list.component';
import {AgencyResolver} from './agency/_subs/agency-resolver';
import {KeyPlacesListComponent} from './key-places/key-places-list/key-places-list.component';
import {KeyPlaceEditComponent} from './key-places/key-place-edit/key-place-edit.component';
import {TrainingDocumentsListComponent} from './training-documents/training-documents-list/training-documents-list.component';
import {TrainingDocumentEditComponent} from './training-documents/training-document-edit/training-document-edit.component';
import {AppendixCategoriesListComponent} from './appendices/appendix-categories-list/appendix-categories-list.component';
import {AppendixCategoryEditComponent} from './appendices/appendix-category-edit/appendix-category-edit.component';
import {AppendicesListComponent} from './appendices/appendices-list/appendices-list.component';
import {AppendixEditComponent} from './appendices/appendix-edit/appendix-edit.component';
import {TrainingDocumentResolver} from './training-documents/training-document-edit/training-document-resolver';
import {TrainingDocumentEffects, trainingDocumentsReducer, TrainingDocumentsService} from '../../../core/training-documents';
import {AppendicesService} from '../../../core/admin';
import {AppendixCategoryResolver} from './appendices/appendix-category-edit/appendix-category-resolver';
import {AppendixResolver} from './appendices/appendix-edit/appendix-resolver';
import {AgmMarkerClustererModule} from '@agm/markerclusterer';
import { I18nEditorComponent } from './i18n-editor/i18n-editor.component';
import { LoadingDialogComponent } from './i18n-editor/loading-dialog/loading-dialog.component';
import { AgencySettingsComponent } from './agency/_subs/agency-settings/agency-settings.component';
import { CriteriaSettingsComponent } from './agency/_subs/agency-settings/criteria-settings/criteria-settings.component';
import { AgencyCriteriaService } from 'src/app/core/admin/_services/agency-criteria.service';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { VposTableComponent } from './agency/_subs/vpos-table/vpos-table.component';
import { OtherDocumentsTableComponent } from './agency/_subs/other-documents-table/other-documents-table.component';
import { AdditionalTermsTableComponent } from './agency/_subs/additional-terms-table/additional-terms-table.component';
import { ReportDesignsTableComponent } from './agency/_subs/report-designs-table/report-designs-table.component';
import { MadPermissionGuard } from 'src/app/core/mad-auth/mad-permission.guard';
import { GeneralSettingsComponent } from './general-settings/general-settings.component';
import { GeneralSettingsEffects, generalSettingsReducer } from 'src/app/core/general-settings/general-settings.store';

export const options: Partial<IConfig> | (() => Partial<IConfig>) = {};

const routes: Routes = [
    {
        path: '',
        component: AdminManagementComponent,
        children: [
            {
                path: 'countries',
                component: CountriesListComponent
            },
            {
                path: 'countries/:id',
                component: CitiesListComponent
            },
            {
                path: 'currencies',
                component: CurrenciesListComponent
            },
            {
                path: 'key-places',
                component: KeyPlacesListComponent
            },
            {
                path: 'key-places/add',
                component: KeyPlaceEditComponent,
                canDeactivate: [CanDeactivateGuard]
            },
            {
                path: 'key-places/edit/:id',
                component: KeyPlaceEditComponent,
                canDeactivate: [CanDeactivateGuard]
            },
            {
                path: 'linked-tables',
                loadChildren: () => import('../linked-tables/linked-tables.module').then(m => m.LinkedTablesModule),
                canActivate: [MadPermissionGuard],
                data: {
                    permissions: ['linked_table']
                }
            },
            {
                path: '',
                redirectTo: 'clients',
                pathMatch: 'full'
            },
            {
                path: 'clients',
                component: ClientsListComponent,
                canActivate: [MadPermissionGuard],
                data: {
                    permissions: ['client:view', 'client:crud']
                }
            },
            {
                path: 'clients/add',
                component: ClientEditComponent,
                canDeactivate: [CanDeactivateGuard],
                canActivate: [MadPermissionGuard],
                data: {
                    permissions: ['client:view', 'client:crud']
                }
            },
            {
                path: 'clients/edit/:id',
                component: ClientEditComponent,
                canDeactivate: [CanDeactivateGuard],
                canActivate: [MadPermissionGuard],
                data: {
                    permissions: ['client:view', 'client:crud']
                }
            },
            {
                path: 'agencies',
                component: AgencyListComponent,
                canActivate: [MadPermissionGuard],
                data: {
                    permissions: ['agency:view', 'agency:crud']
                }
            },
            {
                path: 'agencies/add',
                component: AgencyEditComponent,
                canDeactivate: [CanDeactivateGuard],
                canActivate: [MadPermissionGuard],
                data: {
                    permissions: ['agency:view', 'agency:crud']
                }
            },
            {
                path: 'agencies/edit/:id',
                component: AgencyEditComponent,
                canDeactivate: [CanDeactivateGuard],
                canActivate: [MadPermissionGuard],
                data: {
                    permissions: ['agency:view', 'agency:crud']
                },
                resolve: {
                    data: AgencyResolver,
                },
            },

            // training-documents

            {
                path: 'training-documents',
                component: TrainingDocumentsListComponent

            },
            {
                path: 'training-documents/add',
                component: TrainingDocumentEditComponent,
                canDeactivate: [CanDeactivateGuard]
            },
            {
                path: 'training-documents/edit/:id',
                component: TrainingDocumentEditComponent,
                resolve: {data: TrainingDocumentResolver},
                canDeactivate: [CanDeactivateGuard]

            },

            // appendix

            {
                path: 'appendix-categories',
                component: AppendixCategoriesListComponent
            },
            {
                path: 'appendix-categories/add',
                component: AppendixCategoryEditComponent,
                canDeactivate: [CanDeactivateGuard]
            },
            {
                path: 'appendix-categories/edit/:id',
                component: AppendixCategoryEditComponent,
                resolve: {data: AppendixCategoryResolver},
                canDeactivate: [CanDeactivateGuard]
            },
            {
                path: 'appendix-categories/:cat_id/appendices',
                component: AppendicesListComponent
            },
            {
                path: 'appendix-categories/:cat_id/appendices/add',
                component: AppendixEditComponent,
                canDeactivate: [CanDeactivateGuard]
            },
            {
                path: 'appendix-categories/:cat_id/appendices/edit/:id',
                component: AppendixEditComponent,
                resolve: {data: AppendixResolver},
                canDeactivate: [CanDeactivateGuard]

            },
            // {
            //     path: 'tooltip-editor',
            //     component: I18nEditorComponent
            // },
            {
                path: 'general-settings',
                component: GeneralSettingsComponent
            }
        ]
    }
];

@NgModule({
    imports: [
        CommonModule,
        HttpClientModule,
        PartialsModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule.forChild(routes),
        TranslateModule.forChild(),
        CustomPipesModule,
        NgbModule,
        NgxMaskDirective,
        NgxMaskPipe,
        MatButtonModule,
        MatMenuModule,
        MatSelectModule,
        MatInputModule,
        MatTableModule,
        MatAutocompleteModule,
        MatRadioModule,
        MatIconModule,
        MatNativeDateModule,
        MatProgressBarModule,
        MatDatepickerModule,
        MatCardModule,
        MatPaginatorModule,
        MatSortModule,
        MatCheckboxModule,
        MatProgressSpinnerModule,
        MatSnackBarModule,
        MatExpansionModule,
        MatTabsModule,
        MatTooltipModule,
        MatDialogModule,
        ColorSketchModule,
        ColorChromeModule,
        NgxMatSelectSearchModule,
        NgbPopoverModule,
        SharedModule,
        TagInputModule,
        CKEditorModule,
        AgmCoreModule.forRoot({
            apiKey: environment.agMapKey,
            libraries: ['places', 'drawing', 'geometry']
        }),
        AgmMarkerClustererModule,
        // Store
        StoreModule.forFeature('countries', countriesReducer),
        StoreModule.forFeature('cities', citiesReducer),
        StoreModule.forFeature('currencies', currenciesReducer),
        EffectsModule.forFeature([
            CountryEffects,
            CityEffects,
            CurrencyEffects,
            KeyPlaceEffects,
            ClientEffects,
            AgencyEffects
        ]),
        StoreModule.forFeature('clients', clientsReducer),
        EffectsModule.forFeature([ClientEffects]),
        StoreModule.forFeature('contacts', contactsReducer),
        EffectsModule.forFeature([ContactEffects]),
        StoreModule.forFeature('agencies', agenciesReducer),
        EffectsModule.forFeature([AgencyEffects]),
        StoreModule.forFeature('key-places', keyPlacesReducer),
        EffectsModule.forFeature([KeyPlaceEffects]),
        StoreModule.forFeature('trainingDocuments', trainingDocumentsReducer),
        EffectsModule.forFeature([TrainingDocumentEffects]),
        StoreModule.forFeature('appendixCategories', appendixCategoriesReducer),
        EffectsModule.forFeature([AppendixCategoryEffects]),
        StoreModule.forFeature('appendices', appendicesReducer),
        EffectsModule.forFeature([AppendixEffects]),
        StoreModule.forFeature('generalSettings', generalSettingsReducer),
        EffectsModule.forFeature([GeneralSettingsEffects])
    ],
    providers: [
        CanDeactivateGuard,
        // InterceptService,
        // {
        //     provide: HTTP_INTERCEPTORS,
        //     useClass: InterceptService,
        //     multi: true
        // },
        {
            provide: MAT_DIALOG_DEFAULT_OPTIONS,
            useValue: {
                hasBackdrop: true,
                panelClass: 'kt-mat-dialog-container__wrapper',
                maxHeight: 'auto',
                width: '900px'
            }
        },
        HttpUtilsService,
        TypesUtilsService,
        LayoutUtilsService,
        AdminService,
        CountryService,
        CityService,
        CurrencyService,
        AgencyService,
        AgencyResolver,
        KeyPlaceService,
        TrainingDocumentResolver,
        TrainingDocumentsService,
        AppendixCategoriesService,
        AppendixCategoryResolver,
        AppendicesService,
        AppendixResolver,
        AgencyCriteriaService,
        provideNgxMask(options)
    ],
    declarations: [
        AdminManagementComponent,
        CountriesListComponent,
        CountryEditComponent,
        CityEditComponent,
        CitiesListComponent,
        CurrenciesListComponent,
        CurrencyEditComponent,
        KeyPlaceEditComponent,
        KeyPlacesListComponent,
        // Clients
        ClientsListComponent,
        ClientEditComponent,
        // Agency
        AgencyListComponent,
        AgencyEditComponent,
        // MarketResearch
        TrainingDocumentsListComponent,
        TrainingDocumentEditComponent,
        AppendixCategoriesListComponent,
        AppendixCategoryEditComponent,
        AppendicesListComponent,
        AppendixEditComponent,
        I18nEditorComponent,
        LoadingDialogComponent,
        AgencySettingsComponent,
        CriteriaSettingsComponent,
        VposTableComponent,
        OtherDocumentsTableComponent,
        AdditionalTermsTableComponent,
        ReportDesignsTableComponent,
        GeneralSettingsComponent
    ]
})

export class AdminManagementModule {
}