import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgxMaskPipe } from 'ngx-mask';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { awConst } from 'src/app/app.constants';
import { SubdomainService } from 'src/app/core/_base/subdomain.service';
import { User } from 'src/app/core/auth';
import { AssetClassTenure } from 'src/app/core/comparable';
import { VComparable } from 'src/app/core/valuation-process/_models';
import { sizeInfo, VAssetClassConsideration, VAssetClassSize } from 'src/app/core/valuation-process/_models/valuation-asset-class-common.models';
import { VCLandModel } from 'src/app/core/valuation-process/_models/valuation-land.model';
import { getPictureUrl } from 'src/app/core/valuation-process/_models/valuation-process-target-property.model';
import { environment } from 'src/environments/environment';

type MapComparableInfo = {
  id: number,
  bg: string,
  refNum: string,
  title: string,
  subtitle: string,
  pictures: string[],
  status: number,
  usedTimes: number,
  fields: any[],
  points: Array<{lat: number, lng: number}>,
  latitude: number,
  longitude: number,
  selection: {
    buttonText: string,
    action: 'remove' | 'add',
    selected: boolean
  }
}

@Component({
  selector: 'kt-map-comparable-info',
  templateUrl: './map-comparable-info.component.html',
  styleUrls: ['./map-comparable-info.component.scss'],
  providers: [NgxMaskPipe]
})
export class MapComparableInfoComponent implements OnInit {
  @Input() comparables$: Observable<VComparable[]>;
  @Input() selectedRefNums$: Observable<string[]>;
  @Input() user: {
    unit_of_measurement_id: number
  };
  @Input() loading$: Observable<boolean>;
  @Input() hasAddButton: boolean = false;
  @Input() hasShowInfoButton: boolean = false;
  @Output() noClick: EventEmitter<void> = new EventEmitter()
  @Output() add: EventEmitter<{action: 'remove' | 'add', refNum: string}> = new EventEmitter()
  @Output() openInfoModal: EventEmitter<string> = new EventEmitter()

  private map;
  private _drawingManager: google.maps.drawing.DrawingManager;
  title$: Observable<string>
  datasource$: Observable<MapComparableInfo[]>

  constructor(
    private ngMask: NgxMaskPipe,
    private datePipe: DatePipe,
    private subDomainService: SubdomainService
  ) { }

  ngOnInit(): void {
    this.title$ = this.comparables$.pipe(map(com => com.length > 1 ? 'COMPARABLES' : 'COMPARABLE'))

    this.datasource$ = combineLatest([
      this.comparables$,
      this.selectedRefNums$
    ]).pipe(
      map(([comparables, refNums]) => {
        return comparables.map((com, i) => {
          let bg = '';
          if (comparables.length > 1) {
            bg = i % 2 == 0 ? 'gray' : ''
          }
          const selected = refNums.includes(com.refNum);
          const info: MapComparableInfo  =  {
            id: com.id,
            bg,
            refNum: com.refNum,
            title: com.propertySubType != 'Land' ? com.property.subTypeCategory : 'Land',
            subtitle: com.propertySubType != 'Land' ? com.property.subCategory : this._landSubTitle(com),
            pictures: com.pictures.map(picture => environment.baseApiUrl + getPictureUrl(picture, this.subDomainService.subDomain)),
            status: com.status,
            usedTimes: com.usedTimes,
            fields: this._fields(com),
            points: com.propertySubType == 'Land' ? com.locationData.points : [],
            latitude: com.locationData.latitude,
            longitude: com.locationData.longitude,
            selection: {
              buttonText: selected ? 'Remove' : 'Add',
              action: selected ? 'remove' : 'add' as const,
              selected
            }
          }
          return info
        })
      })
    )
  }

  onNoClick() {
    this.noClick.emit()
  }
  onAdd(action: 'remove' | 'add', refNum: string) {
    this.add.emit({action, refNum});
  }
  onOpenInfoModal(refNum: string) {
    this.openInfoModal.emit(refNum)
  }

  onMapReady(event: any, points: { lat: number, lng: number }[]) {
    this.map = event;
    this.map.controls[google.maps.ControlPosition.RIGHT_CENTER].push(document.getElementById('Profile'));

    this._initializeDrawingManager(this.map)

    const _shape = new google.maps.Polygon({
      paths: points.filter(p => p.lat != null && p.lng != null),
      editable: false
    });
    _shape.setMap(this.map);
  }

  private _initializeDrawingManager(map: google.maps.Map) {
    const options: google.maps.drawing.DrawingManagerOptions = {
      drawingMode: null,
      drawingControl: false,
      drawingControlOptions: {
        position: google.maps.ControlPosition.TOP_CENTER,
        drawingModes: [
          google.maps.drawing.OverlayType.POLYGON
        ]
      },
      polygonOptions: {
        draggable: false,
        editable: true
      }
    }

    this._drawingManager = new google.maps.drawing.DrawingManager(options);
    this._drawingManager.setMap(map);

    google.maps.event.addListener(this._drawingManager, 'overlaycomplete', (event) => {
    })
  }

  public imgOnError(e) {
    e.target.src = awConst.IMAGE_NOT_FOUND
  }

  getItemCssClassByStatus(status: number): string {
    switch (status) {
      case 0:
        return 'warning';
      case 2:
        return 'info';
      default:
        return 'success';
    }
  }
  getItemStatusString(status: number): string {
    switch (status) {
      case 0:
        return 'Draft';
      case 2:
        return 'Imported';
      default:
        return 'Validated';
    }
  }

  private _landSubTitle(comparable: VCLandModel): string {
    if (comparable.property.hasBuilding != 'Yes') {
      return null
    }
    if (comparable.property.buildings.length == 0) {
      return null
    }
    return `${comparable.property.buildings.length} building structure${comparable.property.buildings.length > 1 ? 's' : ''} on parcel`
  }

  private _considerationsFields(considerations: VAssetClassConsideration[], timezoneOffset: string | null): any[] {
    const fields = []
    considerations
      .map(consideration => {
        const tenure = consideration.tenureName
        const totalTenure = this.ngMask.transform(consideration.value.toFixed(2), 'separator', {thousandSeparator: ','})
        const strong = consideration.considerationType == 'Land'
          ? `${tenure} - ${totalTenure} ${consideration.currency}`
          : `${tenure} - ${totalTenure} ${consideration.currency}${this._rentBasisId(consideration.oldTenure)}`
        const simple = consideration.source
          ? `${consideration.source.sourceTypeName} - ${this.datePipe.transform(consideration.source.sourceType == 'Advertised' 
                ? consideration.source.informationDate 
                : consideration.source.transactionDate, 'dd MMM yyyy')} - ${consideration.source.sourceInformation}`
          : null
        return {
          strong,
          simple
        }
      })
      .forEach(({ strong, simple }, i) => {
        fields.push({ header: `Consideration ${considerations.length == 1 ? '' : (i + 1)}`, value: { strong, simple }, type: 'consideration' })
      })
    return fields
  }

  private _rentBasisId(con: AssetClassTenure) {
    if (!con.consideration_type_id) return ''
    if (con.consideration_type_id == 1) return ''
    if (con.rent_basis_id == 1) {
      return '/month'
    }
    if (con.rent_basis_id == 2) {
      return '/quarter'
    }
    if (con.rent_basis_id == 3) {
      return '/year'
    }
    return ''
  }

  private _sizeFields(sizes: VAssetClassSize[]): any {
    const size = sizeInfo(sizes[0], this.user?.unit_of_measurement_id);
    if (size == 'Unknown') {
      return {
        header: 'Size',
        value: {
          strong: 'Unknown',
          simple: null,
          type: null
        }
      }
    } else {
      const idx = size.indexOf("(")
      return {
        header: 'Size',
        value: {
          strong: size.substring(0, idx),
          simple: size.substring(idx, size.length),
          type: null
        }
      }
    }
  }

  private _fields(comparable: VComparable): any[] {
    switch (comparable.propertySubType) {
      case 'Apartment': {
        const fields = [];
        fields.push(this._sizeFields(comparable.sizes))
        const bedroom = { header: 'Bedroom(s)', value: null, type: null }
        const bathroom = { header: 'Bathroom(s)', value: null, type: null }
        if (comparable.propertyInfo.bedrooms) {
          bedroom.value = { strong: comparable.propertyInfo.bedrooms, simple: null }
        } else if ( comparable.propertyInfo.accomodation && comparable.propertyInfo.accomodation.data)  {
          const bedrooms = Object.entries(comparable.propertyInfo.accomodation.data).reduce((acc, [_, value]: [any, any]) => {
            if (value.bedrooms) {
              return acc + Number(value.bedrooms);
            }
            return acc;
          }, 0)
          bedroom.value = { strong: bedrooms, simple: null }
        }
        if (comparable.propertyInfo.bathrooms) {
          bathroom.value = { strong: comparable.propertyInfo.bathrooms, simple: null }
        } else if (comparable.propertyInfo.accomodation && comparable.propertyInfo.accomodation.data)  {
          const baths = Object.entries(comparable.propertyInfo.accomodation.data).reduce((acc, [_, value]: [any, any]) => {
            if (value.bath) {
              return acc + Number(value.bath);
            }
            return acc;
          }, 0)
          bathroom.value = { strong: baths, simple: null }
        }
        fields.push(bedroom);
        fields.push(bathroom);

        const t = { header: 'Building', value: null, type: null }
        if (comparable.building.name != null) {
          t.value = { strong: comparable.building.name, simple: comparable.building.buildingType != null ? `(${comparable.building.buildingType})` : null }
          fields.push(t)
        }

        if (comparable.displayConsiderations.length > 0) {
          const considerationFields = this._considerationsFields(comparable.displayConsiderations, comparable.locationData.timezoneOffset)
          considerationFields.forEach(f => fields.push(f))
        }

        return fields
      }
      case 'House': {
        const fields = [];
        fields.push(this._sizeFields(comparable.sizes))
        const propertyGrade = { header: 'Property Grade', value: null, type: null }
        const stateOfRepair = { header: 'State of Repair', value: null, type: null }
        if (comparable.property.propertyGrade) {
          propertyGrade.value = { strong: comparable.property.propertyGrade, simple: null }
        }
        if (comparable.property.stateOfRepair) {
          stateOfRepair.value = { strong: comparable.property.stateOfRepair, simple: null }
        }
        fields.push(propertyGrade);
        fields.push(stateOfRepair);

        if (comparable.displayConsiderations.length > 0) {
          const considerationFields = this._considerationsFields(comparable.displayConsiderations, comparable.locationData.timezoneOffset)
          considerationFields.forEach(f => fields.push(f))
        }

        return fields
      }
      case 'Office': {
        const fields = [];
        fields.push(this._sizeFields(comparable.sizes))

        const t = { header: 'Building', value: null, type: null }
        if (comparable.building.name != null) {
          t.value = { strong: comparable.building.name, simple: comparable.building.buildingType != null ? `(${comparable.building.buildingType})` : null }
          fields.push(t)
        }

        if (comparable.displayConsiderations.length > 0) {
          const considerationFields = this._considerationsFields(comparable.displayConsiderations, comparable.locationData.timezoneOffset)
          considerationFields.forEach(f => fields.push(f))
        }

        return fields
      }
      case 'Retail Shop': {
        const fields = [];
        fields.push(this._sizeFields(comparable.sizes))

        const t = { header: 'Building', value: null, type: null }
        if (comparable.building.name != null) {
          t.value = { strong: comparable.building.name, simple: comparable.building.buildingType != null ? `(${comparable.building.buildingType})` : null }
          fields.push(t)
        }

        if (comparable.displayConsiderations.length > 0) {
          const considerationFields = this._considerationsFields(comparable.displayConsiderations, comparable.locationData.timezoneOffset)
          considerationFields.forEach(f => fields.push(f))
        }

        return fields
      }
      case 'Retail Building': {
        const fields = [];
        fields.push(this._sizeFields(comparable.sizes))

        const floors = { header: 'Floors', value: null, type: null }
        const propertyGrade = { header: 'Property Grade', value: null, type: null }
        if (comparable.property.propertyGrade) {
          propertyGrade.value = { strong: comparable.property.propertyGrade, simple: null }
        }
        let totalFloors = null;
        if (comparable.property.aboveFloors) {
          totalFloors = totalFloors != null ? (totalFloors + comparable.property.aboveFloors) : comparable.property.aboveFloors;
        }
        if (comparable.property.belowFloors) {
          totalFloors = totalFloors != null ? (totalFloors + comparable.property.belowFloors) : comparable.property.belowFloors;
        }
        if (totalFloors != null) {
          floors.value = { strong: totalFloors, simple: null }
        }
        fields.push(floors);
        fields.push(propertyGrade);

        if (comparable.displayConsiderations.length > 0) {
          const considerationFields = this._considerationsFields(comparable.displayConsiderations, comparable.locationData.timezoneOffset)
          considerationFields.forEach(f => fields.push(f))
        }
        return fields
      }
      case 'Parking': {
        const fields = [];
        fields.push(this._sizeFields(comparable.sizes))

        const units = { header: 'Units', value: null, type: null }
        const types = { header: 'Parking Type', value: null, type: null }
        if (comparable.property.numberOfUnits) {
          units.value = { strong: comparable.property.numberOfUnits, simple: null }
        }
        if (comparable.property.parkingType) {
          types.value = { strong: comparable.property.parkingType, simple: null }
        }
        fields.push(units);
        fields.push(types);

        if (comparable.displayConsiderations.length > 0) {
          const considerationFields = this._considerationsFields(comparable.displayConsiderations, comparable.locationData.timezoneOffset)
          considerationFields.forEach(f => fields.push(f))
        }
        return fields
      }
      case 'Warehouse': {
        const fields = [];
        fields.push(this._sizeFields(comparable.sizes))

        const completionYear = { header: 'Completion Year', value: null, type: null }
        const propertyGrade = { header: 'Property Grade', value: null, type: null }
        if (comparable.property.completionYear != null && comparable.property.completionYear.length > 0) {
          completionYear.value = { strong: comparable.property.completionYear, simple: null }
        }
        if (comparable.property.propertyGrade) {
          propertyGrade.value = { strong: comparable.property.propertyGrade, simple: null }
        }
        fields.push(completionYear);
        fields.push(propertyGrade);

        if (comparable.displayConsiderations.length > 0) {
          const considerationFields = this._considerationsFields(comparable.displayConsiderations, comparable.locationData.timezoneOffset)
          considerationFields.forEach(f => fields.push(f))
        }
        return fields
      }
      case 'Land': {
        const fields = [];
        fields.push(this._sizeFields(comparable.sizes))

        const subType = {
          header: 'Sub-type',
          value: null,
          type: null
        }
        if (comparable.propertySubTypeName) {
          subType.value = {
            strong: comparable.propertySubTypeName,
            simple: null
          };
        }
        fields.push(subType);

        const compUse = {
          header: 'Comp. land use',
          value: null,
          type: null
        }
        if (comparable.property.comparativeLandUse) {
          compUse.value = {
            strong: comparable.property.comparativeLandUse,
            simple: null
          }
        }
        fields.push(compUse);

        const buildingStructure = {
          header: 'Building Structure',
          value: {
            strong: comparable.property.hasBuilding == 'Idk'
              ? 'Information not available'
              : comparable.property.hasBuilding == 'No'
                ? 'None'
                : comparable.property.buildings.length,
            simple: null
          },
          type: null
        }
        fields.push(buildingStructure);



        if (comparable.displayConsiderations.length > 0) {
          const considerationFields = this._considerationsFields(comparable.displayConsiderations, comparable.locationData.timezoneOffset)
          considerationFields.forEach(f => fields.push(f))
        }
        return fields
      }
      default:
        return []
    }
  }
}
