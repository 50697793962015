<ng-container *ngIf="viewModel$|async as viewModel">
  <div class="row">
    <div class="col-12">
      <div class="card cost-summary">
        <div class="card-header">
          <span>Revenues and costs summary</span>
        </div>
        <div class="card-body">
          <div class="mat-table-wrapper" *ngIf="viewModel.summary.costsSummary as vm">
            <table class="table" style="overflow: hidden">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Name</th>
                  <th>Classification</th>
                  <th>Rent Review</th>
                  <th>Valuation Method</th>
                  <th>Rent / Amount</th>
                  <th colspan="2">
                    <div>
                      <div class="border-bottom">Yields</div>
                      <div class="d-flex justify-content-between">
                        <div>Capitalisation Rate</div>
                        <div>Reversionary Yield</div>
                      </div>
                    </div>
                  </th>
                  <th class="value-header">Value</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let item of vm.lists; let i = index">
                  <td class="font-weight-bolder">
                    {{ i + 1 }}
                  </td>
                  <td>
                    {{ item.name }}
                  </td>
                  <td>
                    {{ item.classification }}
                  </td>
                  <td>
                    {{ item.rentReview }}
                  </td>
                  <td>
                    {{ item.method ? item.method : 'N/A' }}
                  </td>
                  <td>
                    {{ item.currency }} {{ item.amount | mask:'separator.2':{thousandSeparator:','} }} {{ item.rentBasis
                    }}
                  </td>
                  <td>
                    {{ item.capitalisationRate != null ? (item.capitalisationRate | mask:'percent.5') : 'N/A' }}
                    {{ item.capitalisationRate != null ? ' %' : '' }}
                  </td>
                  <td>
                    {{ item.reversionaryYield != null ? (item.reversionaryYield | mask:'percent.5') : 'N/A' }}
                    {{ item.reversionaryYield != null ? ' %' : '' }}
                  </td>
                  <td>
                    <ng-container *ngIf="item.value !== undefined">
                      <ng-container *ngIf="item.currency === item.countryCurrency">
                        {{ item.currency }} {{ item.value | mask:'separator.2':{thousandSeparator:','} }}
                      </ng-container>
                      <ng-container *ngIf="item.currency !== item.countryCurrency">
                        {{ item.countryCurrency }} {{ item.value | mask:'separator.2':{thousandSeparator:','} }} / {{ item.currency }} {{ item.originalValue | mask:'separator.2':{thousandSeparator:','} }}
                      </ng-container>
                    </ng-container>
                    <ng-container *ngIf="item.value === undefined">
                      N/A
                    </ng-container>
                  </td>
                </tr>
              </tbody>
            </table>

            <table class="table">
              <thead>
                <tr>
                  <th></th>
                  <th class="value-header"></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td class="text-left font-weight-bolder">
                    Target Property Value ({{ vm.result.currency }} / {{ vm.result.sizeAcronym }})
                  </td>
                  <td>
                    {{ vm.result.perUnit ? (vm.result.perUnit | mask:'separator.2':{thousandSeparator:','}) : 'N/A' }}
                  </td>
                </tr>
                <tr>
                  <td class="text-left font-weight-bolder">
                    Target Property Value ({{ vm.result.currency }})
                  </td>
                  <td>
                    {{ vm.result.value ? (vm.result.value | mask:'separator.2':{thousandSeparator:','}) : 'N/A' }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>