import { VBuilding } from "./valuation-asset-class-building.model"
import { VLocationData, VAssetClassSize, VAssetClassConsideration, VAssetClassSourceRemote, VInternalAspectRemote, VAssetClassConsiderationRemote, VGroundRemote, getGarageRange } from "./valuation-asset-class-common.models"
import { VComparable } from "./valuation-comparable.model"
import { PictureModel, TypeOfInspection } from "./valuation-process-target-property.model"


export type VApartmentModel = {
    refNum: string,
    propertySubType: 'Apartment',
    propertySubTypeName: string,

    locationData: VLocationData,
    property: VApartmentAboutProperty,
    internalAspect: VApartmentInternalAspect, 
    ground: VApartmentGround

    building: VBuilding,
    oldBuildingInfo: any

    pictures: PictureModel[]
    sizes: VAssetClassSize[],
}

export function isVCApartmentModel(comparable: VComparable): comparable is VCApartmentModel {
    return comparable.propertySubType == 'Apartment'
}

export type VCApartmentModel = VApartmentModel & {
    id: number,
    // Info

    considerations: VAssetClassConsideration[],
    displayConsiderations: VAssetClassConsideration[],

    // Additional
    status: number,
    usedTimes: number,
    propertyTypeName: string,
    propertyInfo: {
        bedrooms: number,
        bathrooms: number,
        accomodation: any
    }
}

export type VTPApartmentModel = VApartmentModel & {
    inspectionInfo: {
        typeOfInspection: TypeOfInspection,
        inspectionDate: Date
        anyLimitationOrRestriction: boolean,
        limitationDescription: string 
    },
    locationSurrounding: string,
    refNum: 'tp'
} 

export type VApartmentAboutProperty = {
    subTypeCategory: string,
    subCategory: string,
    generalDescription: string,
    stateOfRepair: string,
    handoverStandard: string,
    floors: string,
    energyEfficiencyGrade: string,
}

export type VApartmentInternalAspect = {
    windows: string,
    windowsComment: string,
    ceiling: string,
    ceilingComment: string,
    wallsAndPartition: string,
    wallsAndPartitionComment: string,
    floors: string,
    floorsComment: string,
    bathroomFitting: string,
    bathroomFittingComment: string,
    builtInFittings: string,
    others: string,
}

export function mapApartmentInternalAspectRemoteToDomain(remote: VInternalAspectRemote): VApartmentInternalAspect {
    return {
        windows: remote.windows_name,
        windowsComment: remote.windows_comment,
        ceiling: remote.ceiling_name,
        ceilingComment: remote.ceiling_comment,
        wallsAndPartition: remote.walls_and_partition_name,
        wallsAndPartitionComment: remote.walls_and_partition_comment,
        floors: remote.floor_type_name,
        floorsComment: remote.floor_type_comment,
        bathroomFitting: remote.bathroom_fitting_name,
        bathroomFittingComment: remote.bathroom_fitting_comment,
        others: null,
        // TODO:
        builtInFittings: remote.builtin_fitting_names
    }
}

export type VApartmentGround = {
    indoorGarage: string
    indoorGarageComment: string,
    outdoorGarage: string,
    outdoorGarageComment: string,
    externalAreas: string,
    externalAreasComment: string
}

export type VApartmentComparableRemote = {
    id: number,
    refNum: string,
    property_sub_type_id: 1,
    propertyInfo: {
        subTypeCategoryName: string,
        subCategoryName: string,
        bedrooms: number,
        bathrooms: number,
        accommodation: any
    },
    buildingInfo: {
        name: string,
        buildingType: string,
        completionYear: string,
        buildingTypeComment: string,
        buildingGrade: string,
        energyEfficiencyGrade: string,
        developer: string,
        anchorTenant: string,
        foundationType: string,
        buildingDescription: string,
        pictures: PictureModel[],
    },
    sizes: Array<{
        standardMeasurementId: number,
        standard_measurement_name: string,
        unitOfAreaMeasurementId: number,
        unit_of_area_measurement_acronym: string,
        size: number
    }>,
    considerations: Array<VAssetClassConsiderationRemote>
    pictures: PictureModel[],
    status: number,
    usedTimes: number,
    locationData: {
        latitude: string,
        longitude: string,
        countryName: string,
        cityName: string,
        zipCode: string,
        locationGradeName: string,
        address: string,
        timezoneOffset: string | null
    }
    propertySubTypeName: string,
    topPropertyTypeName: string,
    property: VApartmentAboutProperty,
    internalAspect: VInternalAspectRemote,
    ground: VGroundRemote,

    oldBuildingInfo: any
}

export function mapVApartmentGroundRemoteToDomain(remote: VGroundRemote): VApartmentGround {
    return {
        indoorGarage: getGarageRange(remote.indoor_garage),
        indoorGarageComment: remote.indoor_garage_comment,
        outdoorGarage: getGarageRange(remote.outdoor_garage),
        outdoorGarageComment: remote.outdoor_garage_comment,
        externalAreas: remote.external_areas_name,
        externalAreasComment: remote.external_area_comment
    }
}