import { CashFlowMethodData, CashFlowMethodVersion, CFMEffectiveRent, CFMFirstTerm, CFMLastTerm, CFMMidTerm, CFMPreliminary } from "src/app/views/pages/target-property/valuation-process-edit/_sub/adjustment-tab/_modals/consideration-modal/cf-method-types";
import { AssetClassTenure } from "../../comparable";
import { calculateHeadlineRent, calculatePresentValueAtPercentRate, calculateYearsPurchaseAtPercentRate, durationCalculation, roundTo4Decimals } from "./utils";

export function calculateCFM(consideration: AssetClassTenure, targetRate: number, growthRate: number, size: number) {
  targetRate = Number(targetRate)
  growthRate = Number(growthRate)

  const write_off_period = durationCalculation(Number(consideration.write_off_period), consideration.duration_type)
  const rent_review = durationCalculation(consideration.rent_review, consideration.duration_type)
  const methodVersion = Number(write_off_period) !== 0 && write_off_period < Number(consideration.lease_duration)
    ? CashFlowMethodVersion.WriteOffIsLesserThanLease
    : CashFlowMethodVersion.WriteOffIsEqualToLeaseOrNA;

  let numberOfTerms = undefined;
  let remainingYears = undefined;
  let numberOfTermsReal = undefined;
  if (methodVersion == CashFlowMethodVersion.WriteOffIsLesserThanLease) {
    [numberOfTerms, numberOfTermsReal, remainingYears] = getNumOfTermsAndRemainingYears(write_off_period, rent_review);
  } else {
    [numberOfTerms, numberOfTermsReal, remainingYears] = getNumOfTermsAndRemainingYears(Number(consideration.lease_duration), rent_review);
  }

  const preliminary = calculateCFMPreliminary(consideration, targetRate, methodVersion, size)
  const firstTerm = calculateCFMFirstTerm(consideration, targetRate);
  const lastTerm = calculateCFMLastTerm(consideration, growthRate, targetRate, numberOfTerms, remainingYears);
  const midTerms = calculateCFMMidTerms(consideration, numberOfTerms - 2, growthRate, targetRate);

  let factorValue = firstTerm.factorValue + lastTerm.factorValueOfRevisionaryRent;
  midTerms.forEach(mt => {
    factorValue += mt.factorValueOfRevisionaryRent;
  });

  const effectiveRent = preliminary.capitalValueOfInducements / factorValue;
  const effectiveRents: CFMEffectiveRent[] = [];
  let prevER = effectiveRent;
  for (let i = 0; i < (numberOfTerms - 1); i++) {
    const val = calculateEffectiveRent(prevER, growthRate, rent_review);
    const [suffix, prevSuffix] = getSuffixes(i + 1);
    const er: CFMEffectiveRent = {
      prev: prevER,
      value: val,
      termSuffix: suffix,
      prevSuffix: prevSuffix,
      isSelected: false,
    }
    effectiveRents.push(er);
    prevER = er.value;
  }

  const result: CashFlowMethodData = {
    numberOfTerms,
    numberOfTermsReal,
    remainingYears,
    preliminary,
    firstTerm,
    lastTerm,
    midTerms,
    factorValue,
    results: {
      effectiveRent,
      effectiveRents,
      isSelected: false,
    }
  };
  const rounded = roundCFMResult(result);
  const converted = convertNanToUndefinedCFM(rounded);
  return converted;
}

function calculateCFMPreliminary(consideration: AssetClassTenure, targetRate: number, methodVersion: CashFlowMethodVersion, size: number) {
  const write_off_period = durationCalculation(Number(consideration.write_off_period), consideration.duration_type)
  const rent_free_period = durationCalculation(Number(consideration.rent_free_period), consideration.duration_type)
  const capital_payment = Number(consideration.cap_payment)
  const total_consideration = Number(consideration.total_consideration)
  const headlineRent = calculateHeadlineRent(total_consideration, consideration.rent_input_amount_type, consideration.rent_basis_id, size)
  let rentReceivedFor = 0;
  if (methodVersion === CashFlowMethodVersion.WriteOffIsLesserThanLease) {
    rentReceivedFor = write_off_period - rent_free_period;
  } else {
    rentReceivedFor = Number(consideration.lease_duration) - rent_free_period;
  }
  const yearsPurchaseOfRentReceivedFor = calculateYearsPurchaseAtPercentRate(targetRate, rentReceivedFor);
  const presentValueOfRentFreePeriod = calculatePresentValueAtPercentRate(targetRate, rent_free_period);
  const capitalValueOfHeadlineRent = headlineRent * yearsPurchaseOfRentReceivedFor * presentValueOfRentFreePeriod;
  const capitalValueOfInducements = capitalValueOfHeadlineRent - capital_payment;
  const preliminary = {
    headlineRent,
    rentReceivedFor,
    yearsPurchaseOfRentReceivedFor,
    presentValueOfRentFreePeriod,
    capitalValueOfHeadlineRent,
    capitalValueOfInducements,
  };
  return preliminary;
}

function calculateCFMFirstTerm(consideration: AssetClassTenure, targetRate: number) {
  const rent_review = durationCalculation(consideration.rent_review, consideration.duration_type)
  const fitting_out_period = durationCalculation(consideration.fitting_out_period, consideration.duration_type)
  const yp = calculateYearsPurchaseAtPercentRate(targetRate, rent_review);
  const pv = calculatePresentValueAtPercentRate(targetRate, fitting_out_period);
  const factor = yp * pv;
  const res = {
    yearsPurchaseOfRentReviewCycle: yp,
    presentValueOfFittingOutPeriod: pv,
    factorValue: factor
  }
  return res;
}

function calculateCFMLastTerm(consideration: AssetClassTenure, growthRate: number, targetRate: number, currentTerm: number, lastTermPeriod: number) {
  const rent_review = durationCalculation(consideration.rent_review, consideration.duration_type)
  const revision = calculateRevisionToFutureValue(growthRate, rent_review, currentTerm);
  const yp = calculateYearsPurchaseAtPercentRate(targetRate, lastTermPeriod);
  const year = rent_review * (currentTerm - 1);
  const pv = calculatePresentValueAtPercentRate(targetRate, year);
  const factor = revision * yp * pv;
  const [termSuffix, prevTermSuffix] = getSuffixes(currentTerm);
  const res = {
    year,
    termSuffix,
    prevTermSuffix,
    revisionToFutureRentalValue: revision,
    yearsPurchaseOfLastTermPeriod: yp,
    presentValueOfRentReviewCycle: pv,
    factorValueOfRevisionaryRent: factor,
  }
  return res;
}

function calculateCFMMidTerms(consideration: AssetClassTenure, terms: number, growthRate: number, targetRate: number) {
  const midTerms: CFMMidTerm[] = []
  let i = 0;
  for (i; i < terms; i++) {
    const term = calculateCFMMidTerm(consideration, i + 2, growthRate, targetRate);
    midTerms.push(term);
  }
  return midTerms
}

function calculateCFMMidTerm(consideration: AssetClassTenure, currentTerm: number, growthRate: number, targetRate: number) {
  const rent_review = durationCalculation(consideration.rent_review, consideration.duration_type)
  const revision = calculateRevisionToFutureValue(growthRate, rent_review, currentTerm);
  const yp = calculateYearsPurchaseAtPercentRate(targetRate, rent_review);
  const year = consideration.rent_review * (currentTerm - 1);
  const pv = calculatePresentValueAtPercentRate(targetRate, year);
  const factor = revision * yp * pv;
  const [termSuffix, prevTermSuffix] = getSuffixes(currentTerm);
  const res: CFMMidTerm = {
    year,
    termSuffix,
    prevTermSuffix,
    revisionToFutureRentalValue: revision,
    presentValueOfRentReviewCycle: pv,
    yearsPurchaseOfRentReview: yp,
    factorValueOfRevisionaryRent: factor,
  }
  return res;
}

function calculateEffectiveRent(preER: number, growthRate: number, reviewCycle: number): number {
  if (growthRate === null) {
    return NaN;
  }
  const rateVal = growthRate / 100;
  let temp = Math.pow((1 + rateVal), reviewCycle);
  return preER * temp;
}

function getSuffixes(term: number): [string, string] {
  const r = term % 10;
  switch (r) {
    case 1:
      return ['st', 'th'];
    case 2:
      return ['nd', 'st'];
    case 3:
      return ['rd', 'nd'];
    case 4:
      return ['th', 'rd'];
    default:
      return ['th', 'th'];
  }
}

function calculateRevisionToFutureValue(rate: number, year: number, term: number): number {
  if (rate === null || rate === 0) {
    return NaN;
  }
  const rateVal = rate / 100;
  let temp = year * (term - 1);
  return Math.pow((1 + rateVal), temp);
}

function roundCFMResult(val: CashFlowMethodData): CashFlowMethodData {
  const preliminary = Object.assign({}, val.preliminary) as CFMPreliminary;
  const firstTerm = Object.assign({}, val.firstTerm) as CFMFirstTerm;
  const lastTerm = Object.assign({}, val.lastTerm) as CFMLastTerm;
  const midTerms: CFMMidTerm[] = [];

  Object.entries(val.preliminary).forEach(([field, value]) => {
    preliminary[field] = roundTo4Decimals(value);
  });
  Object.entries(val.firstTerm).forEach(([field, value]) => {
    firstTerm[field] = roundTo4Decimals(value);
  })
  Object.entries(val.lastTerm).forEach(([field, value]) => {
    lastTerm[field] = typeof value === 'number' ? roundTo4Decimals(value) : value;
  });
  val.midTerms.forEach(mt => {
    const midTerm = Object.assign({}, mt) as CFMMidTerm;
    Object.entries(mt).forEach(([field, value]) => {
      midTerm[field] = typeof value === 'number' ? roundTo4Decimals(value) : value;
    });
    midTerms.push(midTerm);
  })

  let effectiveRent = roundTo4Decimals(val.results.effectiveRent);
  const effectiveRents: CFMEffectiveRent[] = [];
  val.results.effectiveRents.forEach(er => {
    const e = Object.assign({}, er) as CFMEffectiveRent;
    e.prev = roundTo4Decimals(er.prev);
    e.value = roundTo4Decimals(er.value);
    effectiveRents.push(e);
  })


  const res: CashFlowMethodData = {
    numberOfTerms: val.numberOfTerms,
    numberOfTermsReal: roundTo4Decimals(val.numberOfTermsReal),
    remainingYears: val.remainingYears,
    preliminary,
    firstTerm,
    lastTerm,
    midTerms,
    factorValue: roundTo4Decimals(val.factorValue),
    results: {
      effectiveRent,
      effectiveRents,
      isSelected: false,
    }
  }
  return res;
}


function convertNanToUndefinedCFM(val: CashFlowMethodData): CashFlowMethodData {
  const preliminary = Object.assign({}, val.preliminary) as CFMPreliminary;
  const firstTerm = Object.assign({}, val.firstTerm) as CFMFirstTerm;
  const lastTerm = Object.assign({}, val.lastTerm) as CFMLastTerm;
  const midTerms: CFMMidTerm[] = [];

  Object.entries(val.preliminary).forEach(([field, value]) => {
    preliminary[field] = Number.isNaN(value) ? undefined : value;
  });
  Object.entries(val.firstTerm).forEach(([field, value]) => {
    firstTerm[field] = Number.isNaN(value) ? undefined : value;
  })
  Object.entries(val.lastTerm).forEach(([field, value]) => {
    lastTerm[field] = typeof value === 'number'
      ? Number.isNaN(value) ? undefined : value
      : value;
  });
  val.midTerms.forEach(mt => {
    const midTerm = Object.assign({}, mt) as CFMMidTerm;
    Object.entries(mt).forEach(([field, value]) => {
      midTerm[field] = typeof value === 'number'
        ? Number.isNaN(value) ? undefined : value
        : value;
    });
    midTerms.push(midTerm);
  })

  let effectiveRent = Number.isNaN(val.results.effectiveRent) ? undefined : val.results.effectiveRent;
  const effectiveRents: CFMEffectiveRent[] = [];
  val.results.effectiveRents.forEach(er => {
    const e = Object.assign({}, er) as CFMEffectiveRent;
    e.prev = Number.isNaN(er.prev) ? undefined : er.prev;
    e.value = Number.isNaN(er.value) ? undefined : er.value;
    effectiveRents.push(e);
  })


  const res: CashFlowMethodData = {
    numberOfTerms: val.numberOfTerms,
    numberOfTermsReal: val.numberOfTermsReal,
    remainingYears: val.remainingYears,
    preliminary,
    firstTerm,
    lastTerm,
    midTerms,
    factorValue: Number.isNaN(val.factorValue) ? undefined : val.factorValue,
    results: {
      effectiveRent,
      effectiveRents,
      isSelected: false,
    }
  }
  return res;
}

function getNumOfTermsAndRemainingYears(writeOffOrLease: number, rentReview: number): [number, number, number] {
  const realValue = writeOffOrLease / rentReview;
  const numberOfTerms = Math.ceil(realValue);
  const remainder = writeOffOrLease % rentReview;
  if (remainder === 0) {
    return [numberOfTerms, realValue, rentReview];
  }
  return [numberOfTerms, realValue, remainder];
}