import { ChangeDetectionStrategy, Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { BehaviorSubject, combineLatest, of, pipe, Subject } from 'rxjs';
import { filter, takeUntil, tap, map, take, switchMap } from 'rxjs/operators';
import { Location } from '@angular/common';
import { AllPropertyDetailReportsRequested, AllTpTaskRequested, AssetClassModel } from 'src/app/core/asset_class';
import { AllAssetClassSEROfTPRequested } from 'src/app/core/asset_class/_actions/asset-class-ser.action';
import { AllTpFileOfTPRequested } from 'src/app/core/asset_class/_actions/tp-file.actions';
import { AssignmentModel } from 'src/app/core/assignment';
import { AllKeyCategoriesRequested, AllSpecialAssumptionsRequested } from 'src/app/core/linked-tables';
import { StandardMeasurementUtility } from 'src/app/core/linked-tables/_services/standard-measurement-utility.service';
import { AppState } from 'src/app/core/reducers';
import { ToeModel } from 'src/app/core/toe';
import {
  RequestValuationProcessData,
  selectValuationProcessLoadingState,
  selectValuationProcessTargetPropertyInfo,
  selectValuationProcessTitle,
  selectValuationProcessValuation,
  selectValuationProcessAdditionalData,
  selectValuationLandmarks
} from 'src/app/core/valuation-process';
import { ReadonlyService } from 'src/app/core/_base/crud/utils/readonly.service';
import { ConditionRatingV2Service } from '../services/condition-rating-v2.service';
import { selectValuationProcessSelectedComparables, selectValuationProcessSelectedConsiderations, selectValuationProcessSelectedTenures, selectValuationProcessType } from 'src/app/core/valuation-process/_selectors/valuation-process.selectors';
import { ValuationProcessLoadCriterions } from 'src/app/core/valuation-process/_actions/criterion.actions';
import { ResetStateOfValuationProcess, StoreValuationProcessData } from 'src/app/core/valuation-process/_actions/valuation-process.actions';
import { selectAdjustmentTabDat, selectValuationProcessValuationCalculations, selectValuationProcessValuationFullState, selectValuationProcessValuationIsValid, selectValuationProcessValuationState } from 'src/app/core/valuation-process/_selectors/valuation.selectors';
import { selectAssumptionTabData } from 'src/app/core/valuation/_selectors/valuation-data.selector';
import * as valuationNotesSelectors from 'src/app/core/valuation/_selectors/valuation-notes.selectors';
import { selectValuationComparableSelectedPropertySubTypeIds } from 'src/app/core/valuation-process/_selectors/valuation-comparable.selectors';
import { LayoutUtilsService, MessageType } from 'src/app/core/_base/crud';
import { TranslateService } from '@ngx-translate/core';
import * as LiquidationValuationSelectors from 'src/app/core/valuation-process/_selectors/liquidation-valuation.selectors'
import { CurrencyExchange } from 'src/app/core/valuation-process/_models/currency-exchange';
import { CurrencyExchangeService } from 'src/app/core/admin/_services/currency_exchange.service';

@Component({
  selector: 'kt-valuation-process',
  templateUrl: './valuation-process.component.html',
  styleUrls: ['./valuation-process.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  providers: [StandardMeasurementUtility, ConditionRatingV2Service]
})
export class ValuationProcessComponent implements OnInit, OnDestroy {
  loading$ = this.store$.select(selectValuationProcessLoadingState)
  readonly = false

  adjustmentDisabled$ = this.store$.select(selectValuationProcessSelectedComparables).pipe(map(refNums => {
    return refNums.length == 0
  }))
  summaryDisabled$ = this.store$.select(selectValuationProcessValuationIsValid).pipe(map(isValid => {
    return !isValid;
  }))

  title$ = this.store$.select(selectValuationProcessTitle)
  valuationProcessType$ = this.store$.select(selectValuationProcessType)

  tabHeaders$ = this.valuationProcessType$.pipe(
    filter(type => type != null),
    map(type => {
      if (type === 'liquidation') {
        return [
          { label: '1 - Background', disabled: of(false) },
          { label: '2 - Market data', disabled: of(false)},
          { label: '3 - Adjustments', disabled: this.adjustmentDisabled$},
          { label: '4 - Liquidation value adjustments', disabled: this.adjustmentDisabled$},
          { label: '5 - Preliminaries review', disabled: this.adjustmentDisabled$},
          { label: '6 - Summary', disabled: this.summaryDisabled$ }
        ]
      }
      return [
        { label: '1 - Background', disabled: of(false) },
        { label: '2 - Comparables', disabled: of(false)},
        { label: '3 - Adjustments', disabled: this.adjustmentDisabled$},
        { label: '4 - Preliminaries review', disabled: this.adjustmentDisabled$},
        { label: '5 - Summary', disabled: this.summaryDisabled$ }
      ]
    })
  )

  tabHeaderIndex$ = new BehaviorSubject<number>(0)
  selectedTab$ = this.tabHeaderIndex$.pipe(
    switchMap(headerIndex =>
      this.valuationProcessType$.pipe(
        map(type => {
          if (type === 'liquidation') {
            return liquidationTabMap[headerIndex]
          }
          return defaultTabMap[headerIndex]
        })
      )
    )
  ) 
  onTabChange(index: number) {
    this.tabHeaderIndex$.next(index)
  }

  targetProperty$ = this.store$.select(selectValuationProcessTargetPropertyInfo).pipe(
    tap(tp => {
      if (!tp) {
        return
      }
      this.center$.next({lat: tp.generalInfo.locationData.latitude, lng: tp.generalInfo.locationData.longitude})
    })
  )
  valuation$ = this.store$.select(selectValuationProcessValuation)
  center$ = new BehaviorSubject({lat: 0, lng: 0})

  private _valuationId: number
  private _targetPropertyId: number

  // Refactor it later.
  toe$ = new BehaviorSubject<ToeModel>(null);
  assignment$ = new BehaviorSubject<AssignmentModel>(null);
  workers$ = new BehaviorSubject<any[]>([]);
  fullTp$ = new BehaviorSubject<AssetClassModel>(null);

  currencyExchange: CurrencyExchange[] = [];

  private _onDestroy$: Subject<void> = new Subject();

  constructor(
    private activatedRoute: ActivatedRoute,
    private store$: Store<AppState>,
    private readonlyService: ReadonlyService, 
    private crService: ConditionRatingV2Service,
    private smUtilityService: StandardMeasurementUtility,
    private location: Location,
    private router: Router,
    private laytoutService: LayoutUtilsService,
    private translateService: TranslateService,
    private currencyExchangeService: CurrencyExchangeService
  ) { }

  ngOnInit(
  ): void {
    this.store$.dispatch(new ValuationProcessLoadCriterions())
    this.readonly = this.readonlyService.isReadOnly()
    this.activatedRoute.params
      .pipe(takeUntil(this._onDestroy$))
      .subscribe(params => {
        this.store$.dispatch(new RequestValuationProcessData({
          targetPropertyId: params.asset_class_id,
          valuationId: params.valuation_id
        }))
        this._valuationId = params.valuation_id
        this._targetPropertyId = params.asset_class_id
      })

    this.activatedRoute.data
      .pipe(takeUntil(this._onDestroy$))
      .subscribe(data => {
        this.toe$.next(data.toeData.data)
        this.assignment$.next(data.assignment.data)
        this.workers$.next(data.workersData ? data.workersData.data : [])
        this.fullTp$.next(data.assetClassData ? data.assetClassData.data : null)
      })


   this.store$.select(selectValuationProcessAdditionalData)
    .pipe(
      takeUntil(this._onDestroy$),
      filter(item => item != null && item != undefined)
    )
    .subscribe(data => {
      this.crService.setComponentsAll(this.smUtilityService, data.assetClass.details, data.assetClass);
      this.crService.setConditionRatingsFromRatingModels(data.ratings);
      this.crService.setSchemeId(data.floorNumberingSchemeId);
    })

    this.store$.dispatch(new AllSpecialAssumptionsRequested());
    this.store$.dispatch(new AllKeyCategoriesRequested());

    this.router.events.forEach(event => {
      if (event instanceof NavigationEnd) {
        this.store$.dispatch(new ResetStateOfValuationProcess())
      }
    })
  }

  ngOnDestroy(): void {
    this._onDestroy$.next()
    this._onDestroy$.complete()
  }

  back() {
    this.location.back();
  }

  onSubmit(isComplete: boolean = false) {
    this.currencyExchangeService.bulkCreateOrUpdate(this.currencyExchange).subscribe()
    combineLatest([
      this.store$.pipe(select(selectValuationProcessSelectedComparables), take(1)),
      this.store$.pipe(select(selectValuationProcessSelectedTenures), take(1)),
      this.store$.pipe(select(selectAssumptionTabData), take(1)),
      this.store$.pipe(select(valuationNotesSelectors.selectAll), take(1)),
      this.store$.pipe(select(selectValuationLandmarks), take(1)),
      this.store$.pipe(select(selectAdjustmentTabDat), take(1)),
      this.store$.pipe(select(selectValuationComparableSelectedPropertySubTypeIds), take(1)),
      this.store$.pipe(select(LiquidationValuationSelectors.selectLiquidationValuationState))
    ]).pipe(takeUntil(this._onDestroy$)).subscribe(([refNums, considerations, assumptionTabData, notes, landMarks
      ,adjustmentTabData, propertySubTypeIds, liquidationValuation
    ]: any[]) => {

      this.store$.dispatch(new StoreValuationProcessData({
        targetPropertyId: this._targetPropertyId,
        valuationId: this._valuationId,
        body: {
          notes,
          validation: isComplete,
          land_marks: landMarks.map(lm => lm.id),
          selected_refNums: refNums,
          tenures: considerations,
          adjustmentTab: adjustmentTabData,
          assumptionTabData: assumptionTabData,
          propertySubTypes: propertySubTypeIds,
          liquidationValuation
        }
      }))
      this.laytoutService.showActionNotification(this.translateService.instant('GENERAL.MESSAGE.SAVE_CHANGES'), MessageType.Update, 3000, true, false)
      this.router.navigate(['../../../../', 'dashboard'], {relativeTo: this.activatedRoute})
    })
  }

  onCurrencyExchangeUpdate(event: CurrencyExchange) {
    const index = this.currencyExchange.findIndex(ce => {
      if (ce.id && ce.id === event.id) {
        return true
      }
      if (ce.base_currency && ce.quote_currency && ce.date) {
        if (ce.base_currency === event.base_currency && ce.quote_currency === event.quote_currency && ce.date === event.date) {
          return true
        }
      }
      return false
    })
    if (index === -1) {
      this.currencyExchange.push(event)
    } else {
      this.currencyExchange[index].exchange_rate = event.exchange_rate
    }
  }
}

const liquidationTabMap = {
  0: 0,
  1: 1,
  2: 2,
  3: 3,
  4: 4,
  5: 5
}

const defaultTabMap = {
  0: 0,
  1: 1,
  2: 2,
  3: 4,
  4: 5
}
