<div class="card card-custom">
    <div class="card-body">
        <div class="row">
            <div class="col-md-12 kt-margin-bottom-20-mobile">
                <h5 class="my-3 text-mad">{{valuation.methods_to_value_name}}</h5>
                <hr class="active">
            </div>
        </div>

        <ng-container *ngIf="!(loading$|async); else loading">
            <div class="row">
                <div class="col-xl-6">
                    <kt-valuation-specific [reporting]="reporting" [valuation]="valuation" [tp]="tp"></kt-valuation-specific>
                </div>
                <div class="col-xl-6">
                    <kt-milestones
                        [tp]="tp"
                        [toe]="toe"
                        [valuation]="valuation"
                        [workers]="workers"
                        [assignment]="assignment"></kt-milestones>
                </div>
            </div>
            <div class="row mt-6">
                <div class="col-xl-12">
                    <ng-container *ngIf="showSummary(valuation) == 'income'">
                        <kt-income-valuation-summary-table
                            [targetProperty]="tp" [valuation]="valuation"
                        ></kt-income-valuation-summary-table>
                    </ng-container>
                    <ng-container *ngIf="showSummary(valuation) == 'market'">
                        <kt-summary-table-v2 [targetProperty]="tp" [valuation]="valuation"></kt-summary-table-v2>
                    </ng-container>
                </div>
            </div>
        </ng-container>
    </div>
</div>

<ng-template #loading>
    Loading ...
</ng-template>