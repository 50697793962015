import { ThisReceiver } from '@angular/compiler';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { select, Store } from '@ngrx/store';
import { map, switchMap, take, takeUntil } from 'rxjs/operators';
import { currentUser, User } from 'src/app/core/mad-auth/mad-auth.store';
import { AppState } from 'src/app/core/reducers';
import { selectValuationProcessLoadingState, selectValuationProcessValuation, ValuationProcessTargetPropertyModel, ValuationProcessValuationModel } from 'src/app/core/valuation-process';
import { FullComparablesRequested } from 'src/app/core/valuation-process/_actions';
import { AddedComparablesRequested } from 'src/app/core/valuation-process/_actions/valuation-comparable.actions';
import { ApartmentModalComponent } from '../../../comparable/apartments/modal/apartment-modal.component';
import { HouseModalComponent } from '../../../comparable/house/modal/house-modal.component';
import { LandModalComponent } from '../../../comparable/lands/modal/land-modal.component';
import { OfficeModalComponent } from '../../../comparable/offices/modal/office-modal.component';
import { ParkingModalComponent } from '../../../comparable/parkings/modal/parking-modal.component';
import { RetailBuildingModalComponent } from '../../../comparable/retail-building/modal/retail-building-modal.component';
import { RetailsModalComponent } from '../../../comparable/retails/modal/retails-modal.component';
import { WarehouseModalComponent } from '../../../comparable/warehouse/modal/warehouse-modal.component';
import { selectOtherValidatedValuations, selectValuationProcessType } from 'src/app/core/valuation-process/_selectors/valuation-process.selectors';
import { FormControl, FormGroup } from '@angular/forms';
import { formControlValueChanges } from 'src/app/core/v2/utils/form-value-changes';
import { Subject, combineLatest } from 'rxjs';
import { ImportMultipleComparablestoValuationProcess, ResetStateOfValuationProcess, UnimportValuationFromValuationProcess } from 'src/app/core/valuation-process/_actions/valuation-process.actions';
import * as LiquidationValuationActions from 'src/app/core/valuation-process/_actions/liquidation-valuation.actions'
import * as LiquidationValuationSelectors from 'src/app/core/valuation-process/_selectors/liquidation-valuation.selectors'
import * as AssumptionDepartureActions from 'src/app/core/valuation/_actions/assumption_departure.actions'
import { ConfirmDialogComponent, ConfirmDialogComponentInput, ConfirmDialogComponentOutput } from '../../../mad_shared/confirm-dialog/confirm-dialog.component';
import { CurrencyExchange } from 'src/app/core/valuation-process/_models/currency-exchange';

@Component({
  selector: 'kt-comparables-tab-v2',
  templateUrl: './comparables-tab-v2.component.html',
  styleUrls: ['./comparables-tab-v2.component.scss']
})
export class ComparablesTabV2Component implements OnInit, OnDestroy {

  @Input() targetProperty: ValuationProcessTargetPropertyModel; 
  @Input() valuation: ValuationProcessValuationModel
  @Output() currencyExchangeUpdate: EventEmitter<CurrencyExchange> = new EventEmitter()
  loading$ = this.store$.select(selectValuationProcessLoadingState)
  valuation$ = this.store$.select(selectValuationProcessValuation)
  valuationProcessType$ = this.store$.select(selectValuationProcessType)

  // Liquidation Valuation
  otherValidatedValuations$ = this.store$.select(selectOtherValidatedValuations)
  isLiquidationValuation$ = combineLatest([
    this.valuationProcessType$.pipe(
      map(type => type === 'liquidation')
    ),
    this.otherValidatedValuations$.pipe(
      map(others => others.length > 0)
    )
  ]).pipe(map(([isLiquidation, hasOtherVals]) => isLiquidation && hasOtherVals))

  liquidationFormGroup = new FormGroup({
    import_other_valuations: new FormControl(false),
    selected_valuation: new FormControl<number>(null)
  })
  showValuations$ = formControlValueChanges(this.liquidationFormGroup.controls.import_other_valuations)

  currentUser: User;

  private _onDestroy$ = new Subject<void>()

  constructor(
    private store$: Store<AppState>,
    private dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.store$.pipe(take(1), select(currentUser)).subscribe(user => this.currentUser = user);

    this.store$.select(LiquidationValuationSelectors.selectLiquidationFormValue).pipe(
      take(1)
    ).subscribe(value => {
      this.liquidationFormGroup.patchValue({
        import_other_valuations: value.importOtherValuation,
        selected_valuation: value.selectedValuationId
      })
    })

    // Side Effect
    combineLatest([
      formControlValueChanges(this.liquidationFormGroup.controls.import_other_valuations),
      this.store$.select(LiquidationValuationSelectors.selectLiquidationFormValue)
    ]).pipe(
      takeUntil(this._onDestroy$)
    ).subscribe(([importOtherValuations, state]) => {
      if (importOtherValuations === false && state.importOtherValuation === true) {
        this.unImportValuationDialog()
      }
    })
    this.liquidationFormGroup.valueChanges.pipe(
      takeUntil(this._onDestroy$)
    ).subscribe(values => {
      this.store$.dispatch(LiquidationValuationActions.changeLiquidationFormValues({
        values: {
          importOtherValuations: values.import_other_valuations,
          selectedValuationId: values.selected_valuation
        }
      }))
    })

    combineLatest([
      formControlValueChanges(this.liquidationFormGroup.controls.selected_valuation),
      this.store$.select(selectOtherValidatedValuations)
    ]).pipe(
      takeUntil(this._onDestroy$)
    ).subscribe(([valuationId, valuations]) => {
      const valuation = valuations.find(item => item.id === valuationId)
      if (!valuation) {
        return;
      }
      this.store$.dispatch(new ImportMultipleComparablestoValuationProcess({
        comparables: valuation.comparables.map(item => ({refNum: item.ref_num, tenureId: item.selected_tenure_id})),
        adjustmentsTabData: valuation.adjustment_tab_data
      }))
      this.store$.dispatch(new AssumptionDepartureActions.LoadData({
        default: valuation.default_assumptions.map(item => ({...item, valuation_id: valuation.id})),
        special: valuation.special_assumptions.map(item => ({...item, valuation_id: valuation.id})),
        departure: valuation.departures.map(item => ({...item, valuation_id: valuation.id}))
      }))
    })
  }

  ngOnDestroy(): void {
    this._onDestroy$.next()
    this._onDestroy$.complete()
  }

  unImportValuationDialog() {
    const dialogRef = this.dialog.open<ConfirmDialogComponent, ConfirmDialogComponentInput, ConfirmDialogComponentOutput>(ConfirmDialogComponent, {
      width: '450px',
      data: {
        description: 'Warning, all inputs from the valuation will be lost. Would you like to continue?'
      }
    }) 
    dialogRef.afterClosed().subscribe(res => {
      if (!res || res === 'no') {
        this.liquidationFormGroup.controls.import_other_valuations.patchValue(true)
        return;
      }
      this.store$.dispatch(new UnimportValuationFromValuationProcess())
      this.store$.dispatch(LiquidationValuationActions.resetLiquidationValues())
      this.liquidationFormGroup.controls.selected_valuation.patchValue(null)
    })
  }

  addComparable() {
    let dialogRef = null;
    switch (this.targetProperty.generalInfo.propertySubType) {
      case 'Apartment': {
        dialogRef = this.dialog.open(ApartmentModalComponent, {
          width: '80vw',
          data: {
            valuationId: this.valuation.valuationId
          }
        })
        break;
      }
      case 'House': {
        dialogRef = this.dialog.open(HouseModalComponent, {
          width: '80vw',
          data: {
            valuationId: this.valuation.valuationId
          }
        })
        break;
      }
      case 'Office': {
        dialogRef = this.dialog.open(OfficeModalComponent, {
          width: '80vw',
          data: {
            valuationId: this.valuation.valuationId
          }
        })
        break;
      }
      case 'Retail Shop': {
        dialogRef = this.dialog.open(RetailsModalComponent, {
          width: '80vw',
          data: {
            valuationId: this.valuation.valuationId
          }
        })
        break;
      }
      case 'Retail Building': {
        dialogRef = this.dialog.open(RetailBuildingModalComponent, {
          width: '80vw',
          data: {
            valuationId: this.valuation.valuationId
          }
        })
        break;
      }
      case 'Parking': {
        dialogRef = this.dialog.open(ParkingModalComponent, {
          width: '80vw',
          data: {
            valuationId: this.valuation.valuationId
          }
        })
        break;
      }
      case 'Warehouse': {
        dialogRef = this.dialog.open(WarehouseModalComponent, {
          width: '80vw',
          data: {
            valuationId: this.valuation.valuationId
          }
        })
        break;
      }
      case 'Land': {
        dialogRef = this.dialog.open(LandModalComponent, {
          width: '80vw',
          data: {
            valuationId: this.valuation.valuationId
          }
        })
        break;
      }
    }

    if (dialogRef != null) {
      dialogRef.afterClosed().subscribe(res => {
        if (!res) {
          return;
        }
        this.store$.dispatch(new AddedComparablesRequested({refNum: res.ref_num}))
      })
    }
  }

  onCurrencyExchangeUpdate(event: CurrencyExchange) {
    this.currencyExchangeUpdate.emit(event)
  }

}
