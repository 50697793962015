import { Component, Inject, OnDestroy, ViewChild } from "@angular/core";
import { AbstractControl, FormControl, FormGroup, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { ComponentStore } from "@ngrx/component-store";
import * as moment from "moment";
import { BehaviorSubject, combineLatest, Observable, Subject } from "rxjs";
import { distinctUntilChanged, filter, map, startWith, switchMap, takeUntil, tap, withLatestFrom } from "rxjs/operators";
import { AssetClassLandAreaComponentModel } from "src/app/core/asset_class";
import { AcSource } from "src/app/core/comparable";
import { RentBasisService, RentReviewTypeService, ConsiderationTypeService } from "src/app/core/v2/services";
import { Consideration, RentReviewTypes, AssetClassSize, RentType, InputAmountType, LeaseDurationType, RentBasisListing, RentReviewTypeListing, RentReviewDetailType } from "src/app/core/v2/types";
import { ConsiderationType } from "src/app/core/v2/types/consideration-type";
import { formControlValueChanges } from "src/app/core/v2/utils";
import { CountryData } from "src/app/views/pages/admin-management/countries/countries-list/local-data";
import { DatepickerTzInputComponent } from "src/app/views/pages/mad-forms/datepicker-tz-input/datepicker-tz-input.component";
import { SourceComponent } from "src/app/views/pages/shared_components/source/source.component";
import { ParcelConsiderationTableComponent } from "../parcel-consideration-table/parcel-consideration-table.component";
import { AcLandTenure } from "src/app/core/comparable/_models/asset-class-tenure.model";

export interface AddConsiderationDialogV2Input {
  consideration?: Consideration,
  sizes: AssetClassSize[],
  otherDates: {tenure: string, date: string}[],
  components: AssetClassLandAreaComponentModel[],
  hasParcel: boolean,
  additionals: AcLandTenure[]
}
export interface AddConsiderationDialogV2Output {
  consideration: Consideration
}

interface State {
  id?: number,
  title: string,
  considerationTypes: ConsiderationType[],
  sizes: AssetClassSize[],
  rentBases: RentBasisListing[],
  rentReviewTypes: RentReviewTypeListing[]
  countries: {
    currencyCode: string
  }[],
  otherDates: string[],
}

@Component({
  selector: 'app-add-consideration-dialog-v2',
  templateUrl: './add-consideration-dialog-v2.component.html',
  styleUrls: ['./add-consideration-dialog-v2.component.scss', '../../../complex-btn.scss'],
  providers: [ComponentStore]
})
export class AddConsiderationDialogV2Component implements OnDestroy {
  @ViewChild(SourceComponent, {static: false})
  private sourceComponent: SourceComponent;
  @ViewChild(ParcelConsiderationTableComponent, {static: false})
  private parcelConsiderations: ParcelConsiderationTableComponent;

  title$ = this.store.select(state => state.title)

  hasFormError$ = new BehaviorSubject(false)
  formGroup = new FormGroup({
    currency: new FormControl<string>(null, [Validators.required]),
    tenure: new FormControl<string>(null, [Validators.required]),
    consideration_type_id: new FormControl<number>(null, [Validators.required]),
    sale_input_amount: new FormControl<number>(null),
    rent_type: new FormControl<RentType>(null),
    start_date: new FormControl<Date>(null),
    duration: new FormControl<number>(null),
    duration_type: new FormControl<LeaseDurationType>('years'),
    rent_free_period: new FormControl<number>(null),
    fitting_out_period: new FormControl<number>(null),
    write_off_period_after: new FormControl<number>(null),
    break_option_after: new FormControl<number>(null),
    capital_payment: new FormControl<number>(0),
    unexpired_lease_terms: new FormControl<number>({value: null, disabled: true}),
    rent_input_amount_type: new FormControl<InputAmountType>(null),
    rent_size_id: new FormControl<string>(null),
    rent_amount_per_unit: new FormControl<number>(null),
    rent_amount_total: new FormControl<number>(null),
    rent_basis_id: new FormControl<number>(null),
    rent_review_type_id: new FormControl<number>(null),
    rent_review_cycle: new FormControl<number>(null),
    rent_review_detail_number: new FormControl<number>(null),
    rent_review_detail_percentage: new FormControl<number>(null),
    rent_review_detail_type: new FormControl<RentReviewDetailType>('number'),
    expenses_input_amount_type: new FormControl<InputAmountType>(null),
    expenses_size_id: new FormControl<string>(null),
    recoverable_property_management: new FormControl<number>(null),
    recoverable_leasing_expenses: new FormControl<number>(null),
    recoverable_utilities: new FormControl<number>(null),
    recoverable_maintenance: new FormControl<number>(null),
    recoverable_insurance: new FormControl<number>(null),
    recoverable_janitorial: new FormControl<number>(null),
    recoverable_property_taxes: new FormControl<number>(null),
    recoverable_business: new FormControl<number>(null),
    recoverable_others: new FormControl<number>(null),
    nonrecoverable_property_management: new FormControl<number>(null),
    nonrecoverable_leasing_expenses: new FormControl<number>(null),
    nonrecoverable_utilities: new FormControl<number>(null),
    nonrecoverable_maintenance: new FormControl<number>(null),
    nonrecoverable_insurance: new FormControl<number>(null),
    nonrecoverable_janitorial: new FormControl<number>(null),
    nonrecoverable_property_taxes: new FormControl<number>(null),
    nonrecoverable_business: new FormControl<number>(null),
    nonrecoverable_others: new FormControl<number>(null),
    notes: new FormControl<string>(null)
  }, {validators: [
    checkIfLesserThanLease('rent_review_cycle'),
    checkIfLesserThanLease('write_off_period_after'),
    checkIfLesserThanOrEqualToLease('break_option_after'),
    checkIfLesserThanOrEqualToLease('rent_free_period'),
    checkIfLesserThanOrEqualToLease('fitting_out_period'),
    checkIfLesserThanWriteOff('rent_free_period'),
    checkIfLesserThanWriteOff('fitting_out_period')
  ]})
  formSelectionCtrl = new FormControl<'simple' | 'advanced'>('simple')
  isAdvancedForm$ = formControlValueChanges(this.formSelectionCtrl).pipe(
    map(value => value === 'advanced')
  )

  filterCurrencyFormControl = new FormControl<string>('')
  private _currencies$ = this.store.select(state => state.countries.map(country => country.currencyCode))
  currencies$ = combineLatest([
    this.filterCurrencyFormControl.valueChanges.pipe(startWith('')),
    this._currencies$
  ]).pipe(map(([filter, currencies]) => {
    if (!filter || filter === '') {
      return currencies
    }
    const _filter = filter.toLowerCase()
    return currencies.filter(currency => currency.toLowerCase().indexOf(_filter) > -1)
  }))
  currencySuffix$ = formControlValueChanges(this.formGroup.controls.currency).pipe(
    map(value => value ? ` ${value}` : '')
  )

  tenures = ['Freehold', 'Leasehold']
  selectedTenure$ = formControlValueChanges(this.formGroup.controls.tenure).pipe(
    map(value => {
      if (value === 'freehold') return 'Freehold'
      if (value === 'leasehold') return 'Leasehold'
      return ''
    })
  )
  considerationTypes$ = this.considerationTypeService.fetchListing().pipe(
    tap(items => this.store.patchState({considerationTypes: items}))
  )
  showSaleTerms$ = formControlValueChanges(this.formGroup.controls.consideration_type_id).pipe(
    map(value => value === 1)
  )
  showLeaseholdTerms$ = formControlValueChanges(this.formGroup.controls.consideration_type_id).pipe(
    map(value => value === 2)
  )

  // General Information
  leaseStartDate$ = formControlValueChanges(this.formGroup.controls.start_date).pipe(
    map(value => {
      if (value) {
        if (moment.isMoment(value)) {
          return value.toDate()
        }
        return value
      }
      return null
    })
  )
  duration_types: {value: LeaseDurationType, text: string}[] = [{value: 'months', text: 'Months'}, {value: 'years', text: 'Years'}]
  getDurationType(value: LeaseDurationType) {
    if (value === 'months') return '(months)'
    if (value === 'years') return '(years)'
    return null
  }
  rentFreePeriodLabel$ = formControlValueChanges(this.formGroup.controls.duration_type).pipe(
    map(value => {
      const duration = this.getDurationType(value)
      return `Rent Free period${duration !== null ? (' ' + duration) : ''}`
    })
  )
  fittingOutPeriodLabel$ = formControlValueChanges(this.formGroup.controls.duration_type).pipe(
    map(value => {
      const duration = this.getDurationType(value)
      return `Fitting out period${duration !== null ? (' ' + duration) : ''}`
    })
  )
  writeOffPeriodAfterLabel$ = formControlValueChanges(this.formGroup.controls.duration_type).pipe(
    map(value => {
      const duration = this.getDurationType(value)
      return `Write off period after${duration !== null ? (' ' + duration) : ''}`
    })
  )
  breakOptionAfterLabel$ = formControlValueChanges(this.formGroup.controls.duration_type).pipe(
    map(value => {
      const duration = this.getDurationType(value)
      return `Break option after${duration !== null ? (' ' + duration) : ''}`
    })
  )

  // Rent
  sizes$ = this.store.select(state => state.sizes)
  showRentSizeDropdown$ = combineLatest([
    this.sizes$,
    formControlValueChanges(this.formGroup.controls.rent_input_amount_type)
  ]).pipe(map(([sizes, inputAmountType]) => {
    return inputAmountType === 'per_units' && sizes.length > 1
  }))
  showRentAmountPerUnit$ = formControlValueChanges(this.formGroup.controls.rent_input_amount_type).pipe(
    map(value => value === 'per_units')
  )
  showRentAmountTotal$ = formControlValueChanges(this.formGroup.controls.rent_input_amount_type).pipe(
    map(value => value === 'total')
  )
  amountPerUnitSizeTotal$ = combineLatest([
    formControlValueChanges(this.formGroup.controls.rent_amount_per_unit),
    formControlValueChanges(this.formGroup.controls.rent_size_id).pipe(
      switchMap(value => this.sizes$.pipe(
        map(sizes => {
          if (sizes.length === 1) {
            return sizes[0].size
          }
          const size = sizes.find(size => size.uid === value)
          return size ? size.size : undefined
        })
      ))
    )
  ]).pipe(
    map(([perUnit, size]) => {
      if (size === undefined) return 'N/A'
      if (perUnit === null) return 'N/A'
      return size * perUnit
    })
  )
  rentBases$ = this.rentBasisService.fetchListing().pipe(
    tap(items => this.store.patchState({rentBases: items}))
  )
  rentReviewTypes$ = this.rentReviewTypeService.fetchListing().pipe(
    tap(items => this.store.patchState({rentReviewTypes: items})),
  )
  showRentReviewCycle$ = formControlValueChanges(this.formGroup.controls.rent_review_type_id).pipe(
    map(value => value !== null && value !== -1)
  )
  rentReviewCycleLabel$ = formControlValueChanges(this.formGroup.controls.duration_type).pipe(
    map(value => {
      const duration = this.getDurationType(value)
      return `Rent Review Cycle${duration !== null ? (' ' + duration) : ''}`
    })
  )
  showRentReviewDetail$ = formControlValueChanges(this.formGroup.controls.rent_review_type_id).pipe(
    map(value => value === RentReviewTypes.FixedRentIncrease)
  )
  showRentReviewDetailWholeNumber$ = formControlValueChanges(this.formGroup.controls.rent_review_detail_type).pipe(
    map(value => value === 'number')
  )
  showRentReviewDetailPercentage$ = formControlValueChanges(this.formGroup.controls.rent_review_detail_type).pipe(
    map(value => value === 'percentage')
  )

  // Expenses
  showExpensesSizeDropdown$ = combineLatest([
    this.sizes$,
    formControlValueChanges(this.formGroup.controls.expenses_input_amount_type)
  ]).pipe(map(([sizes, inputAmountType]) => {
    return inputAmountType === 'per_units' && sizes.length > 1
  }))
  expensesInputAmountType$ = formControlValueChanges(this.formGroup.controls.expenses_input_amount_type).pipe(
    map(value => {
      if (value === 'per_units') return 'Per unit'
      if (value === 'total') return 'Total'
      return ''
    })
  )
  propertyManagementAmountPerUnit$ = this._expensesPerUnit(formControlValueChanges(this.formGroup.controls.recoverable_property_management)) 
  leasingExpensesAmountPerUnit$ = this._expensesPerUnit(formControlValueChanges(this.formGroup.controls.recoverable_leasing_expenses))
  utilitiesAmountPerUnit$ = this._expensesPerUnit(formControlValueChanges(this.formGroup.controls.recoverable_utilities))
  maintenanceAmountPerUnit$ = this._expensesPerUnit(formControlValueChanges(this.formGroup.controls.recoverable_maintenance))
  insuranceAmountPerUnit$ = this._expensesPerUnit(formControlValueChanges(this.formGroup.controls.recoverable_insurance))
  janitorialAmountPerUnit$ = this._expensesPerUnit(formControlValueChanges(this.formGroup.controls.recoverable_janitorial))
  propertyTaxesAmountPerUnit$ = this._expensesPerUnit(formControlValueChanges(this.formGroup.controls.recoverable_property_taxes))
  businessAmountPerUnit$ = this._expensesPerUnit(formControlValueChanges(this.formGroup.controls.recoverable_business))
  otherAmountPerUnit$ = this._expensesPerUnit(formControlValueChanges(this.formGroup.controls.recoverable_others))

  nonpropertyManagementAmountPerUnit$ = this._expensesPerUnit(formControlValueChanges(this.formGroup.controls.nonrecoverable_property_management)) 
  nonleasingExpensesAmountPerUnit$ = this._expensesPerUnit(formControlValueChanges(this.formGroup.controls.nonrecoverable_leasing_expenses))
  nonutilitiesAmountPerUnit$ = this._expensesPerUnit(formControlValueChanges(this.formGroup.controls.nonrecoverable_utilities))
  nonmaintenanceAmountPerUnit$ = this._expensesPerUnit(formControlValueChanges(this.formGroup.controls.nonrecoverable_maintenance))
  noninsuranceAmountPerUnit$ = this._expensesPerUnit(formControlValueChanges(this.formGroup.controls.nonrecoverable_insurance))
  nonjanitorialAmountPerUnit$ = this._expensesPerUnit(formControlValueChanges(this.formGroup.controls.nonrecoverable_janitorial))
  nonpropertyTaxesAmountPerUnit$ = this._expensesPerUnit(formControlValueChanges(this.formGroup.controls.nonrecoverable_property_taxes))
  nonbusinessAmountPerUnit$ = this._expensesPerUnit(formControlValueChanges(this.formGroup.controls.nonrecoverable_business))
  nonotherAmountPerUnit$ = this._expensesPerUnit(formControlValueChanges(this.formGroup.controls.nonrecoverable_others))

  // Change it Later
  sourceSubject = new BehaviorSubject<AcSource>(new AcSource())
  otherDates$ = this.store.select(state => state.otherDates)
  parcelError$ = new BehaviorSubject(false);

  private _onDestroy$ = new Subject<void>()

  constructor(
    private readonly store: ComponentStore<State>,
    public dialogRef: MatDialogRef<AddConsiderationDialogV2Component, AddConsiderationDialogV2Output | undefined>,
    @Inject(MAT_DIALOG_DATA) public data: AddConsiderationDialogV2Input,
    private considerationTypeService: ConsiderationTypeService,
    private rentBasisService: RentBasisService,
    private rentReviewTypeService: RentReviewTypeService
  ) {
    this.store.setState({
      id: this.data.consideration ? this.data.consideration.id : undefined,
      title: this.data.consideration ? 'Edit Consideration'  : 'Add Consideration',
      countries: CountryData.countriesWithNoDuplicateCurrency(),
      sizes: this.data.sizes,
      rentBases: [],
      rentReviewTypes: [],
      considerationTypes: [],
      otherDates: this.data.otherDates.map(od => od.date),
    })

    if (this.data.consideration) {
      this.formSelectionCtrl.patchValue(this.data.consideration.formType === 1 ? 'advanced' : 'simple')

      this.formGroup.patchValue({
        currency: this.data.consideration.currency,
        tenure: this.data.consideration.tenure,
        consideration_type_id: this.data.consideration.considerationType
          ? this.data.consideration.considerationType.id 
          : null,
        sale_input_amount: this.data.consideration.saleInputAmount,
        rent_type: this.data.consideration.rentType,
        start_date: this.data.consideration.startDate,
        duration: this.data.consideration.duration,
        duration_type: this.data.consideration.durationType,
        rent_free_period: this.data.consideration.rentFreePeriod,
        fitting_out_period: this.data.consideration.fittingOutPeriod,
        write_off_period_after: this.data.consideration.writeOffPeriodAfter,
        break_option_after: this.data.consideration.breakOptionAfter,
        capital_payment: this.data.consideration.capitalPayment,
        unexpired_lease_terms: this.data.consideration.unexpiredLeaseTerms,
        rent_input_amount_type: this.data.consideration.rentInputAmountType,
        rent_size_id: this.data.consideration.rentSizeUId,
        rent_basis_id: this.data.consideration.rentBasis
          ? this.data.consideration.rentBasis.id
          : null,
        rent_review_type_id: this.data.consideration.rentReviewType
          ? this.data.consideration.rentReviewType.id
          : -1,
        rent_review_detail_type: this.data.consideration.rentReviewDetailType,
        rent_review_cycle: this.data.consideration.rentReviewCycle,
        expenses_input_amount_type: this.data.consideration.expensesInputAmountType,
        expenses_size_id: this.data.consideration.expensesSizeUid,
        recoverable_property_management: this.data.consideration.recoverablePropertyManagement,
        recoverable_leasing_expenses: this.data.consideration.recoverableLeasingExpenses,
        recoverable_utilities: this.data.consideration.recoverableUtilities,
        recoverable_maintenance: this.data.consideration.recoverableMaintenance,
        recoverable_insurance: this.data.consideration.recoverableInsurance,
        recoverable_janitorial: this.data.consideration.recoverableJanitorial,
        recoverable_business: this.data.consideration.recoverableBusiness,
        recoverable_property_taxes: this.data.consideration.recoverablePropertyTaxes,
        recoverable_others: this.data.consideration.recoverableOthers,
        nonrecoverable_property_management: this.data.consideration.nonRecoverablePropertyManagement,
        nonrecoverable_leasing_expenses: this.data.consideration.nonRecoverableLeasingExpenses,
        nonrecoverable_utilities: this.data.consideration.nonRecoverableUtilities,
        nonrecoverable_maintenance: this.data.consideration.nonRecoverableMaintenance,
        nonrecoverable_insurance: this.data.consideration.nonRecoverableInsurance,
        nonrecoverable_janitorial: this.data.consideration.nonRecoverableJanitorial,
        nonrecoverable_business: this.data.consideration.nonRecoverableBusiness,
        nonrecoverable_property_taxes: this.data.consideration.nonRecoverablePropertyTaxes,
        nonrecoverable_others: this.data.consideration.nonRecoverableOthers,
        notes: this.data.consideration.notes
      })

      if (this.data.consideration.rentInputAmountType === 'total') {
        this.formGroup.patchValue({rent_amount_total: this.data.consideration.rentInputAmount})
      } else if (this.data.consideration.rentInputAmountType === 'per_units') {
        this.formGroup.patchValue({rent_amount_per_unit: this.data.consideration.rentInputAmount})
      }

      if (this.data.consideration.rentReviewDetailType === 'number') {
        this.formGroup.patchValue({rent_review_detail_number: this.data.consideration.rentReviewDetail})
      } else if (this.data.consideration.rentReviewDetailType === 'percentage') {
        this.formGroup.patchValue({rent_review_detail_percentage: this.data.consideration.rentReviewDetail})
      }

      this.sourceSubject.next(this.data.consideration.source)
    }

    formControlValueChanges(this.formGroup.controls.tenure).pipe(
      takeUntil(this._onDestroy$)
    ).subscribe(tenure => {
      this.store.patchState({
        otherDates: this.data.otherDates.filter(od => od.tenure === tenure).map(od => od.date)
        }
      )
    })

    // Custom Effects
    formControlValueChanges(this.formGroup.controls.rent_review_type_id).pipe(
      takeUntil(this._onDestroy$)
    ).subscribe(value => {
      if (value === null) {
        this.formGroup.controls.rent_review_cycle.setValidators(null)
        this.formGroup.controls.rent_review_cycle.updateValueAndValidity()
      } else {
        this.formGroup.controls.rent_review_cycle.setValidators([notNegativeValidator, notZeroValidator])
        this.formGroup.controls.rent_review_cycle.updateValueAndValidity()
      }
    })
    formControlValueChanges(this.formGroup.controls.consideration_type_id).pipe(
      takeUntil(this._onDestroy$),
      filter(value => value !== null)
    ).subscribe(value => {
      if (value === 1) {
        // Sale
        this.formGroup.controls.sale_input_amount.setValidators([Validators.required])
        this.formGroup.controls.sale_input_amount.updateValueAndValidity()
        this.formGroup.controls.duration.setValidators(null)
        this.formGroup.controls.duration.updateValueAndValidity()
        this.formGroup.controls.rent_type.setValidators(null)
        this.formGroup.controls.rent_type.updateValueAndValidity()
        this.formGroup.controls.rent_free_period.setValidators(null)
        this.formGroup.controls.rent_free_period.updateValueAndValidity()
        this.formGroup.controls.fitting_out_period.setValidators(null)
        this.formGroup.controls.fitting_out_period.updateValueAndValidity()
        this.formGroup.controls.break_option_after.setValidators(null)
        this.formGroup.controls.break_option_after.updateValueAndValidity()
        this.formGroup.controls.write_off_period_after.setValidators(null)
        this.formGroup.controls.write_off_period_after.updateValueAndValidity()
        this.formGroup.controls.rent_input_amount_type.setValidators(null)
        this.formGroup.controls.rent_input_amount_type.updateValueAndValidity()
      } else {
        // Rent
        this.formGroup.controls.sale_input_amount.setValidators(null)
        this.formGroup.controls.sale_input_amount.updateValueAndValidity()
        this.formGroup.controls.duration.setValidators([Validators.required])
        this.formGroup.controls.duration.updateValueAndValidity()
        this.formGroup.controls.rent_type.setValidators([Validators.required])
        this.formGroup.controls.rent_type.updateValueAndValidity()
        this.formGroup.controls.rent_free_period.setValidators([notNegativeValidator])
        this.formGroup.controls.rent_free_period.updateValueAndValidity()
        this.formGroup.controls.fitting_out_period.setValidators([notNegativeValidator])
        this.formGroup.controls.fitting_out_period.updateValueAndValidity()
        this.formGroup.controls.break_option_after.setValidators([notNegativeValidator])
        this.formGroup.controls.break_option_after.updateValueAndValidity()
        this.formGroup.controls.write_off_period_after.setValidators([notNegativeValidator, notZeroValidator])
        this.formGroup.controls.write_off_period_after.updateValueAndValidity()
        this.formGroup.controls.rent_input_amount_type.setValidators([Validators.required])
        this.formGroup.controls.rent_input_amount_type.updateValueAndValidity()
      }
    })
    
    combineLatest([
      formControlValueChanges(this.formGroup.controls.start_date),
      formControlValueChanges(this.formGroup.controls.duration),
      formControlValueChanges(this.formGroup.controls.duration_type),
    ]).pipe(takeUntil(this._onDestroy$)).subscribe(([_startDate, duration, durationType]) => {
        if (_startDate) {
          const today = moment()
          // TODO: If it is moment
          if (moment.isMoment(_startDate) && _startDate.isBefore(today)) {
            const _date = _startDate.clone().add(durationType === 'months' ? duration : duration * 12, 'M')
            const yearDiff = Math.abs(today.diff(_date, 'years'))
            const monthDiff = this._monthDif(today, _date)
            const _value = yearDiff + (monthDiff / 12)
            const value = Math.round((_value + Number.EPSILON) * 100) / 100
            if (!isNaN(value)) {
              this.formGroup.controls.unexpired_lease_terms.patchValue(value)
            }
            return
          } 
        } 
        if (duration && durationType) {
          this.formGroup.controls.unexpired_lease_terms.patchValue(
            durationType === 'months' ? duration * 12 : duration
          )
        }
      }
    )

    combineLatest([
      formControlValueChanges(this.formGroup.controls.rent_input_amount_type),
      this.sizes$
    ]).pipe(
      takeUntil(this._onDestroy$)
    ).subscribe(([value, sizes]) => {
      if (value === 'per_units') {
        if (sizes.length > 1) {
          this.formGroup.controls.rent_size_id.setValidators([Validators.required])
          this.formGroup.controls.rent_size_id.updateValueAndValidity()
        } else {
          this.formGroup.controls.rent_size_id.setValidators(null)
          this.formGroup.controls.rent_size_id.updateValueAndValidity()
        }
        this.formGroup.controls.rent_amount_per_unit.setValidators([Validators.required])
        this.formGroup.controls.rent_amount_per_unit.updateValueAndValidity()
        this.formGroup.controls.rent_amount_total.setValidators(null)
        this.formGroup.controls.rent_amount_total.updateValueAndValidity()
      } else if (value === 'total') {
        this.formGroup.controls.rent_size_id.setValidators(null)
        this.formGroup.controls.rent_size_id.updateValueAndValidity()
        this.formGroup.controls.rent_amount_per_unit.setValidators(null)
        this.formGroup.controls.rent_amount_per_unit.updateValueAndValidity()
        this.formGroup.controls.rent_amount_total.setValidators([Validators.required])
        this.formGroup.controls.rent_amount_total.updateValueAndValidity()
      }
    })
  }

  private _monthDif(startDate: moment.Moment, endDate: moment.Moment) {
    // Calculate the difference in years and months
    let yearsDiff = endDate.year() - startDate.year();
    let monthsDiff = endDate.month() - startDate.month();

    // Calculate the number of months, ignoring the years
    let totalMonths = yearsDiff * 12 + monthsDiff;

    // Ensure the month difference accounts for partial month
    if (endDate.date() < startDate.date()) {
        totalMonths--;
    }

    let finalMonthsDiff = totalMonths % 12;

    return finalMonthsDiff
  }

  ngOnDestroy(): void {
    this._onDestroy$.next()
    this._onDestroy$.complete()
  }

  onClearStartDate(datePicker: DatepickerTzInputComponent) {
    datePicker.clearDate()
    this.formGroup.controls.start_date.patchValue(null)
  }

  onSubmit() {
    this.submit()
  }
  private readonly submit = this.store.effect<void>(trigger$ =>
    trigger$.pipe(
      withLatestFrom(this.store.state$),
      tap(_ => {
        this.hasFormError$.next(false)
        this.parcelError$.next(false)
      }),
      tap(([_, state]: [void, State]) => {
        let hasFormErrors = false
        if (this.formGroup.invalid) {
          Object.keys(this.formGroup.controls).forEach(controlName => {
            if (this.formGroup.controls[controlName].invalid) {
              console.log(controlName)
              this.formGroup.controls[controlName].markAsTouched()
            }
          })
          hasFormErrors = true
        }

        if (this.parcelConsiderations && !this.parcelConsiderations.validate()) {
          hasFormErrors = true;
          this.parcelError$.next(true);
        }

        if (this.sourceComponent) {
          if (!this.sourceComponent.valid()) {
            hasFormErrors = true
          }
          this.sourceComponent.updateSource(true)
        }

        if (hasFormErrors) {
          this.hasFormError$.next(hasFormErrors)
          return
        }

        const consideration = this._prepareConsideration(state)
        this.dialogRef.close({consideration})
      })
    )
  )

  onClose() {
    this.dialogRef.close()
  }

  private _prepareConsideration(state: State) {
    let startDate = this.formGroup.controls.start_date.value
    if (moment.isMoment(startDate)) {
      startDate = startDate.toDate()
    }
    const considerationType = state.considerationTypes.find(item => item.id === this.formGroup.controls.consideration_type_id.value)
    const rentBasis = state.rentBases.find(item => item.id === this.formGroup.controls.rent_basis_id.value)
    const rentReviewType = state.rentReviewTypes.find(item => item.id === this.formGroup.controls.rent_review_type_id.value)
    const consideration: Consideration = {
      id: state.id,
      assetClassId: undefined,
      formType: this.formSelectionCtrl.value === 'advanced' ? 1 : 2,
      currency: this.formGroup.controls.currency.value,
      tenure: this.formGroup.controls.tenure.value,
      considerationType,
      saleInputAmount: this.formGroup.controls.sale_input_amount.value,
      rentType: this.formGroup.controls.rent_type.value,
      startDate: startDate,
      duration: this.formGroup.controls.duration.value,
      durationType: this.formGroup.controls.duration_type.value,
      rentFreePeriod: this.formGroup.controls.rent_free_period.value,
      fittingOutPeriod: this.formGroup.controls.fitting_out_period.value,
      writeOffPeriodAfter: this.formGroup.controls.write_off_period_after.value,
      breakOptionAfter: this.formGroup.controls.break_option_after.value,
      capitalPayment: this.formGroup.controls.capital_payment.value,
      unexpiredLeaseTerms: this.formGroup.controls.unexpired_lease_terms.value,
      rentInputAmountType: this.formGroup.controls.rent_input_amount_type.value,
      rentInputAmount: this._rentInputAmountValue(),
      rentSizeUId: this._sizeUid(this.formGroup.controls.rent_size_id, state.sizes),
      rentSize: this._size(this.formGroup.controls.rent_size_id, state.sizes),
      rentBasis,
      rentReviewType,
      rentReviewCycle: this.formGroup.controls.rent_review_cycle.value,
      rentReviewDetailType: this.formGroup.controls.rent_review_detail_type.value,
      rentReviewDetail: this._rentReviewDetail(),
      expensesInputAmountType: this.formGroup.controls.expenses_input_amount_type.value,
      expensesSizeUid: this._sizeUid(this.formGroup.controls.expenses_size_id, state.sizes),
      recoverablePropertyManagement: this.formGroup.controls.recoverable_property_management.value,
      recoverableLeasingExpenses: this.formGroup.controls.recoverable_leasing_expenses.value,
      recoverableUtilities: this.formGroup.controls.recoverable_utilities.value,
      recoverableMaintenance: this.formGroup.controls.recoverable_maintenance.value,
      recoverableInsurance: this.formGroup.controls.recoverable_insurance.value,
      recoverableJanitorial: this.formGroup.controls.recoverable_janitorial.value,
      recoverablePropertyTaxes: this.formGroup.controls.recoverable_property_taxes.value,
      recoverableBusiness: this.formGroup.controls.recoverable_business.value,
      recoverableOthers: this.formGroup.controls.recoverable_others.value,

      nonRecoverablePropertyManagement: this.formGroup.controls.nonrecoverable_property_management.value,
      nonRecoverableLeasingExpenses: this.formGroup.controls.nonrecoverable_leasing_expenses.value,
      nonRecoverableUtilities: this.formGroup.controls.nonrecoverable_utilities.value,
      nonRecoverableMaintenance: this.formGroup.controls.nonrecoverable_maintenance.value,
      nonRecoverableInsurance: this.formGroup.controls.nonrecoverable_insurance.value,
      nonRecoverableJanitorial: this.formGroup.controls.nonrecoverable_janitorial.value,
      nonRecoverablePropertyTaxes: this.formGroup.controls.nonrecoverable_property_taxes.value,
      nonRecoverableBusiness: this.formGroup.controls.nonrecoverable_business.value,
      nonRecoverableOthers: this.formGroup.controls.nonrecoverable_others.value,

      notes: this.formGroup.controls.notes.value,

      source: this.sourceSubject.value,
      additional: this.parcelConsiderations ? this.parcelConsiderations.onSave() : []
    }
    return consideration
  }

  private _rentInputAmountValue(): number {
    const rentInputAmountType = this.formGroup.controls.rent_input_amount_type.value
    if (rentInputAmountType === 'total') {
      return this.formGroup.controls.rent_amount_total.value
    }
    if (rentInputAmountType === 'per_units') {
      return this.formGroup.controls.rent_amount_per_unit.value
    }
    return null
  }

  private _sizeUid(controls: AbstractControl<string>, sizes: AssetClassSize[]) {
    if (sizes.length > 1) {
      return controls.value === undefined ? null : controls.value
    }
    if (sizes.length === 1) {
      return sizes[0].uid
    }
    return null
  }

  private _size(controls: AbstractControl<string>, sizes: AssetClassSize[]) {
    const sizeUid = this._sizeUid(controls, sizes)
    const size = sizes.find(size => size.uid === sizeUid)
    return size ? size : null
  }

  private _rentReviewDetail(): number {
    const rentReviewDetailType = this.formGroup.controls.rent_review_detail_type.value
    if (rentReviewDetailType === 'number') {
      return this.formGroup.controls.rent_review_detail_number.value
    }
    if (rentReviewDetailType === 'percentage') {
      return this.formGroup.controls.rent_review_detail_percentage.value
    }
    return null
  }

  private _expensesPerUnit(value$: Observable<number | null>) {
    return combineLatest([
      value$,
      formControlValueChanges(this.formGroup.controls.expenses_input_amount_type),
      formControlValueChanges(this.formGroup.controls.expenses_size_id).pipe(
        switchMap(value => this.sizes$.pipe(
          map(sizes => {
            if (sizes.length === 1) {
              return sizes[0].size
            }
            const size = sizes.find(size => size.uid === value)
            return size ? size.size : undefined
          })
        ))
      )
    ]).pipe(
      map(([value, type, size]) => {
        if (type === 'total') return null
        if (size === undefined) return null
        if (value === null) return null
        return {value: value * size}
      })
    )
  }
}

function notNegativeValidator(control: AbstractControl) {
  if (control.value !== null && control.value !== '' && control.value < 0) {
    return {'notNegative': true}
  }
  return null
}

function notZeroValidator(control: AbstractControl) {
  if (control.value !== null && control.value !== '' && Number(control.value) === 0) {
    return {isZero: true}
  }
  return null
}

function checkIfLesserThanLease(field: string) {
  return function (fg: FormGroup) {
    const fieldValue = fg.get(field).value;
    const leaseValue = fg.get('duration').value;

    if (
      fieldValue !== null &&
      (Number(fieldValue) > Number(leaseValue)) 
    ) {
      fg.get(field).setErrors({notLesserThanLease: true})
    } else {
      removeError(fg.get(field), 'notLesserThanLease')
    }

    return null
  }
}

function checkIfLesserThanOrEqualToLease(field: string) {
  return function (fg: FormGroup) {
    const fieldValue = fg.get(field).value;
    const leaseValue = fg.get('duration').value;

    if (
      fieldValue !== null &&
      (Number(fieldValue) >= Number(leaseValue)) 
    ) {
      fg.get(field).setErrors({notLesserThanLeaseOrEqualToLease: true})
    } else {
      removeError(fg.get(field), 'notLesserThanLeaseOrEqualToLease')
    }

    return null
  }
}

function checkIfLesserThanWriteOff(field: string) {
  return function (fg: FormGroup) {
    const fieldValue = fg.get(field).value;
    const writeOffPeriodValue = fg.get('write_off_period_after').value;

    if (
      fieldValue !== null &&
      writeOffPeriodValue !== null &&
      writeOffPeriodValue !== '' &&
      (Number(fieldValue) >= Number(writeOffPeriodValue)) 
    ) {
      fg.get(field).setErrors({notLesserThanWriteOff: true})
    } else {
      removeError(fg.get(field), 'notLesserThanWriteOff')
    }

    return null
  }
}

function removeError(control: AbstractControl, error: string) {
  const err = control.errors
  if (err) {
    delete err[error]
    if (!Object.keys(err).length) {
      control.setErrors(null)
    } else {
      control.setErrors(err)
    }
  }
}