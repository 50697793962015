<div [formGroup]="formGroup" class="form-group kt-form__group row">
    <div class="col-lg-12 kt-margin-bottom-20-mobile" *ngIf="title.length > 0">
        <h5 class="my-3 text-mad text-uppercase">
           {{title}}
        </h5>
        <hr class="active">
    </div>

    <div class="col-lg-12 kt-margin-bottom-10-mobile md-2" *ngIf="showMap">
        <mat-form-field class="mat-form-field-fluid">
            <input matInput type="text" (keydown.enter)="$event.preventDefault()"
                   [placeholder]="'GENERAL.PLACEHOLDER.SEARCH_BY_CITY' | translate" autocorrect="off" autocapitalize="off"
                   spellcheck="off" #search/>
            <mat-icon
                    matSuffix
                    class="cursor-pointer"
                    (click)="$event.stopPropagation();"
                    container="body"
                    [ngbPopover]="'TOOLTIP.HOUSE.LOCATE.DESCRIPTION' | translate"
                    [popoverTitle]="'TOOLTIP.HOUSE.LOCATE.TITLE' | translate">help
            </mat-icon>
        </mat-form-field>
    </div>

    <div class="col-lg-12 kt-margin-bottom-20-mobile mb-4" *ngIf="showMap">
        <agm-map [latitude]="centerLatitude$|async"
                 [longitude]="centerLongitude$|async"
                 [zoom]="zoom"
                 (mapReady)="onMapReady($event)"
                 (centerChange)="centerChange($event)"
                 [scrollwheel]="null"
                 [usePanning]="true"
                 [clickableIcons]="false">
            <agm-map-type-control></agm-map-type-control>
            <button type="button"
                    id="Profile"
                    class="btn btn-light btn-icon mr-2 map-button"
                    (click)="setLocation()">
                <mat-icon>my_location</mat-icon>
            </button>
            <agm-map-type-control></agm-map-type-control>
            <agm-marker
                    *ngIf="isShown"
                    [latitude]="markerLat"
                    [longitude]="markerLng"
                    [markerDraggable]="!lockMarker"
                    (dragEnd)="markerDragEnd($event)">
            </agm-marker>
        </agm-map>
        <mat-error class="tag-custom-error tag-custom-margin-top-10"
                   *ngIf="(formGroup.controls['latitude'].hasError('required') || formGroup.controls['longitude'].hasError('required')) && (formGroup.get('latitude').dirty || formGroup.get('latitude').touched)"
        >
            Location <strong>required</strong> (right-click to define location)
        </mat-error>
    </div>
    <div class="col-lg-4 kt-margin-bottom-20-mobile">
        <mat-form-field class="mat-form-field-fluid">
            <mat-select [placeholder]="'Country'" formControlName="country_id" (selectionChange)="selectCountry()">
                <mat-option *ngFor="let country of allCountries" [value]="country.id">{{country.name}}</mat-option>
            </mat-select>
            <mat-error>
                Country <strong>required</strong>
            </mat-error>
        </mat-form-field>
    </div>
    <div class="col-lg-4 kt-margin-bottom-20-mobile">
        <mat-form-field class="mat-form-field-fluid">
            <mat-select [placeholder]="'City'" formControlName="city_id">
                <mat-option [value]="0">Not Applicable</mat-option>
                <mat-option *ngFor="let city of cityOfCountry" [value]="city.id">{{city.name}}</mat-option>
            </mat-select>
            <mat-error>
                City <strong>required</strong>
            </mat-error>
        </mat-form-field>
    </div>
    <div class="col-lg-4 kt-margin-bottom-10-mobile">
        <mat-form-field class="mat-form-field-fluid">
            <input matInput [placeholder]="'Zipcode/Postalcode'" formControlName="zip_code"/>
        </mat-form-field>
    </div>
    <div class="col-lg-4 kt-margin-bottom-10-mobile">
        <mat-form-field class="mat-form-field-fluid">
            <mat-select [placeholder]="'Location Grade'" formControlName="location_grade_id">
                <mat-option *ngFor="let grade of grades" [value]="grade.id">{{grade.name}}</mat-option>
            </mat-select>
            <mat-error>
                Location Grade <strong>required</strong>
            </mat-error>
        </mat-form-field>
    </div>
    <div class="col-lg-12 kt-margin-b-10-mobile">
        <mat-form-field class="mat-form-field-fluid">
            <textarea matInput rows="4" [placeholder]="'Address'" formControlName="address"></textarea>
            <mat-error>
                Address <strong>required</strong>
            </mat-error>
        </mat-form-field>
    </div>
    <div class="col-lg-12 kt-margin-b-10-mobile" *ngIf="isComplexForm">
        <mat-form-field class="mat-form-field-fluid">
            <textarea matInput rows="4" [placeholder]="'Location and Surroundings Description'" formControlName="location_surrounding"></textarea>
            <mat-error>
                Description <strong>required</strong>
            </mat-error>
        </mat-form-field>
    </div>
</div>