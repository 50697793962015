import { Injectable } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { Actions, Effect, ofType } from "@ngrx/effects";
import { Action, Store, createFeatureSelector, createSelector, select } from "@ngrx/store";
import { combineLatest, defer, from, Observable, of } from "rxjs";
import { map, switchMap, take, tap } from "rxjs/operators";
import { LoginComponent } from "src/app/views/pages/mad-auth/login/login.component";
import { MadAuthService } from "./mad-auth.service";
import { MadPermission } from "./mad-permission";
import { MadRole } from "./mad-roles.store";
import { UserActionTypes, UserUpdated } from "../auth/_actions/user.actions";
import { Update } from "@ngrx/entity";

/** Models */
export interface IProvider {
  uid: string;
  email: string;
  plan_uid: string;
  subscription_uid: string;
}
export interface User {
  id: number;
  email: string;
  provider: IProvider,
  mad_role_id: number,
  mad_role: MadRole | null

  first_name: string;
  last_name: string;
  full_name: string;
  picture: string;

  path: string;
  reports: any[]

  role_id: number;
  is_active: number;
  role_name: string;
  qualification_id: number;
  qualification_name: string;
  phone: string;
  description: string;
  valuer_experience: string;
  insurance_policy: string;
  standard_limitations: string;

  using_place_cnt: number;
  assignment_manager: number;
  lead_valuer: number;
  certificates: any[];
  files: any[];
  settings: {
      unit_of_measurement_id: number;
      time_zone: string;
      time_zone_gmt: string;
      use_system_location: boolean;
      latitude: number;
      longitude: number;
  }

  is_account_user: boolean;

  createdBy: string;
  lastUpdatedBy: string;

  created_at: Date;
  updated_at: Date;
  deleted_at: Date;
}

export function newUser(): User {
  return {
        id : undefined,
        email : '',
        mad_role_id: undefined,
        role_id : undefined,
        is_active : 1,
        role_name : '',
        qualification_id : undefined,
        qualification_name : '',
        using_place_cnt : 0,
        assignment_manager : 0,
        lead_valuer : 0,
        path : '',
        first_name : '',
        last_name : '',
        full_name : '',

        valuer_experience : '',
        insurance_policy : '',

        reports : [],
        certificates : [],
        settings : {
            unit_of_measurement_id: undefined,
            time_zone: null,
            time_zone_gmt: null,
            use_system_location: false,
            latitude: null,
            longitude: null
        },
        // this.accessToken = 'access-token-' + Math.random();
        // this.refreshToken = 'access-token-' + Math.random();
        picture : '',
        phone :'',
        provider: {
          uid: '',
          email: '',
          plan_uid: '',
          subscription_uid: ''
        },
        is_account_user: false,
        mad_role: null,
        standard_limitations: '',
        created_at: new Date(),
        createdBy: undefined,
        deleted_at: new Date(),
        description: '',
        files: [],
        lastUpdatedBy: '',
        updated_at: new Date()
  }
}

/** Actions */
export enum MadAuthActionTypes {
  Login = '[Login Page] Login User',
  LoggedIn = '[Mad Auth Effects] User logged in',

  LogOut = '[Dashboard Page] Logout User',

  UpdateLoggedUser = '[User Edit Page] Update Logged user',

  InitLogin = '[Mad Auth Effects] Init Login'
}

export class MadLogin implements Action {
  readonly type = MadAuthActionTypes.Login

  constructor(public payload: {
    token: string
  }) {}
}

export class MadLoggedIn implements Action {
  readonly type = MadAuthActionTypes.LoggedIn
  constructor(public payload: {
    user: User
  }) {}
}

export class MadLogOut implements Action {
  readonly type = MadAuthActionTypes.LogOut
  constructor() {}
}

export class MadInitLogin implements Action {
  readonly type = MadAuthActionTypes.InitLogin
  constructor(public payload: {token: string}) {}
}

export class MadLoggedUserUpdate implements Action {
  readonly type = MadAuthActionTypes.UpdateLoggedUser
  constructor(public payload: {user: User}) {}
}

export type MadAuthActions 
  = MadLogin
  | MadLoggedIn
  | MadLogOut
  | MadInitLogin
  | MadLoggedUserUpdate

/** Reducer */
export interface MadAuthState {
  user: User | null
  loggedIn: boolean
}
export function madAuthReducer(
  state: MadAuthState =  {
    user: null,
    loggedIn: false
  },
  actions: MadAuthActions
): MadAuthState {
  switch (actions.type) {
    case MadAuthActionTypes.InitLogin: {
      return {
        ...state,
        loggedIn: true 
      }
    }
    case (MadAuthActionTypes.LoggedIn): {
      return {
        ...state,
        user: actions.payload.user,
        loggedIn: true
      }
    }
    case (MadAuthActionTypes.LogOut): {
      return {
        ...state,
        user: null,
        loggedIn: false
      }
    }
    case (MadAuthActionTypes.UpdateLoggedUser): {
      if (state.user.id == actions.payload.user.id) {
        return {
          ...state,
          user: {
            ...state.user,
            first_name: actions.payload.user.first_name,
            last_name: actions.payload.user.last_name,
            picture: actions.payload.user.picture,
            settings: {
              ...state.user.settings,
              use_system_location: actions.payload.user.settings.use_system_location,
              latitude: actions.payload.user.settings.latitude,
              longitude: actions.payload.user.settings.longitude
            }
          }
        }
      }
      return state
    }
    default: {
      return state
    }
  }
}

/** Effects */
@Injectable()
export class MadAuthEffects {
  private returnUrl: string;

  constructor(
    private _actions$: Actions,
    private _authService: MadAuthService,
    private _router: Router,
    private _store$: Store
  ) {
    this._router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.returnUrl = event.url
      }
    })
  }

  @Effect()
  login$ 
    = this._actions$.pipe(
      ofType<MadLogin>(MadAuthActionTypes.Login),
      switchMap(({payload}) => {
        return from(this._authService.login(payload.token)).pipe(
          tap(_ => {
            this._router.navigateByUrl('/dashboard')
          })
        )
      }),
      map(user => {
        return new MadLoggedIn({user})
      })
    )

  @Effect()
  initLogin$ 
    = this._actions$.pipe(
      ofType<MadInitLogin>(MadAuthActionTypes.InitLogin),
      switchMap(({payload}) => {
        return from(this._authService.login(payload.token)).pipe(
          tap(_ => {
            this._router.navigateByUrl(this.returnUrl)
          })
        )
      }),
      map(user => {
        return new MadLoggedIn({user})
      })
    )

  @Effect({dispatch: false})
  logout$
    = this._actions$.pipe(
      ofType<MadLogOut>(MadAuthActionTypes.LogOut),
      map(() => {
        this._authService.logout()
        this._router.navigateByUrl('/mad-auth/login')
        document.location.reload();
      })
    )

  @Effect()
  updateUser$
    = this._actions$.pipe(
      ofType<UserUpdated>(UserActionTypes.UserUpdated),
      map(({payload}) => {
        return new MadLoggedUserUpdate({user: payload.user})
      })
    )

  @Effect()
  init$: Observable<Action> = defer(() => {
    const token = this._authService.getToken()
    let observableResult = of({type: 'NO_ACTION'})
    if (token) {
      observableResult = of(new MadInitLogin({token}))
    }
    return observableResult
  })
}

/** Selectors */
export const selectAuthState = createFeatureSelector<MadAuthState>('auth')
export const isLoggedIn = createSelector(selectAuthState, state => state.loggedIn)
export const currentUser = createSelector(selectAuthState, state => state.user)
export const hasPermission = (permissions: MadPermission[]) => createSelector(selectAuthState, 
  state => {
    const user = state.user
    if (!user) {
      return false;
    }

    if (!user.mad_role) {
      return false;
    }

    if (user.mad_role.permissions.find(p => permissions.includes(p))) {
      return true;
    }

    return false;
  }
)

/** Utils */
export const permissionAllowed = (permissions: MadPermission[], user: User): boolean => {
    if (!user) {
      return false;
    }

    if (!user.mad_role) {
      return false;
    }

    if (user.mad_role.permissions.find(p => permissions.includes(p))) {
      return true;
    }

    return false;
}