import {BaseModel} from '../../_base/crud';
import { RegNumberModel } from './reg-number.model';
import { ReportScheme } from './report-scheme.model';
import { PostValuationDeliveryServicetData, newPostValuationDeliveryServicetData } from './post-valuation-delivery-service.model';

export class AgencyModel extends BaseModel {
    id: number;

    name: string;
    legal_name: string;
    address: string;

    country_id: number;
    country_name: string;

    city_id: number;
    city_name: string;

    insurance: string;
    website: string;
    email: string;
    phone: string;
    zip_code: string;
    registration_number: string;
    description: string;
    source_of_data: string;
    complain_of_procedure: string;

    bank_accounts: string;
    tax_payer_number: number;

    user_id: number;
    user_name: string;

    ivs_standard_id: number;
    rics_standard_id: number;
    ipms_standard_id: number;
    unit_of_measurement_id: number;


    picture: string;
    cover_picture: string;


    certificates: any[];
    payment_info: string;
    disclaimer: string;
    reg_numbers: RegNumberModel[];
    path: string;
    auto_insert: boolean;

    floor_numbering_scheme_id: number;

    rics_regulated: boolean;
    vpo_regulated: boolean;
    vpos: VPOModel[];
    additionalTerms: AdditionalTermModel[];
    reportSchemes: ReportScheme[];

    ass_cnt: number;

    deletedBy: string;
    userDeletedBy: string;

    createdBy: string;
    lastUpdatedBy: string;

    //hourly-rates
    postValuationDeliveryServicetData: PostValuationDeliveryServicetData;


    user_deleted: Date;
    deleted_at: Date;
    created_at: Date;
    updated_at: Date;

    clear(): void {
        this.id = undefined;
        this.name = '';
        this.legal_name = '';
        this.address = '';
        this.country_id = undefined;
        this.country_name = '';

        this.city_id = undefined;
        this.city_name = '';

        this.insurance = '';
        this.website = '';
        this.email = '';
        this.zip_code = '';
        this.registration_number = '';
        this.description = '';
        this.source_of_data = '';
        this.complain_of_procedure = '';
        this.bank_accounts = '';
        this.tax_payer_number = undefined;

        this.user_id = undefined;

        this.ivs_standard_id = 0;
        this.rics_standard_id = 0;
        this.ipms_standard_id = 0;
        this.unit_of_measurement_id = 0;

        this.picture = null;
        this.cover_picture = null;
        this.path = '';
        this.auto_insert = false;
        this.certificates = [];
        this.payment_info = '';
        this.disclaimer = '';
        this.reg_numbers = [];
        this.floor_numbering_scheme_id = null;

        this.rics_regulated = false;
        this.vpo_regulated = false;
        this.vpos = [];
        this.additionalTerms = [];
        this.reportSchemes = [];

        this.ass_cnt = 0;

        //hourly-rates
        this.postValuationDeliveryServicetData = newPostValuationDeliveryServicetData();

        this.deletedBy = '';
        this.userDeletedBy = '';

        this.user_deleted = null;
        this.deleted_at = null;
        this.created_at = new Date();
        this.updated_at = new Date();
    }
}

export class VPOModel {
    id: number;
    name: string;
    membership_id: string;
    is_prime_ref: boolean;

    constructor() {
        this.id = undefined;
        this.name = null;
        this.membership_id = null;
        this.is_prime_ref = false;
    }
}

export class AdditionalTermModel {
    id: number;
    title: string;
    content: string;
    location_id: number;

    constructor() {
        this.id = undefined;
        this.title = null;
        this.content = null;
        this.location_id = null;
    }
}