<!-- <kt-portlet (window:beforeunload)="saveState()"> -->
<kt-portlet >
    <!-- See prop => '../../_core/models/data-sources/_base.datasource.ts' (loading$) -->
    <kt-portlet-header [class]="'kt-portlet-head--lg'" [viewLoading$]="dataSource.loading$"
                       [tooltipTitle]="'TOOLTIP.TOE.LIST.TITLE' | translate"
                       [tooltipDesc]="'TOOLTIP.TOE.LIST.DESCRIPTION' | translate">
        <!-- PORTLET LOADING | Binded to TABLE Datasource -->

        <ng-container ktPortletTitle>
            <h3 class="kt-portlet-head-title">
                <span>
                    {{ 'TOE.LIST.TITLE' | translate:{assignment_name: (assignment ? assignment.name : ''),
                    pm_name : (assignment && assignment.manager ? assignment.manager.full_name : '')} }}
                </span>
            </h3>
            <!-- For localisations we use @ngx-translate | See off. documentations => https://github.com/ngx-translate/core -->
            <!-- Localization libraries (en/fr) are here => '../../../.././config/i18n/en.ts|fr.ts' -->
        </ng-container>

        <ng-container ktPortletTools>

            <a [routerLink]="['../../']"
               class="btn mr-2"
               mat-raised-button
               matTooltip="Back to the Assignment List">
                <mat-icon>arrow_back</mat-icon>
                <span class="kt-hidden-mobile">
                    {{ 'GENERAL.BUTTON.BACK' | translate }}
                </span>
            </a>

            <!--<kt-context-menu2>-->

            <!--</kt-context-menu2>-->
            <kt-context-menu-mad
                    [(menuItems)]="menuItems"
                    [(menuSubject)]="menuSubject"
                    [width]="'230'">
            </kt-context-menu-mad>

            <button *ngIf="(trashCnt$ |async) != 0" (click)="trash()" mat-raised-button [matTooltip]="'TOE.LIST.TOOLTIP.DELETED' | translate" type="button" class="button-gray ml-2">
                <span>
                    {{ 'GENERAL.BUTTON.TRASHED' | translate }} ({{trashCnt$ |async }})
                </span>
            </button>
            <button *ngIf="(adminTrashCnt$ |async) != 0 && (canAccessAdminTrash$|async)" (click)="adminTrash()" mat-raised-button [matTooltip]="'TOE.LIST.TOOLTIP.DELETED' | translate" type="button"
                    class="button-gray ml-2">
                <span>
                    {{ 'GENERAL.BUTTON.ADMIN_TRASHED' | translate }} ({{adminTrashCnt$ |async }})
                </span>
            </button>
        </ng-container>
    </kt-portlet-header>
    <!-- end::Header -->

    <kt-portlet-body>
        <div class="kt-margin-bottom-20-mobile p-1 section-margin-bottom-60" *ngIf="toeStatusCnts$ | async as vm;">
            <div class="row kt-margin-bottom-20-mobile" style="text-align: right; font-weight: 600; font-size: large">

                <div class="col rounded-sm p-4 m-1 d-flex align-items-center" style="color: white; background-color: #999999 !important;">
                    <div class="float-left h-100 d-flex justify-content-center align-items-center my-3">
                        <span style="vertical-align: middle; color: #E8E8E8;">
                            {{ vm.total_toes }}
                        </span>
                    </div>
                    <div class="float-right align-content-end w-100 text-uppercase text-white">
                        <p class="m-0">
                            <span style="color: #cccccc;">TERMS OF ENGAGEMENT</span>
                        </p>
                    </div>
                </div>
                <div class="col rounded-sm p-4 m-1 d-flex align-items-center" style="color: white; background-color: #f44d5e !important;">
                    <span style="vertical-align: middle; color: #E8E8E8;">
                        {{ vm.total_properties }}
                    </span>
                    <div class="float-right align-content-end w-100 text-uppercase text-white">
                        <p class="m-0"><span style="color: #E8E8E8;">PROPERTIES</span></p>
                    </div>
                </div>
                <div class="col rounded-sm p-4 m-1 d-flex align-items-center" style="color: white; background-color: #fda701 !important;">
                    <span style="vertical-align: middle; color: #E8E8E8;">
                        {{ vm.total_valuations }}
                    </span>
                    <div class="float-right align-content-end w-100 text-uppercase text-white">
                        <p class="m-0"><span style="color: #E8E8E8;">VALUATIONS</span></p>
                    </div>
                </div>
            </div>
        </div>

        <kt-filter 
            [config]="{
                search: {
                    main: true,
                    location: false,
                    reset: false
                }
            }"
            (filterChange)="onFilterChange($event)">
            <kt-f-selection
                class="mr-2 mt-2"
                [key]="'lv_id'"
                [bg]="'#187016'"
                [placeholder]="'Lead Valuer'"
                [selections]="leadValuers"></kt-f-selection>
            <kt-f-selection
                class="mr-2 mt-2"
                [key]="'status'"
                [bg]="'#187016'"
                [placeholder]="'Status'"
                [selections]="status"></kt-f-selection>
        </kt-filter>
        <!-- end::FILTERS & GROUP ACTIONS -->

        <!-- MATERIAL TABLE | Binded to datasources -->
        <!-- See off.documentations 'https://material.angular.io/components/table/overview' -->
        <div class="mat-table-wrapper">
            <mat-table class="lmat-elevation-z8 table-striped"
                       #table
                       [dataSource]="dataSource"
                       matSort
                       #sort1="matSort"
                       matSortActive="id"
                       matSortDirection="asc"
                       matSortDisableClear>
                <!-- Checkbox Column -->

                <ng-container matColumnDef="id">
                    <!-- ATTRIBUTE mat-sort-header  for sorting | https://material.angular.io/components/sort/overview -->
                    <mat-header-cell *matHeaderCellDef mat-sort-header>#</mat-header-cell>
                    <mat-cell
                            *matCellDef="let toe">{{toe.id}}
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="name">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>
                        {{ 'TOE.COLUMN.ID' | translate }}
                    </mat-header-cell>
                    <mat-cell *matCellDef="let toe">{{toe.name}}</mat-cell>
                </ng-container>

                <ng-container matColumnDef="lead_valuer_name">
                    <mat-header-cell *matHeaderCellDef mat-sort-header class="lead-valuer">
                        {{ 'TOE.COLUMN.LEAD_VALUER' | translate }}
                    </mat-header-cell>
                    <mat-cell *matCellDef="let toe" class="lead-valuer">
                        <ng-container *ngIf="toe.leadValuer">
                            <kt-user-tooltip [user]="convertLeadValuer(toe)"></kt-user-tooltip>
                        </ng-container>
                        <ng-container *ngIf="!toe.leadValuer">
                            'N/A'
                        </ng-container>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="instruction_date">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>
                        {{ 'TOE.COLUMN.INSTRUCTION_DATE' | translate }}
                    </mat-header-cell>
                    <mat-cell *matCellDef="let toe">
                        {{ toe.instruction_date | dateWithOffset:toe.timezone }}
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="final_delivery_date">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>
                        {{ 'TOE.COLUMN.FINAL_DELIVERY_DATE' | translate }}
                    </mat-header-cell>
                    <mat-cell *matCellDef="let toe">{{toe.final_delivery_date| dateWithOffset:toe.timezone}}</mat-cell>
                </ng-container>

                <ng-container matColumnDef="tp_cnt">
                    <mat-header-cell *matHeaderCellDef>
                        {{ 'TOE.COLUMN.TARGET_PROPERTIES' | translate }}
                    </mat-header-cell>
                    <mat-cell *matCellDef="let toe">{{toe.tp_cnt}}</mat-cell>
                </ng-container>

                <ng-container matColumnDef="val_cnt">
                    <mat-header-cell *matHeaderCellDef>
                        {{ 'TOE.COLUMN.VALS' | translate }}
                    </mat-header-cell>
                    <mat-cell *matCellDef="let toe">{{toe.val_cnt}}</mat-cell>
                </ng-container>

                <ng-container matColumnDef="status">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>
                        {{ 'TOE.COLUMN.STATUS' | translate }}
                    </mat-header-cell>
                    <mat-cell *matCellDef="let toe">
                        <!-- <b><span
                                class="kt-badge kt-badge--inline kt-badge--pill kt-badge--outline kt-badge--outline-2x kt-badge--{{ getItemCssClassByStatus(toe.status)}} kt-badge--wide py-1"
                                style="width: 10rem;">{{  getItemStatusString(toe) }}
                        </span></b> -->
                        <b>
                            <span
                                    class="label label-inline label-rounded label-{{ getItemCssClassByStatus(toe.status) }} label-lg" style="width: 150px;">
                                {{ getItemStatusString(toe) }}
                            </span>
                            <!-- <span
                                    class="label label-inline label-rounded label-success label-lg" style="width: 150px;">
                                {{ getItemStatusString(toe) }}
                            </span> -->
                        </b>
                        <ng-container *ngIf="toe.status >= 3 && toe.payment_percent">
                                <span matTooltip="Settlement not fully completed" class="label label-inline label-rounded label-danger label-lg" style="width: 80px; margin-left: 10px">
                                    <mat-icon inline>attach_money</mat-icon>
                                    {{ toe.payment_percent.percent + '%' }}
                                </span>
                        </ng-container>
                        <!-- <ng-template #noPaymentBalancePercentage>
                            <span style="width: 80px; margin-left: 10px"></span>
                        </ng-template> -->
                        <!-- <mat-checkbox class="ml-2" *ngIf="toe.status >= 3" [disabled]="assignment && assignment.status == 2" [checked]="toe.status == 4" (change)="changeStatus($event, toe)"></mat-checkbox> -->
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="actions">
                    <mat-header-cell *matHeaderCellDef>
                        {{ 'TOE.COLUMN.ACTIONS' | translate }}
                    </mat-header-cell>
                    <mat-cell *matCellDef="let toe">
                        <ng-container *ngIf="toe.status != 0">
                            <button mat-icon-button
                                    [disabled]="toe.status == 0"
                                    [matTooltip]="'TOE.LIST.BUTTON.DUPLICATE.TOOLTIP' | translate"
                                    (click)="duplicateToe(toe)"
                            >
                                <i [style.color]="toe.status !== 0 
                                    ? 'green' 
                                    : 'grey'" 
                                    class="flaticon2-layers"></i>
                            </button>
                        </ng-container>
                        <ng-container *ngIf="toe.status == 0">
                            <button mat-icon-button [matTooltip]="'Move to different assignment'" (click)="onMoveToe(toe)">
                                <mat-icon>content_copy</mat-icon>
                            </button>
                        </ng-container>
                        <!-- Information Action Tooltip -->
                        <ng-template #popTitle>
                            {{ 'GENERAL.INFORMATION_TOOLTIP.TITLE' | translate }}
                        </ng-template>
                        <ng-template #popContent>
                            <p [innerHTML]="'GENERAL.INFORMATION_TOOLTIP.CREATED_BY' |translate:{
                            username: toe.createdBy ? toe.createdBy : 'Unknown',
                            date: (toe.created_at | date: 'dd MMM yyyy':currentUser.settings.time_zone_gmt)
                            }"></p>

                            <p [innerHTML]="'GENERAL.INFORMATION_TOOLTIP.LAST_EDITED_BY' | translate:{
                                username: toe.lastUpdatedBy ? toe.lastUpdatedBy : 'Unknown',
                                date: (toe.updated_at | date: 'dd MMM yyyy':currentUser.settings.time_zone_gmt)
                                }">
                            </p>
                        </ng-template>
                        <button mat-icon-button
                                color="primary"
                                container="body"
                                [ngbPopover]="popContent"
                                [popoverTitle]="popTitle"
                                *ngIf="currentUser"
                        >
                            <mat-icon [matTooltip]="'GENERAL.TOOLTIP.INFORMATION' | translate">info</mat-icon>
                        </button>

                        <button mat-icon-button
                                color="primary"
                                [matTooltip]="toe.status == -1 || toe.status >= 1 ? 'View ToE': 'Edit ToE'"
                                (click)="editToe(toe)">
                            <mat-icon>{{toe.status > 0 || toe.status == -1 ? 'remove_red_eye':'create'}}</mat-icon>
                        </button>&nbsp;

                        <!-- <ng-container *ngIf="toe.status > 0 && canAccessFileDownload(toe, currentUser)"> -->
                        <ng-container>
                            <button mat-icon-button color="primary" (click)="onOpenDownloadFileModal(toe)" [ngStyle]="{'visibility': canAccessFileDownload(toe, currentUser)}">
                                <mat-icon>download</mat-icon>
                            </button>
                        </ng-container>

                        <span
                                [matTooltip]="(toe.status == 0 || toe.status == 1)&& toe.audit_cnt == 0 ? 'Delete ToE':(toe.audit_cnt == 0 ? 'ToE is not Draft mode.' : 'The ToE is linked to one or several audit trail interactions.')">
                            <button mat-icon-button
                                    color="warn"
                                    [disabled]="(toe.status != 0 && toe.status != 1 )|| toe.audit_cnt > 0 "
                                    type="button"
                                    (click)="deleteToe(toe)">
                            <mat-icon>delete</mat-icon>
                        </button>
                        </span>
                        <span
                            [matTooltip]="toe.status >= 1 || toe.status == -1 
                                ? 'ToE Dashboard'
                                : 'Cannot Reach ToE Dashboard, The status is not Signed'"
                            [ngStyle]="{'visibility': canAccessToe(toe, currentUser)}">
                            <button mat-icon-button
                                    type="button"
                                    [disabled]="toe.status == 0"
                                    (click)="dashboard(toe)">
                                <i class="flaticon2-arrow"
                                    [ngStyle]="{
                                        'color': toe.status == 0 ? 'lightgray' : 'black'
                                    }"></i>
                            </button>
                        </span>
                    </mat-cell>
                </ng-container>

                <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>

                <mat-row *matRowDef="let row; columns: displayedColumns; let index = index"
                         [ngClass]="{gray: index%2}"></mat-row>
            </mat-table>
            <!-- Message for empty data  -->
            <div class="mat-table-message" *ngIf="!dataSource.hasItems">
                {{ 'GENERAL.MESSAGE.NO_DATA' | translate }}
            </div>
            <div class="mat-table-message" *ngIf="dataSource.isPreloadTextViewed$ | async">
                {{ 'GENERAL.MESSAGE.PLEASE_WAIT' | translate }}
            </div>
        </div>

        <!-- start: BOTTOM -->
        <div class="mat-table-bottom">
            <!-- MATERIAL SPINNER | Url: 'https://material.angular.io/components/progress-spinner/overview' -->
            <mat-spinner [diameter]="20" *ngIf="dataSource.loading$ | async"></mat-spinner>
            <!-- MATERIAL PAGINATOR | Binded to dasources -->
            <!-- See off.documentations 'https://material.angular.io/components/paginator/overview' -->
            <mat-paginator [pageSize]="25" [pageSizeOptions]="[25, 50, 100]" [length]="dataSource.paginatorTotal$ | async" [showFirstLastButtons]="true"></mat-paginator>
        </div>
        <!-- end: BOTTOM -->
    </kt-portlet-body>
    <!-- end::Body -->

</kt-portlet>
