import { DatePipe } from '@angular/common';
import { Component, Input, OnInit, Output, EventEmitter, ViewContainerRef } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { NgxMaskPipe, provideNgxMask } from 'ngx-mask';
import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { User } from 'src/app/core/mad-auth/mad-auth.store';
import { AppState } from 'src/app/core/reducers';
import { ValuationProcessTargetPropertyModel } from 'src/app/core/valuation-process';
import { RemoveComparableFromValuationProcess } from 'src/app/core/valuation-process/_actions/valuation-process.actions';
import { sizeInfo, VAssetClassConsideration } from 'src/app/core/valuation-process/_models/valuation-asset-class-common.models';
import { VComparable } from 'src/app/core/valuation-process/_models/valuation-comparable.model';
import { selectValuationProcessSelectedComparables, selectValuationProcessSelectedComparablesFull, selectValuationProcessSelectedConsiderations } from 'src/app/core/valuation-process/_selectors/valuation-process.selectors';
import { ComparableStatsComponent } from 'src/app/views/pages/comparable/_sub/comparable-stats/comparable-stats.component';
import { DeleteAgencyDialogComponent } from 'src/app/views/partials/content/crud';
import { ComparableInfoDialogV2Component, ComparableInfoDialogV2DataInput } from '../../_sub/comparable-info-dialog-v2/comparable-info-dialog-v2.component';
import { ChangeConsiderationCurrencyExchange } from 'src/app/core/valuation-process/_actions/valuation-comparable.actions';
import { TypesUtilsService } from 'src/app/core/_base/crud';
import { selectValuationProcessDefaultSizeCriterion } from 'src/app/core/valuation-process/_selectors/size-criterion.selectors';
import { CurrencyExchange } from 'src/app/core/valuation-process/_models/currency-exchange';

@Component({
  selector: 'kt-comparables-table-v2',
  templateUrl: './comparables-table-v2.component.html',
  styleUrls: ['./comparables-table-v2.component.scss'],
  providers: [NgxMaskPipe]
})
export class ComparablesTableV2Component implements OnInit {
  @Input() currentUser?: any
  @Input() readonly: boolean = false
  @Input() targetProperty: ValuationProcessTargetPropertyModel
  @Output()
  onAddComparable: EventEmitter<void> = new EventEmitter();

  @Output()
  onCurrencyExchangeUpdate: EventEmitter<CurrencyExchange> = new EventEmitter();

  refNums$ = this.store$.select(selectValuationProcessSelectedComparables)

  form: UntypedFormGroup = new UntypedFormGroup({})

  dataSource$ = combineLatest([
    this.store$.select(selectValuationProcessSelectedComparablesFull),
    this.store$.select(selectValuationProcessSelectedConsiderations),
    this.store$.select(selectValuationProcessDefaultSizeCriterion)
  ]).pipe(
    map(([comparables, considerations, sizes]) => {
      const {dataSource, convertedValues} = this._mapComparables(comparables, considerations)
      return {
        sizes,
        comparables: dataSource,
        hasBuilding: this._hasBuilding(comparables),
        convertedValues
      }
    })
  )

  constructor(
    private store$: Store<AppState>,
    private dialog: MatDialog,
    private ngMask: NgxMaskPipe,
    private datePipe: DatePipe,
    private viewContainerRef: ViewContainerRef,
    private typesUtilService: TypesUtilsService
  ) { }

  ngOnInit(): void {}

  showComparableOverview(refNums: string[]) {
    this.dialog.open<ComparableInfoDialogV2Component, ComparableInfoDialogV2DataInput>(ComparableInfoDialogV2Component, {
      data: {
        refNums,
        isMulti: true 
      },
      width: '80vw',
      viewContainerRef: this.viewContainerRef
    })
  }

  private _hasBuilding(comparables: VComparable[]): boolean {
    let hasBuilding = false;
    comparables.forEach(comparable => {
      if (comparable.propertySubType != 'House' && comparable.propertySubType != 'Warehouse' && comparable.propertySubType != 'Retail Building' && comparable.propertySubType != 'Land') {
        if (comparable.building != null) {
          hasBuilding = hasBuilding || true;
        }
      }
    })
    return hasBuilding
  }

  private _mapComparables(comparables: VComparable[], considerations: {refNum: string, id: number}[]): {
    dataSource: {refNum: string, subTypeCategory: string}[],
    convertedValues: {[key: string]: {rate: number, converted: number}}
  }  {
    const convertedValues: {[keys: string]: {rate: number, converted: number}} = {}

    const dataSource = comparables.map(comparable => {
      const considerationId = considerations.find(consideration => consideration.refNum == comparable.refNum)
      const consideration = considerationId ? comparable.considerations.find(consideration => consideration.id == considerationId.id) : comparable.considerations[0]

      if (consideration.currency == this.targetProperty.countryCurrency) {
        convertedValues[comparable.refNum] = {
          rate: null,
          converted: consideration.value
        }
      } else {
        convertedValues[comparable.refNum] = {
          rate: null,
          converted: null
        }
        const conv = consideration.conversion.find(con => con.quoteCurrency == this.targetProperty.countryCurrency)
        if (conv) {
          const convertedValue = consideration.value * conv.exchangeRate
          convertedValues[comparable.refNum] = {
            rate: conv.exchangeRate,
            converted: convertedValue
          }
          if (convertedValue <= 0) {
            convertedValues[comparable.refNum] = {
              rate: null,
              converted: null 
            }
          }
        }
      }

      this._createFormControl(comparable.refNum, consideration, this.targetProperty.countryCurrency)

      if (comparable.propertySubType == 'Land') {
        return {
          refNum: comparable.refNum,
          subTypeCategory: this._subTypeCategory(comparable),
          size: sizeInfo(comparable.sizes[0], this.currentUser?.unit_of_measurement_id),
          displayConsiderations: this._mapConsiderations([consideration]),
          buildingID: this._buildingName(comparable),
          sourceDates: this._sourceDate([consideration]),
          consideration,
          tenureInfo: this._tenureInfo(consideration)
        }
      }
      return {
        refNum: comparable.refNum,
        subTypeCategory: this._subTypeCategory(comparable),
        size: sizeInfo(comparable.sizes[0], this.currentUser?.unit_of_measurement_id),
        displayConsiderations: this._mapConsiderations([consideration]),
        buildingID: this._buildingName(comparable),
        sourceDates: this._sourceDate([consideration]),
        sourceType: consideration.source.sourceType,
        consideration,
        tenureInfo: this._tenureInfo(consideration)
      }
    })
    return {
      dataSource: dataSource,
      convertedValues: convertedValues
    }
  }

  onFocusOut(event, refNum: string, consideration: VAssetClassConsideration, size: number) {
    const control = this.form.get(refNum + '_' + consideration.id)
    const rate = Number(control.value)

    const conv = consideration.conversion.find(_conv => _conv.quoteCurrency == this.targetProperty.countryCurrency)

    if (conv && conv.id) {
      this.onCurrencyExchangeUpdate.emit({
        id: conv.id,
        exchange_rate: rate
      })
      this.store$.dispatch(new ChangeConsiderationCurrencyExchange({
        refNum,
        consideraitonId: consideration.id,
        currencyExchange: {
          id: conv.id,
          exchange_rate: rate
        },
        size
      }))
    } else {
      this.onCurrencyExchangeUpdate.emit({
        date: this.typesUtilService.dateFormat(consideration.source.sourceDate),
        base_currency: consideration.currency,
        quote_currency: this.targetProperty.countryCurrency,
        exchange_rate: rate
      })
      this.store$.dispatch(new ChangeConsiderationCurrencyExchange({
        refNum,
        consideraitonId: consideration.id,
        currencyExchange: {
          date: this.typesUtilService.dateFormat(consideration.source.sourceDate),
          base_currency: consideration.currency,
          quote_currency: this.targetProperty.countryCurrency,
          exchange_rate: rate
        },
        size
      }))
    }
  }


  private _subTypeCategory(comparable: VComparable): string {
    if (comparable.propertySubType == 'Land') {
      return 'Unknown'
    }
    if (!comparable.property.subTypeCategory) {
      return 'Unknown'
    }
    let subCategory = 'Unknown'
    if (comparable.property.subCategory) {
      subCategory = comparable.property.subCategory
    }
    return comparable.property.subTypeCategory + ' (' + subCategory + ')'
  }

  private _mapConsiderations(considerations: VAssetClassConsideration[]): string[] {
    return considerations.map(consideration => {
      const tenure = consideration.tenureName
      const totalTenure = this.ngMask.transform(consideration.value.toFixed(2), 'separator', {thousandSeparator: ','})
      const strong = `${tenure} - ${totalTenure} ${consideration.currency}${consideration.considerationType == 'Rent' ? '/month' : ''}`
      return strong
    })
  }

  private _buildingName(comparable: VComparable): string {
    if (comparable.propertySubType != 'House' && comparable.propertySubType != 'Warehouse' && comparable.propertySubType != 'Retail Building' && comparable.propertySubType != 'Land')  {
      return comparable.building.name
    }
    return 'N/I'
  }

  private _sourceDate(considerations: VAssetClassConsideration[]): string[] {
    return considerations.map(consideration => {
      if (!consideration.source) {
        return 'N/A'
      }
      if (!consideration.source.sourceDate) {
        return 'N/A'
      }
      return this.datePipe.transform(consideration.source.sourceDate, 'dd MMM yyyy')
    })
  }

  private _tenureInfo(consideration: VAssetClassConsideration): string {
    switch (consideration.considerationType) {
      case 'Rent': {
        if (consideration.oldTenure.rent_basis_id == 1) return `${this.targetProperty.countryCurrency}/month (${consideration.tenureName})`
        if (consideration.oldTenure.rent_basis_id == 2) return `${this.targetProperty.countryCurrency}/quarter (${consideration.tenureName})`
        if (consideration.oldTenure.rent_basis_id == 3) return `${this.targetProperty.countryCurrency}/year (${consideration.tenureName})`
        return `${this.targetProperty.countryCurrency} (${consideration.tenureName})`
      }
      default:
        return `${this.targetProperty.countryCurrency} (${consideration.tenureName})`
    }
  }

  private _createFormControl(refNum: string, consideration: VAssetClassConsideration, countryCurrency: string) {
    if (consideration.currency == countryCurrency) {
      return;
    }
    const conv = consideration.conversion.find(con => con.quoteCurrency == countryCurrency)
    if (conv) {
      const control = new UntypedFormControl(conv.exchangeRate)
      this.form.addControl(refNum + '_' + consideration.id, control)
    } else {
      const control = new UntypedFormControl(0)
      this.form.addControl(refNum + '_' + consideration.id, control)
    }
  }

  showAcInfo(refNum: string) {
    this.dialog.open<ComparableInfoDialogV2Component, ComparableInfoDialogV2DataInput>(ComparableInfoDialogV2Component, {
      data: {
        refNums: [refNum],
        isMulti: false
      },
      width: '80vw',
      viewContainerRef: this.viewContainerRef
    })
  }

  deleteAc(refNum) {
    this.store$.dispatch(new RemoveComparableFromValuationProcess({refNum}))
  }

  addComparable() {
    this.onAddComparable.emit()
  }
}
