import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { BehaviorSubject, combineLatest, Observable} from 'rxjs';
import { map, startWith, tap } from 'rxjs/operators';
import { AssetClassTenure } from 'src/app/core/comparable';
import { ConsiderationCriterionService } from 'src/app/core/valuation/_services/consideration-criterion.service';
import { RateValues, TVOMMethodData, TVOMMethodVersion, TVOMRatesAndJustification, TVOMSubmethodType } from '../../tvom-types';
import { TVOMMethodResult } from '../../result-data-types';
import { Store } from '@ngrx/store';
import { selectDefaultSizeCriterion } from 'src/app/core/valuation/_selectors/size_criterion.selectors';
import { selectValuationProcessDefaultSizeCriterion } from 'src/app/core/valuation-process/_selectors/size-criterion.selectors';
import { calculateTVOMCapAndTargetRateMethod, calculateTVOMCapRateMethod, calculateTVOMTargetRateMethod, durationCalculation } from 'src/app/core/v2/valuation';
import { duration } from 'moment';

@Component({
  selector: 'kt-time-value-of-money-method',
  templateUrl: './time-value-of-money-method.component.html',
  styleUrls: [
    './time-value-of-money-method.component.scss',
    '../headline-to-effective.component.scss'
  ]
})
export class TimeValueOfMoneyMethodComponent implements OnInit {
  @Input() refNum: string;
  @Input() tpCurrency: string;
  @Input() selectedSubMethod$: BehaviorSubject<TVOMSubmethodType>;
  @Input() consideration: AssetClassTenure;
  @Input() ratesAndJustifications: TVOMRatesAndJustification;
  @Input() title: string;
  @Output() valueComputed = new EventEmitter<TVOMMethodResult>();

  form: UntypedFormGroup;

  subMethodType = TVOMSubmethodType;
  subMethod$: Observable<TVOMSubmethodType>;
  methodVersion: TVOMMethodVersion;
  methodVersionEnum = TVOMMethodVersion;

  total_consideration: number;
  capital_payment: number;

  templateData$: Observable<{
    tpCurrency: string,
    total_consideration: number,
    capital_payment: number,
    calculatedResult: TVOMMethodData,
    rateValues: RateValues,
    subMethodType: TVOMSubmethodType,
    methodVersion: TVOMMethodVersion
  }>;

  constructor(
    private store: Store,
    private service: ConsiderationCriterionService
  ) { }

  ngOnInit(): void {
    this.methodVersion = Number(this.consideration.write_off_period) !== 0 && Number(this.consideration.write_off_period) < Number(this.consideration.lease_duration) 
      ? TVOMMethodVersion.WriteOffIsLesserThanLease
      : TVOMMethodVersion.WriteOffIsEqualToLeaseOrNA;

    
    this.form = new UntypedFormGroup({
      capRate: new UntypedFormControl(this.ratesAndJustifications.capRate),
      capRateJustification: new UntypedFormControl(this.ratesAndJustifications.capRateJustification),
      targetRate: new UntypedFormControl(this.ratesAndJustifications.targetRate),
      targetRateJustification: new UntypedFormControl(this.ratesAndJustifications.targetRateJustification)
    });

    this.subMethod$ = this.selectedSubMethod$.pipe(
      tap(val => {
        switch (val) {
          case TVOMSubmethodType.TargetRateAndCapitalisationRate: {
            this._addValidators(['capRate', 'capRateJustification', 'targetRate', 'targetRateJustification']);
            break;
          }
          case TVOMSubmethodType.CapitalisationRate: {
            this._addValidators(['capRate', 'capRateJustification']);
            this._removeValidators(['targetRate', 'targetRateJustification']);
            break;
          }
          case TVOMSubmethodType.TargetRate: {
            this._addValidators(['targetRate', 'targetRateJustification']);
            this._removeValidators(['capRate', 'capRateJustification']);
          }
        }
        unTouchFields(this.form);
      })
    );

    this.templateData$ = combineLatest([
      this.form.get('capRate').valueChanges.pipe(startWith(this.form.get('capRate').value)),
      this.form.get('targetRate').valueChanges.pipe(startWith(this.form.get('targetRate').value)),
      this.subMethod$,
      this.store.select(selectValuationProcessDefaultSizeCriterion)
    ]).pipe(
      map(([capRate, targetRate, subMethodType, defaultSize]) => {
        removeError(this.form.get('capRate'), 'mask');
        removeError(this.form.get('targetRate'), 'mask');
        const rateValues: RateValues = {
          capRate,
          targetRate,
        }
        const calculatedResult = this._computeCalculation(this.consideration, this.methodVersion, subMethodType, rateValues, defaultSize.values[this.refNum]);
        if (calculatedResult === undefined) {
          return undefined;
        }

        const templateData = {
          // consideration: this.consideration, 
          calculatedResult: calculatedResult, 
          tpCurrency: this.tpCurrency,
          total_consideration: this.consideration.total_consideration,
          capital_payment: this.consideration.cap_payment,
          rateValues: rateValues, 
          subMethodType: subMethodType,
          methodVersion: this.methodVersion,
          size: defaultSize.values[this.refNum],
          rent_free_period: durationCalculation(this.consideration.rent_free_period, this.consideration.duration_type),
          write_off_period: durationCalculation(this.consideration.write_off_period, this.consideration.duration_type),
          fitting_out_period: durationCalculation(this.consideration.fitting_out_period, this.consideration.duration_type),
          lease_duration: this.consideration.lease_duration,
          rent_input_amount_type: this.consideration.rent_input_amount_type,
          rent_basis_id: this.consideration.rent_basis_id,
          rent_basis_name: this.consideration.rent_basis_name,
        }

        this.valueComputed.emit({
          calculatedValue: calculatedResult.effectiveRent,
        });

        return templateData;
      })
    );
  }

  _computeCalculation(
    consideration: AssetClassTenure, 
    methodVersion: TVOMMethodVersion,
    subMethodType: TVOMSubmethodType,
    rateValues: RateValues,
    size: number | undefined
  ): TVOMMethodData|undefined {
      if (subMethodType === undefined) {
        return undefined;
      }

      switch (subMethodType) {
        case TVOMSubmethodType.TargetRateAndCapitalisationRate: {
          // return this.service.computeTVOMCapAndTargetRateMethod(consideration, rateValues.targetRate, rateValues.capRate, methodVersion, this.tpCurrency, size);
          return calculateTVOMCapAndTargetRateMethod(consideration, rateValues.targetRate, rateValues.capRate, size)
        }
        case TVOMSubmethodType.CapitalisationRate: {
          // return this.service.computeTVOMCapRateMethod(consideration, rateValues.capRate, methodVersion, this.tpCurrency)
          return calculateTVOMCapRateMethod(consideration, rateValues.capRate, size)
        }
        case TVOMSubmethodType.TargetRate: {
          // return this.service.computeTVOMTargetRateMethod(consideration, rateValues.targetRate, methodVersion, this.tpCurrency)
          return calculateTVOMTargetRateMethod(consideration, rateValues.targetRate, size)
        }
      }
  }

  _addValidators(fields: string[]) {
    fields.forEach(field => {
      const control = this.form.get(field);
      if (field.substring(field.length - 4) === 'Rate') {
        control.setValidators([Validators.required, notZeroValidator]);
      } else {
        control.setValidators([Validators.required])
      }
      control.updateValueAndValidity();
    })
  }

  _removeValidators(fields: string[]) {
    fields.forEach(field => {
      const control = this.form.get(field);
      control.setValidators(null);
      control.updateValueAndValidity();
    })
  }

  getSubmethodName(method: TVOMSubmethodType): string {
    switch (method) {
      case TVOMSubmethodType.TargetRateAndCapitalisationRate: {
        return 'Target rate & capitalisation rate'
      }
      case TVOMSubmethodType.CapitalisationRate: {
        return 'Capitalisation rate';
      }
      case TVOMSubmethodType.TargetRate: {
        return 'Target rate';
      }
    }
  }

  isInValid(): boolean {
    if (this.form.invalid) {
      const controls = this.form.controls;
      Object.keys(controls).forEach(controlName => {
        controls[controlName].markAsTouched();
      });
      return true;
    }
    return false;
  }

  getJustifications(): TVOMRatesAndJustification {
    return {
      capRate: this.form.get('capRate').value,
      capRateJustification: this.form.get('capRateJustification').value,
      targetRate: this.form.get('targetRate').value,
      targetRateJustification: this.form.get('targetRateJustification').value,
    }
  }
}

function unTouchFields(fg: UntypedFormGroup) {
  const controls = fg.controls;
  Object.keys(controls).forEach(cn => {
    controls[cn].markAsUntouched();
  });
}

function notZeroValidator(control: AbstractControl): {[key: string]: boolean} | null {
  if ((control.value !== null && control.value !== '') && Number(control.value) == 0) {
    return {isZero: true}
  }
  return null;
}

function removeError(control: AbstractControl, error: string) {
  const err = control.errors; // get control errors
  if (err) {
      delete err[error]; // delete your own error
      if (!Object.keys(err).length) { // if no errors left
      control.setErrors(null); // set control errors to null making it VALID
      } else {
      control.setErrors(err); // controls got other errors so set them back
      }
  }
}