import { CostRevenue } from "../../types";

export function convertedResultValue(
  result: {value: number},
  item: CostRevenue,
  countryCurrency: string
) {
  return convertValueIntoCountryCurrency(
    result === undefined ? undefined : result.value,
    item,
    countryCurrency
  )
}

export function convertValueIntoCountryCurrency(
  value: number,
  item: CostRevenue,
  countryCurrency: string
) {
  if (value === undefined) return undefined
  if (item.currency === countryCurrency) return value
  if (item.calculationMeta.exchangeRate === null || item.calculationMeta.exchangeRate === 0) return undefined
  return item.calculationMeta.reportingToLeaseCurrency
    ? value * item.calculationMeta.exchangeRate
    : value / item.calculationMeta.exchangeRate
}