import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { add } from 'lodash';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { AssetClassLandAreaComponentModel } from 'src/app/core/asset_class';
import { AssetClassTenure, AcLandTenure } from 'src/app/core/comparable/_models/asset-class-tenure.model';
import { AddConsiderationDialogComponent } from './add-consideration-dialog/add-consideration-dialog.component';
import { AddConsiderationDialogV2Component, AddConsiderationDialogV2Input, AddConsiderationDialogV2Output } from './add-consideration-dialog-v2/add-consideration-dialog-v2.component';
import { AssetClassSize } from 'src/app/core/v2/types';

@Component({
  selector: 'kt-consideration-and-source',
  templateUrl: './consideration-and-source.component.html',
  styleUrls: ['./consideration-and-source.component.scss']
})
export class ConsiderationAndSourceComponent implements OnInit, OnChanges {
  @Input() landAreaComponentListSubject: BehaviorSubject<AssetClassLandAreaComponentModel[]>;
  @Input() hasParcelInfo$: Observable<boolean>;

  private considerations: AssetClassTenure[] = [];
  private considerations$: BehaviorSubject<AssetClassTenure[]> = new BehaviorSubject([]);
  @Input() 
  set inputConsiderations(values:  AssetClassTenure[]) {
    this.considerations = values
    this.considerations$.next(this.considerations)
  }; 
  private _sizes$ = new BehaviorSubject<AssetClassSize[]>([])
  @Input() 
  set sizes(value: AssetClassSize[]) {
    this._sizes$.next(value)
  }
  @Input() timezoneOffset: string;
  // Table
  dataSource = new MatTableDataSource();
  columns = ['date', 'source', 'tenure', 'consideration', 'additional_consideration', 'action'];

  private hasParcel: boolean = false;

  constructor(private dialog: MatDialog) { }

  ngOnChanges(changes: SimpleChanges): void {}

  ngOnInit(): void {
    this.hasParcelInfo$.subscribe(val => {
      this.hasParcel = val;
      if (val) {
        this.columns = ['date', 'source', 'tenure', 'consideration', 'additional_consideration', 'actions'];
      } else {
        this.columns = ['date', 'source', 'tenure', 'consideration', 'actions'];
      }
    });

    this.landAreaComponentListSubject.subscribe(components => {
      this.considerations = this.considerations.map(con => {
        return deepCopy(con, components);
      });
      this.considerations$.next(this.considerations);
    });

    this.considerations$.subscribe(cons => {
      const temp = cons.map(con => {
        const add_const = con.additional.filter(t => !t.included).reduce((acc, t) => acc + t.consideration, 0);
        return {
          id: con.id,
          date: con.source.source_type_id != 1 ? con.source.information_date : con.source.transaction_date,
          source: con.source.source_type_name,
          tenure: capitalize(con.tenure),
          consideration_type_id: con.consideration_type_id,
          consideration: this._totalConsideration(con),
          additional_consideration: add_const,
          num: con.additional.filter(t => !t.included).length,
          con: con,
          hasAdditional: con.additional.filter(t => !t.included).length != 0,
          currency: con.currency.toUpperCase(),
          rent_basis: this.rentBasisId(con) 
        };
      }); 
      temp.sort((a: any, b: any): number => {
        const aDate = new Date(a.date);
        const bDate = new Date(b.date);
        return bDate.getTime() - aDate.getTime();
      });
      this.dataSource.data = temp;
    })
  }

  private rentBasisId(con: AssetClassTenure) {
    if (!con.consideration_type_id) return ''
    if (con.consideration_type_id == 1) return ''
    if (con.rent_basis_id == 1) {
      return '(per month)'
    }
    if (con.rent_basis_id == 2) {
      return '(per quarter)'
    }
    if (con.rent_basis_id == 3) {
      return '(per year)'
    }
    return ''
  }

  private _totalConsideration(consideration: AssetClassTenure) {
    if (consideration.consideration_type_id === 1) {
      return consideration.total_consideration
    }
    if (consideration.consideration_type_id === 2) {
      if (consideration.rent_input_amount_type === 'total') return consideration.total_consideration
      return consideration.total_consideration * (consideration.rent_size ? consideration.rent_size.size : 0)
    }
    return 0
  }

  // Actions
  addConsiderationV2() {
    const additionals = this.landAreaComponentListSubject.value.map(com => {
      const t = new AcLandTenure();
      t.clear();
      t.com_order = com.order;
      return t;
    })
    const dialogRef = this.dialog.open<AddConsiderationDialogV2Component, AddConsiderationDialogV2Input, AddConsiderationDialogV2Output>(AddConsiderationDialogV2Component, {
      data: {
        sizes: this._sizes$.value,
        components: this.landAreaComponentListSubject.value,
        hasParcel: this.hasParcel,
        otherDates: this.considerations.map(con => ({
          tenure: con.tenure,
          date: con.source.source_type_id != 1 ? con.source.information_date : con.source.transaction_date
        })),
        additionals: additionals
      },
      width: '65vw'
    })

    dialogRef.afterClosed().subscribe(res => {
      if (!res) {
        return
      }
      this.considerations.push(AssetClassTenure.fromConsideration(res.consideration, this._sizes$.value));
      this.considerations$.next(this.considerations);
    })
  }
  addConsideration() {
    const acTenure = new AssetClassTenure();
    acTenure.clear();
    acTenure.additional = this.landAreaComponentListSubject.value.map(com => {
      const t = new AcLandTenure();
      t.clear();
      t.com_order = com.order;
      return t;
    })
    const dialogRef = this.dialog.open(AddConsiderationDialogComponent, 
      {
        data: {
          acTenure: acTenure, 
          components: this.landAreaComponentListSubject.value,
          hasParcel: this.hasParcel,
          mode: 'add',
          otherDates: this.considerations.map(con => ({
            tenure: con.tenure,
            date: con.source.source_type_id != 1 ? con.source.information_date : con.source.transaction_date
          }))
        },
        width: '60vw'
      }
    );

    dialogRef.afterClosed().subscribe(res => {
      if (!res) {
        return;
      }
      this.considerations.push(res.result);
      this.considerations$.next(this.considerations);
    })
  }

  editConsideration(tenure: AssetClassTenure, index: number, editable: boolean = true) {
    const dialogRef = this.dialog.open<AddConsiderationDialogV2Component, AddConsiderationDialogV2Input, AddConsiderationDialogV2Output>(AddConsiderationDialogV2Component, {
      data: {
        consideration: AssetClassTenure.toConsideration(tenure),
        sizes: this._sizes$.value,
        components: this.landAreaComponentListSubject.value,
        hasParcel: this.hasParcel,
        otherDates: this.considerations.filter(con => con != tenure).map(con => ({
          tenure: con.tenure,
          date: con.source.source_type_id != 1 ? con.source.information_date : con.source.transaction_date
        })),
        additionals: tenure.additional
      },
      width: '65vw'
    })

    dialogRef.afterClosed().subscribe(res => {
      if (!res) {
        return
      }

      this.considerations.splice(index, 1, AssetClassTenure.fromConsideration(res.consideration, this._sizes$.value))
      this.considerations$.next(this.considerations);
    })
  }

  // editConsideration(consideration: AssetClassTenure, index: number, editable: boolean = true) {
  //   const dialogRef = this.dialog.open(AddConsiderationDialogComponent, 
  //     {
  //       data: {
  //         acTenure: consideration, 
  //         components: this.landAreaComponentListSubject.value,
  //         hasParcel: this.hasParcel,
  //         mode: 'edit',
  //         editable: editable,
  //         otherDates: this.considerations.filter(con => con != consideration).map(con => ({
  //           tenure: con.tenure,
  //           date: con.source.source_type_id != 1 ? con.source.information_date : con.source.transaction_date
  //         }))
  //       },
  //       width: '60vw'
  //     }
  //   );
  //   dialogRef.afterClosed().subscribe(res => {
  //     if (!res) {
  //       return;
  //     }
  //     this.considerations.splice(index, 1, res.result)
  //     this.considerations$.next(this.considerations);
  //   })
  // }

  deleteConsideration(index: number) {
    this.considerations.splice(index, 1);
    this.considerations$.next(this.considerations);
  }

  getData() {
    return this.considerations;
  }

  validate(): boolean {
    return this.considerations.length > 0;
  }

}

function deepCopy(item: AssetClassTenure, components: AssetClassLandAreaComponentModel[]): AssetClassTenure {
  // const _new = new AssetClassTenure();
  // _new.clear();
  const _new  = Object.assign({}, item);
  _new.source = Object.assign({}, item.source);
  const comOrders = components.map(com => com.order);
  _new.additional = comOrders.map(order => {
    const indx = item.additional.findIndex(add => add.com_order == order);
    if (indx > -1) {
      const el = Object.assign({}, item.additional[indx]);
      return el;
    }
    const t = new AcLandTenure();
    t.clear();
    t.com_order = order;
    return t;
  });
  return _new;
}

export function capitalize(text: string): string {
  if (text == null || text.length == 0) {
    return text;
  }
  if (text.length == 1) {
    return text.toUpperCase();
  }
  return text[0].toUpperCase() + text.substring(1);
}