import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { AbstractControl, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Behavior } from 'popper.js';
import { BehaviorSubject, combineLatest, Observable, ReplaySubject, Subject } from 'rxjs';
import { map, startWith, takeUntil } from 'rxjs/operators';
import { AssetClassTenure } from 'src/app/core/comparable';
import { ConsiderationCriterionService } from 'src/app/core/valuation/_services/consideration-criterion.service';
import { CashFlowMethodData, CashFlowMethodVersion, CFMRatesAndJustifications, CFMReturnType, SelectedRent } from '../../../cf-method-types';
import { calculateCFM, durationCalculation } from 'src/app/core/v2/valuation';
import { Store } from '@ngrx/store';
import { selectDefaultSizeCriterion } from 'src/app/core/valuation/_selectors/size_criterion.selectors';
import { selectValuationProcessDefaultSizeCriterion } from 'src/app/core/valuation-process/_selectors/size-criterion.selectors';

@Component({
  selector: 'kt-cfm',
  templateUrl: './cfm.component.html',
  styleUrls: [
    './cfm.component.scss',
    '../../headline-to-effective.component.scss'
  ]
})
export class CfmComponent implements OnInit, OnDestroy {
  @Input() refNum: string;
  @Input() tpCurrency: string;
  @Input() consideration: AssetClassTenure;
  @Input() ratesAndJustifications: CFMRatesAndJustifications;
  @Input() selected: SelectedRent;
  @Output() valueComputed = new EventEmitter<number>();

  form: UntypedFormGroup;
  methodVersion: CashFlowMethodVersion;
  methodVersionEnum = CashFlowMethodVersion;

  numberOfTerms$: ReplaySubject<{
    val: number,
    real: number,
  }> = new ReplaySubject();
  templateData$ = new BehaviorSubject<{
    tpCurrency: string,
    total_consideration: number,
    capital_payment: number,
    consideration: AssetClassTenure,
    calculatedResult: CashFlowMethodData,
    rateValue: {
      targetRate: number,
      growthRate: number,
    },
    methodVersion: CashFlowMethodVersion,
    rent_free_period: number,
    write_off_period: number,
    fitting_out_period: number,
    rent_input_amount_type: string,
    rent_basis_id: number
  }>(null);

  select$: BehaviorSubject<{
    main: boolean,
    index: number
  }> = new BehaviorSubject({
    main: false,
    index: undefined,
  });
  selectError$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  write_off_period = 0;
  rent_review = 0;
  fitting_out_period = 0;

  
  private _onDestroy$ = new Subject<void>()
  constructor(
    private store: Store,
    private service: ConsiderationCriterionService
  ) { }

  ngOnDestroy(): void {
    this._onDestroy$.next() 
    this._onDestroy$.complete()
  }

  ngOnInit(): void {
    const isSameCurrency = this.consideration.currency === this.tpCurrency;
    const conv = this.consideration.conversion.find(c => c.quote_currency === this.tpCurrency);

    this.methodVersion = Number(this.consideration.write_off_period) !== 0 && Number(this.consideration.write_off_period) < Number(this.consideration.lease_duration) 
      ? CashFlowMethodVersion.WriteOffIsLesserThanLease
      : CashFlowMethodVersion.WriteOffIsEqualToLeaseOrNA;

    this.write_off_period = durationCalculation(this.consideration.write_off_period, this.consideration.duration_type)
    this.rent_review = durationCalculation(this.consideration.rent_review, this.consideration.duration_type)
    this.fitting_out_period = durationCalculation(this.consideration.fitting_out_period, this.consideration.duration_type)

    this.form = new UntypedFormGroup({
      targetRate: new UntypedFormControl(this.ratesAndJustifications.targetRate),
      targetRateJustification: new UntypedFormControl(this.ratesAndJustifications.targetRateJustification),
      growthRate: new UntypedFormControl(this.ratesAndJustifications.growthRate),
      growthRateJustification: new UntypedFormControl(this.ratesAndJustifications.growthRateJustification),
      effectiveRentJustification: new UntypedFormControl(this.ratesAndJustifications.effectiveRentJustification),
    });
    this._addValidators(['targetRate', 'targetRateJustification', 'growthRate', 'growthRateJustification', 'effectiveRentJustification']);
    this.select$.next(this.selected);

    combineLatest([
      this.form.get('targetRate').valueChanges.pipe(startWith(this.form.get('targetRate').value)),
      this.form.get('growthRate').valueChanges.pipe(startWith(this.form.get('growthRate').value)),
      this.select$,
      this.store.select(selectValuationProcessDefaultSizeCriterion)
    ]).pipe(
      takeUntil(this._onDestroy$)
    ).subscribe(([targetRate, growthRate, selection, defaultSize]) => {
      removeError(this.form.get('targetRate'), 'mask');
      removeError(this.form.get('growthRate'), 'mask');
      const rates = {
        targetRate,
        growthRate,
      };
      const calculatedResult = calculateCFM(this.consideration, targetRate, growthRate, defaultSize.values[this.refNum]);
      this.numberOfTerms$.next({val: calculatedResult.numberOfTerms, real: calculatedResult.numberOfTermsReal});

      let value = undefined;
      if (selection.main) {
        calculatedResult.results.isSelected = true;
        calculatedResult.results.effectiveRents.forEach(er => er.isSelected = false);
        value = calculatedResult.results.effectiveRent;
        this.selectError$.next(false);
      } else if (selection.index !== undefined) {
        calculatedResult.results.isSelected = false;
        calculatedResult.results.effectiveRents.forEach((er, i) => {
          if (i === selection.index) {
            er.isSelected = true;
            value = er.value;
          } else {
            er.isSelected = false;
          }
        });
        this.selectError$.next(false);
      }

      if (value) {
        this.valueComputed.emit(value);
      }

      const templateData = {
        tpCurrency: this.tpCurrency,
        total_consideration: this.consideration.total_consideration,
        capital_payment: this.consideration.cap_payment,
        consideration: this.consideration,
        calculatedResult,
        rateValue: rates,
        methodVersion: this.methodVersion,
        rent_free_period: durationCalculation(this.consideration.rent_free_period, this.consideration.duration_type),
        write_off_period: durationCalculation(this.consideration.write_off_period, this.consideration.duration_type),
        fitting_out_period: durationCalculation(this.consideration.fitting_out_period, this.consideration.duration_type),
        rent_input_amount_type: this.consideration.rent_input_amount_type,
        rent_basis_id: this.consideration.rent_basis_id,
        rent_basis_name: this.consideration.rent_basis_name,
        size: defaultSize.values[this.refNum]
      }
      this.templateData$.next(templateData)
    });
  }

  onSelectEffectiveRent(main: boolean, index: number) {
    this.select$.next({
      main,
      index
    });
  }

  isInValid(): boolean {
    if (this.form.invalid) {
      const controls = this.form.controls;
      Object.keys(controls).forEach(controlName => {
        controls[controlName].markAsTouched();
      });
      return true;
    }
    if (this.select$.value.main === undefined && this.select$.value.index === undefined) {
      this.selectError$.next(true);
      return true;
    }
    this.selectError$.next(false);
    return false;
  }

  _addValidators(fields: string[]) {
    fields.forEach(field => {
      const control = this.form.get(field);
      if (field.substring(field.length - 4) === 'Rate') {
        control.setValidators([Validators.required, notZeroValidator]);
      } else {
        control.setValidators([Validators.required])
      }
      control.updateValueAndValidity();
    })
  }

  getJustifications(): CFMReturnType {
    return {
      targetRate: this.form.get('targetRate').value,
      targetRateJustification: this.form.get('targetRateJustification').value,
      growthRate: this.form.get('growthRate').value,
      growthRateJustification: this.form.get('growthRateJustification').value,
      effectiveRentJustification: this.form.get('effectiveRentJustification').value,
      main: this.select$.value.main,
      index: this.select$.value.index,
    }
  }
}

function notZeroValidator(control: AbstractControl): {[key: string]: boolean} | null {
  if ((control.value !== null && control.value !== '') && Number(control.value) == 0) {
    return {isZero: true}
  }
  return null;
}

function removeError(control: AbstractControl, error: string) {
  const err = control.errors; // get control errors
  if (err) {
      delete err[error]; // delete your own error
      if (!Object.keys(err).length) { // if no errors left
      control.setErrors(null); // set control errors to null making it VALID
      } else {
      control.setErrors(err); // controls got other errors so set them back
      }
  }
}
