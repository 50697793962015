<kt-portlet>
    <kt-portlet-header
        [sticky]="true"
        [title]="title$ | async"
        [class]="'kt-portlet-head--lg'"
        [tooltipTitle]="'TOOLTIP.VALUATION_PROCESS.FORM.TITLE' | translate"
        [tooltipDesc]="'TOOLTIP.VALUATION_PROCESS.FORM.DESCRIPTION' | translate"
        [viewLoading$]="loading$">
        <ng-container ktPortletTools>
            <a href="javascript:;"
               (click)="back()"
               class="btn btn-secondary mr-2" mat-raised-button matTooltip="Back to ToE Dashboard">
                <mat-icon>arrow_back</mat-icon>
                <span class="kt-hidden-mobile">Close</span>
            </a>
            <button 
                (click)="onSubmit()"
                *ngIf="!readonly"
                class="btn btn-save-draft mr-2"
                mat-raised-button
                matTooltip="Save to Draft">
                <span>Save draft</span>
            </button> 
            <button  
                (click)="onSubmit(true)"
                [disabled]="summaryDisabled$|async"
                *ngIf="!readonly"
                class="btn btn-success mr-2"
                mat-raised-button
                matTooltip="Save to Validate">
                Validate
            </button> 
        </ng-container>
    </kt-portlet-header>

    <kt-portlet-body>
        <div class="kt-portlet-body pt-0">
            <div class="col-lg-12 px-4">
                <kt-tab-header
                    [headerLabels]="tabHeaders$|async"
                    (tabChange)="onTabChange($event)"></kt-tab-header>
                <mat-tab-group 
                    class="invinsible-tabs"
                    [selectedIndex]="selectedTab$|async">
                    <mat-tab>
                        <ng-template matTabContent>
                            <ng-container *ngIf="!(loading$|async); else loading">
                                <kt-background-tab-v2
                                    [targetProperty]="targetProperty$ | async"
                                    [valuation]="valuation$ | async"
                                    [toe]="toe$ | async"
                                    [assignment]="assignment$ | async"
                                    [workers]="workers$ | async"
                                    [fullTp]="fullTp$ | async"
                                    ></kt-background-tab-v2>
                            </ng-container>
                        </ng-template>
                    </mat-tab>
                    <mat-tab>
                        <ng-template matTabContent>
                            <kt-comparables-tab-v2
                                [targetProperty]="targetProperty$ | async"
                                [valuation]="valuation$ | async"
                                (currencyExchangeUpdate)="onCurrencyExchangeUpdate($event)"
                                ></kt-comparables-tab-v2>
                        </ng-template>
                    </mat-tab>
                    <mat-tab [disabled]="adjustmentDisabled$ | async">
                        <ng-template matTabContent>
                            <kt-adjustments-tab-v2></kt-adjustments-tab-v2>
                        </ng-template>
                    </mat-tab>
                    <mat-tab>
                        <ng-template matTabContent>
                            <kt-liquidation-value-estimate-tab></kt-liquidation-value-estimate-tab>
                        </ng-template>
                    </mat-tab>
                    <mat-tab [disabled]="adjustmentDisabled$ | async">
                        <ng-template matTabContent>
                            <kt-assumptions-tab
                                [readonly]="readonly"
                                [targetProperty]="fullTp$ | async"
                                [toe]="null">
                            </kt-assumptions-tab>
                        </ng-template>
                    </mat-tab>
                    <mat-tab [disabled]="summaryDisabled$ | async">
                        <ng-template matTabContent>
                            <kt-summary-tab-v2></kt-summary-tab-v2>
                        </ng-template>
                    </mat-tab>
                </mat-tab-group>
            </div>
        </div>
    </kt-portlet-body>
</kt-portlet>

<ng-template #loading>
    <div class="loading"></div>
</ng-template>