<div class="kt-login-body">
  <!--begin::Signin-->
  <div class="kt-login-form">
    <div class="mad-login-form">
      <img src="assets/media/interval/logo.png" class="interval-blue-logo" alt="InterVal logo">
      <div id="signup-embed"></div>
    </div>
    <div class="sign-up">
        <span class="center">
          <span class="kt-login-signup-label">Already have an account?</span>&nbsp;&nbsp;
          <a href="/mad-auth/login" class="kt-link kt-login-signup-link">
            Login
          </a>
        </span>
      </div>
  </div>
</div>
