import { createEntityAdapter, EntityAdapter, EntityState } from "@ngrx/entity";
import { ValuationComparableAction, ValuationComparableActionTypes } from "../_actions/valuation-comparable.actions";
import { VComparable } from "../_models/valuation-comparable.model";
import { setCurrencyExchange } from "../_utils/comparable";

export interface ValuationComparableFullState extends EntityState<VComparable> {
    actionLoading: boolean
}
export const adapter: EntityAdapter<VComparable> = createEntityAdapter<VComparable>({
    selectId: (entity) => entity.refNum
})
export const initialValuationComparableFullState: ValuationComparableFullState = adapter.getInitialState({
    actionLoading: false
})
export function valuationComparableFullReducer(
    state = initialValuationComparableFullState,
    action: ValuationComparableAction
): ValuationComparableFullState {
    switch (action.type) {
        case ValuationComparableActionTypes.FullComparablesRequested: {
            return {...state, actionLoading: true}
        }
        case ValuationComparableActionTypes.FullComparablesRequestedOnServer: {
            return {...state, actionLoading: true}
        }
        case ValuationComparableActionTypes.FullComparablesLoadedFromServer: {
            if (action.payload.items.length == 0) {
                return {...state, actionLoading: false}
            }
            return adapter.upsertMany(action.payload.items, {...state, actionLoading: false})
        }
        case ValuationComparableActionTypes.ImportedComparablesRequested: {
            return adapter.setAll([], {...state, actionLoading: true}) 
        }
        case ValuationComparableActionTypes.ImportedComparablesLoadedFromServer: {
            return adapter.setAll(action.payload.items, {...state, actionLoading: false})
        }
        case ValuationComparableActionTypes.ChangeComparableConsideration: {
            const comparable = state.entities[action.payload.refNum];
            if (!comparable) {
                return state
            }
            const update = setCurrencyExchange(comparable, action.payload.consideraitonId, action.payload.currencyExchange)
            return adapter.updateOne(update, {...state})
        }
        default:
            return state
    }
}