import { Injectable } from "@angular/core";
import { MadAuthService } from "./mad-auth.service";
import { from, Observable } from "rxjs";
import { map, switchMap } from "rxjs/operators";

interface Plan {
  type: 'test' | 'essential' | 'standard',
  label: string,
  approaches: number[],
  methods: number[]
}

@Injectable({
  providedIn: 'root'
})
export class OutsetaPlanService {
  private readonly _plans: Record<string, Plan> = {
    'wmjNp7mV': {
      type: 'test',
      label: 'Beta Denied',
      // approaches: [4],
      // methods: [2]
      approaches: [2, 4],
      methods: [2, 16, 18, 19, 20, 23, 24, 25, 27, 28, 33, 9]
    },
    'nmDD07my': {
      type: 'test',
      label: 'Test',
      approaches: [2, 4],
      methods: [2, 16, 18, 19, 20, 23, 24, 25, 27, 28, 33]
    },
    'wQXzylWK': {
      type: 'essential',
      label: 'Essential',
      approaches: [4],
      methods: [2]
    },
    'yW1Ddj9B': {
      type: 'standard',
      label: 'Standard',
      approaches: [2, 4],
      methods: [2, 16, 18, 19, 20, 23, 24, 25, 27, 28, 33]
    }
  }
  constructor(
    private authService: MadAuthService
  ) {}

  getCurrentPlan(): Observable<string> {
    return from(this.authService.getCurrentPlan())
  }

  allowedApproaches(): Observable<number[]> {
    return from(this.authService.getCurrentPlan()).pipe(
      map(planUuid => {
        const plan = this._plans[planUuid]
        if (!plan) return []
        return plan.approaches
      })
    )
  }

  allowedMethods(): Observable<number[]> {
    return this.getCurrentPlan().pipe(
      map(planUuid => {
        const plan = this._plans[planUuid]
        if (!plan) return []
        return plan.methods
      })
    )
  }
}