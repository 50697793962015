export class MenuConfig {
    public defaults = {
        header: {
            self: {},
            items: [
                {
                    'title': 'Help',
                    'root': true,
                    'icon-': 'flaticon-paper-plane',
                    'toggle': 'click',
                    'alignment': 'left',
                    'translate': 'MENU.APPS',
                    'submenu': {
                        'type': 'classic',
                        'alignment': 'left',
                        'items': [
                            // {
                            //     'title': 'FAQs',
                            //     'icon': 'flaticon-questions-circular-button'
                            // },
                            {
                                'title': 'Open a ticket',
                                // 'page': 'components/datatable_v1',
                                'icon': 'flaticon-network',
                                'href': 'https://interval.outseta.com/support/kb'
                                // 'submenu': {
                                //     'type': 'classic',
                                //     'alignment': 'right',
                                //     'items': [
                                //         {
                                //             'title': 'Reached Users',
                                //             'icon': 'flaticon-users'
                                //         },
                                //         {
                                //             'title': 'SEO Ranking',
                                //             'icon': 'flaticon-interface-1'
                                //         },
                                //         {
                                //             'title': 'User Dropout Points',
                                //             'icon': 'flaticon-lifebuoy'
                                //         },
                                //         {
                                //             'title': 'Market Segments',
                                //             'icon': 'flaticon-graphic-1'
                                //         },
                                //         {
                                //             'title': 'Opportunity Growth',
                                //             'icon': 'flaticon-graphic'
                                //         }
                                //     ]
                                // }
                            },
                            // {
                            //     'title': 'RICS standards',
                            //     'icon_url': 'assets/media/icons/RICS.svg'
                            //     // 'icon': 'flaticon-customer'
                            // },
                            {
                                'title': 'Documentation',
                                'icon': 'flaticon2-list-2',
                                'href': 'https://docs.interval-soft.com'
                                // 'badge': {
                                //     'type': 'kt-badge--success',
                                //     'value': '3'
                                // }
                            },
                            // {
                                // 'title': 'Support',
                                // 'page': '',
                                // 'icon': 'flaticon-exclamation-square',
                                // 'href': 'https://interval.outseta.com/support/kb'
                                // 'submenu': {
                                //     'type': 'classic',
                                //     'alignment': 'right',
                                //     'items': [
                                //         {
                                //             'title': 'Merge Branch',
                                //             'icon': 'flaticon-add',
                                //             'badge': {
                                //                 'type': 'kt-badge--danger',
                                //                 'value': '3'
                                //             }
                                //         },
                                //         {
                                //             'title': 'Version Controls',
                                //             'icon': 'flaticon-signs-1'
                                //         },
                                //         {
                                //             'title': 'Database Manager',
                                //             'icon': 'flaticon-folder'
                                //         },
                                //         {
                                //             'title': 'System Settings',
                                //             'icon': 'flaticon-cogwheel-2'
                                //         }
                                //     ]
                                // }
                            // }
                        ]
                    }
                }
            ]
        },
        aside: {
            self: {},
            items: [
                {
                    title: 'Dashboard',
                    root: true,
                    icon: 'flaticon2-architecture-and-city',
                    page: '/dashboard',
                    translate: 'MENU.DASHBOARD',
                    bullet: 'dot',
                },
                {
                    title: 'Projects',
                    root: true,
                    icon: 'flaticon-list',
                    bullet: 'dot',
                    submenu: [
                        {
                            title: 'Assignments',
                            bullet: 'dot',
                            page: '/assignment/assignments',
                        },
                        {
                            title: 'Terms of Engagement',
                            bullet: 'dot',
                            page: '/terms-of-engagements'
                        },
                        {
                            title: 'Quotes',
                            bullet: 'dot',
                            page: '/quotes'
                        },
                        {
                            title: 'Clients',
                            bullet: 'dot',
                            page: '/admin-management/clients',
                            permissions: ['client:view', 'client:crud']
                        },
                        {
                            title: 'Management',
                            page: '/my-task/pm/list-new',
                            translate: 'MENU.PROJECT_MANAGEMENT',
                            bullet: 'dot',
                            permissions: ['pm:view', 'pm:export']
                        },
                        {
                            title: 'Financials',
                            page: '/my-task/pm/list-financials',
                            translate: 'MENU.PROJECT_FINANCIAL',
                            bullet: 'dot',
                            permissions: ['pf:view', 'pf:export']
                        },
                    ]
                },
                {
                    title: 'Property Databases',
                    root: true,
                    icon: 'flaticon-placeholder-3',
                    bullet: 'dot',
                    submenu: [
                        {
                            title: 'Residential',
                            bullet: 'line',
                            submenu: [
                                {
                                    title: 'Apartment / Flat',
                                    page: '/comparable/apartments'
                                },
                                {
                                    title: 'House',
                                    page: '/comparable/houses',
                                }
                            ]
                        },
                        {
                            title: 'Commercial',
                            bullet: 'line',
                            submenu: [
                                {
                                    title: 'Office',
                                    page: '/comparable/offices'
                                },
                                {
                                    title: 'Retail shop',
                                    page: '/comparable/retail-shops',
                                },
                                {
                                    title: 'Retail building',
                                    page: '/comparable/retail-buildings'
                                },
                                {
                                    title: 'Parking',
                                    page: '/comparable/parkings'
                                }
                            ]
                        },
                        {
                            title: 'Industrial',
                            bullet: 'line',
                            submenu: [
                                {
                                    title: 'Warehouse',
                                    page: '/comparable/warehouses'
                                }
                            ]
                        },
                        {
                            title: 'Land',
                            page: '/comparable/lands'
                        },
                        {
                            title: 'Buildings',
                            page: '/comparable/buildings'
                        },
                    ]
                },
                {
                    title: 'Resources',
                    root: true,
                    bullet: 'dot',
                    icon: 'flaticon2-layers-1',
                    submenu: [
                        {
                            title: 'Countries',
                            page: '/admin-management/countries'
                        },
                        {
                            title: 'Cities',
                            page: '/admin-management/countries/0'
                        },
                        {
                            title: 'Landmarks',
                            page: '/admin-management/key-places'
                        },
                        {
                            title: 'Appendices',
                            page: '/admin-management/appendix-categories'
                        },
                    ]
                },
                {
                    title: 'Admin',
                    root: true,
                    bullet: 'dot',
                    icon: 'flaticon-security',
                    permissions: ['agency:view', 'agency:crud', 'user:view', 'user:edit', 'profile:view', 'profile:edit', 'role:view', 'role:crud', 'template:view', 'template:crud'],
                    submenu: [
                        {
                            title: 'General Settings',
                            page: '/admin-management/general-settings'
                        },
                        {
                            title: 'Profiles',
                            bullet: 'line',
                            submenu: [
                                {
                                    title: 'Agencies',
                                    page: '/admin-management/agencies',
                                    permissions: ['agency:view', 'agency:crud']
                                },
                                {
                                    title: 'Users',
                                    page: '/user-management/users',
                                    permissions: ['user:view', 'user:edit', 'profile:view', 'profile:edit']
                                },
                            ]
                        },
                        {
                            title: 'Linked tables',
                            page: '/admin-management/linked-tables',
                            permissions: ['linked_table']
                        },
                        {
                            title: 'Roles',
                            page: '/admin/roles',
                            permissions: ['role:view', 'role:crud']
                        },
                        // {
                        //     title: 'Tooltips',
                        //     page: '/admin-management/tooltip-editor'
                        // },
                        {
                            title: 'Template',
                            page: '/template',
                            permissions: ['template:view', 'template:crud']
                        }
                    ]
                }

            ]
        },
    };

    public get configs()  {
        return this.defaults;
    }
}
