import { CalculationMeta } from "../types";

export function defaultCalculationMeta(): CalculationMeta {
  return {
    reportingToLeaseCurrency: true,
    exchangeRate: null,
    marketRent: null,
    activeBreakOption: false,
    capitalisationRate: null,
    capitalisationRateJustification: null,
    estimatedVoidPeriod: null,
    estimatedVoidPeriodJustification: null,
    methodId: null,
    purchasersCost: null,
    timingId: null,
    growthRatePerYear: null,
    allRiskYieldJustification: null,
    allRiskYield: null,
    reversionaryYield: null,
    reversionaryYieldJustification: null,
    annualSinkingFund: null,
    annualSinkingFundJustification: null,
    annualSinkingFund2ndTerm: null,
    annualSinkingFund2ndTermJustification: null,
    taxRate: null,
    taxRateJustification: null,
    subLeaseActiveBreakOption: false,
    subLeaseEstimatedVoidPeriod: null,
    subLeaseEstimatedVoidPeriodJustification: null,
    subLeaseGrowthRatePerYear: null,
    securedRent: null,
    estimatedRentForLand: null,
    allRiskYieldForASF: null,
    allRiskYieldForASFJustification: null,
    allRiskYieldOnPerpetuity: null,
    allRiskYieldOnPerpetuityJustification: null,
    costOfRebuilding: null,
    estimatedBuildingTerminableLife: null
  }
}