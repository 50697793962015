import { CostRevenue } from "../../types";
import * as _ from 'lodash'
import { EffectiveRentUtil } from "../headline-rent";
import { durationCalculation } from "src/app/core/v2/valuation";
import { mapCost, totalNonRecoverable, totalRecoverable } from "../../utils";
import { calculationResult } from "../../_selectors";
import { groupCostRevenuesByPropertySubType } from "./utils";
import { convertedResultValue, convertValueIntoCountryCurrency } from "./converted-result-value";


export function avgVoidPeriod(items: CostRevenue[]): number {
  const validItems = items
    .filter(item => {
      if (item.calculationMeta == null) return false
      if (item.calculationMeta.methodId == 23) return false
      return true
    })
    .map(item => {
      if ([16, 18, 19, 20, 27, 28].includes(item.calculationMeta.methodId)) {
        return item.calculationMeta.estimatedVoidPeriod
      }
      if ([24, 25].includes(item.calculationMeta.methodId)) {
        return item.calculationMeta.subLeaseEstimatedVoidPeriod
      }
      return 0
    })

  const totalVoidPeriod = validItems.reduce(
    (acc, item) => acc + item,
    0
  )

  return (totalVoidPeriod / validItems.length) * 12
}

export function leasesAndRentInformation(
  items: CostRevenue[],
  sizes: {
    totalLeasedArea: number,
    tpSize: number
  },
  valuationDate: Date,
  countryCurrency: string
) {
  const grouped = groupCostRevenuesByPropertySubType(items)
  const columns = Object.entries(grouped).map(([key, values]) => {
    const mapped = values.map(item => {
      const effectiveRent = EffectiveRentUtil.getValue(item, sizes)
      const result = calculationResult(
        item,
        effectiveRent,
        sizes,
        valuationDate,
      )
      const sizeValue = item.kind === 'lease'
        ? item.leasedArea
        : item.size === 'total_lease'
          ? sizes.totalLeasedArea
          : item.size === 'tp'
            ? sizes.tpSize
            : item.sizeInput
      const recoverable = mapCost(totalRecoverable(item), item.expensesInputAmountType, sizeValue)
      const nonRecoverable = mapCost(totalNonRecoverable(item), item.expensesInputAmountType, sizeValue)
      const basisMultiplier = item.rentBasis
        ? item.rentBasis.id === 1
          ? 12
          : item.rentBasis.id === 2
            ? 4
            : 1
        : 1

      return {
        effectiveRent: convertValueIntoCountryCurrency(effectiveRent, item, countryCurrency),
        passingRent: convertValueIntoCountryCurrency(result.passingRent, item, countryCurrency),
        leaseDuration: durationCalculation(item.duration, item.durationType),
        rentFreePeriod: durationCalculation(item.rentFreePeriod, item.durationType),
        fittingOutPeriod: durationCalculation(item.fittingOutPeriod, item.durationType),
        rentReviewCycle: durationCalculation(item.rentReviewCycle, item.durationType),
        totalRecoverable: convertValueIntoCountryCurrency(recoverable * basisMultiplier, item, countryCurrency),
        totalNonRecoverable: convertValueIntoCountryCurrency(nonRecoverable * basisMultiplier, item, countryCurrency)
      }
    })
    const totalEffectiveRent = mapped.reduce((acc, item) => acc + item.effectiveRent, 0)
    const totalPassingRent = mapped.reduce((acc, item) => acc + item.passingRent, 0)
    const totalLeaseDuration = mapped.reduce((acc, item) => acc + item.leaseDuration, 0)
    const totalRentFreePeriod = mapped.reduce((acc, item) => acc + item.rentFreePeriod, 0)
    const totalFittingOutPeriod = mapped.reduce((acc, item) => acc + item.fittingOutPeriod, 0)
    const totalRentReviewCycle = mapped.reduce((acc, item) => acc + item.rentReviewCycle, 0)
    const totalAllRecoverable = mapped.reduce((acc, item) => acc + item.totalRecoverable, 0)
    const totalAllNonRecoverable = mapped.reduce((acc, item) => acc + item.totalNonRecoverable, 0)

    const avgFittingOutPeriod = totalFittingOutPeriod / values.filter(item => item.fittingOutPeriod !== null && item.fittingOutPeriod !== undefined && item.fittingOutPeriod !== 0).length;
    return {
      propertySubType: key,
      avgGrossRentPerYear: totalEffectiveRent / values.length,
      avgNetRentPerYear: totalPassingRent / values.length,
      avgLeaseDuration: totalLeaseDuration / values.filter(item => item.duration !== null && item.duration !== undefined && item.duration !== 0).length,
      avgRentFreePeriod: totalRentFreePeriod / values.filter(item => item.rentFreePeriod !== null && item.rentFreePeriod !== undefined && item.rentFreePeriod !== 0).length,
      avgFittingOutPeriod: isNaN(avgFittingOutPeriod)
        ? undefined
        : avgFittingOutPeriod,
      avgRentReviewCycle: totalRentReviewCycle / values.filter(item => {
        if (item.rentReviewType === null) return false
        return item.rentReviewCycle !== null && item.rentReviewCycle !== undefined && item.rentReviewCycle !== 0
      }).length,
      avgRecoverable: totalAllRecoverable / values.length,
      avgNonRecoverable: totalAllNonRecoverable / values.length
    }
  })

  return columns
}