import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AssetClassModel } from 'src/app/core/asset_class';
import { AssetClassTenure } from 'src/app/core/comparable';
import { ConsiderationCriterionService } from 'src/app/core/valuation/_services/consideration-criterion.service';
import { SLMethodResult } from '../../result-data-types';
import { StraightLineMethodData } from '../../straight-line-method-types';
import { calculateStraightLine, durationCalculation } from 'src/app/core/v2/valuation';
import { Store } from '@ngrx/store';
import { selectValuationProcessDefaultSizeCriterion } from 'src/app/core/valuation-process/_selectors/size-criterion.selectors';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Component({
  selector: 'kt-straightline-method',
  templateUrl: './straight-line-method.component.html',
  styleUrls: [
    './straight-line-method.component.scss',
    '../headline-to-effective.component.scss'
  ]
})
export class StraightlineMethodComponent implements OnInit {
  @Input() refNum: string
  @Input() tpCurrency: string;
  @Input() consideration: AssetClassTenure;
  @Output() valueComputed = new EventEmitter<SLMethodResult>();
  data$: Observable<StraightLineMethodData & {
    rent_input_amount_type: string;
    rent_basis_id: number,
    rent_basis_name: string,
    size: number
  }>;
  total_consideration: number = undefined;
  capital_payment: number = undefined;
  rent_free_period = 0
  fitting_out_period = 0
  constructor(
    private store: Store,
    private considerationCriterionService: ConsiderationCriterionService,
  ) { }

  ngOnInit(): void {
    this.rent_free_period = durationCalculation(this.consideration.rent_free_period, this.consideration.duration_type)
    this.fitting_out_period = durationCalculation(this.consideration.fitting_out_period, this.consideration.duration_type)
    this.data$ = this.store.select(selectValuationProcessDefaultSizeCriterion).pipe(
      map(defaultSize => {
        const result = calculateStraightLine(this.consideration, defaultSize.values[this.refNum])
        this.valueComputed.emit({
          calculatedValue: result.effectiveRent
        });
        return {
          ...result,
          rent_input_amount_type: this.consideration.rent_input_amount_type,
          rent_basis_id: this.consideration.rent_basis_id,
          rent_basis_name: this.consideration.rent_basis_name,
          size: defaultSize.values[this.refNum],
          total_consideration: this.consideration.total_consideration,
          capital_payment: this.consideration.cap_payment
        }
      })
    ) ;
  }

  isInValid(): boolean {
    return false;
  }
}