import { CostRevenue } from "../../types"

export function rounddown4(value: number) {
  return Math.floor(value * 10000) / 10000
}

export function round2(value: number) {
  return Math.round(value * 100) / 100
}

export function round(value: number) {
  if (value === null) return null
  if (value === undefined) return null
  return Math.round(value * 100) / 100
}

export function yieldPurchaseOnPerpetuityAnnually(capitalRate: number) {
  const rateInVal = capitalRate / 100
  if (rateInVal === 0) return undefined
  return rounddown4(1 / rateInVal)
}

export function yieldPurchaseOnPerpetuityQuarterly(capitalRate: number) {
  const rateInVal = capitalRate / 100
  let temp = 1 + rateInVal
  temp = Math.pow(temp, -0.25)
  temp = 1 - temp
  if (temp === 0) return undefined
  return rounddown4(1 / (4 * temp))
}

export function miniFs(years: Record<string, number>) {
  const value = Object.entries(years)
    .filter(([_, value]) => value > 0)
    .reduce<{key: string, value: number}>((min, [key, value]) => {
      if (min === null || value < min.value) {
        return {key, value}
      }
      return min
    }, null)

  return value == null ? {key: "N/A", value: null} : value
}

export function yieldPurchaseUntilReviewQuarterly(rate: number, year: number) {
  if (year === null) return undefined 
  const rateInVal = rate / 100;
  const onePlusRate = 1 + rateInVal;
  const value = (1 - Math.pow(onePlusRate, -year)) / (4 * (1 - Math.pow(onePlusRate, -0.25)))
  return rounddown4(value)
}

export function yieldPurchaseUntilReviewAnnually(rate: number, year: number) {
  if (year === null) return undefined
  const rateInVal = rate / 100;
  const onePlusRate = 1 + rateInVal;
  const value = (1 - Math.pow(onePlusRate, -year)) / rateInVal;
  return rounddown4(value)
}

export function groupCostRevenuesByPropertySubType(
  items: CostRevenue[]
) {
  return items.reduce(
    (acc, item) => {
      if (!item.propertySubType) return acc
      const propertySubType = item.propertySubType.name
      if (acc[propertySubType]) {
        return {
          ...acc,
          [propertySubType]: [...acc[propertySubType], item]
        }
      }
      return {
        ...acc,
        [propertySubType]: [item]
      }
    },
    {} as Record<string, CostRevenue[]>
  )
}