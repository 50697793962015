<div class="card card-custom section-margin-bottom-60">
    <div class="card-header size-header">
        <div class="card-title">
            <h5 class="card-label">
                <span>
                    {{ title$ | async }}
                </span>
            </h5>
            <mat-icon 
                style="cursor: pointer; display: flex; align-items: center;" 
                container="body" 
                [ngbPopover]="'TOOLTIP.VALUATION_PROCESS.SIZES_TABLE.TEXT' | translate"
                >
                help
            </mat-icon>
        </div>
    </div>
    <div class="card-body size-body">
        <ng-container *ngIf="viewModel$|async as viewModel">
            <div class="col-md-12 mat-table-wrapper">
                <table mat-table class="lmat-elevation-z8" [dataSource]="viewModel.dataSource"> 
                    <ng-container [matColumnDef]="'standard-' + viewModel.refNums.length +'-header'">
                        <th mat-header-cell *matHeaderCellDef >Measurement Standards</th>
                    </ng-container>
            
                    <ng-container *ngFor="let refNum of viewModel.refNums; let i = index" [matColumnDef]="'com-' + i + '-' + viewModel.refNums.length + '-header'">
                        <th mat-header-cell *matHeaderCellDef>
                            <span class="cell">
                                {{refNum}}
                                <span>
                                    <button mat-icon-button color="primary" (click)="onShowComparable(refNum)">
                                        <mat-icon>visibility</mat-icon>
                                    </button>
                                    <button mat-icon-button color="warn" (click)="onRemoveComparable(refNum)">
                                        <mat-icon>delete</mat-icon>
                                    </button>
                                </span>
                            </span>
                        </th>
                    </ng-container>
            
                    <ng-container [matColumnDef]="'tp-' + viewModel.refNums.length + '-header'">
                        <th mat-header-cell *matHeaderCellDef>
                            Target Property
                            <button mat-icon-button color="primary" (click)="onShowComparableOverview(viewModel.refNums)">
                                <mat-icon>visibility</mat-icon>
                            </button>
                        </th>
                    </ng-container>
            
                    <ng-container matColumnDef="action-header">
                        <th mat-header-cell *matHeaderCellDef>Action</th>
                    </ng-container>
            
                    <!-- Cells -->
                    <ng-container [matColumnDef]="'criterion-' + viewModel.refNums.length">
                        <td mat-cell *matCellDef="let criterion">
                            {{ criterion.publicName }}
                        </td>
                    </ng-container>
            
            
                    <ng-container *ngFor="let refNum of viewModel.refNums; let i = index" [matColumnDef]="'com-' + i + '-' + viewModel.refNums.length">
                        <td mat-cell *matCellDef="let criterion; let j = index">
                            <input class="inline-input" [value]="criterion.values[refNum]" (focusout)="onFocusOut($event, criterion, refNum, viewModel.rates[refNum])" />
                        </td>
                    </ng-container>
            
                    <ng-container [matColumnDef]="'tp-' + viewModel.refNums.length">
                        <td mat-cell *matCellDef="let criterion; let j = index">
                            <input class="inline-input" [value]="criterion.values['tp']" (focusout)="onFocusOut($event, criterion, 'tp', 0)">
                        </td>
                    </ng-container>
            
                    <ng-container matColumnDef="actions">
                        <td mat-cell *matCellDef="let criterion; let j = index">
                            <button 
                                mat-icon-button 
                                (click)="onSetDefaultSizeCriterion(criterion)">
                                <mat-icon class="blue" *ngIf="!criterion.active">add_circle</mat-icon>
                                <mat-icon class="green" *ngIf="criterion.active">check_circle</mat-icon>
                            </button>
                        </td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="headerColumns$ | async"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns$ | async; let i = index"
                        [ngClass]="{gray: i%2}"></tr>
                </table>
            </div>
        </ng-container>
    </div>
</div>