import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Store } from '@ngrx/store';
import { BehaviorSubject, combineLatest, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { AppState } from 'src/app/core/reducers';
import { ValuationProcessSetDefaultSizeCriterion, ValuationProcessUpdateSizeCriterion } from 'src/app/core/valuation-process/_actions/size_criterion.actions';
import { SizeCriterionModel } from 'src/app/core/valuation-process/_models';
import { selectValuationProcessSizeCriterions } from 'src/app/core/valuation-process/_selectors';
import { selectValuationProcessConsiderationCriterion } from 'src/app/core/valuation-process/_selectors/consideration-criterion.selector';
import { selectValuationProcessSelectedComparables, selectValuationProcessSelectedComparablesFull, selectValuationProcessTargetPropertyInfo, selectValuationProcessTargetPropertyUnitMeasurement } from 'src/app/core/valuation-process/_selectors/valuation-process.selectors';

@Component({
  selector: 'kt-sizes-table-v2',
  templateUrl: './sizes-table-v2.component.html',
  styleUrls: ['./sizes-table-v2.component.scss']
})
export class SizesTableV2Component implements OnInit {
  @Output() removeComparable: EventEmitter<{refNum: string}> = new EventEmitter();
  @Output() showComparable: EventEmitter<{refNum: string}> = new EventEmitter();
  @Output() showComparableOverview: EventEmitter<{refNums: string[]}> = new EventEmitter();

  title$ = this.store$.select(selectValuationProcessTargetPropertyUnitMeasurement).pipe(
    map(unitMeasurement => {
      if (!unitMeasurement) {
        return 'Sizes'
      }
      return `Sizes (${unitMeasurement})`
    })
  );
  headerColumns$: BehaviorSubject<string[]> = new BehaviorSubject([]); 
  displayedColumns$: BehaviorSubject<string[]> = new BehaviorSubject([]);

  viewModel$ = combineLatest([
    this.store$.select(selectValuationProcessSelectedComparables),
    this.store$.select(selectValuationProcessSizeCriterions),
    this.store$.select(selectValuationProcessConsiderationCriterion),
    this.store$.select(selectValuationProcessSelectedComparablesFull),
    this.store$.select(selectValuationProcessTargetPropertyInfo),
  ]).pipe(
    map(([refNums, criterions, conCrits, comparables, tp]) => {
      const rates = comparables 
        .map(com => {
          const crit = conCrits.values[com.refNum]
          if (!crit) return null
          if (crit.consideration.considerationType === 'Land') return null
          let c: number = null
          for (const con of com.considerations) {
            if (con.id === crit.consideration.oldTenure.id) {
              if (con.currency === tp.countryCurrency) {
                c = 1
              } else {
                const conv = con.conversion.find(_conv => _conv.quoteCurrency === tp.countryCurrency)
                if (conv) {
                  c = conv.exchangeRate
                }
              }
              break;
            }
          }
          return {
            refNum: com.refNum,
            exchangerate: c
          }
        })
        .reduce((acc, item) => {
          acc[item.refNum] = item.exchangerate
          return acc
        }, {})
      this._setHeaders(refNums)
      const dataSource = new MatTableDataSource()
      dataSource.data = criterions 
      return {
        refNums,
        dataSource: dataSource,
        rates
      }
    })
  )

  constructor(
    private store$: Store<AppState>
  ) { }

  ngOnInit(): void {
  }

  onShowComparable(refNum: string) {
    this.showComparable.emit({refNum})
  }

  onShowComparableOverview(refNums: string[]) {
    this.showComparableOverview.emit({refNums})
  }

  onRemoveComparable(refNum: string) {
    this.removeComparable.emit({refNum})
  }

  private _setHeaders(refNums: string[]) {
    const headerColumns = [`standard-${refNums.length}-header`];
    const displayedColumns = [`criterion-${refNums.length}`];
    const comparableHeaderColumns = [];
    refNums.forEach((com, i) => {
      headerColumns.push(`com-${i}-${refNums.length}-header`);
      comparableHeaderColumns.push(`com-${i}-${refNums.length}-header`);
      displayedColumns.push(`com-${i}-${refNums.length}`);
    });
    headerColumns.push(`tp-${refNums.length}-header`, 'action-header');
    displayedColumns.push(`tp-${refNums.length}`, 'actions');
    this.headerColumns$.next(headerColumns);
    this.displayedColumns$.next(displayedColumns);
  }

  onFocusOut(event: any, criterion: SizeCriterionModel, refNum: string, rate: number) {
    const target = event.target as HTMLInputElement
    const val = Number(target.value)
    this.store$.dispatch(new ValuationProcessUpdateSizeCriterion({
      criterion,
      value: val,
      refNum,
      exchangeRate: rate
    }))
  }

  onSetDefaultSizeCriterion(criterion: SizeCriterionModel) {
    if (criterion.active) {
      return
    }
    this.store$.dispatch(new ValuationProcessSetDefaultSizeCriterion({criterion}))
  }

}
