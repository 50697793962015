import { Update } from "@ngrx/entity";
import { CurrencyExchange } from "../_models/currency-exchange";
import { VComparable } from "../_models";

export function setCurrencyExchange(
  comparable: VComparable,
  considerationId: number,
  currencyExchange: CurrencyExchange
): Update<VComparable> {
  return {
    id: comparable.refNum,
    changes: {
      considerations: comparable.considerations.map(consideration => {
        if (consideration.id != considerationId) return {...consideration}
        return {
          ...consideration,
          conversion: setConversions(consideration.conversion, currencyExchange)
        }
      })
    }
  }
}

function setConversions(
  conversions: {id: number, quoteCurrency: string, exchangeRate: number}[], 
  currencyExchange: CurrencyExchange
) {
  if (currencyExchange.id) {
    return conversions.map(conversion => {
      if (conversion.id !== currencyExchange.id) return {...conversion}
      return {
        ...conversion,
        quoteCurrency: currencyExchange.quote_currency,
        exchangeRate: currencyExchange.exchange_rate
      }
    })
  }

  const conversion = conversions.find(conv => conv.quoteCurrency === currencyExchange.quote_currency)
  if (conversion) {
    return conversions.map(conversion => {
      if (conversion.quoteCurrency !== currencyExchange.quote_currency) return {...conversion}
      return {
        ...conversion,
        quoteCurrency: currencyExchange.quote_currency,
        exchangeRate: currencyExchange.exchange_rate
      }
    })
  }

  return [...conversions, {id: undefined, quoteCurrency: currencyExchange.quote_currency, exchangeRate: currencyExchange.exchange_rate}]
}