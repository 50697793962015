import { HttpEvent, HttpEventType } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { Observable, of, Subject } from 'rxjs';
import { catchError, concatMap, takeUntil, tap } from 'rxjs/operators';
import { FileUploadService } from 'src/app/core/file-upload';
import * as fromFileUploadState from '../../../../core/file-upload/_states/file-upload.state';

@Component({
  selector: 'kt-upload-wrapper',
  templateUrl: './upload-wrapper.component.html',
  styleUrls: ['./upload-wrapper.component.scss'],
  providers: [ComponentStore]
})
export class UploadWrapperComponent implements OnInit {
  @Input() addButtonLabel: string = 'Add';
  @Input() fileType: string = '.pdf, image/png, image/jpeg, image/jpg, application/pdf';
  @Input() fileFolder: string;
  @Input() hasPrefix: boolean = false;
  @Output() fileUploaded: EventEmitter<any> = new EventEmitter();
  @Output() pathChanged: EventEmitter<any> = new EventEmitter();
  @ViewChild('fileInput') fileInput;

  progress$: Observable<number>;
  isInProgress$: Observable<boolean>;
  error$: Observable<string>;
  hasFailed$: Observable<boolean>;
  completed$: Observable<boolean>;
  isReady$: Observable<boolean>;
  res$: Observable<any>;

  fileName: string;
  fileTypes = ['pdf',
    'png',
    'jpeg',
    'jpg',
    'jp2',
    'png'];

  cancel$ = new Subject<void>();

  constructor(
    private readonly store$: ComponentStore<fromFileUploadState.State>,
    private service: FileUploadService
  ) {
    this.store$.setState(fromFileUploadState.initialState);
  }

  ngOnInit(): void {
    this.fileName = '';
    this.completed$ = this.selectUploadFileCompleted$;
    this.progress$ = this.selectUploadFileProgress$;
    this.error$ = this.selectUploadFileError$;
    this.isInProgress$ = this.selectUploadFileInProgress$;
    this.isReady$ = this.selectUploadFileReady$;
    this.hasFailed$ = this.selectUploadFileFailed$;
    this.res$ = this.selectUploadFileRes$;

    this.res$.subscribe(res => {
      if (res && res.uploaded_files && res.uploaded_files.length > 0) {
        this.fileUploaded.emit(res.uploaded_files[0]);
        this.pathChanged.emit(res.success);
      }
    })
  }

  addFile() {
    this.fileInput.nativeElement.click();
  }

  uploadFile(event: any) {
    const files: FileList = event.target.files;
    const firstFile = files.item(0);
    this.fileName = firstFile.name;
    const formData = new FormData();
    let wrongExt = false;
    Array.from(files).forEach((file, i) => {
      const ext = file.name.split('.').pop().toLowerCase();
      if (!this.fileTypes.find(e => e === ext)) {
        wrongExt = true;
      }
      formData.append('files[' + i + ']', file, file.name);
    });
    if (wrongExt) {
      alert(`Please upload "${this.fileTypes.join(', ')}" files only.`);
      return;
    }
    if (this.fileFolder) {
      formData.append('path', this.fileFolder);
    }
    if (this.hasPrefix) {
      formData.append('has_prefix', 'true');
    }

    this.requestUpload(formData);

    // clear the input form
    event.srcElement.value = null;
  }

  cancelUpload() {
    this.fileName = '';
    this.cancel$.next();
    this._cancelUpload();
  }

  // Store
  private getError = (state: fromFileUploadState.State): string => state.error;
  private getStarted = (state: fromFileUploadState.State): boolean => state.status === fromFileUploadState.UploadStatus.Started;
  private getRequested = (state: fromFileUploadState.State): boolean => state.status === fromFileUploadState.UploadStatus.Requested;
  private getReady = (state: fromFileUploadState.State): boolean => state.status === fromFileUploadState.UploadStatus.Ready;
  private getProgress = (state: fromFileUploadState.State): number => state.progress;
  private getInProgress = (state: fromFileUploadState.State): boolean => state.status === fromFileUploadState.UploadStatus.Started && state.progress >= 0;
  private getFailed = (state: fromFileUploadState.State): boolean => state.status === fromFileUploadState.UploadStatus.Failed;
  private getCompleted = (state: fromFileUploadState.State): boolean => state.status === fromFileUploadState.UploadStatus.Completed;
  private getRes = (state: fromFileUploadState.State): object => state.res;

  // Selectors
  readonly selectUploadFileCompleted$ = this.store$.select(this.getCompleted);
  readonly selectUploadFileProgress$ = this.store$.select(this.getProgress);
  readonly selectUploadFileFailed$ = this.store$.select(this.getFailed);
  readonly selectUploadFileRes$ = this.store$.select(this.getRes);
  readonly selectUploadFileStarted$ = this.store$.select(this.getStarted);
  readonly selectUploadFileError$ = this.store$.select(this.getError);
  readonly selectUploadFileReady$ = this.store$.select(this.getReady);
  readonly selectUploadFileRequested$ = this.store$.select(this.getRequested);
  readonly selectUploadFileInProgress$ = this.store$.select(this.getInProgress);

  // Actions
  readonly _requestUpload = this.store$.updater((state) => ({
    ...state,
    status: fromFileUploadState.UploadStatus.Requested,
    progress: null,
    error: null,
    res: null,
  }))
  readonly _cancelUpload = this.store$.updater((state) => ({
    ...state,
    status: fromFileUploadState.UploadStatus.Ready,
    progress: null,
    error: null,
    res: null,
  }))
  readonly _resetUpload = this.store$.updater((state) => ({
    ...state,
    status: fromFileUploadState.UploadStatus.Ready,
    progress: null,
    error: null,
    res: null
  }))
  readonly startedUpload = this.store$.updater((state) => ({
    ...state,
    status: fromFileUploadState.UploadStatus.Started,
    progress: 0
  }))
  readonly progressUpload = this.store$.updater((state, progress: number) => ({
    ...state,
    progress
  }))
  readonly failureUpload = this.store$.updater((state, error: string) => ({
    ...state,
    status: fromFileUploadState.UploadStatus.Failed,
    error,
    progress: null,
    res: null
  }))
  readonly completedUpload = this.store$.updater((state, res: object) => ({
    ...state,
    status: fromFileUploadState.UploadStatus.Completed,
    progress: 100,
    error: null,
    res
  }))

  // Effects
  readonly requestUpload = this.store$.effect((formData$: Observable<FormData>) => {
    return formData$.pipe(
      tap((_) => this._requestUpload()),
      concatMap((data) => this.service.uploadFile(data).pipe(
        takeUntil(
          this.cancel$
        ),
        tap(event => this.getActionFromHttpEvent(event)),
        catchError(error => of(this.handleError(error)))
      ))
    )
  })

  private getActionFromHttpEvent(event: HttpEvent<any>) {
    switch (event.type) {
      case HttpEventType.Sent: {
        this.startedUpload();
        return;
      }
      case HttpEventType.UploadProgress: {
        this.progressUpload(Math.round((100 * event.loaded) / event.total));
        return;
      }
      case HttpEventType.ResponseHeader:
      case HttpEventType.Response: {
        if (event.status === 200) {
          let response_body;
          if (event.type == HttpEventType.Response) {
            response_body = event.body;
          }
          this.completedUpload(response_body);
          return;
        } else {
          if (event.status === 400) {

            /*  400 Bad Request
            The server cannot or will not process the request due to an
            apparent client error (e.g., malformed request syntax, size too large,
            invalid request message framing, or deceptive request routing)
            */
            this.failureUpload(`Bad Request, Please check your form data.`)
            return;
          }

          if (event.status === 403) {

            /*  403 Forbidden
              The request contained valid data and was understood by the server, but the server is refusing action. This may be due to the user not having the necessary permissions for a resource or needing
              an account of some sort, or attempting a prohibited action (e.g. creating a duplicate record where only one is allowed). This code is also typically used if the request provided
              authentication via the WWW-Authenticate header field, but the server did not accept that authentication. The request should not be repeated.
            */

            this.failureUpload(`The Server is refusing action, Please check your form data.`)
            return;
          }


          if (event.status === 404) {

            /*  404 Not Found
                The requested resource could not be found but may be available in the future. Subsequent requests by the client are permissible.
            */
            this.failureUpload(`Not Found, API have not developed yet.`)
            return;
          }

          if (event.status === 500) {

            /*  500 Internal Server Error
            The server cannot or will not process the request due to an
            apparent client error (e.g., malformed request syntax, size too large,
            invalid request message framing, or deceptive request routing)
            */
            this.failureUpload(`Internal Server Error, Please inform us by email.`)
            return;
          }

          if (event.status >= 500) {
            this.failureUpload(`Server Error ${event.status}, Please inform us by email.`)
            return;
          }

          if (event.status >= 400) {
            this.failureUpload(`Client Error ${event.status}, Please check your inserted data`)
            return;
          }

          this.failureUpload(`Request Error ${event.status}, Please check action`)
          return;
        }
      }
      default: {
        this.failureUpload(`Unknown Event: ${JSON.stringify(event)}`)
        return;
      }
    }
  }

  private handleError(error: any) {
    this.failureUpload('Too large file. Max file upload is: 16mb.')
    return;
  }
}
