import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSelectChange } from '@angular/material/select';
import { MatTableDataSource } from '@angular/material/table';
import { select, Store } from '@ngrx/store';
import { combineLatest, Observable, ReplaySubject, Subject, Subscription } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { AllApproachesToValuesRequested, AllBaseOfValuesRequested, AllLandTenuresRequested, AllMethodsToValuesRequested, AllPremiseOfValuesRequested, AllTenureRequested, ApproachesToValue, BaseOfValue, MethodsToValue, PremiseOfValue, selectAllApproachesToValues, selectAllBaseOfValues, selectAllLandTenures, selectAllMethodsToValues, selectAllPremiseOfValues, selectAllTenure } from 'src/app/core/linked-tables';
import { PropertyTypes } from 'src/app/core/linked-tables/_models/top-property-type.model';
import { AppState } from 'src/app/core/reducers';
import { ITpValuation } from 'src/app/core/template/_models/tp-template.model';
import { TenureType } from 'src/app/views/pages/shared_components/ac-valuation/eventtype';
import { AddValuationModalComponent } from './add-valuation-modal/add-valuation-modal.component';
import { ConsiderationTypeService } from 'src/app/core/v2/services';
import { OutsetaPlanService } from 'src/app/core/mad-auth/outseta-plan.service';

@Component({
  selector: 'kt-ac-valuation',
  templateUrl: './ac-valuation.component.html',
  styleUrls: ['./ac-valuation.component.scss']
})
export class AcValuationComponent implements OnInit, OnDestroy {
  @Input() readonly: boolean;
  @Input() customTp$: Observable<boolean>;
  @Input() valuations$: Observable<ITpValuation[]>
  @Input() propertyType$: Observable<number>;
  @Input() baseOfValueId$: Observable<number>;
  @Output() onValuationAdded: EventEmitter<ITpValuation> = new EventEmitter();
  @Output() onValuationEdited: EventEmitter<{valuation: ITpValuation, index: number}> = new EventEmitter();
  @Output() onValuationRemoved: EventEmitter<number> = new EventEmitter();

  dataSource = new MatTableDataSource<ITpValuation>();
  displayedColumns = [
    'base_of_value_name',
    'premise_of_value_name', 
    'approaches_to_value_name', 
    'methods_to_value_name', 
    'tenure', 
    'consideration_type',
    'actions'
    ];
  private _onDestroy$: Subject<void> = new Subject();
  tenureType$: Observable<TenureType>;
  tenureType = TenureType;
  tenures: any[] = [];
  landTenures: any[] = [];
  premiseOfValues: PremiseOfValue[] = [];
  methodsToValues: MethodsToValue[];
  approachesToValues: ApproachesToValue[];
  baseOfValues: BaseOfValue[]
  public filteredMethodsToValuesData: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
  public filteredApproachesToValuesData: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
  private valuations: ITpValuation[] = [];

  subscriptions: Subscription[] = [];
  methodsToValueMap = {}

  considerationTypes$ = this.considerationTypeService.fetchListing()

  constructor(
    private dialog: MatDialog,
    private store: Store<AppState>,
    private considerationTypeService: ConsiderationTypeService,
    private outsetaPlanService: OutsetaPlanService
  ) { }

  ngOnInit(): void {
    this.valuations$.pipe(takeUntil(this._onDestroy$)).subscribe(data => {
      this.valuations = data;
    //   this.displayColumns(this.valuations);
      this.dataSource.data = this.valuations;
    })
    this.tenureType$ = this.propertyType$.pipe(map(propertyType => {
        if (propertyType == PropertyTypes.Commercial) {
            return TenureType.Default;
        }
        if (propertyType == PropertyTypes.Residential) {
            return TenureType.Default;
        }
        if (propertyType == PropertyTypes.Land) {
            return TenureType.Land;
        }
        if (propertyType == PropertyTypes.Custom) {
            return TenureType.Custom;
        }
        return TenureType.Default;
    }))

    this.store.dispatch(new AllBaseOfValuesRequested())
    const baseOfValueSubscribe = this.store.pipe(
        select(selectAllBaseOfValues)
    ).subscribe(res => {
        this.baseOfValues = []
        if (res) {
            this.baseOfValues = res
        }
    })
    this.subscriptions.push(baseOfValueSubscribe)

      this.store.dispatch(new AllTenureRequested())
      const tenureSub = combineLatest([
          this.store.select(selectAllTenure),
          this.baseOfValueId$
      ]).subscribe(([tenures, baseOfValueId]) => {
          this.tenures = [];
          if (tenures) {
              this.tenures = tenures.map(t => ({
                  ...t,
                  disabled: baseOfValueId == 2 && t.id != 1
              }))
          }
      });    
      this.subscriptions.push(tenureSub);

      this.store.dispatch(new AllLandTenuresRequested())
      const landTenureSub = this.store.select(selectAllLandTenures).subscribe(tenures => {
          this.landTenures = [];
          if (tenures) {
              this.landTenures = tenures;
          }
      })
      this.subscriptions.push(landTenureSub);

      this.store.dispatch(new AllPremiseOfValuesRequested())
      const premiseOfValueSub = this.store.select(selectAllPremiseOfValues).subscribe(res => {
          if (!res) {
              this.premiseOfValues = [];
              return;
          }
          this.premiseOfValues = res;
      });
      this.subscriptions.push(premiseOfValueSub);

    //   this.store.dispatch(new AllMethodsToValuesRequested());
    //   const methodsToValuesSubscribe = combineLatest([
    //       this.store.pipe(
    //       select(selectAllMethodsToValues)),
    //       this.customTp$
    //   ]).subscribe(([res, customTp]) => {
    //           this.methodsToValues = [];
    //           if (res) {
    //               this.methodsToValues = res;
    //               const methodsToValues = this.methodsToValues.map(mtv => {
    //                   const disabled = !(customTp || (mtv.id == 2 || mtv.id == 9));
    //                   return {
    //                       ...mtv,
    //                       disabled,
    //                       text: disabled ? `${mtv.name} (Coming soon)` : mtv.name
    //                   }
    //               })
    //               this.filteredMethodsToValuesData.next(methodsToValues);
    //           }
    //       });
    //   this.subscriptions.push(methodsToValuesSubscribe);

      this.store.dispatch(new AllApproachesToValuesRequested());
      const approachesToValuesSubscribe = combineLatest([
          this.store.pipe(select(selectAllApproachesToValues)),
          this.outsetaPlanService.allowedApproaches(),
          this.outsetaPlanService.allowedMethods()
      ]).subscribe(([res, allowedApproaches, allowedMethods]) => {
          this.approachesToValues = []
          if (res) {
              this.approachesToValues = res.filter(item => allowedApproaches.includes(item.id))
              this.approachesToValues.forEach(item => {
                  this.methodsToValueMap[item.id] = item.methods_to_value
                      .filter(mtv => allowedMethods.includes(mtv.id))
                      .map(mtv => {
                          return {
                              ...mtv,
                              text: mtv.name
                          }
                      })
              })
              this.filteredApproachesToValuesData.next(this.approachesToValues)
          }
      })
      this.subscriptions.push(approachesToValuesSubscribe);
  }

  ngOnDestroy() {
    this._onDestroy$.next();
    this._onDestroy$.complete();
    this.subscriptions.forEach(s => s.unsubscribe());
  }

liquidationValueAlreadyExists() {
    return this.valuations.findIndex(item => item.base_of_value_id == 7) > -1
}

  addItem() {
    // const dialogRef = this.dialog.open(AddValuationModalComponent, {data: {
    //   customTp$: this.customTp$,
    //   tenureType$: this.tenureType$,
    //   baseOfValueId$: this.baseOfValueId$
    // }});
    // dialogRef.afterClosed().subscribe(res => {
    //     if (!res) {
    //         return;
    //     }
    //     const temp: ITpValuation = Object.assign({}, res);
    //     this.onValuationAdded.emit(temp)
    // });
    const temp: ITpValuation = {
        id: undefined,
        methods_to_value_id: undefined,
        methods_to_value_name: null,
        approaches_to_value_id: undefined,
        approaches_to_value_name: null,
        tenure_id: undefined,
        tenure_custom: null,
        premise_of_value_id: undefined,
        base_of_value_id: undefined,
        consideration_type_id: undefined
    }
    this.onValuationAdded.emit(temp);
  }
  deleteItem(index: number) {
    this.onValuationRemoved.emit(index);
  }

//   displayColumns(valuations: ITpValuation[]) {
//       const val = valuations.find(val => val.interest_valued < 100);
//       if (val) {
//           this.displayedColumns = ['premise_of_value_name', 'approaches_to_value_name', 'methods_to_value_name', 'tenure', 'actions'];
//           return;
//       }
//       this.displayedColumns = ['premise_of_value_name', 'approaches_to_value_name', 'methods_to_value_name', 'tenure', 'actions'];
//   }

  seeData() {}

  selectionChange(event: MatSelectChange, index: number, field: string) {
    const val = this.valuations[index];
    const tmp = Object.assign({}, val);
    tmp[field] = event.value;
    this.onValuationEdited.emit({valuation: tmp, index});
  }

  inputChange(event, index: number, field: string) {
    const val = this.valuations[index];
    const tmp = Object.assign({}, val);
    let value = event.target.value ? event.target.value.trim() : '';
    switch (field) {
        case 'interest_valued': {
            if (value.length > 0) {
                value = value.split('%')[0];
                value = Number(value);
            }
            break;
        }
        case 'discount': {
            if (value.length > 0) {
                value = value.split('%')[0];
                value = Number(value);
            } 
            break;
        }
    }
    tmp[field] = value;
    this.onValuationEdited.emit({valuation: tmp, index});
  }

}
