import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { delay, map } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { MadAuthService } from "../../mad-auth/mad-auth.service";
import { HttpUtilsService } from "../../_base/crud";
import { ValuationComparableFilter } from "../_actions/valuation-comparable.actions";
import { mapApartmentInternalAspectRemoteToDomain, mapVApartmentGroundRemoteToDomain, VApartmentComparableRemote, VCApartmentModel } from "../_models/valuation-apartment.model";
import { displayConsiderations, mapConsiderationRemoteToDomain, mapSourceRemoteToDomain } from "../_models/valuation-asset-class-common.models";
import { ValuationComparableMapData, VComparable} from "../_models/valuation-comparable.model";
import { mapVCHouseRemoteToDomain, VCHouseRemote } from "../_models/valuation-house.model";
import { mapVCLandRemoteToDomain, VCLandRemote } from "../_models/valuation-land.model";
import { mapComparableOfficeRemoteToDomain, VCOfficeRemote } from "../_models/valuation-office.model";
import { mapVCParkingRemoteToDomain, VCParkingRemote } from "../_models/valuation-parking.model";
import { mapVCRetailBuildingRemoteToDomain, VCRetailBuildingModel, VCRetailBuildingRemote } from "../_models/valuation-retail-building.model";
import { mapVCRetailShopRemoteToDomain, VCRetailShopRemote } from "../_models/valuation-retail-shop.model";
import { mapVCWarehouseRemoteToDomain, VCWarehouseRemote } from "../_models/valuation-warehouse.model";
import ValuationUtils from "../_utils/map";
import { SubdomainService } from "../../_base/subdomain.service";


@Injectable()
export class ValuationComparableService {
    API_URL = environment.baseApiUrl + `api/${this.subDomainService.subDomain}/valuation-process`
    constructor(
        private http: HttpClient,
        private authService: MadAuthService,
        private subDomainService: SubdomainService
    ) {}

    load(filter: ValuationComparableFilter): Observable<ValuationComparableMapData[]> {
        const headers = this.authService.getAuthHeaders()
        const url = `${this.API_URL}/comparables`
        return this.http.post<{data: ValuationComparableRemoteData[]}>(url, {filter}, {headers})
            .pipe(
                map(response => response.data),
                map(response => {
                    return response.map(res => mapRemoteToDomain(res)).filter(item => item != null)
                })
            )
    }

    loadComparables(refNums: string[]): Observable<VComparable[]> {
        const headers = this.authService.getAuthHeaders()
        const url = `${this.API_URL}/comparables`
        const params = new HttpParams().set('refNums', refNums.join(','))
        return this.http.get<{data: ValuationComparableFullRemoteData[]}>(url, {headers, params: params})
            .pipe(
                // delay(2000),
                map(response => response.data),
                map(response => {
                    return response.map(res => mapFullRemoteToDomain(res)).filter(item => item != null)
                })
            )
    }
}

type ValuationComparableRemoteData = {
    id: number,
    refNum: string,
    propertySubTypeId: 1 | 3 | 17 | 5 | 11 | 7 | 2,
    latitude: string,
    longitude: string
} | {
    id: number,
    refNum: string,
    propertySubTypeId: 13,
    land_paths: any[]
}

function mapRemoteToDomain(comparable: ValuationComparableRemoteData): ValuationComparableMapData | null {
    switch (comparable.propertySubTypeId) {
        case 1:
            return {
                id: comparable.id,
                refNum: comparable.refNum,
                propertySubType: 'Apartment',
                latitude: Number(comparable.latitude),
                longitude: Number(comparable.longitude)
            }
        case 17: {
            return {
                id: comparable.id,
                refNum: comparable.refNum,
                propertySubType: 'House',
                latitude: Number(comparable.latitude),
                longitude: Number(comparable.longitude)
            }
        }
        case 3: {
            return {
                id: comparable.id,
                refNum: comparable.refNum,
                propertySubType: 'Office',
                latitude: Number(comparable.latitude),
                longitude: Number(comparable.longitude)
            }
        }
        case 5: {
            return {
                id: comparable.id,
                refNum: comparable.refNum,
                propertySubType: 'Retail Shop',
                latitude: Number(comparable.latitude),
                longitude: Number(comparable.longitude)
            }
        }
        case 11: {
            return {
                id: comparable.id,
                refNum: comparable.refNum,
                propertySubType: 'Retail Building',
                latitude: Number(comparable.latitude),
                longitude: Number(comparable.longitude)
            }
        }
        case 7: {
            return {
                id: comparable.id,
                refNum: comparable.refNum,
                propertySubType: 'Warehouse',
                latitude: Number(comparable.latitude),
                longitude: Number(comparable.longitude)
            }
        }
        case 2: {
            return {
                id: comparable.id,
                refNum: comparable.refNum,
                propertySubType: 'Parking',
                latitude: Number(comparable.latitude),
                longitude: Number(comparable.longitude)
            }
        }
        case 13: {
            const center = ValuationUtils.getCenter(comparable.land_paths)
            return {
                id: comparable.id,
                refNum: comparable.refNum,
                propertySubType: 'Land',
                latitude: center.lat,
                longitude: center.lng
            }
        }
        default:
            return null
    }
}

type ValuationComparableFullRemoteData 
    = VApartmentComparableRemote
    | VCHouseRemote
    | VCOfficeRemote
    | VCRetailShopRemote
    | VCRetailBuildingRemote
    | VCWarehouseRemote
    | VCParkingRemote
    | VCLandRemote
   

export function mapFullRemoteToDomain(comparable: ValuationComparableFullRemoteData): VComparable | null {
    switch (comparable.property_sub_type_id) {
        case 1:
            const considerations = mapConsiderationRemoteToDomain(comparable.considerations)
            const data: VCApartmentModel = {
                id: comparable.id,
                refNum: comparable.refNum,
                propertySubType: 'Apartment',
                propertySubTypeName: comparable.propertySubTypeName,
                propertyTypeName: comparable.topPropertyTypeName,
                propertyInfo: {
                    bedrooms: comparable.propertyInfo.bedrooms,
                    bathrooms: comparable.propertyInfo.bathrooms,
                    accomodation: comparable.propertyInfo.accommodation
                },
                locationData: {
                    latitude: Number(comparable.locationData.latitude),
                    longitude: Number(comparable.locationData.longitude),
                    country: comparable.locationData.countryName,
                    city: comparable.locationData.cityName,
                    zipCode: comparable.locationData.zipCode,
                    address: comparable.locationData.address,
                    locationGrade: comparable.locationData.locationGradeName,
                    locationSurrounding: null,
                    timezoneOffset: comparable.locationData.timezoneOffset
                },
                internalAspect: mapApartmentInternalAspectRemoteToDomain(comparable.internalAspect),
                property: comparable.property,
                building: comparable.buildingInfo,
                sizes: comparable.sizes.map(size => ({
                    standardMeasurementId: size.standardMeasurementId,
                    standardMeasurementName: size.standard_measurement_name,
                    unitOfAreaMeasurementId: size.unitOfAreaMeasurementId,
                    unitOfAreaMeasurementAcronym: size.unit_of_area_measurement_acronym,
                    size: size.size 
                })),
                considerations,
                displayConsiderations: displayConsiderations(considerations),
                pictures: comparable.pictures,
                status: comparable.status,
                usedTimes: comparable.usedTimes,
                oldBuildingInfo: comparable.oldBuildingInfo,
                ground: mapVApartmentGroundRemoteToDomain(comparable.ground)
            }
            return data
        case 3: {
            return mapComparableOfficeRemoteToDomain(comparable)
        }
        case 17: {
            return mapVCHouseRemoteToDomain(comparable) 
        }
        case 5: {
            return mapVCRetailShopRemoteToDomain(comparable)
        }
        case 11: {
            return mapVCRetailBuildingRemoteToDomain(comparable)
        }
        case 7: {
            return mapVCWarehouseRemoteToDomain(comparable)
        }
        case 2: {
            return mapVCParkingRemoteToDomain(comparable)
        }
        case 13: {
            return mapVCLandRemoteToDomain(comparable)
        }
        default:
            return null
    }
}

