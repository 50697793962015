import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { AcDocumentUploadComponent } from '../../../shared_components/ac-document-upload/ac-document-upload.component';
import { PropertyObservationChecklistComponent } from '../../../shared_components/property-observation-checklist/property-observation-checklist.component';

@Component({
  selector: 'kt-inspection-observation',
  templateUrl: './inspection-observation.component.html',
  styleUrls: ['./inspection-observation.component.scss']
})
export class InspectionObservationComponent implements OnInit {
  @Input() propertyTypeID: number;
  @Input() observationChecklist: {
    table1: any[];
    table2: any[];
    otherNotes: string;
  } 
  @Input() observationDocuments: any[];
  @Input() recordInfoType: number;
  @ViewChild(PropertyObservationChecklistComponent, {static: false}) checkListComponent: PropertyObservationChecklistComponent;
  @ViewChild(AcDocumentUploadComponent, {static: false}) documentUploadComponent: AcDocumentUploadComponent;
  form: UntypedFormGroup;

  constructor(
    private formBuilder: UntypedFormBuilder
  ) { }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      record_info_type: [this.recordInfoType]
    });
  }

  getData(): {
    record_info_type: number,
    checkList: any,
    documents: any[]
  } {
    return {
      record_info_type: this.form.controls.record_info_type.value,
      checkList: this.checkListComponent ? this.checkListComponent.getData() : null,
      documents: this.documentUploadComponent ? this.documentUploadComponent.getDocuments() : []
    }
  }

}
