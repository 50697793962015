import { CurrencyExchange } from "../../valuation-process/_models/currency-exchange"
import { VAssetClassConsideration } from "../../valuation-process/_models/valuation-asset-class-common.models"
import { ConsiderationValue, ConsiderationValueHeadlineToEffective, ConsiderationValueHeadlineToEffectiveRentToSale } from "../../valuation-process/_models/valuation-process-criterion.model"
import { calculateCFM } from "./cashflow-method"
import { calculateStraightLine } from "./straigthline-method"
import { calculateTVOM } from "./time-of-value-money"
import { roundTo4Decimals } from "./utils"

export function convertBasedOnCurrencyExchange(considerationValue: ConsiderationValue, currencyExchange: CurrencyExchange): ConsiderationValue {

  return {
    ...considerationValue,
    value: considerationValue.value * currencyExchange.exchange_rate,
  }
}

export function calculateValuation(considerationValue: ConsiderationValue, size: number): ConsiderationValue {
  const value = calculateValue(considerationValue, size)
  return {
    ...considerationValue,
    value
  }
}

function calculateValue(considerationValue: ConsiderationValue, size: number) {
  if (considerationValue.kind === 'headline-to-effective' || considerationValue.kind === 'headline-to-effective-to-rent-to-sale') {
    const result = calculateHeadlineToEffective(considerationValue, size)
    if (considerationValue.kind === 'headline-to-effective-to-rent-to-sale') {
      return convertHeadlineRentToSale(considerationValue.considerationConvertionData.ary, result)
    }
    return result
  }
  if (considerationValue.kind === 'rent-to-sale') {
    return convertRentToSale(considerationValue.considerationConvertionData.ary, considerationValue.consideration.oldTenure.total_consideration)
  }              
  if (considerationValue.kind === 'sale-to-rent') {
    return convertSaleToRent(considerationValue.considerationConvertionData.ary, considerationValue.consideration.oldTenure.total_consideration)
  }
  if (considerationValue.kind === 'no process') {
    if (considerationValue.consideration.considerationType === 'Land') return considerationValue.value
    return Number(considerationValue.consideration.oldTenure.total_consideration)
  }
  return undefined
}

function calculateHeadlineToEffective(
  considerationValue: ConsiderationValueHeadlineToEffective | ConsiderationValueHeadlineToEffectiveRentToSale, 
  size: number
): number {
  if (considerationValue.considerationConvertionData.methods.mainMethod === 0) {
    return calculateTVOM(considerationValue, size)
  }
  if (considerationValue.considerationConvertionData.methods.mainMethod === 1) {
    const result = calculateCFM(
      considerationValue.consideration.oldTenure,
      considerationValue.considerationConvertionData.cfmRateValues.targetRate,
      considerationValue.considerationConvertionData.cfmRateValues.growthRate,
      size
    )
    if (!result) return undefined
    if (result.results.isSelected) return result.results.effectiveRent
    return result.results.effectiveRents.find(er => er.isSelected)?.value
  }
  if (considerationValue.considerationConvertionData.methods.mainMethod === 2) {
    const result = calculateStraightLine(
      considerationValue.consideration.oldTenure,
      size
    )
    return result ? result.effectiveRent : undefined
  }
  return undefined
}

export function convertSaleToRent(allRiskYield: number, capitalValue: number): number {
  const ary = allRiskYield / 100;
  const result = (capitalValue * ary) / 12;
  return roundTo4Decimals(result) * 12;
}

export function convertRentToSale(allRiskYield: number, considerationPerMonth: number): number {
  const ary = allRiskYield / 100;
  const result = considerationPerMonth * 12 / ary;
  return roundTo4Decimals(result);
}

export function convertHeadlineRentToSale(allRiskYield: number, effectiveRentPerYear: number): number {
  const ary = allRiskYield / 100
  const result = effectiveRentPerYear / ary;
  return roundTo4Decimals(result)
}