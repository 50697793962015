import { Component, Inject, OnDestroy } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { ReadonlyService } from "src/app/core/_base/crud/utils/readonly.service";
import { formControlValueChanges } from "src/app/core/v2/utils";

@Component({
  selector: 'kt-valuation-manual-modal',
  templateUrl: './valuation-manual-modal.component.html',
  styleUrls: ['./valuation-manual-modal.component.scss']
})
export class ValuationManualModalComponent implements OnDestroy {
  form = new FormGroup({
    completed: new FormControl<boolean>(false),
    completed_at: new FormControl<Date>(null),
    value: new FormControl<number>(null)
  })
  readonly: boolean = false
  currency: string = ''

  private _onDestroy$ = new Subject<void>()

  constructor(
    private dialogRef: MatDialogRef<ValuationManualModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      completed: boolean,
      completed_date: Date,
      country_currency: string,
      value: number
    },
    private readonlyService: ReadonlyService
  ) {
    this.readonly = this.readonlyService.isReadOnly()
    this.currency = ` ${data.country_currency}`
    this.form.patchValue({
      completed: data.completed,
      completed_at: data.completed_date,
      value: data.value
    })

    // Effects
    formControlValueChanges(this.form.controls.completed).pipe(
      takeUntil(this._onDestroy$)
    ).subscribe(val => {
      if (val) {
        this.form.controls.completed_at.setValidators([Validators.required])
        this.form.controls.value.setValidators([Validators.required])
      } else {
        this.form.controls.completed_at.clearValidators()
        this.form.controls.value.clearValidators()
      }
      this.form.controls.completed_at.updateValueAndValidity()
      this.form.controls.value.updateValueAndValidity()
    })
  }

  ngOnDestroy(): void {
    this._onDestroy$.next()
    this._onDestroy$.complete()
  }

  save() {
    if (this.readonly) {
      this.dialogRef.close()
      return
    }

    let hasError = false
    if (this.form.invalid) {
      const controls = this.form.controls
      Object.keys(controls).forEach(controlName => {
        if (controls[controlName].invalid) {
          controls[controlName].markAsTouched()
          hasError = true
        }
      })
    }

    if (hasError) return;
    const returnData = {
      completed: this.form.controls.completed.value,
      completed_date: this.form.controls.completed_at.value ? new Date(this.form.controls.completed_at.value) : null,
      value: this.form.controls.value.value
    };
    this.dialogRef.close(returnData);
  }

  close() {this.dialogRef.close()}
  clearDate(control) {
    control.setValue(null)
    control.updateValueAndValidity()
  }
}