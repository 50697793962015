import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { combineLatest, Subject } from 'rxjs';
import { startWith, takeUntil } from 'rxjs/operators';
import { selectUsersInStore, User } from 'src/app/core/auth';
import { selectAllValuerQualifications, ValuerQualification } from 'src/app/core/linked-tables';
import { AppState } from 'src/app/core/reducers';
import { IToeTeamMember, TpTaskSelectionItem } from '../toe-members.component';

@Component({
  selector: 'kt-add-member',
  templateUrl: './add-member.component.html',
  styleUrls: ['./add-member.component.scss']
})
export class AddMemberComponent implements OnInit, OnDestroy {
  private _onDestroy$: Subject<void> = new Subject();
  form: UntypedFormGroup;
  
  tpTasks: Array<TpTaskSelectionItem> = [];
  valuerQualifications: ValuerQualification[] = [];
  private _users: User[] = [];
  filteredUsers: User[] = [];


  constructor(
    public dialogRef: MatDialogRef<AddMemberComponent>,
    @Inject(MAT_DIALOG_DATA) private data: any,
    private formBuilder: UntypedFormBuilder,
    private store: Store<AppState>
  ) { 
    this.tpTasks = data.tasks
  }

  ngOnInit(): void {
    this.store.select(selectAllValuerQualifications).pipe(
      takeUntil(this._onDestroy$)
    ).subscribe(res => {
      this.valuerQualifications = [];
      if (res) {
        this.valuerQualifications = res;
      }
    });
    this.store.select(selectUsersInStore).pipe(takeUntil(this._onDestroy$)).subscribe(
      res => {
        this._users = [];
        if (res) {
          this._users = res.items;
          this.filteredUsers = this._users
            .filter(user => !this.data.teamMembers.map(m => parseInt(m.worker_id)).includes(user.id))
            .filter(user => user.qualification_id != null && user.qualification_id != undefined);
        }
      }
    );
    this._createForm();
  };

  ngOnDestroy() {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  /**
   * Actions
   */
  onSubmit() {
    const controls = this.form.controls;
    if (this.form.invalid) {
      Object.keys(controls).forEach(ctrlName => {
        controls[ctrlName].markAsTouched();
      })
      return;
    }

    const valuer = this._prepareMember();
    this.dialogRef.close({
      member: valuer,
      tpTasks: this.tpTasks
    })
  }
  onClose() {
    this.dialogRef.close();
  }
  
  /**
   * Template actions 
   */
  getValuerQualificationName(id): string {
      if (!id) {
          return '(N/A)';
      }
      if (this.valuerQualifications.length > 0) {
          return this.valuerQualifications.find(el => el.id == id).name;
      } else {
          return 'N/A';
      }
  }

  /**
   * Methods
   */
  private _createForm() {
    this.form = this.formBuilder.group({
      worker_id: [undefined, Validators.required],
      lead_valuer: [{value: false, disabled: true}],
      tasks: [null]
    });
    this.form.controls.worker_id.valueChanges.pipe(
      startWith(this.form.controls.worker_id.value),
      takeUntil(this._onDestroy$)
    ).subscribe(worker_id => this._changeLeadValuerState(worker_id));
    combineLatest([
      this.form.controls.worker_id.valueChanges.pipe(startWith(this.form.controls.worker_id.value)),
      this.form.controls.lead_valuer.valueChanges.pipe(startWith(this.form.controls.lead_valuer.value))
    ]).pipe(
      takeUntil(this._onDestroy$)
    ).subscribe(([worker_id, isLeadValuer]) => this._changeTaskAvailability(worker_id, isLeadValuer));
  }

  private _changeLeadValuerState(user_id: number) {
    if (user_id == undefined) {
      return;
    }

    const user = this._users.find(u => u.id == user_id);
    if (!user) {
      return;
    }

    const isQualified = user.qualification_id == 1;
    if (isQualified) {
      const reportTask = this.tpTasks.find(t => t.id == 3 && t.selected);
      if (reportTask) {
        this.form.controls.lead_valuer.disable();
      } else {
        this.form.controls.lead_valuer.enable();
      }
    } else {
      this.form.controls.lead_valuer.patchValue(false);
      this.form.controls.lead_valuer.disable();
    }
  }

  private _changeTaskAvailability(user_id: number, isLeadValuer: boolean) {
    const user = this._users.find(u => u.id == user_id);
    if (!user) {
      return;
    }
    const isQualified = user.qualification_id == 1;
    this.tpTasks = this.tpTasks.map(task => {
      let disabled = false;
      if (task.id == 2) {
        disabled = !isQualified;
      }
      if (task.id == 3) {
        disabled = !(isQualified && isLeadValuer);
      }
      disabled = task.selected || disabled;
      return {
        ...task,
        disabled
      }
    });
    const selectedDoableTasks = this.form.controls.tasks.value ? this.form.controls.tasks.value.filter(t => {
      if (t == 2) {
        return isQualified;
      }
      if (t == 3) {
        return isQualified && isLeadValuer;
      }
      return true;
    }) : [];
    this.form.controls.tasks.patchValue(selectedDoableTasks);
    this.form.controls.tasks.updateValueAndValidity();
  }

  private _prepareMember(): IToeTeamMember {
    const worker_id = this.form.controls.worker_id.value;
    const lead_valuer = this.form.controls.lead_valuer.value;
    const assigned_default_tasks = this.form.controls.tasks.value;
    const worker = this._users.find(u => u.id == worker_id)
    const valuer: IToeTeamMember = {
      id: undefined,
      ref_id: undefined,
      worker_id,
      lead_valuer,
      worker,
      position: lead_valuer ? 'Lead Valuer' : 'Team Member',
      daily_rate: 0,
      days: 0,
      total_price: 0,
      assigned_default_tasks: assigned_default_tasks != null ? assigned_default_tasks : [],
      selected_document: [],
      selected_certificates: []
    }
    return valuer;
  }

}
