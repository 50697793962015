import { Action, createReducer, on } from "@ngrx/store"
import { ValuationProcessTargetPropertyModel } from "./types/asset-class/target-property"
import { ValuationModel } from "./types/valuation"
import * as valuationProcessActions from './_actions'
import { ValuationProcess } from "./types/valuation-process"
import { addLease, addRevenue, editLease, editRevenue, removeLease, removeRevenue, setHeadlineToEffectiveMeta, updateMethodAndTiming, updateModelAssumptions, updateSubLease, updateSubLeaseHeadlineToEffectiveMeta } from "./core/income"
import { ValuationComparableMapData, VComparable } from "src/app/core/valuation-process/_models"
import { addAssumptionOrDeparture, addComparable, addFullComparables, addNote, addVpga10matter, editAssumptionOrDeparture, editVpga10Matter, removeComparable, removeInputAssumptionOrDeparture, removeInputVpga10Matter, removeNote, removeToeAssumptionOrDeparture, removeToeLocalVpga10Matter, restoreAssumptionOrDeparture, restoreLocalVpga10Matter, selectConsideration, setComparableHeadlineToEffective, setCurrencyExchange, updateNote } from "./core"
import { AssetClassModel } from "src/app/core/asset_class"
import { createEntityAdapter, EntityAdapter, EntityState } from "@ngrx/entity"
import { ValuationAssumptionDeparture } from "./types"
import { ValuationVpga10Matter } from "src/app/core/valuation/_models/vpga10-matter.model"
import { ValuationSRERModel } from "src/app/core/valuation/_models/valuation-srer.model"
import { ValuationNoteModel } from "src/app/core/valuation/_models/valuation-note.model"

export const featureKey = 'valuation-process-v2'

interface DefaultAssumptionState extends EntityState<ValuationAssumptionDeparture> {
  lastCreatedId: number
}
export const defaultAssumptionAdapter: EntityAdapter<ValuationAssumptionDeparture> = createEntityAdapter({
  selectId: (ad: ValuationAssumptionDeparture) => ad.front_id
})
interface SpecialAssumptionState extends EntityState<ValuationAssumptionDeparture> {
  lastCreatedId: number
};
export const specialAssumptionAdapter: EntityAdapter<ValuationAssumptionDeparture> = createEntityAdapter({
  selectId: (ad: ValuationAssumptionDeparture) => ad.front_id
});
interface DepartureState extends EntityState<ValuationAssumptionDeparture> {
  lastCreatedId: number
};
export const departureAdapter: EntityAdapter<ValuationAssumptionDeparture> = createEntityAdapter({
  selectId: (ad: ValuationAssumptionDeparture) => ad.front_id
});
interface Vpga10MattersState extends EntityState<ValuationVpga10Matter> {
    lastCreatedId: number
};
export const vpga10Adapter: EntityAdapter<ValuationVpga10Matter> = createEntityAdapter({
    selectId: (entity: ValuationVpga10Matter) => entity.front_id
});
interface ValuationSRERState extends EntityState<ValuationSRERModel> {}
export const srerAdapter: EntityAdapter<ValuationSRERModel> = createEntityAdapter();
interface ValuationNotesState extends EntityState<ValuationNoteModel> {
    lastCreatedId: number
};
export const valuationNotesAdapter: EntityAdapter<ValuationNoteModel> = createEntityAdapter({
    selectId: (entity: ValuationNoteModel) => entity.front_id
});

export interface ValuationProcessState {
  isLoading: boolean,
  valuationProcess: ValuationProcess,
  targetProperty: ValuationProcessTargetPropertyModel,
  valuation: ValuationModel,
  comparables: ValuationComparableMapData[],
  selectedPropertySubTypes: number[],
  fullComparablesLoading: boolean,
  fullComparables: VComparable[],
  additional: {
    assetClass: AssetClassModel,
    ratings: any[],
  },
  assumptionDeparture: {
    default: DefaultAssumptionState,
    special: SpecialAssumptionState,
    departure: DepartureState
  },
  vpga10Matters: Vpga10MattersState,
  srer: ValuationSRERState,
  notes: ValuationNotesState
}

const initialState: ValuationProcessState = {
  isLoading: true,
  valuationProcess: { kind: 'unknown' },
  targetProperty: null,
  valuation: null,
  comparables: [],
  selectedPropertySubTypes: [],
  fullComparablesLoading: false,
  fullComparables: [],
  additional: {
    assetClass: null,
    ratings: []
  },
  assumptionDeparture: {
    default: defaultAssumptionAdapter.getInitialState({lastCreatedId: 0}),
    special: specialAssumptionAdapter.getInitialState({lastCreatedId: 0}),
    departure: departureAdapter.getInitialState({lastCreatedId: 0}),
  },
  vpga10Matters: vpga10Adapter.getInitialState({lastCreatedId: 0}),
  srer: srerAdapter.getInitialState(),
  notes: valuationNotesAdapter.getInitialState({lastCreatedId: 0})
}

const valuationProcessReducer = createReducer(
  initialState,
  on(valuationProcessActions.dataLoaded, (state, {
    valuationProcess,
    targetProperty,
    valuation,
    additional,
    assumptionDepartures,
    vpga10Matters,
    sers,
    notes,
    propertySubTypeIds
  }) => {
    return {
      ...state,
      isLoading: false,
      valuationProcess,
      targetProperty,
      valuation,
      additional,
      assumptionDeparture: {
        default: defaultAssumptionAdapter.setAll(assumptionDepartures.default, {
          ...state.assumptionDeparture.default,
          lastCreatedId: assumptionDepartures.def_id
        }),
        special: specialAssumptionAdapter.setAll(assumptionDepartures.special, {
          ...state.assumptionDeparture.special, 
          lastCreatedId: assumptionDepartures.sp_id}),
        departure: departureAdapter.setAll(assumptionDepartures.departure, {
          ...state.assumptionDeparture.departure, 
          lastCreatedId: assumptionDepartures.dp_id}),
      },
      vpga10Matters: vpga10Adapter.setAll(vpga10Matters.data, {
        ...state.vpga10Matters,
        lastCreatedId: vpga10Matters.id
      }),
      srer: srerAdapter.setAll(sers, {...state.srer}),
      notes: valuationNotesAdapter.setAll(
        notes.data,
        {...state.notes, lastCreatedId: notes.lastCreatedId}
      ),
      selectedPropertySubTypes: propertySubTypeIds
    }
  }),
  on(valuationProcessActions.updateHeadlineToEffectiveOfLease, (state, {
    uuid,
    kind,
    meta
  }) => {
    if (state.valuationProcess.kind !== 'income') return state
    return {
      ...state,
      valuationProcess: setHeadlineToEffectiveMeta(state.valuationProcess, { uuid, kind, meta })
    }
  }),
  on(valuationProcessActions.addedComparableLoaded, (state, {item}) => {
    const fullComparables = addFullComparables(state.fullComparables, [item])
    return {
      ...state,
      comparables: [...state.comparables, {
        id: item.id,
        refNum: item.refNum,
        latitude: item.locationData.latitude,
        longitude: item.locationData.longitude,
        propertySubType: item.propertySubType
      }] as ValuationComparableMapData[],
      fullComparables,
      valuationProcess: addComparable(state.valuationProcess, fullComparables, item.refNum)
    }
  }),
  on(valuationProcessActions.loadComparables, (state, { filter }) => ({
    ...state,
    selectedPropertySubTypes: filter.propertySubTypeIds
  })),
  on(valuationProcessActions.comparablesLoaded, (state, { items }) => ({
    ...state,
    comparables: items
  })),
  on(valuationProcessActions.loadFullComparables, (state, _) => ({
    ...state,
    fullComparablesLoading: true,
  })),
  on(valuationProcessActions.fullComparablesLoaded, (state, { items }) => ({
    ...state,
    fullComparablesLoading: false,
    fullComparables: addFullComparables(state.fullComparables, items)
  })),
  on(valuationProcessActions.addComparable, (state, { refNum }) => ({
    ...state,
    valuationProcess: addComparable(state.valuationProcess, state.fullComparables, refNum)
  })),
  on(valuationProcessActions.removeComparable, (state, { refNum }) => ({
    ...state,
    valuationProcess: removeComparable(state.valuationProcess, refNum)
  })),
  on(valuationProcessActions.updateModelAssumptions, (state, { uuid, kind, data }) => {
    if (state.valuationProcess.kind !== 'income') return state
    return {
      ...state,
      valuationProcess: updateModelAssumptions(state.valuationProcess, { uuid, kind, data })
    }
  }),
  on(valuationProcessActions.updateMethodAndTiming, (state, { uuid, kind, data }) => {
    if (state.valuationProcess.kind !== 'income') return state
    return {
      ...state,
      valuationProcess: updateMethodAndTiming(state.valuationProcess, {uuid, kind, data})
    }
  }),
  on(valuationProcessActions.selectConsideration, (state, { refNum, id }) => {
    return {
      ...state,
      valuationProcess: selectConsideration(state.valuationProcess, refNum, id)
    }
  }),
  on(valuationProcessActions.addAssumptionOrDeparture, (state, {data}) => {
    return {
      ...state,
      assumptionDeparture: addAssumptionOrDeparture(state.assumptionDeparture, data)
    }
  }),
  on(valuationProcessActions.editAssumptionOrDeparture, (state, {data}) => {
    return {
      ...state,
      assumptionDeparture: editAssumptionOrDeparture(state.assumptionDeparture, data)
    }
  }),
  on(valuationProcessActions.removeToeAssumptionOrDeparture, (state, {data, tp, justification}) => {
    return {
      ...state,
      assumptionDeparture: removeToeAssumptionOrDeparture(state.assumptionDeparture, data, tp, justification)
    }
  }),
  on(valuationProcessActions.removeInputAssumptionOrDeparture, (state, {data}) => {
    return {
      ...state,
      assumptionDeparture: removeInputAssumptionOrDeparture(state.assumptionDeparture, data)
    }
  }),
  on(valuationProcessActions.restoreAssumptionOrDeparture, (state, {data, tp}) => {
    return {
      ...state,
      assumptionDeparture: restoreAssumptionOrDeparture(state.assumptionDeparture, data, tp)
    }
  }),
  on(valuationProcessActions.addVpga10Matter, (state, {data}) => {
    return {
      ...state,
      vpga10Matters: addVpga10matter(state.vpga10Matters, data)
    }
  }),
  on(valuationProcessActions.editVpga10Matter, (state, {data}) => {
    return {
      ...state,
      vpga10Matters: editVpga10Matter(state.vpga10Matters, data)
    }
  }) ,
  on(valuationProcessActions.removeToeLocalVpga10Matter, (state, {data, tp ,justification}) => {
    return {
      ...state,
      vpga10Matters: removeToeLocalVpga10Matter(state.vpga10Matters, data, tp, justification)
    }
  }),
  on(valuationProcessActions.removeInputVpga10Matter, (state, {id}) => {
    return {
      ...state,
      vpga10Matters: removeInputVpga10Matter(state.vpga10Matters, id)
    }
  }),
  on(valuationProcessActions.restoreLocalVpga10Matter, (state, {data, tp}) => {
    return {
      ...state,
      vpga10Matters: restoreLocalVpga10Matter(state.vpga10Matters, data, tp)
    }
  }),
  on(valuationProcessActions.addLease, (state, {lease}) => {
    if (state.valuationProcess.kind === 'unknown') return state
    if (state.valuationProcess.kind === 'market') return state
    return {
      ...state,
      valuationProcess: addLease(state.valuationProcess, lease)
    }
  }),
  on(valuationProcessActions.editLease, (state, {lease}) => {
    if (state.valuationProcess.kind === 'unknown') return state
    if (state.valuationProcess.kind === 'market') return state
    return {
      ...state,
      valuationProcess: editLease(state.valuationProcess, lease)
    }
  }),
  on(valuationProcessActions.deleteLease, (state, {lease}) => {
    if (state.valuationProcess.kind === 'unknown') return state
    if (state.valuationProcess.kind === 'market') return state
    return {
      ...state,
      valuationProcess: removeLease(state.valuationProcess, lease)
    }
  }),
  on(valuationProcessActions.addRevenue, (state, {revenue}) => {
    if (state.valuationProcess.kind === 'unknown') return state
    if (state.valuationProcess.kind === 'market') return state
    return {
      ...state,
      valuationProcess: addRevenue(state.valuationProcess, revenue)
    }
  }),
  on(valuationProcessActions.editRevenue, (state, {revenue}) => {
    if (state.valuationProcess.kind === 'unknown') return state
    if (state.valuationProcess.kind === 'market') return state
    return {
      ...state,
      valuationProcess: editRevenue(state.valuationProcess, revenue)
    }
  }),
  on(valuationProcessActions.deleteRevenue, (state, {revenue}) => {
    if (state.valuationProcess.kind === 'unknown') return state
    if (state.valuationProcess.kind === 'market') return state
    return {
      ...state,
      valuationProcess: removeRevenue(state.valuationProcess, revenue)
    }
  }),
  on(valuationProcessActions.setComparableHeadlineToEffective, (state, {refNum, meta}) => {
    return {
      ...state,
      valuationProcess: setComparableHeadlineToEffective(state.valuationProcess, {refNum, meta})
    }
  }),
  on(valuationProcessActions.updateValuationDate, (state, {valuationDate}) => {
    if (state.valuationProcess.kind === 'unknown') return state
    if (state.valuationProcess.kind === 'market') return state
    return {
      ...state,
      valuationProcess: {
        ...state.valuationProcess,
        data: {
          ...state.valuationProcess.data,
          valuationDate
        }
      }
    }
  }),
  on(valuationProcessActions.updateSubLease, (state, prop) => {
    if (state.valuationProcess.kind === 'unknown') return state
    if (state.valuationProcess.kind === 'market') return state
    return {
      ...state,
      valuationProcess: updateSubLease(state.valuationProcess, prop)
    }
  }),
  on(valuationProcessActions.updateSubLeaseHeadlineToEffectiveMeta, (state, prop) => {
    if (state.valuationProcess.kind === 'unknown') return state
    if (state.valuationProcess.kind === 'market') return state
    return {
      ...state,
      valuationProcess: updateSubLeaseHeadlineToEffectiveMeta(state.valuationProcess, prop)
    }
  }),
  on(valuationProcessActions.addNote, (state, prop) => {
    return {
      ...state,
      notes: addNote(state.notes, prop.data)
    }
  }),
  on(valuationProcessActions.editNote, (state, prop) => {
    return {
      ...state,
      notes: updateNote(state.notes, prop.data)
    }
  }),
  on(valuationProcessActions.removeNote, (state, prop) => {
    return {
      ...state,
      notes: removeNote(state.notes, prop.id)
    }
  }),
  on(valuationProcessActions.updateConsiderationCurrencyExchange, (state, prop) => {
    return {
      ...state,
      valuationProcess: setCurrencyExchange(state.valuationProcess, prop)
    }
  })
)

export function reducer(state: ValuationProcessState | undefined, action: Action) {
  return valuationProcessReducer(state, action)
}