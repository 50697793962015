<kt-portlet>
    <kt-portlet-header
            [title]="getComponentTitle() | async"
            [class]="'kt-portlet-head--lg'"
            [tooltipTitle]="'TOOLTIP.USER.FORM'+(user && user.id > 0 ? '_EDIT':'')+'.TITLE' | translate"
            [tooltipDesc]="'TOOLTIP.USER.FORM'+(user && user.id > 0 ? '_EDIT':'')+'.DESCRIPTION' | translate"
            [viewLoading$]="loading$"
            [sticky]="true">
        <ng-container ktPortletTools>
            <a
                (click)="back()"
               href="javascript:;"
               class="btn mr-2" mat-raised-button [matTooltip]="'USER.FORM.TOOLTIP.BACK' | translate">
                <mat-icon>arrow_back</mat-icon>
                <span class="kt-hidden-mobile">{{ 'GENERAL.BUTTON.BACK' | translate }}</span>
            </a>

            <button
                    *ngIf="canEdit$|async"
                    class="btn btn-success mr-2"
                    mat-raised-button
                    [matTooltip]="'USER.FORM.TOOLTIP.SAVE' | translate"
                    [hidden]="isPasswordTabSub | async"
                    (click)="onSubmit(false)"
                    color="submit">
                <span class="kt-hidden-mobile">{{ 'GENERAL.BUTTON.SAVE' | translate }}</span>
            </button>
        </ng-container>
    </kt-portlet-header>

    <kt-portlet-body [ngClass]="{'readonly': !(canEdit$|async)}">
        <form class="form" [formGroup]="userForm" *ngIf="userForm">
            <kt-tab-header
                [headerLabels]="tabHeaders"
                (tabChange)="onTabChange($event)"
                [selectedTabChange]="selectedtabchange$">
            </kt-tab-header>
            <mat-tab-group class="invinsible-tabs" [(selectedIndex)]="selectedTab" (selectedTabChange)="onTabChanged($event);">
                <mat-tab>
                    <ng-template mat-tab-label>
                        <span>{{ 'USER.FORM.TAB.TITLE1' | translate }}</span>
                    </ng-template>
                    <div class="card-body pt-0 mad-container">
                        <kt-form-error
                            [hasFormErrors]="hasFormErrors"
                            [tabHeaders]="tabHeaders"
                            [error]="error"
                            [erroTabMap]="erroTabMap()"
                            (hasFormErrorsChange)="onHasFormErrorsChange($event)">
                        </kt-form-error>

                        <div class="row form-group section-margin-top-60">
                            <div class="col-12">
                                <h5 class="my-3 text-mad text-uppercase">
                                    Profile
                                </h5>
                                <hr class="active">
                            </div>

                            <div class="col-12">
                                <div class="row">
                                    <div class="col-4 d-flex justify-content-center">
                                        <div style="width: 200px; height: 200px;">
                                            <ng-container *ngIf="!(canEdit$|async)">
                                                <label class="non-hoverable">
                                                    <img class="avatar" [src]="imgURL | async" (error)="imgOnError($event)">
                                                </label>
                                            </ng-container>
                                            <ng-container *ngIf="canEdit$|async">
                                                <label class="hoverable" for="fileInput">
                                                    <img class="avatar" [src]="imgURL | async" (error)="imgOnError($event)">
                                                    <div class="hover-text">{{ 'USER.FORM.FORM_FIELD.GENERAL.CHOOSE_FILE' | translate }}</div>
                                                    <div class="background"></div>
                                                </label>

                                                <br/>
                                                <input id="fileInput" type='file' formControlName="picture" (change)="onSelectFile($event)" accept="image/*">
                                                <div *ngIf="uploadResponse.status === 'progress'">
                                                    <div role="progressbar" [style.width.%]="uploadResponse.message" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">
                                                        {{uploadResponse.message}}%
                                                    </div>
                                                </div>
                                            </ng-container>
                                        </div>
                                    </div>
                                    <div class="col-8 my-auto">
                                        <div class="row">
                                            <div class="col-6">
                                                <mat-form-field class="mat-form-field-fluid">
                                                    <mat-select
                                                        formControlName="is_active"
                                                        [placeholder]="'USER.FORM.FIELD.STATUS' | translate">
                                                        <mat-option [value]="1">
                                                            Enabled
                                                        </mat-option>
                                                        <mat-option [value]="0">
                                                            Disabled
                                                        </mat-option>
                                                    </mat-select>
                                                    <mat-error>
                                                        {{'USER.FORM.FIELD.STATUS' | translate}} <strong>required</strong>
                                                    </mat-error>
                                                </mat-form-field>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-6">
                                                <mat-form-field class="mat-form-field-fluid">
                                                    <input matInput [placeholder]="'USER.FORM.FORM_FIELD.GENERAL.FIRSTNAME' | translate" formControlName="first_name"/>
                                                    <mat-icon
                                                        matSuffix
                                                        class="cursor-pointer icon-gray"
                                                        container="body"
                                                        [ngbPopover]="'Please open a support ticket to change your name here'"
                                                        (click)="$event.stopPropagation();">help
                                                    </mat-icon>
                                                    <mat-error
                                                            [innerHtml]="'GENERAL.VALIDATION.REQUIRED' | translate:{field: ('USER.FORM.FORM_FIELD.GENERAL.FIRSTNAME' | translate)}"
                                                    >
                                                    </mat-error>
                                                </mat-form-field>
                                            </div>
                                            <div class="col-6">
                                                <mat-form-field class="mat-form-field-fluid">
                                                    <input matInput [placeholder]="'USER.FORM.FORM_FIELD.GENERAL.LASTNAME' | translate" formControlName="last_name"/>
                                                    <mat-icon
                                                        matSuffix
                                                        class="cursor-pointer icon-gray"
                                                        container="body"
                                                        [ngbPopover]="'Please open a support ticket to change your name here'"
                                                        (click)="$event.stopPropagation();">help
                                                    </mat-icon>
                                                    <mat-error
                                                            [innerHtml]="'GENERAL.VALIDATION.REQUIRED' | translate:{field: ('USER.FORM.FORM_FIELD.GENERAL.LASTNAME' | translate)}"
                                                    >
                                                    </mat-error>
                                                </mat-form-field>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-6">
                                                <mat-form-field class="mat-form-field-fluid">
                                                    <input matInput [placeholder]="'USER.FORM.FIELD.PRONOUNS' | translate" formControlName="pronouns"/>
                                                </mat-form-field>
                                            </div>
                                            <div class="col-6">
                                                <mat-form-field class="mat-form-field-fluid">
                                                    <input type="email" matInput [placeholder]="'USER.FORM.FORM_FIELD.GENERAL.EMAIL' | translate" formControlName="email"/>
                                                    <mat-error
                                                            *ngIf="this.userForm.get('email').hasError('email')"
                                                            [innerHtml]="'GENERAL.VALIDATION.WRONG_FORMAT' | translate:{field: ('USER.FORM.FORM_FIELD.GENERAL.EMAIL' | translate)}"
                                                    >
                                                    </mat-error>
                                                    <mat-error
                                                            *ngIf="this.user.email !== this.userForm.get('email').value && this.userForm.get('email').hasError('emailExist')"
                                                            [innerHtml]="'GENERAL.VALIDATION.ALREADY_IN_USE' | translate:{field: ('USER.FORM.FORM_FIELD.GENERAL.EMAIL' | translate)}"
                                                    >
                                                    </mat-error>

                                                    <mat-error *ngIf="this.userForm.get('email').hasError('required')"
                                                            [innerHtml]="'GENERAL.VALIDATION.REQUIRED' | translate:{field: ('USER.FORM.FORM_FIELD.GENERAL.EMAIL' | translate)}"
                                                    >
                                                    </mat-error>
                                                </mat-form-field>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row form-group section-margin-top-60">
                            <div class="col-12">
                                <h5 class="my-3 text-mad text-uppercase">
                                    user rights
                                </h5>
                                <hr class="active">
                            </div>

                            <div class="col-12">
                                <div class="row">
                                    <div class="col-4">
                                        <mat-form-field class="mat-form-field-fluid">
                                            <mat-select [placeholder]="'USER.FORM.FORM_FIELD.GENERAL.QUALIFICATION' | translate"
                                                        formControlName="qualification_id"
                                                        (selectionChange)="selectQualification($event)">
                                                <mat-option *ngFor="let item of valuerQualifications"
                                                            [value]="item.id">{{item.name}}
                                                </mat-option>
                                            </mat-select>
                                            <mat-error
                                                    [innerHtml]="'GENERAL.VALIDATION.REQUIRED' | translate:{field: ('USER.FORM.FORM_FIELD.GENERAL.QUALIFICATION' | translate)}"
                                            >
                                            </mat-error>
                                        </mat-form-field>
                                    </div>
                                    <div class="col-4" *ngIf="canUpdateRole$|async">
                                        <mat-form-field class="mat-form-field-fluid">
                                            <mat-select [placeholder]="'USER.FORM.FORM_FIELD.GENERAL.ROLE' | translate" formControlName="role_id">
                                                <mat-option *ngFor="let item of allRoles" [value]="item.id">{{item.name}}</mat-option>
                                            </mat-select>
                                            <mat-error
                                                    [innerHtml]="'GENERAL.VALIDATION.REQUIRED' | translate:{field: ('USER.FORM.FORM_FIELD.GENERAL.ROLE' | translate)}"
                                            >
                                            </mat-error>
                                        </mat-form-field>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div >
                </mat-tab>
                <mat-tab>
                    <ng-template mat-tab-label>
                        <span>Certifications & documents</span>
                    </ng-template>
                    <div class="card-body pt-0 mad-container">
                        <kt-form-error
                            [hasFormErrors]="hasFormErrors"
                            [tabHeaders]="tabHeaders"
                            [error]="error"
                            [erroTabMap]="erroTabMap()"
                            (hasFormErrorsChange)="onHasFormErrorsChange($event)">
                        </kt-form-error>

                        <div class="row form-group section-margin-top-60">
                            <div class="col-12">
                                <h5 class="my-3 text-mad text-uppercase">
                                    professional requirements
                                </h5>
                                <hr class="active">
                            </div>
                            <div class="col-lg-12 kt-margin-bottom-10-mobile">
                                <mat-form-field class="mat-form-field-fluid">
                                    <textarea matInput rows="5" [placeholder]="'USER.FORM.FORM_FIELD.GENERAL.EXP_VALUER' | translate" formControlName="valuer_experience"></textarea>
                                    <mat-error
                                            [innerHtml]="'GENERAL.VALIDATION.REQUIRED' | translate:{field: ('USER.FORM.FORM_FIELD.GENERAL.EXP_VALUER' | translate)}"
                                    >
                                    </mat-error>
                                </mat-form-field>
                            </div>
                            <div class="col-lg-12 kt-margin-bottom-10-mobile">
                                <mat-form-field class="mat-form-field-fluid">
                                    <textarea matInput
                                            rows="5"
                                            [placeholder]="'USER.FORM.FORM_FIELD.GENERAL.INSURANCE_POLICY' | translate"
                                            formControlName="insurance_policy"></textarea>
                                    <mat-error
                                            [innerHtml]="'GENERAL.VALIDATION.REQUIRED' | translate:{field: ('USER.FORM.FORM_FIELD.GENERAL.INSURANCE_POLICY' | translate)}"
                                    >
                                    </mat-error>
                                </mat-form-field>
                            </div>
                            <div class="col-lg-12 kt-margin-bottom-10-mobile">
                                <mat-form-field class="mat-form-field-fluid">
                                    <textarea matInput
                                            rows="3"
                                            [placeholder]="'USER.FORM.FORM_FIELD.GENERAL.STANDARD_LIMITATIONS' | translate"
                                            formControlName="standard_limitations"></textarea>
                                    <mat-error
                                            [innerHtml]="'GENERAL.VALIDATION.REQUIRED' | translate:{field: ('USER.FORM.FORM_FIELD.GENERAL.STANDARD_LIMITATIONS' | translate)}"
                                    >
                                    </mat-error>
                                </mat-form-field>
                            </div>
                        </div>

                        <div class="form-group row section-margin-top-60">
                            <kt-memberships-table class="col-lg-12" [certificates$]="certificates$" [readonly]="!(canEdit$|async)"></kt-memberships-table>
                            <div class="col-md-12 kt-margin-bottom-20-mobile">
                                <mat-error class="tag-custom-error"
                                    *ngIf="hasFormErrors && errors['membership'] && errors['membership'].length > 0"
                                    [innerHTML]="errors['membership'][0]">
                                </mat-error>
                            </div>
                        </div>

                        <div class="form-group row section-margin-top-60" *ngIf="user">
                            <kt-other-documents class="col-lg-12" [user]="user" [readonly]="!(canEdit$|async)"></kt-other-documents>
                        </div>
                    </div >
                </mat-tab>
                <mat-tab>
                    <ng-template mat-tab-label>
                        <span>Settings</span>
                    </ng-template>
                    <div class="card-body pt-0 mad-container">
                        <kt-form-error
                            [hasFormErrors]="hasFormErrors"
                            [tabHeaders]="tabHeaders"
                            [error]="error"
                            [erroTabMap]="erroTabMap()"
                            (hasFormErrorsChange)="onHasFormErrorsChange($event)">
                        </kt-form-error>

                        <div class="row form-group">
                            <div class="col-12">
                                <h5 class="my-3 text-mad text-uppercase">
                                    system requirements
                                </h5>
                                <hr class="active" />
                            </div>
                            <div class="col-4 kt-margin-bottom-10-mobile">
                                <mat-form-field class="mat-form-field-fluid">
                                    <mat-select placeholder="Preferred timezone" formControlName="timezone">
                                        <mat-option>
                                            <ngx-mat-select-search
                                                [formControl]="timeZoneCtlr"
                                                [placeholderLabel]="'GENERAL.PLACEHOLDER.SEARCH' | translate"
                                                [noEntriesFoundLabel]="'Not Found'"></ngx-mat-select-search>
                                        </mat-option>
                                        <mat-option *ngFor="let item of (timezoneService.timeZones$ | async)" [value]="item.name">
                                            {{item.name}} (GMT {{item.gmtValue}})
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                        </div>

                        <div class="row form-group section-margin-top-60">
                            <div class="col-12">
                                <h5 class="my-3 text-mad text-uppercase">
                                    professional requirements
                                </h5>
                                <hr class="active">
                            </div>
                            <div class="col-4 kt-margin-bottom-10-mobile">
                                <mat-form-field class="mat-form-field-fluid">
                                    <mat-select placeholder="Preferred system of measurement" formControlName="unit_of_measurement_id">
                                        <mat-option *ngFor="let item of units" [value]="item.id">{{item.name}}</mat-option>
                                    </mat-select>
                                    <mat-error>Preferred system of measurement
                                        <strong>required</strong>
                                    </mat-error>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="row form-group section-margin-top-60">
                            <div class="col-12">
                                <h5 class="my-3 text-mad text-uppercase">
                                    System Maps
                                </h5>
                                <hr class="active" />
                            </div>
                            <div class="col-12 kt-margin-bottom-10-mobile">
                                <mat-checkbox formControlName="override_system_location">
                                    Override default location map
                                </mat-checkbox>
                            </div>
                            <div *ngIf="userForm.controls.override_system_location.value" class="col-12">
                                <div>
                                    <mat-form-field class="mat-form-field-fluid">
                                        <input
                                            #locationSearch
                                            matInput
                                            [placeholder]="'GENERAL.PLACEHOLDER.SEARCH_BY_CITY' | translate"
                                        />
                                        <mat-icon matSuffix container="body"
                                            [ngbPopover]="'TOOLTIP.HOUSE.LOCATE.DESCRIPTION' | translate"
                                            [popoverTitle]="'TOOLTIP.HOUSE.LOCATE.TITLE' | translate">
                                            help
                                        </mat-icon>
                                    </mat-form-field>
                                </div>
                                <agm-map
                                    [latitude]="centerLat$|async"
                                    [longitude]="centerLng$|async"
                                    [zoom]="15"
                                    [usePanning]="true"
                                    [clickableIcons]="false"
                                    [scrollwheel]="null"
                                    (mapReady)="onMapReady($event)">
                                    <agm-map-type-control></agm-map-type-control>
                                    <agm-marker
                                        [latitude]="centerLat$|async"
                                        [longitude]="centerLng$|async"
                                        [markerDraggable]="false">
                                    </agm-marker>
                                </agm-map>
                            </div>
                        </div>
                    </div >
                </mat-tab>
                <!-- <mat-tab>
                    <ng-template mat-tab-label>
                        <span>{{ 'USER.FORM.TAB.TITLE2' | translate }}</span>
                    </ng-template>
                    <div class="card-body pt-0 mad-container">
                        <kt-change-password [userId]="user.id"></kt-change-password>
                    </div >
                </mat-tab>  -->
            </mat-tab-group>
        </form>
    </kt-portlet-body>
</kt-portlet>

<ng-template #na>
    N/A
</ng-template>
