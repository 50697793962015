import {AfterViewInit, Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {debounceTime, delay, distinctUntilChanged, skip, take, takeUntil, tap} from 'rxjs/operators';
import {LayoutUtilsService, QueryParamsModel} from '../../../../core/_base/crud';
import {
    OneToeDeleted, selectAdminTrashedToeCount, selectTrashedToeCount,
    ToeActionToggleLoading, ToeClear, ToeDeletedFromAdminTrash,
    ToeDeletedFromTrash,
    ToeModel, ToeOnServerAdminRestored,
    ToeOnServerRestored,
    ToesDataSource,
    ToeService,
    ToesPageRequested,
    ToeTrashFlushed,
    selectToesLastQuery,
    ToeStatusUpdated
} from '../../../../core/toe';
import {MatPaginator} from '@angular/material/paginator';
import {MatSnackBar} from '@angular/material/snack-bar';
import {MatSort} from '@angular/material/sort';
import {BehaviorSubject, fromEvent, merge, Observable, of, Subscription} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';
import {AppState} from '../../../../core/reducers';
import {select, Store} from '@ngrx/store';
import {SelectionModel} from '@angular/cdk/collections';
import {CookieService} from 'ngx-cookie-service';
import {SubheaderService} from '../../../../core/_base/layout';
import {Location} from '@angular/common';
import {
    AssignmentModel,
    selectAssignmentById
} from '../../../../core/assignment';
import {AllUsersOfRoleRequested, selectValuersInStore, User} from '../../../../core/auth';
import { currentUser, hasPermission } from 'src/app/core/mad-auth/mad-auth.store';
import {Globals} from '../../../../core/_base/crud/utils/globals.service';
import { DeleteState, SaveState } from 'src/app/core/meta';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { Update } from '@ngrx/entity';
import { AssignmentStatusUpdated, OneAssignmentRequest, SetAssignmentStatusToOngoing, SetAssignmentStatusToValidated } from 'src/app/core/assignment/_actions/assignment.actions';
import { MatDialog } from '@angular/material/dialog';
import { ToeTemplateListModalComponent } from '../_sub/toe-template-list-modal/toe-template-list-modal.component';
import { ReadonlyService } from 'src/app/core/_base/crud/utils/readonly.service';
import { ToeDuplicateDialogComponent } from '../_sub/toe-duplicate-dialog/toe-duplicate-dialog.component';
import { Option } from '../../shared_components/filter/f-selection/f-selection.component';
import { concat } from 'lodash';
import { DuplicateToeDialogComponent } from '../../shared_components/duplicate-toe-dialog/duplicate-toe-dialog.component';
import { MoveToeDialogComponent } from '../../shared_components/move-toe-dialog/move-toe-dialog.component';
import { DownloadToeFilesDialogComponent } from '../../shared_components/download-toe-files-dialog/download-toe-files-dialog.component';
import { SubdomainService } from 'src/app/core/_base/subdomain.service';
import { AllValuerQualificationsRequested, ValuerQualification, selectAllValuerQualifications } from 'src/app/core/linked-tables';
type pmStatus = {
    total_toes: string,
    total_properties: string,
    total_valuations: string,
}

@Component({
    selector: 'kt-toe-list',
    templateUrl: './toe-list.component.html',
    styleUrls: ['./toe-list.component.scss']
})
export class ToeListComponent implements OnInit, OnDestroy, AfterViewInit {
// Table fields
    dataSource: ToesDataSource;
    displayedColumns = ['id',
        'name',
        'lead_valuer_name',
        'instruction_date',
        'final_delivery_date',
        'tp_cnt',
        'val_cnt',
        'status',
        'actions'];

    @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
    @ViewChild('sort1', {static: true}) sort: MatSort;
    // Filter fields

    assignmentId: number;
    assignment: AssignmentModel;
    currentUser: User|null;

    // Selection
    selection = new SelectionModel<ToeModel>(true, []);
    toeResult: ToeModel[] = [];


    menuItems = [
        {
            title: 'Add ToE',
            icon: 'flaticon2-add',
            refId: 0,
            disabled: false
        },
        {
            title: 'Add ToE from template',
            icon: 'flaticon2-add',
            refId: 3,
            disabled: false
        },
        {
            title: 'Edit Assignment',
            icon: 'flaticon2-edit',
            refId: 1,
            disabled: false
        },
        {
            title: 'Audit Trail',
            icon: 'flaticon-notepad',
            refId: 2,
            disabled: false
        }];
    menuSubject = new BehaviorSubject<number>(-1);
    toeStatusCnts$ = new BehaviorSubject<pmStatus>({
        total_toes: '0',
        total_properties: '0',
        total_valuations: '0',
    });

    // Subscriptions
    private subscriptions: Subscription[] = [];
    trashCnt$: Observable<number>;
    adminTrashCnt$: Observable<number>;

    // Last Query Param
    lastQueryParams: QueryParamsModel;
    filter: any;
    leadValuers: Option[] = [];
    status: Option[] = [{id: -1, text: 'All'}, {id: 0, text: 'Draft'}, {id: 1, text: 'Ongoing'}]

    canAccessAdminTrash$ = this.store.pipe(select(hasPermission(['admin_trash'])));

    qualifications: ValuerQualification[] = []

    /**
     * Component constructor
     *
     * @param activatedRoute
     * @param router
     * @param snackBar: MatSnackBar
     * @param location
     * @param subheaderService
     * @param cookieService
     * @param layoutUtilsService: LayoutUtilsService
     * @param translate: TranslateService
     * @param toesService
     * @param store: Store<AppState>
     */
    constructor(private activatedRoute: ActivatedRoute,
                private router: Router,
                public snackBar: MatSnackBar,
                public location: Location,
                public subheaderService: SubheaderService,
                public cookieService: CookieService,
                private layoutUtilsService: LayoutUtilsService,
                private translate: TranslateService,
                private toesService: ToeService,
                private store: Store<AppState>,
                private global: Globals,
                private dialog: MatDialog,
                private readonly: ReadonlyService,
            public subDomainService: SubdomainService) {
    }

    /**
     * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
     */

    /**
     * On init
     */
    ngOnInit() {
        /* TODO: Workaround */
        this.store.dispatch(new DeleteState());
        this.store.dispatch(new AllValuerQualificationsRequested())

        const userSubscription = this.store.pipe(
            select(currentUser))
            .subscribe(res => {
                if (res) {
                    this.currentUser = res;
                }
            });
        this.subscriptions.push(userSubscription);

        const qualificationsSub = this.store.pipe(
            select(selectAllValuerQualifications)
        ).subscribe(res => {
            if (res) {
                this.qualifications = res
            }
        })
        this.subscriptions.push(qualificationsSub)


        this.subheaderService.setTitle('Terms of Engagements');
        this.subheaderService.setBreadcrumbs([
            {title: 'Main menu', page: `../default/assignment`},
            {title: 'Assignments', page: `../default/assignment/assignments`},
            {title: 'Terms of Engagements', page: `../default/assignment/assignments/toes`},
        ]);

        if (this.global.activeAssignmentId) {
            // this.assignmentId = Number(this.cookieService.get('todo_ass_id'));
            this.assignmentId = this.global.activeAssignmentId;
            // assignmentId
            this.store.dispatch(new OneAssignmentRequest({id: this.assignmentId}));
            const assignmentSub = this.store.pipe(select(selectAssignmentById(this.assignmentId))).subscribe(res => {
                if (res) {
                    this.assignment = res;
                    // If Current User is not PM, He or She cannot add ToE or edit Assignment.
                    if (this.currentUser && this.currentUser.id !== this.assignment.valuer_id) {
                        this.menuItems[0].disabled = true;
                        this.menuItems[1].disabled = true;
                        this.menuItems[2].disabled = true;
                    }
                    if (this.assignment.status == 2) {
                        this.menuItems[0].disabled = true;
                        this.menuItems[1].disabled = true;
                        this.menuItems[2].disabled = true;
                    }
                }
            });
            this.subscriptions.push(assignmentSub);
        } else {
            this.router.navigate(['../../'], {relativeTo: this.activatedRoute});
        }

        this.paginator._intl.itemsPerPageLabel = 'Display';
        // If the user changes the sort order, reset back to the first page.
        const sortSubscription = this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));
        this.subscriptions.push(sortSubscription);

        /* Data load will be triggered in two cases:
        - when a pagination event occurs => this.paginator.page
        - when a sort event occurs => this.sort.sortChange
        **/
        const paginatorSubscriptions = merge(this.sort.sortChange, this.paginator.page).pipe(
            tap(() => this.loadToesList())
        )
            .subscribe();
        this.subscriptions.push(paginatorSubscriptions);

        this.store.dispatch(new ToeClear());

        // Init DataSource
        this.dataSource = new ToesDataSource(this.store);
        const entitiesSubscription = this.dataSource.entitySubject.pipe(
            skip(1),
            distinctUntilChanged()
        ).subscribe(res => {
            if (res && res.length > 0) {
                this.toeResult = res;
                const completedOrAbortedToes = this.toeResult.filter(toe => toe.status == -1 || toe.status == 4); 
                if (this.toeResult.length == completedOrAbortedToes.length) {
                    this.store.dispatch(new SetAssignmentStatusToValidated({id: this.global.activeAssignmentId}));
                } else {
                    this.store.dispatch(new SetAssignmentStatusToOngoing({id: this.global.activeAssignmentId}));
                }
                this.toeStatusCnts$.next({
                    total_toes: res.length.toString(),
                    total_properties: res.reduce((total, item) => total + item.tp_cnt, 0).toString(),
                    total_valuations: res.reduce((total, item) => total + item.val_cnt, 0).toString(),
                })
            }
        });
        this.store.dispatch(new AllUsersOfRoleRequested({roleId: 2}));
        const usersSubscription = this.store.pipe(
            select(selectValuersInStore))
            .subscribe(res => {
                this.leadValuers = concat([{id: -1, text: 'All'}], res ? res.items.map(item => ({id: item.id, text: item.full_name})) : [])
            });
        this.subscriptions.push(usersSubscription);

        this.subscriptions.push(entitiesSubscription);

        this.trashCnt$ = this.store.pipe(select(selectTrashedToeCount));
        this.adminTrashCnt$ = this.store.pipe(select(selectAdminTrashedToeCount));

        // First load
        of(undefined).pipe(take(1), delay(1000)).subscribe(() => { // Remove this line, just loading imitation
            this.loadToesList();
        }); // Remove this line, just loading imitation


        // menu observer
        const menuSubjectSubscribe = this.menuSubject.asObservable().subscribe(refId => {
            switch (refId) {
                case 0:
                    // add TOE
                    this.addToe();
                    break;
                case 1:
                    // edit Assignment
                    this.router.navigate(['../../edit', this.assignmentId], {relativeTo: this.activatedRoute});
                    break;
                case 2:
                    // Audit Trail
                    localStorage.setItem('audit_trails_url', 'toe_list');
                    this.router.navigate(['../audit-trails'], {relativeTo: this.activatedRoute});
                    break;
                case 3:
                    this.addToeFromTemplate()
                default:
                    break;
            }
        });
        this.subscriptions.push(menuSubjectSubscribe);

        // Init query params
        this.lastQueryParams = new QueryParamsModel({});
        const lastQueryParamsSubscription = this.store.pipe(
            select(selectToesLastQuery),
            take(1)
        ).subscribe(res => {
            if (res.isEmpty()) {
                return;
            }
            this.lastQueryParams = res;
            this.updateFilter(res.filter);
        });
        this.subscriptions.push(lastQueryParamsSubscription);
    }


    /**
     * After init
     */

    ngAfterViewInit(): void {
        if (this.activatedRoute.snapshot.queryParamMap.has('show-trash')) {
            this.trash();
        }

        if (this.activatedRoute.snapshot.queryParamMap.has('show-admin-trash')) {
            this.adminTrash();
        }
    }

    /**
     * On Destroy
     */
    ngOnDestroy() {
        this.subscriptions.forEach(el => el.unsubscribe());
    }

    convertLeadValuer(toe: any) {
        return {
            firstName: toe.leadValuer?.worker?.first_name,
            lastName: toe.leadValuer?.worker?.last_name,
            email: toe.leadValuer?.worker?.email,
            picture: toe.leadValuer?.worker?.picture,
            qualification: this.qualifications.find(q => q.id == toe.leadValuer?.worker?.qualification_id)?.name,
            agency: toe.assignment?.agency?.name,
            role: toe.assignment?.valuer_id == toe.leadValuer?.worker?.id ? 'Project Manager & Lead Valuer' : 'Lead Valuer'
        }
    }

    /**
     * Load Toes List from service through data-source
     */
    loadToesList() {
        this.selection.clear();
        let queryParams = null;
        if (this.lastQueryParams.isEmpty()) {
            queryParams = new QueryParamsModel(
                this.filter,
                this.sort.direction,
                this.sort.active,
                this.paginator.pageIndex + 1,
                this.paginator.pageSize
            );
        } else {
            queryParams = this.lastQueryParams;
        }
        this.lastQueryParams = new QueryParamsModel({});
        // Call request from server
        this.store.dispatch(new ToesPageRequested({page: queryParams, assignmentId: this.assignmentId}));
        this.selection.clear();
    }

    /**
     * Returns object for filter
     */
    updateFilter(filter: any) {
        this.filter = filter;
        this.loadToesList();
    }

    /** ACTIONS */
    /**
     * Delete toe
     *
     * @param _item: ToeModel
     */
    deleteToe(_item: ToeModel) {
        const _title = 'Are you sure?';
        const _description = 'The ToE \"' + _item.name + '\" will be deleted';
        const _waitDesciption = 'ToE deleting';

        const dialogRef = this.layoutUtilsService.deleteElement(_title, _description, _waitDesciption);
        dialogRef.afterClosed().subscribe(res => {
            if (!res) {
                return;
            }

            this.store.dispatch(new OneToeDeleted({id: _item.id}));
        });
    }


    /**
     * Add TOE function
     */
    addToe() {
        this.editToe(null);
    }

    addToeFromTemplate() {
        const dialogRef = this.dialog.open(ToeTemplateListModalComponent, {
            data: {
                agencyID: this.assignment.agency_id
            }
        });
        const dialogSub = dialogRef.afterClosed().subscribe(
            res => {
                if (!res) {
                    return;
                }
                switch (res.type) {
                    case 'select': {
                        this.readonly.setReadOnly(false);
                        this.router.navigate(['../add-from-t/', res.template.id], {relativeTo: this.activatedRoute});
                        break;
                    }
                    case 'view': {
                        this.readonly.setReadOnly(true);
                        this.router.navigate(['/template/toes/t/edit/', res.template.id]);
                        break;
                    }
                    default: {
                        return;
                    }
                }
            }
        );
        this.subscriptions.push(dialogSub);
    }

    /**
     * Edit toe
     * @param toe: ToeModel
     */
    editToe(toe: ToeModel) {
        if (toe) {
            localStorage.setItem('toe_form_url', 'list');
            this.readonly.setReadOnly(false);
            this.router.navigate(['../edit', toe.id], {relativeTo: this.activatedRoute});
        } else {
            this.readonly.setReadOnly(false);
            this.router.navigate(['../add'], {relativeTo: this.activatedRoute});
        }
    }

    reachToe(toe: ToeModel) {
        this.router.navigate(['../toes/', toe.id], {relativeTo: this.activatedRoute});
    }

    back() {
        this.location.back();
    }


    trash() {
        this.store.dispatch(new ToeActionToggleLoading({isLoading: true}));
        this.toesService.getTrashedByAssignmentID(this.assignmentId).subscribe(res => {
            const items = [];
            res.data.forEach(elem => {
                items.push({
                    text: `#${elem.id} - ${elem.name && elem.name.length > 0 ? elem.name : 'N/A'}`,
                    id: elem.id.toString(),
                    hasPermanentlyDelete: true,
                    deletedUser: elem.userDeletedBy,
                    date: elem.user_deleted
                });
            });
            if (items.length > 0) {
                this.show_trash(items);
            }
        });
    }

    adminTrash() {
        this.store.dispatch(new ToeActionToggleLoading({isLoading: true}));
        this.toesService.getAdminTrashedByAssignmentID(this.assignmentId).subscribe(res => {

            this.store.dispatch(new ToeActionToggleLoading({isLoading: false}));
            const items = [];
            res.data.forEach(elem => {
                items.push({
                    text: `#${elem.id} - ${elem.name && elem.name.length > 0 ? elem.name : 'N/A'}`,
                    id: elem.id.toString(),
                    hasPermanentlyDelete: true,
                    date: elem.deleted_at,
                    deletedUser: elem.deletedBy,
                });
            });
            if (items.length > 0) {
                this.show_trash(items, true);
            }
        });
    }

    /**
     * Show Trash
     * @param items
     * @param isAdmin
     */
    show_trash(items, isAdmin = false) {
        let _title = 'Deleted Terms of Engagements';
        if (isAdmin) {
            _title = 'Admin Deleted Terms of Engagements';
        }
        this.layoutUtilsService.trashedEntities(_title, items, this, isAdmin);
    }

    restore(_id: number, isAdmin: boolean) {
        if (isAdmin) {
            this.store.dispatch(new ToeOnServerAdminRestored({id: _id}));
        } else {
            this.store.dispatch(new ToeOnServerRestored({toeId: _id}));
        }
    }

    hardDelete(id: number) {
        this.store.dispatch(new ToeDeletedFromAdminTrash({id}));
    }

    delete(id: number) {
        this.store.dispatch(new ToeDeletedFromTrash({id}));
    }

    flush() {
        this.store.dispatch(new ToeTrashFlushed());
    }

    /**
     * Check all rows are selected
     */
    isAllSelected(): boolean {
        const numSelected = this.selection.selected.length;
        const numRows = this.toeResult.length;
        return numSelected === numRows;
    }

    /**
     * Toggle all selections
     */
    masterToggle() {
        if (this.selection.selected.length === this.toeResult.length) {
            this.selection.clear();
        } else {
            this.toeResult.forEach(row => this.selection.select(row));
        }
    }

    /** UI */
    /**
     * Retursn CSS Class Name by status
     *
     * @param status: number
     */
    getItemCssClassByStatus(status: number = 0): string {
        switch (status) {
            case 0:
                return 'grey';
            case 1:
                return 'warning';
            case 2:
                return 'primary';
            case 3: 
                // return 'validated-light'
            case 4: 
                return 'validated'
            case -1:
                return 'danger';
            default:
                'success';
        }
        return '';
    }

    /**
     * Returns Item Status in string
     * @param _toe
     */
    getItemStatusString(_toe: ToeModel): string {
        switch (_toe.status) {
            case 0:
                return 'TOE IN PREPARATION';
            case 1:
                return 'VALIDATED';
            case 2:
                return 'ONGOING (' + _toe.percent + '%)';
            case 3:
                // return 'ONGOING (' + _toe.percent + '%)';
            case 4:
                return 'COMPLETED';
            case -1:
                return 'ABORTED';
        }
        return '';
    }

    dashboard(toe: ToeModel, v2: boolean = false) {
        if (v2) {
            this.router.navigate(['../toes/v2', toe.id, 'dashboard'], {relativeTo: this.activatedRoute});
            return;
        }
        this.router.navigate(['../toes/', toe.id, 'dashboard'], {relativeTo: this.activatedRoute});
    }

    // @HostListener('window:beforeunload')
    // saveState() {
    //     this.store.dispatch(new SaveState({
    //         name: 'assignments'
    //     }));
    // }

    changeStatus(event: MatCheckboxChange, toe: ToeModel) {
        const status = event.checked ? 4 : 3;
        const newToe: Update<ToeModel> = {
            id: toe.id,
            changes: {
                id: toe.id,
                status
            }
        };
        this.store.dispatch(new ToeStatusUpdated({toe: newToe}));
    }

    duplicateToe(toe: ToeModel) {
        const dialogRef = this.dialog.open(DuplicateToeDialogComponent, {
            data: {
                toe: toe
            }
        })
        dialogRef.afterClosed().subscribe(res => {
            if (!res) {
                return;
            }

            if (res == 'success') {
                this.loadToesList()
            }
        })
    }

    onMoveToe(toe: ToeModel) {
        const dialogRef = this.dialog.open(MoveToeDialogComponent, {
            width: '50vw',
            data: {
                toe: {
                id: toe.id
                }
            }
        });
        dialogRef.afterClosed().subscribe(res => {
            if (!res) {
                return;
            }

            if (res == 'success') {
                this.loadToesList()
            }
        })
    }

    onFilterChange(event) {
        this.paginator.pageIndex = 0;
        this.filter = event;
        this.loadToesList();
    }

    canAccessFileDownload(toe, currentUser) {
        if (toe.status <= 0) {
            return 'hidden';
        }
        if (!currentUser) {
            return 'hidden';
        }
        if (toe.assignment.valuer_id == currentUser.id) {
            return 'visible';
        }
        const memberIndx = toe.members.findIndex(member => member.worker_id == currentUser.id)
        if (memberIndx != -1) {
            return 'visible';
        }
        return 'hidden';
    }

    canAccessToe(toe, currentUser) {
        if (!currentUser) {
            return 'hidden'
        }
        if (toe.assignment.valuer_id == currentUser.id) {
            return 'visible'
        }
        const memberIndx = toe.members.findIndex(member => member.worker_id == currentUser.id)
        if (memberIndx != -1) {
            return 'visible';
        }
        return 'hidden';
    }

    onOpenDownloadFileModal(toe) {
        const dialogRef = this.dialog.open(DownloadToeFilesDialogComponent, {
            data: {
                toe_id: toe.id
            }
        })
    }
}
