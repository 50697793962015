import { ValuationProcessTargetPropertyRemote } from "../_services/valuation-process.service"
import { convertSize, displayConsiderations, mapSourceRemoteToDomain, mapVLandLocationRemoteToDomain, VAssetClassConsiderationLand, VAssetClassLandConsiderationRemote, VAssetClassSize, VAssetClassSourceRemote, VLandAreaComponentRemote, VLandLocationData, VLandLocationDataRemote, VLocationData } from "./valuation-asset-class-common.models"
import { PictureModel, TypeOfInspection, ValuationProcessLandModel } from "./valuation-process-target-property.model"

export type VLandModel = {
    refNum: string,
    propertySubType: 'Land',
    propertySubTypeName: string

    locationData: VLandLocationData,
    property: VLandAboutProperty,

    pictures: PictureModel[],
    sizes: VAssetClassSize[],
}

type VLandAboutPropertyBase = {
    landParcelName: string,
    coordinateReferencySystem: string,
    landCoverType: string,
    qualityOfSoilDegradation: string,
    comparativeLandUse: string,
    soureOfLandUseClassification: string,
    otherCharacteristics: string,
    landUseApproval: string,
}

export type VLandAboutProperty = VLandAboutPropertyBase & {
    hasBuilding: 'Yes',
    buildings: any[]
} | VLandAboutPropertyBase & {
    hasBuilding: 'No'
} | VLandAboutPropertyBase & {
    hasBuilding: 'Idk'
}

export type VCLandModel = VLandModel & {
    id: number

    considerations: VAssetClassConsiderationLand[]
    displayConsiderations: VAssetClassConsiderationLand[]

    status: number,
    usedTimes: number,
    propertyTypeName: string,
}

export type VTPLandModel = VLandModel & {
    refNum: 'tp',
    inspectionInfo: {
        typeOfInspection: TypeOfInspection,
        inspectionDate: Date
        anyLimitationOrRestriction: boolean,
        limitationDescription: string 
    },
}

export type VCLandRemote = {
    id: number,
    property_sub_type_id: 13,

    ref_num: string
    location_data: VLandLocationDataRemote

    status: number,
    used_times: number,
    property_sub_type_name: string,

    land_area_components: VLandAreaComponentRemote[]

    pictures: PictureModel[],
    building_exist: number,
    buildings: any[],

    land_cover_type: string,
    qos_degradation_name: string,
    comparative_land_use_name: string,
    sol_use_classification_name: string,
    other_characteristics: string,
    land_use_approval_implementation: string,
    coordinate_reference_system_name: string,
    land_parcel_name: string,

    considerations: VLandAssetClassConsiderationRemote[]
}

export type VLandAssetClassConsiderationRemote = {
    id: number,
    land_tenure_name: string,
    currency: string,
    consideration: number,
    conversion: {id: number, quote_currency: string, exchange_rate: number}[],
    source: VAssetClassSourceRemote
}

function mapVLandConsiderationRemoteToDomain(remotes: VLandAssetClassConsiderationRemote[]): VAssetClassConsiderationLand[] {
    const considerations: VAssetClassConsiderationLand[] = remotes.map(remote => {
        return {
            id: remote.id,
            tenureName: remote.land_tenure_name,
            currency: remote.currency,
            value: remote.consideration,
            considerationType: 'Land',
            // conversion: [],
            conversion: remote.conversion.map(con => ({id: con.id, quoteCurrency: con.quote_currency, exchangeRate: con.exchange_rate})),
            source: mapSourceRemoteToDomain(remote.source)
        } as const
    })
    considerations.sort((a, b) => {
        if (a.source == null || b.source == null) {
            return -1
        }
        const aDate = a.source.sourceType == 'Advertised' ? a.source.informationDate : a.source.transactionDate
        const bDate = b.source.sourceType == 'Advertised' ? b.source.informationDate : b.source.transactionDate
        return bDate.getTime() - aDate.getTime()
    })
    return considerations
}

function landDisplayConsiderations(considerations: VAssetClassConsiderationLand[]): VAssetClassConsiderationLand[] {
    const items = []
    if (considerations.length > 0) {
        items.push(considerations[0])
    }
    return items
}


export function mapVCLandRemoteToDomain(remote: VCLandRemote): VCLandModel {
    const considerations = mapVLandConsiderationRemoteToDomain(remote.considerations)
    const locationData = mapVLandLocationRemoteToDomain(remote.location_data)
    const propertyBase: VLandAboutPropertyBase = {
        landParcelName: remote.land_parcel_name,
        coordinateReferencySystem: remote.coordinate_reference_system_name,
        qualityOfSoilDegradation: remote.qos_degradation_name,
        comparativeLandUse: remote.comparative_land_use_name,
        landCoverType: remote.land_cover_type,
        soureOfLandUseClassification: remote.sol_use_classification_name,
        otherCharacteristics: remote.other_characteristics,
        landUseApproval: remote.land_use_approval_implementation
    }
    return {
        id: remote.id,
        propertySubType: 'Land',

        refNum: remote.ref_num,
        locationData: locationData,

        status: remote.status,
        usedTimes: remote.used_times,
        propertySubTypeName: remote.property_sub_type_name,

        sizes: mapVLandSize(remote.land_area_components),
        pictures: remote.pictures,

        considerations,
        displayConsiderations: landDisplayConsiderations(considerations),

        propertyTypeName: 'Land',
        
        property: mapVLandAboutProperty(propertyBase, remote.building_exist, remote.buildings) 
    }
}

function mapVLandAboutProperty(baseProperty: VLandAboutPropertyBase, hasBuilding: number, buildings: any[]): VLandAboutProperty {
    switch (hasBuilding) {
        case 1: {
            return {
                ...baseProperty,
                hasBuilding: 'Yes',
                buildings,
            }
        }
        case 0: {
            return {
                ...baseProperty,
                hasBuilding: 'No'
            }
        }
        default: {
            return  {
                ...baseProperty,
                hasBuilding: 'Idk'
            }
        }
    }
}

export function mapVTPLandRemoteToDomain(tpRemote: ValuationProcessTargetPropertyRemote, generalInfo: ValuationProcessLandModel): VTPLandModel {
    const propertyBase: VLandAboutPropertyBase = {
        landParcelName: null,
        coordinateReferencySystem: tpRemote.landParcel.coordinateRefSystem,
        qualityOfSoilDegradation: tpRemote.landParcel.qos_degradation_name,
        comparativeLandUse: tpRemote.landParcel.comparative_land_use_name,
        landCoverType: tpRemote.landParcel.land_cover_type,
        soureOfLandUseClassification: tpRemote.landParcel.sol_use_classification_name,
        otherCharacteristics: tpRemote.landParcel.other_characteristics,
        landUseApproval: tpRemote.landParcel.land_use_approval_implementation
    }
    return {
        refNum: 'tp',
        propertySubType: 'Land',
        locationData: {
            latitude: generalInfo.locationData.latitude,
            longitude: generalInfo.locationData.longitude,
            country: generalInfo.locationData.countryName,
            city: generalInfo.locationData.cityName,
            zipCode: generalInfo.locationData.zipCode,
            address: generalInfo.locationData.address,
            timezoneOffset: generalInfo.locationData.timezoneOffset,
            locationGrade: generalInfo.locationGrade == 'N/A' ? 'Unknown' : generalInfo.locationGrade,
            points: tpRemote.landParcel.lp_path.map(p => ({lat: Number(p.lat), lng: Number(p.lng)})).filter(p => p.lat != null && p.lng != null)
        },
        inspectionInfo: {
            typeOfInspection: tpRemote.inspectionTypeId,
            inspectionDate: new Date(tpRemote.inspectionDate),
            anyLimitationOrRestriction: tpRemote.anyLimitationsOrRestrictions,
            limitationDescription: tpRemote.limitationDesc
        },

        sizes: mapVLandSize(tpRemote.landParcel.landAreas),
        pictures: tpRemote.pictures,
        propertySubTypeName: tpRemote.propertySubTypeName,
        property: mapVLandAboutProperty(propertyBase, tpRemote.landParcel.building_exists, tpRemote.landParcel.buildings)
    }
}

function mapVLandSize(landAreaComponents: VLandAreaComponentRemote[]): VAssetClassSize[] {
    if (landAreaComponents.length == 0) {
        return [{
            standardMeasurementId: 10,
            standardMeasurementName: '(International) - ILMS',
            unitOfAreaMeasurementId: -1,
            unitOfAreaMeasurementAcronym: 'Unknown',
            size: 0
        }]
    }
    const unitAreameasurementId = landAreaComponents[0].unit_area_measurement_id
    const unitAreaMeasurementAcronym = landAreaComponents[0].unit_area_measurement_acronym
    const total= landAreaComponents.reduce((acc, component) => {
        acc = acc + convertSize(component.surface, component.unit_area_measurement_id, unitAreameasurementId)
        return acc
    }, 0)
    const size: VAssetClassSize = {
        standardMeasurementId: 10,
        standardMeasurementName: '(International) - ILMS',
        unitOfAreaMeasurementId: unitAreameasurementId,
        unitOfAreaMeasurementAcronym: unitAreaMeasurementAcronym,
        size: total
    }
    return [size]
}