import { Component, OnInit, Renderer2 } from '@angular/core';
import { ActivatedRoute } from "@angular/router";
import { MadAuthService } from 'src/app/core/mad-auth/mad-auth.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'kt-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {

  constructor(
    private _route: ActivatedRoute,
    private _authService: MadAuthService,
    private _renderer2: Renderer2
  ) { }

  ngOnInit(): void {
    checkCorporateEmail.checkEmailFunc();
    labelChange.labelChangeFunc();
    const planUid = this._route.snapshot.queryParamMap.get('planUid') as string
    const planPaymentTerm = this._route.snapshot.queryParamMap.get('planPaymentTerm') as string
    const isDemo = environment.isDemo;
    const url = isDemo
      ? 'https://demo.interval-soft.com/mad-auth/register'
      : 'https://app.interval-soft.com/mad-auth/register'

    if (planUid && planPaymentTerm) {
      this._authService.loadRegisterSpecificScript(this._renderer2, planUid, planPaymentTerm, url)
    } else {
      this._authService.loadRegisterScript(this._renderer2, url)
    }
  }

}
