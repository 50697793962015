import { createAction, props } from "@ngrx/store"
import { CalculationMeta, CostRevenue, HeadlineToEffectiveMeta } from "../types"

export const updateHeadlineToEffectiveOfLease = createAction(
  '[Valuation Process Page - V2 / Valuation Tab] Update Headline to Effective Rent',
  props<{
    uuid: string,
    kind: CostRevenue['kind']
    meta: HeadlineToEffectiveMeta
  }>()
)

export const updateModelAssumptions = createAction(
  '[Valuation Process Page - V2 / Valuation Tab] Update Calculation Meta',
  props<{
    uuid: string,
    kind: CostRevenue['kind'] 
    data: Partial<CalculationMeta>
  }>()
)

export const updateMethodAndTiming = createAction(
  '[Valuation Process Page - V2 / Valuation Tab] Update Method and Timing',
  props<{
    uuid: string,
    kind: CostRevenue['kind'],
    data: {
      methodId: number,
      timingId: number,
      exchangeRate: number,
      reportingToLeaseCurrency: boolean
    }
  }>()
)

export const updateValuationDate = createAction(
  '[Valuation Process Page - V2 / Valuation Tab] Update Valuation Date',
  props<{
    valuationDate: Date
  }>()
)

export const updateSubLease = createAction(
  '[Valuation Process Page - V2 / Valuation Tab] Update Sub Lease',
  props<{
    uuid: string,
    kind: CostRevenue['kind'],
    data: CostRevenue
  }>()
)

export const updateSubLeaseHeadlineToEffectiveMeta = createAction(
  '[Valuation Process Page - V2 / Valuation Tab] Update Sub Lease Headline To Effective Meta',
  props<{
    uuid: string,
    kind: CostRevenue['kind']
    meta: HeadlineToEffectiveMeta
  }>()
)
