<kt-portlet>
    <!-- See prop => '../../_core/models/data-sources/_base.datasource.ts' (loading$) -->
    <kt-portlet-header [class]="'card-head-lg'" [viewLoading$]="dataSource.loading$"
                        [tooltipTitle]="'TOOLTIP.APARTMENT.LIST.TITLE' | translate"
                        [tooltipDesc]="'TOOLTIP.APARTMENT.LIST.DESCRIPTION' | translate">
        <!-- PORTLET LOADING | Binded to TABLE Datasource -->

        <ng-container ktPortletTitle>
            <h3 class="card-head-title">
                <span>{{ 'APARTMENT.LIST.TITLE.LIST_VIEW' | translate }}</span>
            </h3>
            <!-- For localisations we use @ngx-translate | See off. documentations => https://github.com/ngx-translate/core -->
            <!-- Localization libraries (en/fr) are here => '../../../.././config/i18n/en.ts|fr.ts' -->
        </ng-container>

        <ng-container ktPortletTools>
            <div>
                <input #fileInput type="file" (change)="importComparable($event)" style="display: none" multiple accept="{{fileType}}"/>
            </div>
            <kt-context-menu-mad
                [(menuItems)]="menuItems"
                [(menuSubject)]="menuSubject"
                [width]="'230'"
                menuName="Import"
                class="ml-2">
            </kt-context-menu-mad>
            <button (click)="addResidential()" mat-raised-button class="ml-2"
                [matTooltip]="'APARTMENT.LIST.BUTTON.NEW.TOOLTIP' | translate" color="primary" type="button">
                <span>{{ 'APARTMENT.LIST.BUTTON.NEW.LABEL' | translate }}</span>
            </button>

            <button (click)="goMap()"
                    mat-raised-button
                    [matTooltip]="'APARTMENT.LIST.BUTTON.MAP_VIEW.TOOLTIP' | translate"
                    color="primary"
                    type="button"
                    class="ml-2">
                <span>{{ 'APARTMENT.LIST.BUTTON.MAP_VIEW.LABEL' | translate }}</span>
            </button>
            <button
                    (click)="trash()"
                    *ngIf="trashCnt != 0"
                    mat-raised-button
                    [matTooltip]="'APARTMENT.LIST.BUTTON.TRASHED.TOOLTIP' | translate"
                    type="button"
                    style="background-color: #c4c5d6 !important;"
                    class="ml-2">
                <span>{{ 'GENERAL.BUTTON.TRASHED' | translate }} ({{trashCnt }})</span>
            </button>
            <button
                    (click)="adminTrash()"
                    *ngIf="adminTrashCnt != 0 && (canAccessAdminTrash$|async)"
                    mat-raised-button
                    [matTooltip]="'APARTMENT.LIST.BUTTON.ADMIN_TRASHED.TOOLTIP' | translate"
                    type="button"
                    style="background-color: #c4c5d6 !important;"
                    class="ml-2">
                <span>{{ 'GENERAL.BUTTON.ADMIN_TRASHED' | translate }} ({{adminTrashCnt }})</span>
            </button>
        </ng-container>
    </kt-portlet-header>
    <!-- end::Header -->

    <kt-portlet-body>
        <kt-comparable-stats [acType]="1"></kt-comparable-stats>
        <kt-filter-section
            [acType]="1"
            [filter$]="filterObs$"
            (filterChange)="onFilterChange($event)"
            ></kt-filter-section>

        <div class="mat-table-wrapper">
            <mat-table class="lmat-elevation-z8 table-striped"
                       #table
                       [dataSource]="dataSource"
                       matSort
                       #sort1="matSort"
                       matSortActive="id"
                       matSortDirection="desc"
                       matSortDisableClear>
                <!-- Checkbox Column -->

                <ng-container matColumnDef="id">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>#</mat-header-cell>
                    <mat-cell
                            *matCellDef="let acr">{{acr.ref_num}}
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="sub_type">
                    <mat-header-cell *matHeaderCellDef>
                        Sub-type
                    </mat-header-cell>
                    <mat-cell *matCellDef="let acr">
                        {{acr.sub_type_category_name == 'Unknown'
                            ? 'Unknown'
                            : (acr.sub_type_category_name)}} 
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="size">
                    <mat-header-cell *matHeaderCellDef>
                        {{ 'APARTMENT.LIST.DATATABLE.SIZE' | translate }}
                    </mat-header-cell>
                    <mat-cell *matCellDef="let acr">{{acr.size}}</mat-cell>
                </ng-container>

                <ng-container matColumnDef="consideration">
                    <mat-header-cell *matHeaderCellDef>
                        {{ 'APARTMENT.LIST.DATATABLE.CONSIDERATION' | translate }}
                    </mat-header-cell>
                    <mat-cell *matCellDef="let acr">
                        <div style="display: flex; flex-direction: column;" *ngIf="acr.display_tenures.length > 0 else no_data">
                            <div *ngFor="let t of acr.display_tenures">
                                <ng-container *ngIf="t.consideration_type_id === 1">
                                    {{t.total_consideration.toFixed(2) | mask:'separator':{thousandSeparator: ','} }} {{t.currency}}
                                </ng-container>
                                <ng-container *ngIf="t.consideration_type_id === 2">
                                    <ng-container *ngIf="t.rent_input_amount_type === 'total'">
                                        {{t.total_consideration.toFixed(2) | mask:'separator':{thousandSeparator: ','} }} {{t.currency}}/{{t.rent_basis}}
                                    </ng-container>
                                    <ng-container *ngIf="t.rent_input_amount_type === 'per_units'">
                                        {{(t.total_consideration * (t.rent_size ? t.rent_size.size : 0)).toFixed(2) | mask:'separator':{thousandSeparator: ','} }} {{t.currency}}/{{t.rent_basis}}
                                    </ng-container>
                                </ng-container>
                            </div>
                        </div>
                        <ng-template #no_data>
                            {{ 'No Data' }}
                        </ng-template>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="building_id">
                    <mat-header-cell *matHeaderCellDef>
                        {{ 'APARTMENT.LIST.DATATABLE.BUILDING_ID' | translate }}
                    </mat-header-cell>
                    <mat-cell *matCellDef="let acr">{{acr.building_info && acr.building_info.building_name ? acr.building_info.building_name : 'N/A'}}</mat-cell>
                </ng-container>

                <ng-container matColumnDef="source_date">
                    <mat-header-cell *matHeaderCellDef>
                        {{ 'APARTMENT.LIST.DATATABLE.SOURCE_DATE' | translate }}
                    </mat-header-cell>
                    <mat-cell *matCellDef="let acr">
                        <!-- <div style="display: flex; flex-direction: column;" *ngIf="acr.display_tenures.length > 0 else no_data">
                            <div *ngFor="let t of acr.display_tenures">
                                {{t.source.source_date 
                                    ? (t.source.source_date | date: 'dd MMM yyy') + (acr.locationData.time_offset ? ' (GMT ' + acr.locationData.time_offset + ')' : '') 
                                    : 'N/A'}}
                            </div>
                        </div> -->
                        <div style="display: flex; flex-direction: column;" *ngIf="acr.display_tenures.length > 0 else no_data">
                            <div *ngFor="let t of acr.display_tenures">
                                {{t.source.source_date 
                                    ? (t.source.source_date | date: 'dd MMM yyy')
                                    : 'N/A'}}
                            </div>
                        </div>
                        <ng-template #no_data>
                            {{ 'No Data' }}
                        </ng-template>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="status">
                    <mat-header-cell *matHeaderCellDef>
                        Status
                    </mat-header-cell>
                    <mat-cell *matCellDef="let acr">
                        <b>
                            <span class="label label-inline label-rounded label-outline-{{getItemCssClassByStatus(acr.status)}} label-outline-2x label-lg"
                                style="width: 10rem;">
                                {{getItemStatusString(acr.status)}}
                            </span>
                        </b>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="used_times">
                    <mat-header-cell *matHeaderCellDef>
                        Used (times)
                    </mat-header-cell>
                    <mat-cell *matCellDef="let ac">
                        {{ac.used_times == 0
                            ? 'Never used'
                            : ('Used ' + ac.used_times + ' time' + (ac.used_times == 1 ? '' : 's'))}}
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="actions">
                    <mat-header-cell *matHeaderCellDef>
                        {{ 'APARTMENT.LIST.DATATABLE.ACTIONS' | translate }}
                    </mat-header-cell>
                    <mat-cell *matCellDef="let acr">
                        <!-- Information Tooltip -->
                        <ng-template #popTitle>
                            {{ 'GENERAL.INFORMATION_TOOLTIP.TITLE' | translate }}
                        </ng-template>
                        <ng-template #popContent>
                            <p>
                                <b>Sub Category:</b> {{acr.sub_category_name}}
                            </p>
                            <p>
                                <b>Status:</b> {{getItemStatusString(acr.status)}}
                            </p>
                            <p>
                                <b>Used (times):</b>
                                {{acr.used_times == 0
                                    ? 'Never used'
                                    : ('Used ' + acr.used_times + ' time' + (acr.used_times == 1 ? '' : 's'))}}
                            </p>
                            <p [innerHTML] ="'GENERAL.INFORMATION_TOOLTIP.CREATED_BY' | translate:{
                                    username: acr.createdBy ? acr.createdBy : 'Unknown',
                                    date: (acr.created_at | date: 'dd MMM yyyy':currentUser.settings.time_zone_gmt)
                                }">
                            </p>
                            <p [innerHTML] ="'GENERAL.INFORMATION_TOOLTIP.LAST_EDITED_BY' | translate:{
                                    username: acr.lastUpdatedBy ? acr.lastUpdatedBy : 'Unknown',
                                    date: (acr.updated_at | date: 'dd MMM yyyy':currentUser.settings.time_zone_gmt)
                                }">
                            </p>
                        </ng-template>
                        <button mat-icon-button 
                            *ngIf="acr.status == 1"
                            matTooltip="Duplicate the Comparable"
                            (click)="duplicate(acr.id)">
                            <i [style.color]="acr.status !== 0 ? 'green' : 'grey'" class="flaticon2-layers"></i>
                        </button>
                        <button mat-icon-button
                            color="primary"
                            [ngbPopover]="popContent"
                            [popoverTitle]="popTitle"
                            *ngIf="currentUser"
                        >
                            <mat-icon
                                [matTooltip]="'GENERAL.TOOLTIP.INFORMATION' | translate"
                            >info</mat-icon>
                        </button>

                        <button mat-icon-button
                                color="primary"
                                [matTooltip]="'APARTMENT.LIST.BUTTON.EDIT.TOOLTIP' | translate"
                                (click)="editAssetClassResidential(acr)">
                            <mat-icon>create</mat-icon>
                        </button>&nbsp;

                            <button mat-icon-button
                                    color="warn"
                                    type="button"
                                    [disabled]="!acr.can_be_deleted"
                                    (click)="deleteAssetClassResidential(acr)">
                                <mat-icon [matTooltip]="!acr.can_be_deleted ? 'Cannot delete' : ('APARTMENT.LIST.BUTTON.DELETE.TOOLTIP'|translate)">delete</mat-icon>
                            </button>&nbsp;
                    </mat-cell>
                </ng-container>

                <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>

                <mat-row *matRowDef="let row; columns: displayedColumns;let index = index"
                         [ngClass]="{gray: index%2}"></mat-row>
            </mat-table>
            <!-- Message for empty data  -->
            <div class="mat-table-message" *ngIf="!dataSource.hasItems">
                {{ 'GENERAL.MESSAGE.NO_DATA' | translate }}
            </div>
            <div class="mat-table-message" *ngIf="dataSource.isPreloadTextViewed$ | async">
                {{ 'GENERAL.MESSAGE.PLEASE_WAIT' | translate }}
            </div>
        </div>

        <!-- start: BOTTOM -->
        <div class="mat-table-bottom">
            <!-- MATERIAL SPINNER | Url: 'https://material.angular.io/components/progress-spinner/overview' -->
            <mat-spinner [diameter]="20" *ngIf="dataSource.loading$ | async"></mat-spinner>
            <!-- MATERIAL PAGINATOR | Binded to dasources -->
            <!-- See off.documentations 'https://material.angular.io/components/paginator/overview' -->
            <mat-paginator [pageSize]="25" [pageSizeOptions]="[25, 50, 100]" [length]="dataSource.paginatorTotal$ | async" [showFirstLastButtons]="true"></mat-paginator>
        </div>
        <!-- end: BOTTOM -->
    </kt-portlet-body>
    <!-- end::Body -->

</kt-portlet>