<kt-portlet>
  <kt-portlet-header
    [sticky]="true"
    title="Landmarks"
    [class]="'kt-portlet-head-lg'"
    [viewLoading$]="loading$"
    >
  <ng-container ktPortletTools>
    <a mat-raised-button matTooltip="Back to Toe dashboard" [routerLink]="['../../dashboard']">
      <mat-icon>arrow_back</mat-icon>
      <span class="kt-hidden-mobile">Close</span>
    </a>
    <button class="btn btn-success mr-2" color="submit" mat-raised-button (click)="onSubmit()">
      <span>Save</span>
    </button>
  </ng-container>
  </kt-portlet-header>

  <kt-portlet-body>
    <div class="card-body pt-0">
      <form [formGroup]="form">
        <agm-map [latitude]="centerLat$|async"
          [longitude]="centerLng$|async"
          [zoom]="zoom">
          <agm-map-type-control></agm-map-type-control>
          <button type="button"
            id="Profile"
            class="btn btn-light btn-icon mr-2 map-button"></button>
          <agm-marker [latitude]="tpLat$|async" [longitude]="tpLng$|async" [iconUrl]="tpMapIcon">
          </agm-marker>
          <ng-container *ngIf="landmarks$|async as landmarks">
            <agm-marker *ngFor="let item of landmarks"
              [latitude]="item.latitude"
              [longitude]="item.longitude"
              [iconUrl]="getIcon(item)"
              (markerClick)="showInfo(item, info)">
                    <agm-info-window #info
                                     [disableAutoPan]="true"
                                     style="padding: 2rem">
                        <div class="card card-custom landmark-info-window">
                            <div class="card-header p-1">
                                <div class="card-title">
                                    <ng-container *ngIf="item.picture.length > 0">
                                        <img 
                                            [src]="baseMediaUrl + '/' + item.picture"
                                            (error)="imgOnError($event)"
                                            style="
                                                height: 50px;
                                                width: 50px;
                                                object-fit: cover;
                                            " loading="lazy">
                                    </ng-container>
                                    <h4 class="card-label ml-6">{{item.name}}</h4>
                                </div>
                                <a (click)="addKeyPlace(item, info)" style="float: right; cursor: pointer;">
                                    {{alreadyAdded(item) ? 'Remove' : 'Add'}}
                                </a>
                            </div>
                            <div class="card-body p-1">
                                <p class="mb-1">
                                    <span class="text-mad">Category: </span>
                                    {{item.category_name}}
                                </p>
                                <p class="mb-1">
                                    <span class="text-mad">Description: </span>
                                    {{item.description}}
                                </p>
                                <!-- <a (click)="addKeyPlace(item, readonly, info)" style="float: right; cursor: pointer;">
                                    {{alreadyAdded(item) ? 'Remove' : 'Add'}}
                                </a> -->
                            </div>
                        </div>
                    </agm-info-window>
            </agm-marker>
          </ng-container>  
        </agm-map>

        <div class="row">
          <div class="col-lg-4 pt-2">
            <mat-checkbox
              [checked]="allSelected$ | async"
              (change)="onChangeAll($event)">
              All
            </mat-checkbox>
          </div>
          <div *ngFor="let category of (categories$|async); let i = index" class="col-lg-4 pt-2">
            <mat-checkbox
              [checked]="category.selected"
              (change)="onChangeCategory($event, category.id)"
              >
              {{category.name}}
            </mat-checkbox>
          </div>
        </div>

        <div class="card card-custom">
          <div class="card-header landmark-header">
            <div class="card-title"></div>
            <div class="card-toolbar">
              <div>
                <button type="button" (click)="onAddLandmark()" mat-raised-button
                  class="float-right m-0 ml-4 btn">
                  <span>Add Landmark</span>
                </button>
              </div>
            </div>
          </div>
          <div class="card-body landmark-body">
            <div class="form-group kt-form-group row">
              <div class="mat-table-wrapper">
                <mat-table class="mat-eleveation-z0 table-striped"
                  [dataSource]="dataSource">
                  <ng-container matColumnDef="id">
                    <mat-header-cell *matHeaderCellDef>#</mat-header-cell>
                    <mat-cell *matCellDef="let landmark">
                      {{landmark.id}}
                    </mat-cell>
                  </ng-container>

                  <ng-container matColumnDef="name">
                    <mat-header-cell *matHeaderCellDef>Name</mat-header-cell>
                    <mat-cell *matCellDef="let landmark">
                      {{landmark.name}}
                    </mat-cell>
                  </ng-container>

                  <ng-container matColumnDef="description">
                    <mat-header-cell *matHeaderCellDef>Description</mat-header-cell>
                    <mat-cell *matCellDef="let landmark">
                      {{landmark.description | truncate: ['100']}}
                    </mat-cell>
                  </ng-container>

                  <ng-container matColumnDef="category">
                    <mat-header-cell *matHeaderCellDef>Category</mat-header-cell>
                    <mat-cell *matCellDef="let landmark">
                      {{landmark.category_name}}
                    </mat-cell>
                  </ng-container>

                  <ng-container matColumnDef="city_of_location">
                    <mat-header-cell *matHeaderCellDef>Location</mat-header-cell>
                    <mat-cell *matCellDef="let landmark">
                      {{landmark.city_of_location}}
                    </mat-cell>
                  </ng-container>

                  <ng-container matColumnDef="actions">
                    <mat-header-cell *matHeaderCellDef>Actions</mat-header-cell>
                    <mat-cell *matCellDef="let landmark">
                      <span
                              [matTooltip]="'Unselect Landmark'">
                          <button mat-icon-button
                                  color="warn"
                                  type="button"
                                  (click)="deleteKeyplace(landmark)">
                              <mat-icon>delete</mat-icon>
                          </button>&nbsp;
                      </span>
                    </mat-cell>
                  </ng-container>

                  <mat-header-row *matHeaderRowDef="['id', 'name', 'description', 'city_of_location', 'actions']"></mat-header-row>
                  <mat-row *matRowDef="let row; columns: ['id', 'name', 'description', 'city_of_location', 'actions']; let index = index"
                    [ngClass]="{'colored-row': index%2}"></mat-row>
                </mat-table>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </kt-portlet-body>
</kt-portlet>