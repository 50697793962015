<kt-portlet>
    <kt-portlet-header
            [sticky]="true"
            [title]="getComponentTitle()"
            [class]="'kt-portlet-head--lg'"
            [tooltipTitle]="'TOOLTIP.INSPECTION.FORM.TITLE' | translate"
            [tooltipDesc]="'TOOLTIP.INSPECTION.FORM.DESCRIPTION' | translate"
            [viewLoading$]="loading$">
        <ng-container ktPortletTools>
            <a href="javascript:;"
                (click)="back()"
                class="btn mr-2" mat-raised-button matTooltip="Back to ToE dashboard">
                <mat-icon>arrow_back</mat-icon>
                <span class="kt-hidden-mobile">Close</span>
            </a>
            <a  
                *ngIf="!readonly"
                href="javascript:;"
                class="btn btn-success mr-2"
                color="warn"
                style="background-color: orange !important;"
                (click)="onSubmit(false)"
                mat-raised-button
                matTooltip="Save to Draft">
                <span class="kt-hidden-mobile">Save draft</span>
            </a>
            <a 
                *ngIf="!readonly"
                href="javascript:;"
                mat-raised-button
                class="btn btn-success mr-2"
                color="submit"
                (click)="onSubmit(true)"
                matTooltip="Save to Validate">
                <span class="kt-hidden-mobile">Validate</span>
            </a>
        </ng-container>
    </kt-portlet-header>

    <kt-portlet-body >
        <form class="form" [formGroup]="assetClassDetailForm" *ngIf="assetClassDetail" autocomplete="off" [ngClass]="{'form-disabled': readonly}">
            <div class="card-body pt-0">
                <kt-tab-header
                    [headerLabels]="tabHeaders"
                    [selectedTabChange]="selectedtabchange$"
                    (tabChange)="onTabChange($event)">
                </kt-tab-header>
                <mat-tab-group class="invinsible-tabs"
                    [(selectedIndex)]="selectedTab"
                    (selectedTabChange)="onTabChanged($event)">
                    <!-- <mat-tab>
                        <kt-form-error
                            [hasFormErrors]="hasFormErrors"
                            [tabHeaders]="tabHeaders"
                            [error]="error"
                            [erroTabMap]="erroTabMap()"
                            (hasFormErrorsChange)="onHasFormErrorsChange($event)">
                        </kt-form-error>
                        <kt-inspection-details
                            [typeOfInspection]="assetClass.type_of_inspection"
                            [readonly]="readonly"
                            [agencyName]="agencyName"
                            [clientName]="clientName"
                            [acDetailID]="assetClassDetail.id"
                            [participantsSubject]="participantsSubject"
                            [inputData]="inspectionDetailData"
                            [agencyId$]="agencyId$"
                            [toeId$]="toeId$"
                            [clientId$]="clientId$"
                            ></kt-inspection-details>
                    </mat-tab> -->
                    <mat-tab>
                        <div class="form-group kt-form-group row"
                            [class.section-margin-top-60]="assetClass.type_of_inspection != 1">
                            <div class="col-md-12 kt-margin-bottom-20-mobile">
                                <h5 class="my-3 text-mad text-uppercase">{{(assetClass.top_property_type_id === propertyTypes.Land ? 'INSPECTION.LOCATION_LAND_PARCEL' : 'INSPECTION.LOCATION') | translate}}</h5>
                                <hr class="active">
                            </div>

                            <section class="col-lg-12">
                                <div class="row">
                                    <div class="col-lg-4 kt-margin-bottom-10-mobile">
                                        <mat-form-field class="mat-form-field-fluid">
                                            <input matInput placeholder="Land Parcel ID" formControlName="name"/>
                                        </mat-form-field>
                                    </div>
                                    <div class="col-lg-6 kt-margin-bottom-10-mobile">
                                        <mat-form-field class="mat-form-field-fluid">
                                            <mat-select placeholder="Coordinate Reference System" formControlName="coordinate_reference_system_id">
                                                <mat-option *ngFor="let coordinateReferenceSystem of coordinateReferenceSystems" [value]="coordinateReferenceSystem.id">{{coordinateReferenceSystem.name}}</mat-option>
                                            </mat-select>
                                            <mat-icon
                                                matSuffix
                                                class="cursor-pointer"
                                                (click)="$event.stopPropagation();"
                                                container="body"
                                                [ngbPopover]="'TOOLTIP.LAND_PARCEL.CRS.DESCRIPTION' | translate">help
                                            </mat-icon>
                                            <mat-error>
                                                Location Grade
                                                <strong>required</strong>
                                            </mat-error>
                                        </mat-form-field>
                                    </div>
                                    <div class="col-lg-12 mt-2">
                                        <mat-label style="color: gray;"> 
                                            Please draw on the map below the coordinates of each boundary cover or pivot point of the land parcel:
                                        </mat-label>
                                        <mat-icon
                                            matSuffix
                                            class="cursor-pointer"
                                            (click)="$event.stopPropagation();"
                                            container="body"
                                            [ngbPopover]="'TOOLTIP.LAND_PARCEL.MAP.DESCRIPTION' | translate">help
                                        </mat-icon>
                                    </div>
                                </div>
                            </section>
                            <ng-container *ngIf="assetClass.top_property_type_id === propertyTypes.Land">
                                <div class="col-lg-12 mb-10"> 
                                    <kt-land-parcel-map 
                                        [points]="points"
                                        [centerLat]="centerLat" 
                                        [centerLng]="centerLng"
                                        [staticUnitLengthName]="staticUnitLengthName"
                                        [staticUnit]="staticUnit"
                                        [staticUnitName]="staticUnitName">
                                        <div class="col-lg-12 kt-margin-bottom-10-mobile md-2">
                                            <mat-form-field class="mat-form-field-fluid">
                                                <input matInput type="text" (keydown.enter)="$event.preventDefault()"
                                                    placeholder="Search By City" autocorrect="off" autocapitalize="off"
                                                    spellcheck="off" #search/>
                                                <mat-icon
                                                        matSuffix
                                                        class="cursor-pointer"
                                                        (click)="$event.stopPropagation();"
                                                        container="body"
                                                        [popoverTitle]="'TOOLTIP.INSPECTION.LOCATE.TITLE' | translate"
                                                        [ngbPopover]="'TOOLTIP.INSPECTION.LOCATE.DESCRIPTION' | translate">help
                                                </mat-icon>
                                            </mat-form-field>
                                        </div>
                                    </kt-land-parcel-map>
                                </div>

                            </ng-container>

                            <div class="col-lg-4 kt-margin-bottom-20-mobile">
                                <mat-form-field class="mat-form-field-fluid">
                                    <mat-select placeholder="Country" formControlName="country_id" (selectionChange)="selectCountry()">
                                        <mat-option *ngFor="let country of allCountries" [value]="country.id">{{country.name}}</mat-option>
                                    </mat-select>
                                    <mat-error>Country
                                        <strong>required</strong>
                                    </mat-error>
                                </mat-form-field>
                            </div>
                            <div class="col-lg-4 kt-margin-bottom-20-mobile">
                                <mat-form-field class="mat-form-field-fluid">
                                    <mat-select placeholder="City" formControlName="city_id">
                                        <mat-option [value]="-1">Unknown</mat-option>
                                        <mat-option [value]="0">Not Applicable</mat-option>
                                        <mat-option *ngFor="let city of cityOfCountry" [value]="city.id">{{city.name}}</mat-option>
                                    </mat-select>
                                    <mat-error>City
                                        <strong>required</strong>
                                    </mat-error>
                                </mat-form-field>
                            </div>
                            <div class="col-lg-4 kt-margin-bottom-10-mobile">
                                <mat-form-field class="mat-form-field-fluid">
                                    <input matInput placeholder="Zipcode/Postalcode" formControlName="zip_code"/>
                                </mat-form-field>
                            </div>


                            <div class="col-lg-4 kt-margin-bottom-10-mobile">
                                <mat-form-field class="mat-form-field-fluid">
                                    <mat-select placeholder="Location Grade" formControlName="location_grade_id">
                                        <mat-option *ngFor="let grade of grades" [value]="grade.id">{{grade.name}}</mat-option>
                                    </mat-select>
                                    <mat-icon
                                            matSuffix
                                            class="cursor-pointer"
                                            (click)="$event.stopPropagation();"
                                            placement="left"
                                            container="body"
                                            [popoverTitle]="'TOOLTIP.INSPECTION.LOCATION_GRADE.TITLE' | translate"
                                            [ngbPopover]="'TOOLTIP.INSPECTION.LOCATION_GRADE.DESCRIPTION' | translate">help
                                    </mat-icon>
                                    <mat-error>
                                        Location Grade
                                        <strong>required</strong>
                                    </mat-error>
                                </mat-form-field>
                            </div>

                            <div class="col-lg-12 kt-margin-b-10-mobile">
                                <mat-form-field class="mat-form-field-fluid">
                                    <textarea matInput rows="4" placeholder="Address" formControlName="address"></textarea>
                                    <mat-error>Address
                                        <strong>required</strong>
                                    </mat-error>
                                </mat-form-field>
                            </div>

                            <div class="col-lg-12 kt-margin-bottom-20-mobile">
                                <mat-form-field class="mat-form-field-fluid">
                                    <textarea matInput rows="4" placeholder="Location and Surroundings Description" formControlName="surrounding_description"></textarea>
                                    <mat-error>Location and Surroundings Description
                                        <strong>required</strong>
                                    </mat-error>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="form-group row section-margin-top-60 tag-custom-padding-16_25" *ngIf="assetClass.top_property_type_id == propertyTypes.Land">
                            <kt-land-area-list
                                class="col-md-12"
                                [landAreaComponentListSubject]="landAreaComponentListSubject"
                                [landAreaSubject]="landAreaSubject">
                            </kt-land-area-list>
                        </div>
                    </mat-tab>
                    <mat-tab>
                        <!-- START LAND USE -->
                        <div class="form-group row section-margin-top-60 tag-custom-padding-16_25" *ngIf="assetClass.top_property_type_id == propertyTypes.Land">
                            <kt-land-use
                                class="col-md-12"
                                [landUseSubject]="landUseSubject"
                                [buildingsSubject]="buildingsSubject">
                            </kt-land-use>
                        </div>
                        <!-- END LAND USE -->

                        <div class="form-group row section-margin-top-60">
                            <kt-lp-building-tab 
                                *ngIf="buildingsSubject.value.length > 0 && assetClass.top_property_type_id === propertyTypes.Land"
                                class="col-md-12"
                                [(buildingsSubject)]="buildingsSubject">
                            </kt-lp-building-tab>
                        </div>

                        <!-- START SERVICES AND INFRASTRUCTURES -->
                        <div class="form-group row section-margin-top-60 tag-custom-padding-16_25" *ngIf="assetClass.top_property_type_id == propertyTypes.Land">
                            <div class="col-lg-12 kt-margin-bottom-20-mobile">
                                <h5 class="my-3 text-mad text-uppercase">Services and Infrastructures </h5>
                                <hr class="active">
                            </div>
                            <kt-services-and-infrastructures 
                                class="col-md-12"
                                [propertySubTypeID]="assetClass.type_id"
                                [isComplexForm]="true"
                                [serviceAndInfraData]="assetClassDetail.serviceAndInfraData"
                                ></kt-services-and-infrastructures>
                            <br />
                            <!-- <kt-services-infrastructures
                                class="col-md-12"
                                [serviceInfrastructureSubject]="serviceInfrastructureSubject"
                                [selectFacilitiesSubject]="selectFacilitiesSubject">
                            </kt-services-infrastructures> -->
                        </div>
                        <!-- END SERVICES AND INFRASTRUCTURES -->
                    </mat-tab>
                    <mat-tab>
                        <!-- START TENURE & CONSIDERATION -->
                        <div class="form-group row section-margin-top-60 tag-custom-padding-16_25" *ngIf="assetClass.top_property_type_id === propertyTypes.Land">
                            <kt-tenure-consideration-list
                                class="col-md-12"
                                [considerationListSubject]="considerationListSubject"
                                [tenureSubject]="tenureSubject">
                            </kt-tenure-consideration-list>
                            <div class="col-md-12 kt-margin-bottom-20-mobile mt-2">
                                <mat-error 
                                    class="tag-custom-error" 
                                    *ngIf="hasFormErrors && tenureConsiderationListComponent.tenureConsiderationList.length == 0">
                                    At least 1 Consideration <strong>required</strong>
                                </mat-error>
                            </div>
                        </div>
                        <!-- END Tenure And Consideration -->

                    </mat-tab>
                    <mat-tab>
                        <!-- START Picture -->
                        <div class="form-group row section-margin-top-60 tag-custom-padding-16_25">
                            <div class="col-md-12 kt-margin-bottom-20-mobile">
                                <h5 class="my-3 text-mad text-uppercase">{{assetClass.top_property_type_id === propertyTypes.Land ? 'Land Pictures' : 'Property Pictures'}}</h5>
                                <hr class="active">
                            </div>
                            <kt-upload-file
                                    class="col-md-12"
                                    [filesOnEditMode]="assetClassDetail.ac_pictures"
                                    [folderOnEditMode]="assetClassDetail.picture"
                                    [readOnly]="readonly"
                                    [typeField]="false"
                                    [featuredPictureAllowed]="true"
                                    [dropDownValues$]="conditionRatingDropdownValues$"
                                    [dropDownRelated$]="conditionRatingRelated$"
                                    fileType="image/*"
                                    parentForm="apartment"
                                    split="1"
                                    [hasThumbnail]="true"
                                    [addButtonLabel]="assetClass.top_property_type_id == propertyTypes.Land ? 'Add Land Pictures' : 'Add Property Pictures'">
                            </kt-upload-file>
                            <div class="col-md-12 kt-margin-bottom-20-mobile">
                                <mat-error class="tag-custom-error" *ngIf="hasFormErrors && uploadFileComponent.uploadFiles.length == 0">
                                    At least 1 Picture <strong>required</strong>
                                </mat-error>
                                <mat-error class="tag-custom-error" *ngIf="hasFormErrors && showTitleRequiredError$|async">
                                    Picture should have a <strong>Title</strong>
                                </mat-error>
                            </div>
                        </div>
                        <!-- END Picture -->

                        <!-- START Building section -->

                        <div class="form-group row section-margin-top-60" *ngIf="buildingsSubject.value.length > 0 && assetClass.top_property_type_id === propertyTypes.Land">
                            <div class="col-md-12 kt-margin-bottom-20-mobile">
                                <h5 class="my-3 text-mad text-uppercase">Building Pictures</h5>
                                <hr class="active">
                            </div>
                            <kt-upload-file #lpUploadFile
                                    class="col-md-12"
                                    [(filesOnEditMode)]="buildingSubject.value.files"
                                    [readOnly]="false"
                                    [folderOnEditMode]="buildingSubject.value.path"
                                    fileType="image/*"
                                    parentForm="building"
                                    split="2"
                                    addButtonLabel="Add building pictures"
                                    [featuredPictureAllowed]="true"
                                    [buildingField]="true"
                                    [buildingsSubject]="buildingsSubject">
                            </kt-upload-file>
                            <div class="col-md-12 kt-margin-bottom-20-mobile">
                                <mat-error 
                                    class="tag-custom-error" 
                                    *ngIf="hasFormErrors && uploadFileComponent.uploadFiles.length == 0">
                                    At least 1 Picture <strong>required</strong>
                                </mat-error>
                                <mat-error 
                                    class="tag-custom-error" 
                                    *ngIf="hasFormErrors && showTitleRequiredError$|async">
                                    Picture should have a <strong>Title</strong>
                                </mat-error>
                            </div>
                        </div>

                        <!-- START Video -->
                        <div class="form-group row section-margin-top-60 tag-custom-padding-16_25">
                            <div class="col-md-12 kt-margin-bottom-20-mobile">
                                <h5 class="my-3 text-mad text-uppercase">Property Videos</h5>
                                <hr class="active">
                            </div>
                            <app-upload
                                class="col-md-12"
                                [videos]="assetClassDetail.videos"
                                [readonly]="readonly"></app-upload>
                            <div class="col-md-12 kt-margin-bottom-20-mobile">
                                <mat-error class="tag-custom-error" *ngIf="hasFormErrors && videoTitleRequiredError$|async">
                                    Video should have a <strong>Title</strong>
                                </mat-error>
                            </div>
                        </div>
                    </mat-tab>
                </mat-tab-group>
            </div>
        </form>
    </kt-portlet-body>
</kt-portlet>